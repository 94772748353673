import { useCallback, useState } from "react"
import { useParams } from "react-router"

import Table from "../../components/Table"

import useMissions from "../../hooks/useMissions"
import {
    Convert,
    RowId,
    TableColumn,
    TableOption,
    TableOptionValue,
    TableVariant,
    ToolArray,
    ToolBase,
    ToolDivider,
} from "../../models/Table"
import { Types } from "../../store/types"
import { FlightlineEventType, PlannedLineId } from "../../store/types/missionsTypes"
import { Edit, Gavel, VisibilityOff } from "@mui/icons-material"
import Autocomplete from "../../components/Fields/Autocomplete"

export const unplannedLineLabel = "UL"
export const unplannedLineValue = -1
export const unplannedLineOption = { label: unplannedLineLabel, value: unplannedLineValue }
export const flightLineEventId: keyof FlightlineEventType = "flight_line_event_id"

export enum FlightlineStatusOption {
    UNDER_REVIEW = "Under Review",
    ACCEPTED = "Accepted",
    REFLY_REQUIRED = "Refly Required",
    IGNORED = "Ignored",
}

export const statusOptions: string[] = Object.values(FlightlineStatusOption)

interface FlightlineTableProps {
    handleFlightLineCreator: (line: FlightlineEventType) => void
}

function FlightlineTable({ handleFlightLineCreator }: FlightlineTableProps) {
    const { missionFlow } = useParams<{ [key: string]: string }>()
    const {
        missions,
        isNoFile,
        dispatch,
        handleFlightlineEventChange,
        flightPlannedLineLabelOptions,
    } = useMissions()
    const { flightlineEvents } = missions

    const isSubmitFlow = missionFlow === "submit"

    const [filterIgnored, setFilterIgnored] = useState(false)
    const filteredRows = filterIgnored ? flightlineEvents.filter((fl) => fl.flight_status !== "Ignored") : flightlineEvents

    const handlePlannedLineChange = (rowIndex: number, newValue: TableOption | null) => {
        const planned_line_id: PlannedLineId = newValue?.value as PlannedLineId ?? null

        const row: FlightlineEventType = {
            ...flightlineEvents[rowIndex],
            planned_line_id,
        }

        handleFlightlineEventChange([...flightlineEvents.slice(0, rowIndex), row, ...flightlineEvents.slice(rowIndex + 1)])
    }

    const stickyColumns: TableColumn[] = [
        {
            id: "planned_line_id",
            label: "Flight Plan - Name",
            variant: TableVariant.custom,
            renderCell: (rowId, value) => {
                const rowIndex = flightlineEvents.findIndex(event => event.flight_line_event_id === rowId)
                const curVal = flightPlannedLineLabelOptions.find(opt => opt.value === value as TableOptionValue) ?? null
                const error = curVal == null
                return <Autocomplete
                    size="small"
                    options={flightPlannedLineLabelOptions}
                    disableGutter
                    sx={{ minWidth: 200 }}
                    value={curVal}
                    getOptionLabel={opt => opt.label}
                    isOptionEqualToValue={(opt, val) => opt.value === val.value}
                    onChange={(e, tableOption) => handlePlannedLineChange(rowIndex, tableOption)}
                    TextFieldProps={{ error }}
                />
            },
        },
    ]

    const columns: TableColumn[] = [
        { id: "line_original_name", label: "Original\u00a0Name", minWidth: 100, maxWidth: 167 },
        { id: "coll_event_name", label: "Sensor\u00a0Label", minWidth: 220 },
        {
            id: "flight_status",
            label: "Status",
            minWidth: 180,
            variant: TableVariant.select,
            options: statusOptions,
            cellError: rowId => {
                const row = flightlineEvents.find(event => event.flight_line_event_id === rowId)
                return row?.planned_line_id === unplannedLineValue && row.flight_status !== "Ignored"
            },
        },
        { id: "notes", label: "Notes", minWidth: 220, variant: TableVariant.input },
        { id: "flight_line_start_time", label: "Start", minWidth: 90, align: "right", variant: TableVariant.time },
        { id: "flight_line_stop_time", label: "End", minWidth: 90, align: "right", variant: TableVariant.time },
        {
            id: "flight_line_distance",
            label: "Dist.\u00a0",
            align: "right",
            minWidth: 90,
            convertType: Convert.DISTANCE,
        },
        { id: "flight_line_duration", label: "Dur.\u00a0(s)", align: "right", minWidth: 90 },
        {
            id: "flown_agl",
            label: "AGL.\u00a0",
            align: "right",
            minWidth: 90,
            convertType: Convert.HEIGHT,
        },
    ]

    const handleIgnored = useCallback((ignore: any) => {
        setFilterIgnored(ignore)
    }, [])

    const getIndexesByIds = (ids: RowId[]) => {
        return flightlineEvents.reduce((arr: number[], fl, index: number) => {
            return (fl[flightLineEventId] && ids.includes(fl[flightLineEventId])) ? [...arr, index] : arr
        }, [])
    }

    const handleMenuChange = useCallback(
        (status: string, selectedIds: any[]) => {
            dispatch({
                type: Types.Missions_UpdateFlightlineEventStatuses,
                payload: { status, indexes: getIndexesByIds(selectedIds) },
            })
        },
        [dispatch, flightlineEvents], // eslint-disable-line
    )

    const setEditCollectionEvent = (id: RowId) => {
        const foundLine = filteredRows.find(row => row[flightLineEventId] === id)
        if (foundLine) {
            handleFlightLineCreator(foundLine)
        }
    }

    const overlay: ToolBase[] = [
        {
            label: "Manage Flightline",
            Icon: Edit,
            onChange: ({ selected }) => setEditCollectionEvent(selected[0]),
        },
    ]

    const tools: ToolArray = [
        {
            label: "Filter Ignored Lines",
            Icon: VisibilityOff,
            onChange: handleIgnored,
        },
        {
            label: "Change Status of Selected",
            Icon: Gavel,
            onChange: ({ option, selected }) => option && handleMenuChange(option, selected),
            options: statusOptions,
            enableOnSelect: true,
        },
        ToolDivider,
        {
            label: "Edit Line",
            Icon: Edit,
            onChange: ({ selected }) => setEditCollectionEvent(selected[0]),
            enableOnSingleSelect: true,
        },
    ]

    return (
        <Table
            label="Flightline Events"
            primaryKey={flightLineEventId}
            columns={columns}
            stickyColumns={stickyColumns}
            rows={filteredRows}
            tools={tools}
            updateTableRows={handleFlightlineEventChange}
            canSelect
            canDeleteSelected={isSubmitFlow || !isNoFile}
            footerText={`Flight Plan: (${unplannedLineLabel}) Unplanned Line`}
            selectedRowOverlay={overlay}
        />
    )
}

export default FlightlineTable
