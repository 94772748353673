import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()({
    menu_item: {
        "&.Mui-disabled": {
            pointerEvents: "auto",
        },
    },
})

export default useStyles
