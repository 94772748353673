export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key
        }
        : {
            type: Key
            payload: M[Key]
        }
}

export enum Types {
    App_LogoutUser = "APP__LOGOUT_USER",
    App_FetchUser = "APP__FETCH_USER",
    App_FetchUserSuccess = "APP__FETCH_USER_SUCCESS",
    App_FetchUserFailed = "APP__FETCH_USER_FAILED",
    App_FetchDBVersion = "APP__FETCH_DB_VERSION",
    App_FetchDBVersionSuccess = "APP__FETCH_DB_VERSION_SUCCESS",
    App_FetchDBVersionError = "APP__FETCH_DB_VERSION_ERROR",
    App_FetchMasterLog = "APP__FETCH_MASTER_LOG",
    App_FetchMasterLogSuccess = "APP__FETCH_MASTER_LOG_SUCCESS",
    App_FetchMasterLogError = "APP__FETCH_MASTER_LOG_ERROR",
    App_UpdateConfirmationData = "APP__UPDATE_CONFIRMATION_DATA",
    App_ResetConfirmationData = "APP__RESET_CONFIRMATION_DATA",
    App_UpdateError = "APP__UPDATE_ERROR",
    App_ClearError = "APP__CLEAR_ERROR",

    Projects_FetchProject = "PROJECTS__FETCH_PROJECT",
    Projects_FetchProjectSuccess = "PROJECTS__FETCH_PROJECT_SUCCESS",
    Projects_FetchProjectError = "PROJECTS__FETCH_PROJECT_ERROR",
    Projects_FetchProjectData = "PROJECTS__FETCH_PROJECT_DATA",
    Projects_FetchProjectDataSuccess = "PROJECTS__FETCH_PROJECT_DATA_SUCCESS",
    Projects_FetchProjectDataError = "PROJECTS__FETCH_PROJECT_DATA_ERROR",
    Projects_SelectProject = "PROJECTS__SELECT_PROJECT",
    Projects_ResetProjectData = "PROJECTS__RESET_PROJECT_DATA",
    Projects_ResetAOIList = "PROJECTS__RESET_AOI_LIST",
    Projects_ResetAOIData = "PROJECTS__RESET_AOI_DATA",
    Projects_SelectEditAOI = "PROJECTS__SELECT_EDIT_AOI",
    Projects_SelectEditAOISuccess = "PROJECTS__SELECT_EDIT_AOI_SUCCESS",
    Projects_SelectEditAOIError = "PROJECTS__SELECT_EDIT_AOI_EDIT",
    Projects_SelectSubmitAOI = "PROJECTS__SELECT_SUBMIT_AOI",
    Projects_TogglePolygonRepairs = "PROJECTS__TOGGLE_POLYGON_REPAIRS",
    Projects_UpdateProjectDetailsData = "PROJECTS__UPDATE_PROJECT_DETAILS_DATA",
    Projects_UpdateAOIData = "PROJECTS__UPDATE_AOI_DATA",
    Projects_DeleteAOI = "PROJECTS__DELETE_AOI",
    Projects_DeleteAOISuccess = "PROJECTS__DELETE_AOI_SUCCESS",
    Projects_DeleteAOIError = "PROJECTS__DELETE_AOI_ERROR",
    Projects_ParseFile = "PROJECTS__PARSE_FILE",
    Projects_ParseFileSuccess = "PROJECTS__PARSE_FILE_SUCCESS",
    Projects_ParseFileError = "PROJECTS__PARSE_FILE_ERROR",
    Projects_FetchProjectList = "PROJECTS__FETCH_PROJECT_LIST",
    Projects_FetchProjectListSuccess = "PROJECTS__FETCH_PROJECT_LIST_SUCCESS",
    Projects_FetchNonAcquisitionProjectList = "PROJECTS__FETCH_NON_ACQUISITION_PROJECT_LIST",
    Projects_FetchNonAcquisitionProjectListSuccess = "PROJECTS__FETCH_NON_ACQUISITION_PROJECT_LIST_SUCCESS",
    Projects_FetchAOIList = "PROJECTS__FETCH_AOI_LIST",
    Projects_FetchAOIListSuccess = "PROJECTS__FETCH_AOI_LIST_SUCCESS",
    Projects_FetchAOIListError = "PROJECTS__FETCH_AOI_LIST_ERROR",
    Projects_FetchNonAcquisitionProject = "PROJECTS__FETCH_NON_ACQUISITION PROJECT",
    Projects_FetchNonAcquisitionProjectSuccess = "PROJECTS__FETCH_NON_ACQUISITION PROJECT_SUCCESS",
    Projects_InitializeAcquistionProject = "PROJECTS__INITIALIZE_ACQUISITION_PROJECT",
    Projects_InitializeAcquistionProjectSuccess = "PROJECTS__INITIALIZE_ACQUISITION_PROJECT_SUCCESS",

    Missions_ResetState = "MISSIONS__RESET_STATE",
    Missions_SelectParsedMission = "MISSIONS__SELECT_PARSED_MISSION",
    Missions_SelectMissionId = "MISSIONS__SELECT_MISSION_ID",
    Missions_SelectFlightplans = "MISSIONS__SELECT_FLIGHT_PLANS",
    Missions_SelectFlightplanList = "MISSIONS__SELECT_FLIGHT_PLANS_LIST",
    Missions_SelectActiveFlow = "MISSIONS__SELECT_ACTIVE_FLOW",
    Missions_ImportFlightplannedLines = "MISSIONS__IMPORT_FLIGHT_PLANNED_LINES",
    Missions_UpdateMissionData = "MISSIONS__UPDATE_MISSION_DATA",
    Missions_UpdateMissionShippingInfoData = "MISSIONS__UPDATE_MISSION_SHIPPING_INFO_DATA",
    Missions_UpdateFlightPlanData = "MISSIONS__UPDATE_FLIGHTPLAN_DATA",
    Missions_UpdateMissionReviewData = "MISSIONS__UPDATE_MISSION_REVIEW_DATA",
    Missions_UpdateFlightlineEventData = "MISSIONS__UPDATE_FLIGHTLINE_EVENT_DATA",
    Missions_UpdateFlightlineEventNotes = "MISSIONS__UPDATE_FLIGHTLINE_EVENT_NOTES",
    Missions_UpdateFlightlineEventStatuses = "MISSIONS__UPDATE_FLIGHTLINE_EVENT_STATUSES",
    Missions_UpdateMatchingExpressionData = "MISSIONS__UPDATE_MATCHING_EXPRESSION_DATA",
    Missions_UpdateIsVendorFlow = "MISSIONS__UPDATE_IS_VENDOR_FLOW",
    Missions_DeleteMission = "MISSIONS__DELETE_MISSION",
    Missions_DeleteMissionSuccess = "MISSIONS__DELETE_MISSION_SUCCESS",
    Missions_DeleteMissionError = "MISSIONS__DELETE_MISSION_ERROR",
    Missions_FetchParsedMissions = "MISSIONS__FETCH_PARSED_MISSIONS",
    Missions_FetchParsedMissionsSuccess = "MISSIONS__FETCH_PARSED_MISSIONS_SUCCESS",
    Missions_FetchMissionNameList = "MISSIONS__FETCH_MISSION_NAME_LIST",
    Missions_FetchMissionNameListSuccess = "MISSIONS__FETCH_MISSION_NAME_LIST_SUCCESS",
    Missions_FetchMission = "MISSIONS__FETCH_MISSION",
    Missions_FetchMissionSuccess = "MISSIONS__FETCH_MISSION_SUCCESS",
    Missions_FetchFlightlineEvents = "MISSIONS__FETCH_FLIGHTLINE_EVENTS",
    Missions_FetchFlightlineEventsSuccess = "MISSIONS__FETCH_FLIGHTLINE_EVENTS_SUCCESS",
    Missions_FetchFlightplannedLines = "MISSIONS__FETCH_FLIGHTPLANNED_LINES",
    Missions_FetchFlightplannedLinesSuccess = "MISSIONS__FETCH_FLIGHTPLANNED_LINES_SUCCESS",
    Missions_FetchEmployeeList = "MISSIONS__FETCH_EMPLOYEE_LIST",
    Missions_FetchEmployeeListSuccess = "MISSIONS__FETCH_EMPLOYEE_LIST_SUCCESS",
    Missions_FetchVendorNameList = "MISSIONS__FETCH_VENDOR_NAME_LIST",
    Missions_FetchVendorNameListSuccess = "MISSIONS__FETCH_VENDOR_NAME_LIST_SUCCESS",
    Missions_FetchFlightplanList = "MISSIONS__FETCH_FLIGHTPLAN_LIST",
    Missions_FetchFlightplanListSuccess = "MISSIONS__FETCH_FLIGHTPLAN_LIST_SUCCESS",
    Missions_FetchSensorList = "MISSIONS__FETCH_SENSOR_LIST",
    Missions_FetchSensorListSuccess = "MISSIONS__FETCH_SENSOR_LIST_SUCCESS",
    Missions_FetchPlatformList = "MISSIONS__FETCH_PLATFORM_LIST",
    Missions_FetchPlatformListSuccess = "MISSIONS__FETCH_PLATFORM_LIST_SUCCESS",
    Missions_FetchAirportList = "MISSIONS__FETCH_AIRPORT_LIST",
    Missions_FetchAirportListSuccess = "MISSIONS__FETCH_AIRPORT_LIST_SUCCESS",
    Missions_ParseSensorFile = "MISSIONS__PARSE_SENSOR_FILE",
    Missions_ParseSensorFileSuccess = "MISSIONS__PARSE_SENSOR_FILE_SUCCESS",
    Missions_UpdateError = "MISSIONS__UPDATE_ERROR",
    Missions_ClearError = "MISSIONS__CLEAR_ERROR",
    Missions_ClearImportError = "MISSIONS__CLEAR_IMPORT_ERROR",

    Flightplans_ResetState = "FLIGHTPLANS__RESET_STATE",
    Flightplans_SelectFlightplan = "FLIGHTPLANS__SELECT_FLIGHTPLAN",
    Flightplans_SelectFlightplanSuccess = "FLIGHTPLANS__SELECT_FLIGHTPLAN_SUCCESS",
    Flightplans_UpdateFlightplanData = "FLIGHTPLANS__UPDATE_FLIGHTPLAN_DATA",
    Flightplans_UpdateFlightPlannedLines = "FLIGHTPLANS__UPDATE_FLIGHTPLANNED_LINES",
    Flightplans_FetchFlightplanList = "FLIGHTPLANS__FETCH_FLIGHTPLAN_LIST",
    Flightplans_FetchFlightplanListSuccess = "FLIGHTPLANS__FETCH_FLIGHTPLAN_LIST_SUCCESS",
    Flightplans_FetchFlightplannedLinesList = "FLIGHTPLANS__FETCH_FLIGHTPLANNED_LINES_LIST",
    Flightplans_FetchFlightplannedLinesListSuccess = "FLIGHTPLANS__FETCH_FLIGHTPLANNED_LINES_LIST_SUCCESS",
    Flightplans_ParseFile = "FLIGHTPLANS__PARSE_FILE",
    Flightplans_ParseFileSuccess = "FLIGHTPLANS__PARSE_FILE_SUCCESS",
    Flightplans_DeleteFlightplan = "FLIGHTPLANS__DELETE_FLIGHTPLAN",
    Flightplans_DeleteFlightplanSuccess = "FLIGHTPLANS__DELETE_FLIGHTPLAN_SUCCESS",
    Flightplans_DeleteFlightplanError = "FLIGHTPLANS__DELETE_FLIGHTPLAN_ERROR",

    Lookup_ResetState = "LOOKUP__RESET_STATE",
    Lookup_SelectAction = "LOOKUP__SELECT_ACTION",
    Lookup_UpdateRowData = "LOOKUP__UPDATE_ROW_DATA",
    Lookup_UpdateDataSuccess = "LOOKUP__UPDATE_DATA_SUCCESS",
    Lookup_DeleteTableRow = "LOOKUP_DELETE_TABLE_ROW",
    Lookup_FetchTable = "LOOKUP__SELECT_TABLE",
    Lookup_FetchTableSuccess = "LOOKUP__SELECT_TABLE_SUCCESS",
}
