import { DataGrid } from "@mui/x-data-grid"
import LoadingButton from "../LoadingButton"
import useStyles from "./styles"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material"
import { AlertModalProps }  from "./AlertModal"
import { OptionsType } from "../../store/types/missionsTypes"
import React from "react"

// @Todo export all interfaces to reduce prop duplication  
interface DataGridModalProps extends AlertModalProps {
    columns: any[]
    rows: OptionsType[]
    selected: number[]
    onSelectionChange: (x: any) => void
    additionalActions?: React.ReactNode
    isRowSelectableFunct?: (params: any) => boolean
}

function DataGridModal({
    children,
    confirmText,
    danger,
    title,
    onClose,
    onConfirm,
    open,
    pending,
    columns,
    rows,
    selected,
    onSelectionChange,
    additionalActions,
    isRowSelectableFunct,
}: DataGridModalProps) {
    const { classes, cx } = useStyles()

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            {children && (
                <DialogContent>
                    <DialogContentText>{children}</DialogContentText>
                    <Grid>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={ [5, 10, 25] }
                            checkboxSelection
                            rowSelectionModel={selected}
                            isRowSelectable={isRowSelectableFunct}
                            sx={{ height: 450 }}
                            //@Todo update with generic params
                            onRowSelectionModelChange={onSelectionChange}
                        />
                    </Grid>
                </DialogContent>
            )}
            <DialogActions>
                {additionalActions}
                <LoadingButton
                    pending={pending}
                    color="primary"
                    className={cx({ [classes.btn_delete]: danger })}
                    variant="outlined"
                    onClick={onConfirm}
                >
                    {confirmText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default DataGridModal
