import { useEffect, useContext } from "react"
import { useLocation } from "react-router"

import { CssBaseline } from "@mui/material"

import Header from "../../components/Header"
import Loading from "../../pages/Loading"

import { fetchDatabaseVersion } from "../../store/actions/appActions"
import { RootContext } from "../../store/context"
import { useQuery } from "../../hooks/useQuery"
import useAuth from "../../hooks/useAuth"
import { setLocalStorageItem } from "../../tools"
import useStyles from "./styles"
import Config from "../../config"

export default function Layout({ children }: any) {
    const { classes } = useStyles()
    const query = useQuery()
    const queryToken = query.get("token")
    const location = useLocation()
    const { state, dispatch } = useContext(RootContext)
    const { databaseName, databaseVersion, userMgmtUrl, databaseError } = state.app
    const {
        isLoggedIn,
        permissions,
        userLoading,
        loginError,
        goToLandingScreen,
        goToUserMgmtLoginScreen,
        getUser,
        checkRouteAccess,
        handleLogout,
    } = useAuth(true)

    /**
     * Fetch database info on app start.
     *
     * The Production server will use a different url than Develop and
     * Test, so it needs to be dynamic. The url is used on the frontend
     * and backend, so retrieving the url from the backend makes it so
     * there is one source of truth.
     */
    useEffect(() => {
        if (!databaseName || !databaseVersion || !userMgmtUrl) {
            fetchDatabaseVersion(dispatch)
        }
    }, []) // eslint-disable-line

    /**
     * Check auth token on app start after fetchDatabaseVersion completes.
     *
     * Scenarios:
     * 1. Token exists as query param:  set token and fetch user data
     *                                  (redirected from user mgmt w/ token)
     * 2. Token exists locally:         fetch user data
     * 3. Token doesn't exist:          redirect to user mgmt
     */
    useEffect(() => {
        if (userMgmtUrl) {
            if (queryToken) {
                setLocalStorageItem({ auth_token: queryToken })
                getUser()
                goToLandingScreen()
            } else if (localStorage.getItem("auth_token")) {
                Config.checkTokenExpire()
                getUser()
            } else {
                goToUserMgmtLoginScreen()
            }
        }
    }, [userMgmtUrl]) // eslint-disable-line

    /**
     * Redirect to landing if user doesn't have permission for current
     * route.
     */
    useEffect(() => {
        checkRouteAccess()
    }, [location, permissions, checkRouteAccess])

    const renderedError = loginError || databaseError

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header />
            {userLoading || renderedError || !isLoggedIn ? (
                <Loading
                    label={renderedError ? "Authentication Failed" : "Fetching user..."}
                    body={renderedError}
                    button={loginError && "Logout"}
                    onButtonClick={loginError && handleLogout}
                />
            ) : (
                children
            )}
        </div>
    )
}
