import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    aoi_select: {
        display: "inline-block",
    },
    number_field: {
        width: "100%",
        maxWidth: 156,
    },
    dropzone_container: {},
    cancel_btn: {
        marginLeft: theme.spacing(2),
    },
}))

export default useStyles
