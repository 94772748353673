import { makeStyles } from "tss-react/mui"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...spacing(theme),

    label: {
        position: "relative",

        "&:before": {
            position: "absolute",
            content: "''",
            bottom: -14,
            left: -8,
            right: -8,
            borderRadius: 4,
            height: 4,
            background: "rgba(0,0,0,0.48)",
            opacity: 0,

            transition: "opacity 0.2s",
        },
    },
    label__active: {
        "&:before": {
            opacity: 1,
        },
    },
}))

export default useStyles
