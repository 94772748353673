import { useCallback, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { DispatchNexus, RootContext } from "../store/context"

import { Types } from "../store/types"
import { deleteAOI, fetchAoiList, fetchProjectData } from "../store/actions/projectsActions"
import { AOINameType, AoiShape, ProjectInfoShort, ProjectType } from "../store/types/projectsTypes"
import { ModeShape } from "../models/AcquisitionMode"

const useProjects = (load = false): {
    cache: { selectedProjectData: ProjectType | null, selectedAOIData: AoiShape }
    dispatch: DispatchNexus
    selectedAOI: AOINameType | null
    isSubmitAOIFlow: boolean
    doPolygonRepairs: boolean
    handleProjectDetailsChange: (value: (string | null), field: string) => void
    parsingAOI: boolean
    aoiList: AOINameType[]
    handleAOIDataChange: (value: (string | number | null | ModeShape[]), field: string) => void
    handleAOIModesChange: (aoi_mode: ModeShape[], modeIds: number[]) => void
    loading: boolean
    loadingAOI: boolean
    selectedAOIData: AoiShape
    loadingAOIList: boolean
    deletingAOI: boolean
    loadingProject: boolean
    selectedProjectData: ProjectType | null
    isEditAOIFlow: boolean
    selectedProject: ProjectInfoShort | null
    handleAOIDelete: () => Promise<void>
    isAOIOpen: boolean
    handleDataFetch: () => void
} => {
    const { projectNumber } = useParams<{ [key: string]: string }>()
    const { state, dispatch } = useContext(RootContext)
    const {
        selectedProject,
        selectedProjectData,
        selectedAOI,
        selectedAOIData,
        isSubmitAOIFlow,
        isEditAOIFlow,
        doPolygonRepairs,
        aoiList,
        loadingProject,
        loadingAOI,
        loadingAOIList,
        parsingAOI,
        deletingAOI,
        cache,
    } = state.projects

    const [loading, setLoading] = useState(false)

    const isAOIOpen = Boolean(isSubmitAOIFlow || isEditAOIFlow)

    /**
     * Load project data on init if load === true.
     * Hook tracks and returns loading status.
     */
    useEffect(() => {
        if (load) {
            handleDataFetch()
        }
    }, []) // eslint-disable-line

    const handleDataFetch = () => {
        const reqs = []
        dispatch({ type: Types.App_ClearError })
        setLoading(true)

        reqs.push(fetchProjectData(dispatch, projectNumber!))

        Promise.all(reqs).then(() => {
            setLoading(false)
        })
    }

    const handleProjectDetailsChange = useCallback((value: string | null, field: string) => {
        dispatch({
            type: Types.Projects_UpdateProjectDetailsData,
            payload: { [field]: value },
        })
    }, []) // eslint-disable-line

    const handleAOIDataChange = useCallback((value: string | number | null | ModeShape[], field: string) => {
        dispatch({ type: Types.Projects_UpdateAOIData, payload: { [field]: value } })
    }, []) // eslint-disable-line

    const handleAOIModesChange = useCallback((aoi_mode: ModeShape[], mode_ids: number[]) => {
        dispatch({ type: Types.Projects_UpdateAOIData, payload: { aoi_mode, mode_ids } })
    }, []) // eslint-disable-line

    const handleAOIDelete = useCallback(async () => {
        const success = await deleteAOI(dispatch, selectedAOIData?.aoi_id)
        if (success) {
            dispatch({ type: Types.Projects_ResetAOIList })
            await fetchAoiList(dispatch, projectNumber)
        }
    }, [selectedAOIData]) // eslint-disable-line

    return {
        loading,
        selectedProject,
        selectedProjectData,
        selectedAOI,
        selectedAOIData,
        isAOIOpen,
        isSubmitAOIFlow,
        isEditAOIFlow,
        doPolygonRepairs,
        aoiList,
        loadingProject,
        loadingAOI,
        loadingAOIList,
        parsingAOI,
        deletingAOI,
        cache,
        handleDataFetch,
        handleProjectDetailsChange,
        handleAOIDataChange,
        handleAOIModesChange,
        handleAOIDelete,
        dispatch,
    }
}

export default useProjects
