import { SvgIconComponent } from "@mui/icons-material"

export enum Convert {
    HEIGHT = "HEIGHT",
    DISTANCE = "DISTANCE",
}

type ToolFilter = Record<string, { enabled: boolean }>

export interface ToolBase {
    label: string
    Icon: SvgIconComponent
    onChange: (props: { selected: RowId[], bool?: boolean, option?: string }) => void
}

export type onSelectRowOverlay = ToolBase[] | false

export interface ToolShape extends ToolBase {
    options?: string[]
    toolFilter?: ToolFilter
    enabled?: boolean
    enableOnSelect?: boolean
    enableOnSingleSelect?: boolean
}

export const ToolDivider = "divider"

export type ToolArray = (ToolShape | typeof ToolDivider)[]

export enum TableVariant {
    input = "input",
    time = "time",
    select = "select",
    autocomplete = "autocomplete",
    custom = "custom",
}

export type TableOptionValue = string | number | null
export type TableOption = { label: string, value: TableOptionValue }

interface TableColumnBase {
    variant?: TableVariant.time | TableVariant.input
    id: string
    label: string
    disableSort?: boolean
    align?: string
    minWidth?: number
    maxWidth?: number
    width?: number | string
    show?: boolean
    primary?: boolean
    convertType?: Convert
    inputAlign?: string
    defaultDash?: boolean
    required?: boolean
    disableRowWhen?: <T>(row: T) => boolean
    cellError?: (rowId: RowId) => boolean
}

type ExtendTableColumnBase = Omit<TableColumnBase, "variant">

interface TableColumnWithOptions extends ExtendTableColumnBase {
    variant: TableVariant.select | TableVariant.autocomplete
    options: string[]
}

interface TableColumnCustom extends ExtendTableColumnBase {
    variant: TableVariant.custom
    renderCell: (row: RowId, value: unknown) => JSX.Element
}

export type TableColumn = TableColumnBase | TableColumnWithOptions | TableColumnCustom

export type RowId = string | number

export interface TableRowType {
    selectable: boolean
    show: boolean
}

export type UpdateTableRows<T> = (updatedRows: T[], selectedRowsChanged?: T[]) => void

export type HandleChange = <V>(value: V, columnId: string, rowId: RowId) => void

export type HandleSelect = (shiftClick: boolean, rowId?: RowId) => void

export type HandleDeleteRow = false | ((rowId: RowId) => void)

export type HandlePaste = (event: any, rowId: RowId, colId: string) => void

export type GetTableRowId<T> = (row: T) => RowId
