import React, { createContext, useReducer, Dispatch } from "react"

import { InitialStateType, mainReducer } from "./reducers"
import { AppActions } from "./types/appTypes"
import { FlightplansActions } from "./types/flightplansTypes"
import { MissionsActions, MissionType, templates } from "./types/missionsTypes"
import { LookupActions } from "./types/lookupTypes"
import { AoiShape, ProjectsActions } from "./types/projectsTypes"

export const initialSelectedMission: MissionType = {
    channel_name: null,
    mission_id: null,
    mission_name: null,
    flight_num: 1,
    sensor_id_1: null,
    sensor_id_2: null,
    platform_id: null,
    flight_plan_id: null,
    project_number: null,
    flown_date: "",
    start_time: null,
    stop_time: null,
    duration: null,
    start_hobbs: null,
    stop_hobbs: null,
    active_hobbs: 0,
    mob_hobbs: null,
    operator_1_id: null,
    operator_2_id: null,
    pilot_1_id: null,
    pilot_2_id: null,
    base_airport: null,
    manager_id: null,
    flight_notes: null,
    error_notes: null,
    error_action: null,
    tomorrow_notes: null,
    flight_blog: null,
    source_file: null,
    mission_shipping_info: null,
    mission_review: null,
    flight_plan: [],
    vendor_name: null,
    display_name: "",
    flightline_events: [],
    serial_number_1: "",
    serial_number_2: "",
}

const initialAOIData: AoiShape = {
    aoi_mode: [],
    mode_ids: [],
}

export const initialState: InitialStateType = {
    app: {
        userLoading: true,
        userMgmtUrl: "",
        user: {
            auth_token: "",
            avatar: null,
            clients: [],
            created_at: null,
            default_extent: [],
            default_user_groups: [],
            email: "",
            id: null,
            is_active: false,
            last_login: null,
            name: "",
            organisation: "",
            phone: "",
            policy: "",
            role: "",
            scopes: [],
            user_groups: [],
        },
        permissions: null,
        databaseLoading: false,
        databaseName: null,
        databaseVersion: null,
        databaseError: "",
        masterLogLoading: false,
        confirmation: {
            entityName: null,
        },
        // TODO: break into specific errors
        loginError: "",
        error: "",
    },
    flightplans: {
        warnings: [],
        selectedFlightplan: {
            flight_plan_id: null,
            flight_plan_name: null,
            project_number: null,
            status: null,
            source_file: null,
        },
        selectedFlightplanId: null,
        flightplanList: [],
        flightPlannedLines: [],
        deletedPlannedLineIds: [],
        isDeleting: false,
    },
    missions: {
        warnings: [],
        missionNameList: [],
        selectedMission: initialSelectedMission,
        selectedFlightplanIds: [],
        selectedMissionId: null,
        selectedFlightplans: [],
        parsedMissions: [],
        flightlineEvents: [],
        flightPlannedLines: [],
        importExpression: {
            value: "",
            error: "",
        },
        matchingExpression: {
            active: templates[0].value,
            custom: templates[0].value,
            selectedTemplate: templates[0],
            isCustom: false,
        },
        activeFlow: null,
        isVendorFlow: false,
        isDeleting: false,
        employeeList: {
            employees: [],
            project_employees: {
                pilots: [],
                operators: [],
                managers: [],
                reviewers: [],
            },
            nexus_employees: {
                pilots: [],
                operators: [],
                managers: [],
                reviewers: [],
            },
        },
        vendorNameList: [],
        flightplanList: [],
        sensorList: {
            sensors: [],
            project_sensors: [],
        },
        platformList: {
            platforms: [],
            project_platforms: [],
        },
        airportList: [],
        missionsError: null,
        loadingMissionNameList: false,
        loadingFlightplanList: false,
        loadingFlightPlannedList: false,
    },
    lookup: {
        selectedTableId: "platform",
        selectedActionId: "edit",
        rows: [],
        columns: [],
        loadingRows: false,
        cache: {
            initialRows: [],
        },
    },
    projects: {
        projectList: [],
        selectedProject: null,
        selectedProjectData: null,
        selectedAOI: null,
        selectedAOIData: { ...initialAOIData },
        isEditAOIFlow: false,
        isSubmitAOIFlow: false,
        doPolygonRepairs: false,
        nonAcqProjectList: [],
        selectedNonAcqProject: {},
        aoiList: [],
        loadingProjectList: false,
        loadingNonAcqProjectList: false,
        loadingAOIList: false,
        loadingProject: false,
        loadingNonAcqProject: false,
        loadingAOI: false,
        parsingAOI: false,
        deletingAOI: false,
        cache: {
            selectedProjectData: null,
            selectedAOIData: { ...initialAOIData },
        },
    },
}

export type DispatchNexus = Dispatch<AppActions | FlightplansActions | MissionsActions | LookupActions | ProjectsActions>

const RootContext = createContext<{
    state: InitialStateType
    dispatch: DispatchNexus
}>({
    state: initialState,
    dispatch: () => null,
})

const RootProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(mainReducer, initialState)

    return <RootContext.Provider value={{ state, dispatch }}>{children}</RootContext.Provider>
}

export { RootProvider, RootContext }
