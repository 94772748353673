import { useEffect, useState } from "react"

import AOITab from "./AOITab"
import ProjectTab from "./ProjectTab"

import ProjectTitle from "../../components/ProjectTitle"
import StepperForm from "../../components/StepperForm"

import { isDiff } from "../../tools"
import useProjects from "../../hooks/useProjects"
import { Types } from "../../store/types"
import routes, { ConfirmationRoutes } from "../../routes"
import { updateProjectData } from "../../store/actions/projectsActions"
import { getProjectValidatorModel } from "./model"
import { useFindAcquisitionModes } from "../../api/AcquisitionModeApi"
import { RouteComponentProps } from "react-router-dom"

const steps = [
    { label: "Project Details", key: "projectTab" },
    { label: "AOIs", key: "aoiTab" },
]

const getStepContent = (activeStep: number) => {
    switch (activeStep) {
        case 0:
            return <ProjectTab />
        case 1:
            return <AOITab />
        default:
            return "Error loading content"
    }
}

export default function ProjectEditor({ history }: RouteComponentProps) {
    const {
        loading,
        loadingProject,
        selectedProjectData,
        selectedAOIData,
        isAOIOpen,
        deletingAOI,
        parsingAOI,
        cache,
        dispatch,
    } = useProjects(true)

    const [submitting, setSubmitting] = useState(false)
    const [activeStep, setActiveStep] = useState(0)

    // Fetch here to display the loading bar properly
    const acquisitionModesResponse = useFindAcquisitionModes()

    const isEditted = isDiff(
        {
            selectedProjectData,
            selectedAOIData,
        },
        cache,
    )

    // fetch data
    useEffect(() => {
        dispatch({ type: Types.App_ClearError })
        return () => {
            dispatch({ type: Types.Projects_ResetAOIData })
        }
    }, []) // eslint-disable-line

    /**
     * Navigate to confirmation page after saving project.
     */
    const handleConfirmation = () => {
        dispatch({
            type: Types.App_UpdateConfirmationData,
            payload: {
                entityName: `${selectedProjectData?.project_number} `,
            },
        })

        history.push(`${routes.confirm}/${ConfirmationRoutes.PROJECT_EDITED}`)
    }

    /**
     * Move back or forward one step. If out of forward steps,
     * attempt to submit project.
     */
    const handleStepChange = async (newStep: number) => {
        if (newStep === steps.length) {
            setSubmitting(true)
            const aoi = isAOIOpen ? selectedAOIData : undefined
            const success = await updateProjectData(dispatch, selectedProjectData!, aoi)
            if (success) {
                handleConfirmation()
            }
            setSubmitting(false)
        } else if (newStep === -1) {
            history.push(routes.home)
        } else {
            setActiveStep(newStep)
        }
    }

    const handleErrorClose = () => {
        dispatch({ type: Types.Missions_ClearError })
    }

    return (
        <StepperForm
            steps={steps}
            activeStep={activeStep}
            submitting={submitting}
            disableSubmit={deletingAOI || parsingAOI || !isEditted}
            submitTooltip={!isEditted ? "Edit project or AOI to submit" : ""}
            onStepChange={handleStepChange}
            onErrorClose={handleErrorClose}
            validationProps={{
                dependencies: [selectedProjectData, selectedAOIData, isAOIOpen, loading, loadingProject],
                model: getProjectValidatorModel(selectedProjectData!, selectedAOIData, isAOIOpen),
            }}
            header={<ProjectTitle useLocalTitle />}
            loading={loading || acquisitionModesResponse.isLoading}
        >
            {getStepContent(activeStep)}
        </StepperForm>
    )
}
