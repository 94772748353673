import { useContext, useState, useCallback } from "react"
import { RootContext } from "../store/context"

import { diff } from "../tools"
import { Types } from "../store/types"
import { LookupDataType, lookupTableOptions } from "../store/types/lookupTypes"
import { fetchLookupTable, updateLookupData } from "../store/actions/lookupActions"

const useLookupTable = () => {
    const { state, dispatch } = useContext(RootContext)
    const { selectedTableId, rows, columns, cache } = state.lookup
    const { initialRows } = cache

    const [isSubmitting, setIsSubmitting] = useState(false)

    const primaryKey = columns.find((col) => col.primary)?.id || ""
    const isEdited = !!(diff(rows, initialRows).length)
    const selectedTableLabel = lookupTableOptions.find((opt) => opt.id === selectedTableId)?.label

    /**
     * Select table and await backend response.
     */
    const handleTableSelect = useCallback(
        (tableId: string) => {
            fetchLookupTable(dispatch, tableId)
        },
        [dispatch], // eslint-disable-line
    )

    /**
     * Change a single value in the table.
     */
    const handleTableDataChange = useCallback(
        (rows: LookupDataType[]) => {
            dispatch({
                type: Types.Lookup_UpdateRowData,
                payload: { rows },
            })
        },
        [dispatch, rows.length], // eslint-disable-line
    )

    /**
     * Send changes to backend and await response.
     */
    const handleTableDataSubmit = useCallback(async () => {
        setIsSubmitting(true)
        await updateLookupData(dispatch, rows, initialRows, selectedTableId, primaryKey)
        await setIsSubmitting(false)
    }, [dispatch, rows, initialRows, selectedTableId]) // eslint-disable-line

    return {
        ...state.lookup,
        isSubmitting,
        primaryKey,
        isEdited,
        selectedTableLabel,
        lookupTableOptions,
        handleTableSelect,
        handleTableDataChange,
        handleTableDataSubmit,
        dispatch,
    }
}

export default useLookupTable
