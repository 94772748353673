import { Types } from "../types"
import { FlightplansType, FlightplansActions } from "../types/flightplansTypes"
import { initialState } from "../context"

const missionsReducer = (state: FlightplansType, action: FlightplansActions): FlightplansType => {
    switch (action.type) {
        case Types.Flightplans_ResetState: {
            return initialState.flightplans
        }
        case Types.Flightplans_FetchFlightplanList: {
            return {
                ...state,
                flightplanList: [],
                deletedPlannedLineIds: [],
            }
        }
        case Types.Flightplans_FetchFlightplanListSuccess: {
            const { flightplanList, flightPlannedLines, selectedFlightplan } = action.payload

            if (flightplanList.length > 0) {
                const selected = selectedFlightplan || flightplanList[0]
                return {
                    ...state,
                    flightplanList: flightplanList,
                    selectedFlightplanId: selected.flight_plan_id,
                    selectedFlightplan: selected,
                    flightPlannedLines,
                }
            }

            return {
                ...state,
                flightplanList: flightplanList,
            }
        }
        case Types.Flightplans_SelectFlightplan: {
            const { flight_plan_id } = action.payload.selectedFlightplan
            return {
                ...state,
                selectedFlightplanId: flight_plan_id,
                selectedFlightplan: action.payload.selectedFlightplan,
                flightPlannedLines: [],
            }
        }
        case Types.Flightplans_SelectFlightplanSuccess: {
            const { flightPlannedLines } = action.payload

            return {
                ...state,
                flightPlannedLines,
            }
        }
        case Types.Flightplans_UpdateFlightplanData: {
            const { selectedFlightplan } = state
            const newState: any = {}

            for (const field in action.payload) {
                newState[field] = action.payload[field]
            }

            return {
                ...state,
                selectedFlightplan: {
                    ...selectedFlightplan,
                    ...newState,
                },
            }
        }
        case Types.Flightplans_UpdateFlightPlannedLines: {
            const { flightPlannedLines, deletedLines } = action.payload

            const deleted = deletedLines?.length ? deletedLines.map(line => line.planned_line_id) : []
            return {
                ...state,
                flightPlannedLines,
                deletedPlannedLineIds: [...state.deletedPlannedLineIds, ...deleted],
            }
        }
        case Types.Flightplans_ParseFileSuccess: {
            const { flightplan, flightPlannedLines, warnings } = action.payload
            return {
                ...state,
                selectedFlightplan: flightplan,
                flightPlannedLines,
                warnings,
            }
        }
        case Types.Flightplans_DeleteFlightplan: {
            return {
                ...state,
                isDeleting: true,
            }
        }
        case Types.Flightplans_DeleteFlightplanSuccess:
        case Types.Flightplans_DeleteFlightplanError: {
            return {
                ...state,
                isDeleting: false,
            }
        }
        default:
            return state
    }
}

export default missionsReducer
