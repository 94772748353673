import { Types } from "../types"
import { LookupType, LookupActions } from "../types/lookupTypes"
import { initialState } from "../context"

const lookupReducer = (state: LookupType, action: LookupActions) => {
    switch (action.type) {
        case Types.Lookup_ResetState: {
            return initialState.lookup
        }
        case Types.Lookup_SelectAction: {
            const { selectedActionId } = action.payload

            return {
                ...state,
                selectedActionId,
            }
        }
        case Types.Lookup_UpdateRowData: {
            const { rows } = action.payload

            return {
                ...state,
                rows,
            }
        }
        case Types.Lookup_DeleteTableRow: {
            const { primaryKey, ids } = action.payload
            const { rows } = state

            const newRows = rows.filter((row) => !ids.includes(row[primaryKey]))

            return {
                ...state,
                rows: newRows,
            }
        }
        case Types.Lookup_FetchTable: {
            const { selectedTableId } = action.payload

            return {
                ...state,

                selectedTableId,
                rows: [],
                columns: [],
                loadingRows: true,
                cache: {
                    ...state.cache,
                    initialRows: [],
                },
            }
        }
        case Types.Lookup_FetchTableSuccess: {
            const { rows, columns } = action.payload

            // TODO: move autocomplete options generator to backend
            columns.forEach((col) => {
                if (col.variant === "autocomplete") {
                    const options = [...new Set(rows.map((row) => row[col.id]))]
                    col.options = options.sort((a, b) => (a && b && a > b ? 1 : -1)) as string[]
                }
            })

            return {
                ...state,
                rows,
                columns,
                loadingRows: false,
                cache: {
                    ...state.cache,
                    initialRows: rows,
                },
            }
        }
        default:
            return state
    }
}

export default lookupReducer
