import { useEffect, useRef, useState } from "react"
import { useLocalStorageUtil } from "../../LocalStorageUtil"
import {
    GetTableRowId,
    HandleChange,
    HandleDeleteRow,
    HandlePaste,
    HandleSelect,
    onSelectRowOverlay,
    RowId,
    TableColumn,
    ToolArray,
    UpdateTableRows,
} from "../../models/Table"
import { getComparator } from "../../tools"
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@mui/material"
import { EnhancedTableToolbar } from "./toolbar"
import { TableHeader } from "./header"
import { EnhancedTableRow } from "./rows"
import { addSelectToColumns, addTableRow, isRowInserted, transformRowsToTableRows } from "../../utils/tableUtils"

interface CustomTableProps<T> {
    label: string
    rows: T[]
    primaryKey: keyof T
    disableRowWithKey?: keyof T
    stickyColumns?: TableColumn[]
    columns: TableColumn[]
    tools?: ToolArray
    updateTableRows: UpdateTableRows<T>
    canDeleteSelected?: boolean
    canAddRow?: boolean
    canSelect?: boolean
    disablePagination?: boolean
    disableSelectAll?: boolean
    selectedRowOverlay?: onSelectRowOverlay
    footerText?: string
}

const CustomTable = <T, >(props: CustomTableProps<T>) => {
    const {
        label,
        rows,
        primaryKey,
        disableRowWithKey,
        stickyColumns,
        columns,
        tools,
        updateTableRows,
        canAddRow = false,
        canDeleteSelected = false,
        canSelect = false,
        disablePagination = false,
        disableSelectAll = false,
        selectedRowOverlay,
        footerText,
    } = props
    const scrollRef = useRef<any>(null)

    const [selectedIds, setSelectedIds] = useState<RowId[]>([])
    const [lastSelected, setLastSelected] = useState<RowId>(-1)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(20)

    const { orderBy, order } = useLocalStorageUtil(state => state.sortOrder())

    const getRowId: GetTableRowId<T> = (row) => {
        return row[primaryKey] as unknown as RowId
    }
    const transformedRows = transformRowsToTableRows(rows, { disableRowWithKey })
    const getIndexOfTransformedRow = (indexToFind: RowId) => transformedRows.findIndex(row => getRowId(row) === indexToFind)
    const numSelectable = transformedRows.filter(row => row.selectable).length

    // Update rows on new order
    useEffect(() => {
        const orderedRows = rows.slice().sort(getComparator(order, orderBy as keyof T))
        updateTableRows(orderedRows)
    }, [order, orderBy]) // eslint-disable-line

    const resetTableScroll = () => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollTop = 0
        }
    }

    const handleChangePage = (newPage: number) => {
        setPage(newPage)
        resetTableScroll()
    }

    const handleChangeRowsPerPage = (value: number) => {
        setRowsPerPage(value)
        setPage(0)
        resetTableScroll()
    }

    const handleChange: HandleChange = (value, columnId, rowId) => {
        const index = getIndexOfTransformedRow(rowId)
        const currentRow = rows[index]
        updateTableRows([
            ...rows.slice(0, index), { ...currentRow, [columnId]: value || null }, ...rows.slice(index + 1),
        ], [currentRow])
    }

    const deleteSelected = () => {
        const { updatedRows, rowsDeleted } = rows.reduce((acc: { updatedRows: T[], rowsDeleted: T[] }, row) => {
            const selected = selectedIds.includes(getRowId(row))

            const updated = [...acc.updatedRows]
            const deleted = [...acc.rowsDeleted]
            if (selected) {
                deleted.push(row)
            } else {
                updated.push(row)
            }

            return ({
                updatedRows: updated,
                rowsDeleted: deleted,
            })
        }, ({ updatedRows: [], rowsDeleted: [] }))
        setSelectedIds([])
        updateTableRows(updatedRows, rowsDeleted)
    }

    const handleDeleteRow: HandleDeleteRow = (rowId) => {
        const index = rows.findIndex(row => getRowId(row) === rowId)
        const updatedRows = rows.slice()
        updatedRows.splice(index, 1)

        updateTableRows(updatedRows)
    }

    const addRow = () => {
        const rowModel = columns.map(col => col.id)

        const newRow = rowModel.reduce((row, key) => ({ ...row, [key]: undefined }), {}) as T
        const addedRows = rows.filter(row => isRowInserted(row[primaryKey]))
        const row = addTableRow(newRow, addedRows.length, primaryKey)

        updateTableRows([row, ...rows])
    }

    const handleShiftSelect = (i: number, j: number, select: boolean) => {
        if (i === -1) {
            return selectedIds
        }
        const selectableRows = transformedRows.filter(row => row.selectable)

        const ordered = [i, j].sort((a, b) => (a > b ? 1 : -1))
        const ids = transformedRows.slice(ordered[0], ordered[1] + 1).map(getRowId)
        const selectableIds = ids.filter((id) => selectableRows.some((row) => getRowId(row) === id))

        if (select) {
            return [...new Set([...selectedIds, ...selectableIds])]
        } else {
            return selectedIds.filter((old) => !selectableIds.includes(old))
        }
    }

    // Checkbox selecting if canSelect is enabled
    const handleSelect: HandleSelect = (shiftClick, rowId) => {
        let newSelectedIds: RowId[] = []

        // Select/Unselect Rows
        if (rowId != null) {
            // adds or removes specific rowId
            const rowIndex = getIndexOfTransformedRow(rowId)
            const lastSelectedRowIndex = getIndexOfTransformedRow(lastSelected)

            const selectedIdIndex = selectedIds.indexOf(rowId)

            // Is Selected
            if (selectedIds.includes(rowId)) {
                if (shiftClick) {
                    newSelectedIds = handleShiftSelect(lastSelectedRowIndex, rowIndex, false)
                } else {
                    newSelectedIds = [...selectedIds.slice(0, selectedIdIndex), ...selectedIds.slice(selectedIdIndex + 1)]
                }

            } else {
                newSelectedIds = [...selectedIds, rowId]
                if (shiftClick) {
                    newSelectedIds = handleShiftSelect(lastSelectedRowIndex, rowIndex, true)
                }
            }
            setLastSelected(rowId)

        } else {
            // Select/unselect All
            if (!selectedIds.length) {
                // Select all
                newSelectedIds = transformedRows.filter(row => row.selectable).map(row => getRowId(row))
            }
            setLastSelected(-1)
        }
        setSelectedIds(newSelectedIds)
    }

    const handlePaste: HandlePaste = (event: any, rowId: RowId, colId: string) => {
        const startIndex = getIndexOfTransformedRow(rowId)
        const pasteLines = event.clipboardData.getData("text").split(/\r\n|\r|\n/)

        // remove unwanted trailing blank line from MS Excel and sometimes txt
        if (pasteLines.slice(-1)[0] === "") {
            pasteLines.pop()
        }

        // handle multi-line text pasting for the notes column - otherwise let the default action occur
        if (colId === "notes" && pasteLines.length > 1) {
            event.preventDefault()

            const stopIndex = (startIndex + pasteLines.length)

            const result = rows.slice().map((row, idx) => {
                if (idx >= startIndex && idx < stopIndex) {
                    const val = pasteLines[idx - startIndex]
                    row[colId as keyof T] = val !== "" ? val : null
                }
                return row
            })

            updateTableRows(result)
        } // else use default handler
    }

    const slicedRows = transformedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const { cols, stickyCols } = addSelectToColumns(columns, canSelect && !!numSelectable, stickyColumns)
    const headerAndRowProps = {
        columns: cols, stickyColumns: stickyCols, canSelect, handleSelect, getRowId, selectedIds,
    }
    return (
        <Paper sx={{ mb: 4 }}>
            <EnhancedTableToolbar
                selected={selectedIds}
                label={label}
                onDeleteSelectedRows={canDeleteSelected ? deleteSelected : undefined}
                addRow={canAddRow && addRow}
                tools={tools}
            />
            <TableContainer sx={{ maxHeight: 400, minHeight: 200, overflowY: "scroll" }}>
                <Table stickyHeader size="small">
                    <TableHeader
                        disableSelectAll={disableSelectAll}
                        numSelected={selectedIds.length}
                        numSelectable={numSelectable}
                        overlay={selectedRowOverlay}
                        {...headerAndRowProps}
                    />
                    <TableBody>
                        {slicedRows.map((row, idx) => {
                            const selected = selectedIds.includes(getRowId(row))
                            return (
                                <EnhancedTableRow
                                    key={idx}
                                    row={row}
                                    selected={selected}
                                    rowOverlay={selectedRowOverlay}
                                    handleDeleteRow={handleDeleteRow}
                                    handlePaste={handlePaste}
                                    handleChange={handleChange}
                                    {...headerAndRowProps}
                                />
                            )
                        },
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!disablePagination && (
                <TableContainer>
                    <Table>
                        <TableFooter>
                            <TableRow>
                                {footerText &&
                                    <TableCell>{footerText}</TableCell>
                                }
                                <TablePagination
                                    sx={{ overflow: "initial" }}
                                    rowsPerPageOptions={[20, 50, 100]}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(e, page) => handleChangePage(page)}
                                    onRowsPerPageChange={(e) => handleChangeRowsPerPage(+e.target.value)}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            )}
        </Paper>
    )
}

export default CustomTable
