import { Theme } from "@mui/material/styles"

const inputStyles = (theme: Theme) => ({
    input__xs: {
        width: "100%",
        maxWidth: 80,
    },
    input__sm: {
        width: "100%",
        maxWidth: 200,
    },
    input__md: {
        width: "100%",
        maxWidth: 350,
    },
    input__lg: {
        width: "100%",
        maxWidth: 500,
    },
    input__xl: {
        width: "100%",
        maxWidth: 600,
    },

    menuHeight__sm: {
        maxHeight: 225,
    },
    menuHeight__md: {
        maxHeight: 300,
    },
    menuHeight__lg: {
        maxHeight: 375,
    },

    btn_delete: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,

        "&:hover": {
            borderColor: theme.palette.error.dark,
        },
    },
})

export type WidthOpt = "sm" | "md" | "lg"

export default inputStyles
