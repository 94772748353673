import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        textDecoration: "none",
        color: theme.palette.common.white,
        borderBottom: "2px solid transparent",
    },
    linksButton: {
        color: "white",
        justifyContent: "space-between",
        textTransform: "none",
        borderRadius: 0,
    },
    linkName: {
        color: "black",
        textDecoration: "none",
    },
    logout_button: {
        color: "white",
    },

    header_action_group: {
        display: "flex",
        alignItems: "center",
    },

    select: {
        color: "white",
        "&:before, &:after, &:not(.Mui-disabled):hover::before": {
            border: "none",
        },
        marginRight: "1em",
    },
    selectIcon: {
        fill: "white",
    },
    selectRoot: {
        color: "white",
    },
}))

export default useStyles
