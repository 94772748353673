import { FlightPlannedLineType, FlightplanType } from "../../store/types/flightplansTypes"
import { ValidatorModel } from "../../validation"

export const flightplanDisplayNames = {
    flight_plan_name: "Flightplan Name",
    status: "Flightplan Status",
    flightPlannedLines: "Flight Planned Lines",
}

export const getFlightplanValidatorModel = (flightplan: FlightplanType, flightPlannedLines: FlightPlannedLineType[]): ValidatorModel => {
    const [plannedLinesError, displayMessage] = flightPlannedDuplicateValidation(flightPlannedLines.map(line => line.flight_line_name))

    return {
        model: { ...flightplan, flightPlannedLines },
        display_names: flightplanDisplayNames,
        required_fields: ["flight_plan_name", "status"],
        unique_fields: [
            {
                name: "flightPlannedLines",
                isError: plannedLinesError,
                text: displayMessage,
            },
        ],
        custom_validation_fields: [],
    }
}

const flightPlannedDuplicateValidation = (lineNames: string[]): [boolean, string] => {
    const uniqueNames = new Set(lineNames)

    const isError = uniqueNames.size < lineNames.length
    let displayMessage = "Cannot have duplicate line IDs"

    if (isError) {
        const duplicates = lineNames?.filter((line, index) => {
            return lineNames.indexOf(line) !== index
        }) ?? []
        const firstThreeDuplicates = duplicates.slice(0, 3)
        displayMessage = `${displayMessage}: ${firstThreeDuplicates.join(", ")} ${duplicates.length > 3 ? "..." : ""}`
    }

    return [isError, displayMessage]
}
