import { Types } from "../types"
import API from "../../api"
import { AOINameType, AoiShape, ProjectInfoShort, ProjectType } from "../types/projectsTypes"
import { DispatchNexus } from "../context"

export async function fetchProjectList(dispatch: DispatchNexus): Promise<void> {
    dispatch({ type: Types.Projects_FetchProjectList })

    try {
        const result = await API.get("/projects/all")

        if (result.error) {
            throw new Error(result.message)
        }
        dispatch({
            type: Types.Projects_FetchProjectListSuccess,
            payload: { projectList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving project list." },
        })
    }
}

export async function fetchNonAcquisitionProjectList(dispatch: DispatchNexus): Promise<void> {
    dispatch({ type: Types.Projects_FetchNonAcquisitionProjectList })

    try {
        const result = await API.get("/projects/non-acquisition")

        if (result.error) {
            throw new Error(result.message)
        }
        dispatch({
            type: Types.Projects_FetchNonAcquisitionProjectListSuccess,
            payload: { nonAcqProjectList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving non-acquisition project list." },
        })
    }
}

export async function fetchProject(dispatch: DispatchNexus, projectNumber: string): Promise<void> {
    dispatch({ type: Types.Projects_FetchProject })

    try {
        const result = await API.get(`/project/${projectNumber}`)
        const selectedProject: ProjectInfoShort = result.data

        dispatch({
            type: Types.Projects_FetchProjectSuccess,
            payload: { selectedProject },
        })
    } catch (error) {
        console.error(error)
        dispatch({ type: Types.Projects_FetchProjectError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving project data" },
        })
    }
}

export async function fetchProjectData(dispatch: DispatchNexus, projectNumber: string): Promise<void> {
    dispatch({ type: Types.Projects_FetchProjectData })
    try {
        const result = await API.get(`/project-data/${projectNumber}`)
        const { project, aoi_list }: { project?: ProjectInfoShort, aoi_list?: AOINameType[] } = result.data

        if (!project) {
            throw new Error()
        }

        dispatch({
            type: Types.Projects_FetchProjectDataSuccess,
            payload: {
                selectedProject: project,
                aoi_list: aoi_list ?? [],
            },
        })
    } catch (error) {
        console.error(error)
        dispatch({ type: Types.Projects_FetchProjectDataError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving project data" },
        })
    }
}

export async function updateProjectData(
    dispatch: DispatchNexus,
    selectedProjectData: ProjectType,
    selectedAOIData?: AoiShape,
): Promise<boolean> {
    const { project_number, project_detail } = selectedProjectData
    const updatedData: any = {
        project_detail,
    }
    if (selectedAOIData) {
        updatedData.aoi = selectedAOIData
    }

    try {
        const result = await API.put(`/project-data/${project_number}`, updatedData)

        if (!result.success) {
            throw new Error()
        }
        return true
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error },
        })
    }

    return false
}

export async function fetchAoiList(dispatch: DispatchNexus, projectNumber: string): Promise<void> {
    dispatch({ type: Types.Projects_FetchAOIList })
    try {
        const result = await API.get(`/project-data/${projectNumber}`)
        const { aoi_list } = result.data

        dispatch({
            type: Types.Projects_FetchAOIListSuccess,
            payload: {
                aoi_list: aoi_list || [],
            },
        })
    } catch (error) {
        console.error(error)
        dispatch({ type: Types.Projects_FetchAOIListError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving project data" },
        })
    }
}

export async function fetchAoi(dispatch: DispatchNexus, selectedAOI: AOINameType) {
    dispatch({
        type: Types.Projects_SelectEditAOI,
        payload: { selectedAOI },
    })

    try {
        const result = await API.get(`/aoi/${selectedAOI.aoi_id}`)
        const selectedAOIData: AoiShape = result.data

        dispatch({
            type: Types.Projects_SelectEditAOISuccess,
            payload: { selectedAOIData },
        })
        return selectedAOIData
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Projects_SelectEditAOIError,
        })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving AOI data" },
        })
    }
    return undefined
}

export async function parseAOIFile(dispatch: DispatchNexus, file: any, doPolygonRepairs: boolean = false): Promise<boolean> {
    dispatch({ type: Types.Projects_ParseFile })
    dispatch({ type: Types.App_ClearError })

    try {
        const formData = new FormData()
        formData.append("file", file)

        const response = await API.uploadFile("/upload/aoi", formData, { repair_polygons: doPolygonRepairs })
        const { aoi, area, area_contracted } = response.data

        dispatch({
            type: Types.Projects_ParseFileSuccess,
            payload: {
                shape: aoi,
                area,
                area_contracted,
                source_file: file.name,
            },
        })
        return true
    } catch (error) {
        let errorMessage = error.message
        if (error.message === "413") {
            errorMessage = "File too large (50MB max)."
        }
        dispatch({ type: Types.Projects_ParseFileError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: errorMessage },
        })
        return false
    }
}

export async function deleteAOI(dispatch: DispatchNexus, aoi_id?: number): Promise<boolean> {
    dispatch({ type: Types.Projects_DeleteAOI })

    try {
        if (!aoi_id) {
            throw new Error("Error while deleting AOI. No AOI ID found in selected AOI data.")
        }
        const result = await API.delete(`/aoi/${aoi_id}`)
        if (!result.success) {
            throw new Error()
        }

        dispatch({ type: Types.Projects_DeleteAOISuccess })
        return true
    } catch (error) {
        console.error(error)
        dispatch({ type: Types.Projects_DeleteAOIError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error ?? "Failed to delete AOI." },
        })
    }
    return false
}

export async function fetchNonAcquisitionProject(dispatch: DispatchNexus, projectNumber: string | null): Promise<void> {
    dispatch({
        type: Types.Projects_FetchNonAcquisitionProject,
        payload: { loading: projectNumber !== null },
    })

    if (projectNumber === null) {
        return
    }

    try {
        const result = await API.get(`/project/${projectNumber}`)

        dispatch({
            type: Types.Projects_FetchNonAcquisitionProjectSuccess,
            payload: { selectedNonAcqProject: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving project data" },
        })
    }
}

export async function initializeAcquisitionProject(dispatch: DispatchNexus, projectNumber: string): Promise<boolean> {
    dispatch({ type: Types.Projects_InitializeAcquistionProject })

    try {
        const result = await API.post("/project-details", {
            project_number: projectNumber,
        })
        if (result.error) {
            throw new Error()
        }

        dispatch({ type: Types.Projects_InitializeAcquistionProjectSuccess })
        dispatch({ type: Types.Projects_FetchProjectList }) // uncache project list
        return true
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error initializing acquisition project." },
        })
    }

    return false
}
