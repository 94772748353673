import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from "@mui/material"
import LoadingButton from "../LoadingButton"

import useStyles from "./styles"
import React from "react"

interface AlertModalProps extends DialogProps {
    confirmText?: string
    danger?: boolean // makes confirm button red
    declineText?: string
    onClose: () => void
    onConfirm?: React.MouseEventHandler<HTMLButtonElement>
    open: boolean
    pending?: boolean
    title: string
}

function AlertModal({
    children,
    confirmText,
    danger,
    declineText,
    title,
    onClose,
    onConfirm,
    open,
    pending,
}: AlertModalProps) {
    const { classes, cx } = useStyles()

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            {children && (
                <DialogContent>
                    {children}
                </DialogContent>
            )}
            <DialogActions>
                {declineText &&
                    <Button variant="outlined" onClick={onClose}>
                        {declineText}
                    </Button>
                }
                {onConfirm &&
                    <LoadingButton
                        pending={pending}
                        color="primary"
                        className={cx({ [classes.btn_delete]: danger })}
                        variant="outlined"
                        onClick={onConfirm}
                    >
                        {confirmText ?? "Confirm"}
                    </LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}

AlertModal.defaultProps = {
    danger: false,
    declineText: "Back",
}

export default AlertModal
export type { AlertModalProps }
