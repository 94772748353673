import { MissionReviewStatusType } from "../store/types/missionsTypes"

export enum ReviewStatusColor {
    COLOR_DEFAULT = "black",
    COLOR_UNDER_REVIEW = "#D100CE", // Magenta
    COLOR_COMPLETE = "#1410FE", // blue
}

export enum ReviewStatusText {
    UNDER_REVIEW = "Under Review",
    NO_REVIEW_REQUIRED = "No Review Required",
    REVIEW_COMPLETE = "Review Complete",
}

interface Status {
    text: ReviewStatusText
    color: ReviewStatusColor
}

export const getStatusDesign = (opt: MissionReviewStatusType) => {
    let status: Status = { text: ReviewStatusText.REVIEW_COMPLETE, color: ReviewStatusColor.COLOR_COMPLETE }
    if (!opt.event_ct_total) {
        status = { text: ReviewStatusText.NO_REVIEW_REQUIRED, color: ReviewStatusColor.COLOR_DEFAULT }
    } else if (!opt.review_date || !opt.reviewer_id || opt.event_ct_remaining > 0) {
        status = { text: ReviewStatusText.UNDER_REVIEW, color: ReviewStatusColor.COLOR_UNDER_REVIEW }
    }
    return status
}

export const getPercentComplete = (opt: MissionReviewStatusType) => {
    return Math.trunc((opt.event_ct_total - opt.event_ct_remaining) / opt.event_ct_total * 100)
}
