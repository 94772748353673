import { Grid, TextField, StandardTextFieldProps } from "@mui/material"

import useStyles from "./styles"
import { WidthOpt } from "../../assets/inputStyles"

interface ReviewFieldProps extends StandardTextFieldProps {
  field: string
  gutterBottom?: WidthOpt
  onChange: any
}

const ReviewField = ({ field, value, onChange, gutterBottom, required, helperText, ...other }: ReviewFieldProps) => {
    const { classes, cx } = useStyles()
    const gutter = (required && gutterBottom) ? gutterBottom : "md"

    return (
        <Grid>
            <TextField
                variant="outlined"
                value={value ?? ""}
                onChange={(e) => onChange({ [field]: e.target.value || null })}
                className={cx(classes.input__lg, classes[`gutterBottom__${gutter}`])}
                helperText={required && helperText}
                {...other}
            />
        </Grid>
    )
}

export default ReviewField
