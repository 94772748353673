import { useRef } from "react"
import { debounce } from "../tools"

export default function useDebouncer(callback: any, delay: number | null = 200) {
    // save result of debounce across component lifecycle
    const debounceRef = useRef(
        debounce((...args: any) => {
            if (callback) {
                callback(...args)
            }
        }, delay || 0),
    )

    return debounceRef.current
}
