import propTypes from "prop-types"

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, RadioGroupProps } from "@mui/material"
import useStyles from "./styles"
import { WidthOpt } from "../../assets/inputStyles"

export interface RadioOptShape {
    id: string
    label: string
}

interface CustomRadioGroupProps extends RadioGroupProps {
    label?: string
    disabled?: boolean
    disableGutter?: boolean
    gutterBottom?: WidthOpt
    options: RadioOptShape[]
    onChange: any
}

const CustomRadioGroup = ({
    disabled,
    disableGutter,
    gutterBottom = "md",
    label,
    onChange,
    options,
    value,
}: CustomRadioGroupProps) => {
    const { classes: styles, cx } = useStyles()
    return (
        <FormControl
            component="fieldset"
            className={cx(!disableGutter && styles[`gutterBottom__${gutterBottom}`])}
            disabled={disabled}
        >
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <RadioGroup
                value={value}
                onChange={(event) => onChange(options.find((opt: RadioOptShape) => opt.id === event.target.value))}
            >
                {options.map((option: RadioOptShape) => (
                    <FormControlLabel key={option.id} label={option.label} value={option.id} control={<Radio />} />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

CustomRadioGroup.defaultProps = {
    disableGutter: propTypes.bool,
    gutterBottom: propTypes.oneOf(["sm", "md", "lg"]),
    label: propTypes.string,
    onChange: propTypes.func.isRequired,
    options: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.string,
            label: propTypes.string,
        }),
    ).isRequired,
    value: propTypes.string, // option.id
}

CustomRadioGroup.defaultProps = {
    gutterBottom: "md",
}

export default CustomRadioGroup
