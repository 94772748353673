import React, { useEffect } from "react"
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material"
import { Label, Subtitle2 } from "../Typography"
import { useAcquisitionModeStore } from "../../zstore/AcquisitionModeStore"
import { AcquisitionMode } from "../../models/AcquisitionMode"

interface ModesSelectProps {
    modes: AcquisitionMode[]
    initialModeIds: number[]
    handleUpdate?: (selectedModeIds: number[]) => void
}

/**
 * @param modes
 * @param initialModeIds
 * @param handleUpdate Updates other systems as the selected modes change, for example can be used ot send
 * updates to the model being tracked in redux.
 */
export const ModesSelect = (
    { modes, initialModeIds, handleUpdate }: ModesSelectProps,
) => {

    const modesGrouped = useAcquisitionModeStore(state => state.modesByType)
    const selectedAcquisitionModes = useAcquisitionModeStore(state => state.selectedModeIds)
    const setAvailableModes = useAcquisitionModeStore(state => state.setAvailableModes)
    const setSelectedModes = useAcquisitionModeStore(state => state.setSelectedModes)
    const clearSelected = useAcquisitionModeStore(state => state.clearSelected)
    const toggleSelectedMode = useAcquisitionModeStore(state => state.toggleSelectedMode)

    useEffect(() => {
        setAvailableModes(modes)
    }, [...modes])

    useEffect(() => {
        setSelectedModes(initialModeIds)
        return clearSelected
    }, [])

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = await toggleSelectedMode(Number(event.target.name))
        if (handleUpdate) {
            handleUpdate(newValues)
        }
    }

    if (modesGrouped == null || selectedAcquisitionModes == null) {
        return null
    }

    return (
        <>
            <Label disableGutter>Select Acquisition Modes</Label>
            <Subtitle2 gutterBottom="md">Required</Subtitle2>
            {modesGrouped.map(modeGroup => (
                <FormControl
                    component={"fieldset"}
                    sx={{ mb: 4 }}
                    key={modeGroup.mode_type}
                    fullWidth
                >
                    <FormLabel component="legend">{modeGroup.mode_type}</FormLabel>
                    <FormGroup row>
                        {modeGroup.modes.map(mode => (
                            <FormControlLabel
                                key={mode.mode_id}
                                control={
                                    <Checkbox
                                        checked={selectedAcquisitionModes.includes(mode.mode_id)}
                                        onChange={handleChange}
                                        name={mode.mode_id.toString()}
                                    />
                                }
                                label={mode.mode_name}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            ))}
        </>
    )
}
