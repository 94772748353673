import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
}))

export default useStyles
