import React, { useContext, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"

import {
    Typography,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    Container,
    Box,
    Tooltip,
    IconButton,
    ButtonProps,
} from "@mui/material"
import { Add } from "@mui/icons-material"

import Alert from "../../components/Alert"
import Autocomplete from "../../components/Fields/Autocomplete"
import VersionTooltip from "../../components/Tooltips/VersionTooltip"
import { Label } from "../../components/Typography"

import { RootContext } from "../../store/context"
import { fetchProjectList } from "../../store/actions/projectsActions"
import { Types } from "../../store/types"
import { ProjectStatus, ProjectType } from "../../store/types/projectsTypes"
import useAuth from "../../hooks/useAuth"
import routes from "../../routes"
import useStyles from "./styles"
import { sortedByCount } from "../../tools"
import { useLocalStorageUtil } from "../../LocalStorageUtil"
import { FlightPlanFlow } from "../../models/FlightPlanFlow"

interface LinkField {
    children: string
    to: string
    hidden: boolean
    disabled?: boolean
}

interface LandingLink extends ButtonProps {
    disabled?: boolean
    to: string
}

const LandingLink = ({ children, className, disabled, to }: LandingLink) => (
    <Grid item xs={12} sm={6} className={className}>
        <Button component={Link} to={to} disabled={disabled} color="primary" variant="contained" fullWidth size="large">
            {children}
        </Button>
    </Grid>
)

export default function Landing() {
    const { classes } = useStyles()
    const { state, dispatch } = useContext(RootContext)
    const { error } = state.app
    const { projectList, selectedProject, loadingProjectList } = state.projects
    const { user, ToolbarOptions, checkToolbarAccess } = useAuth()
    const [offices, saveOffices] = useLocalStorageUtil(state => [state.offices(), state.saveOffices])
    const [showInactive, saveShowInactive] = useLocalStorageUtil(state => [state.showInactive(), state.saveShowInactive])

    useEffect(() => {
        dispatch({ type: Types.App_ClearError })
        dispatch({ type: Types.Flightplans_ResetState })
        dispatch({ type: Types.Missions_ResetState })

        if (!projectList.length) {
            fetchProjectList(dispatch)
        }
    }, []) // eslint-disable-line

    const locations: string[] = sortedByCount(
        projectList
            .filter(project => project.location != null)
            .map(project => project.location!),
    ).reverse()

    const handleInactiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        saveShowInactive(e.target.checked)
    }

    const handleChange = (value: string | null) => {
        const selectedProject = projectList.find((project) => project.project_number === value)

        dispatch({
            type: Types.Projects_SelectProject,
            payload: { selectedProject: selectedProject ?? null },
        })
    }

    const isProjectSelected = projectList.length && selectedProject
    const userCanInitProjects = checkToolbarAccess(ToolbarOptions.Initialize_Project)

    const links: LinkField[] = [
        {
            children: "Initialize New Acquisition Project",
            to: routes.projectInit,
            hidden: !userCanInitProjects,
        },
        {
            children: "Edit Existing Project Area",
            disabled: !isProjectSelected,
            to: `/${selectedProject?.project_number}${routes.projectEditor}`,
            hidden: !checkToolbarAccess(ToolbarOptions.Edit_Project_Areas),
        },
        {
            children: "Upload New Flightplan",
            disabled: !isProjectSelected,
            to: `/${selectedProject?.project_number}${routes.flightplanUpload}`,
            hidden: !checkToolbarAccess(ToolbarOptions.Submit_Flightplans),
        },
        {
            children: "Edit Existing Flightplan",
            disabled: !isProjectSelected,
            to: `/${selectedProject?.project_number}${routes.flightplanEditor}/${FlightPlanFlow.EDIT}`,
            hidden: !checkToolbarAccess(ToolbarOptions.Edit_Flightplans),
        },
        {
            children: "Submit New Mission Log",
            disabled: !isProjectSelected,
            to: `/${selectedProject?.project_number}${routes.missionUpload}`,
            hidden: !checkToolbarAccess(ToolbarOptions.Submit_Mission_Logs),
        },
        {
            children: "Edit Existing Mission Log",
            disabled: !isProjectSelected,
            to: `/${selectedProject?.project_number}${routes.missionSelect}`,
            hidden: !checkToolbarAccess(ToolbarOptions.Edit_Mission_Logs),
        },
        {
            children: "Edit Lookup Tables",
            to: `${routes.lookupEditor}`,
            hidden: !checkToolbarAccess(ToolbarOptions.Edit_Lookup_Tables),
        },
    ]

    const client = localStorage.getItem("nexus_client")

    const showProjects = useMemo(() => {
        const projsToShow: ProjectType[] = offices.length === 0 ? projectList : projectList.filter(project => offices.includes(project.location!))

        return showInactive ? projsToShow : projsToShow.filter(pl => pl.project_status === ProjectStatus.ACTIVE)
    }, [projectList, showInactive, offices])

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.flex_center}>
                    <Typography variant="h4" className={classes.title}>
                        Nexus Uploader
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        {user.name} {client && `\u2022 ${client}`}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems={"center"} spacing={2} classes={{ root: classes.formControl }}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                disableGutter
                                multiple
                                fullWidth
                                sx={{ minWidth: 250 }}
                                TextFieldProps={{ variant: "standard", label: "Filter Projects by Office" }}
                                options={locations}
                                value={offices}
                                onChange={(_, locations) => saveOffices(locations)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showInactive}
                                        onChange={handleInactiveChange}
                                        name="filterInactive"
                                        size={"small"}
                                    />
                                }
                                sx={{ mr: 0 }}
                                label={<Typography variant="body2">Include Inactive/Dormant</Typography>}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Button
                                variant={"outlined"}
                                onClick={() => {
                                    saveOffices([])
                                    saveShowInactive(false)
                                }}
                            >
                                Clear Filters
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Label gutterBottom="sm">Select Project</Label>
                    <Autocomplete
                        noOptionsText={"No matches. Don't see your project? It may need to be initialized."}
                        placeholder="Select a project or start typing..."
                        value={selectedProject ?? null}
                        options={showProjects}
                        isOptionEqualToValue={(opt, val) => opt.project_number === val.project_number}
                        renderOption={(props, opt) => {
                            return (
                                <Box component={"li"} {...props} key={opt.project_number}>
                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Grid item xs>
                                            <Typography>{opt.project_number} - {opt.project_long_name}</Typography>
                                        </Grid>
                                        <Grid item sm={3} md={2} sx={{ textAlign: "right" }}>
                                            <Typography sx={{ fontWeight: opt.project_status !== "A" ? "bold" : "auto" }}>{getStatusString(opt.project_status!)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        }}
                        getOptionLabel={(opt: ProjectType) => `${opt.project_number} - ${opt.project_long_name}`}
                        onChange={(_, value) => handleChange(value?.project_number ?? null)}
                        fullWidth
                        loading={loadingProjectList}
                        endAdornment={
                            (!selectedProject && userCanInitProjects) &&
                            <Tooltip title={"Initialize New Acquisition Project"}>
                                <IconButton size={"small"} component={Link} to={routes.projectInit}>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        }
                    />

                    {error && (
                        <Alert animate onClose={() => dispatch({ type: Types.App_ClearError })} gutterBottom={"sm"}>
                            {error}
                        </Alert>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {links
                            .filter((link) => !link.hidden)
                            .map((link) =>
                                <LandingLink
                                    key={link.to}
                                    to={link.to}
                                    disabled={link.disabled}
                                    className={classes.button}
                                >
                                    {link.children}
                                </LandingLink>,
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.version}>
                <VersionTooltip />
            </Grid>
        </Container>
    )
}

const getStatusString = (s: ProjectStatus) => {
    switch (s) {
        case ProjectStatus.ACTIVE:
            return "Active"
        case ProjectStatus.INACTIVE:
            return "Inactive"
        case ProjectStatus.DORMANT:
            return "Dormant"
        default:
            console.error(`project with status ${s} not handled`)
            return undefined
    }
}
