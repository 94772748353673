import propTypes from "prop-types"

import { Grid } from "@mui/material"
import { Info } from "@mui/icons-material"

import HtmlTooltip from "../HtmlTooltip"

import useStyles from "./styles"

export default function DetailsTooltip({ animate, className, details, disabled, Icon, label }: any) {
    const { classes, cx } = useStyles()

    const body = (
        <Grid container spacing={2}>
            <Grid item>
                {details.map((detail: any) => (
                    <Grid key={detail.label} className={classes.text_key}>
                        {detail.label}
                    </Grid>
                ))}
            </Grid>
            <Grid item>
                {details.map((detail: any) => (
                    <Grid key={detail.label} className={classes.text_value}>
                        {detail.value}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )

    return (
        <span className={className}>
            <HtmlTooltip title={label} body={body} disabled={disabled}>
                <Icon
                    color={disabled ? "disabled" : "primary"}
                    className={cx(classes.icon, { [classes.bounce]: !disabled && animate })}
                />
            </HtmlTooltip>
        </span>
    )
}

DetailsTooltip.propTypes = {
    animate: propTypes.bool,
    className: propTypes.string,
    details: propTypes.arrayOf(
        propTypes.shape({
            label: propTypes.string,
            value: propTypes.string,
        }),
    ),
    disabled: propTypes.bool,
    label: propTypes.string,
    Icon: propTypes.any,
}

DetailsTooltip.defaultProps = {
    Icon: Info,
}
