import { QueryKeys } from "./QueryKeys"
import { useQuery } from "react-query"
import API from "./index"
import { MissionNameType, MissionReviewStatusType } from "../store/types/missionsTypes"

export const useFindReviewStatus = (missionNames: MissionNameType[], projectId?: string) => {
    return useQuery([QueryKeys.FIND_REVIEW_STATUS_BY_PROJECT, projectId, missionNames], async () => {
        if (!projectId) {
            return
        }
        const result: { data: MissionReviewStatusType[] } = await API.get(`/missions/${projectId}/review_status`)
        return compareMissionNamesToData(result.data, missionNames) ?? []
    })
}

const compareMissionNamesToData = (projectMissionReviews: MissionReviewStatusType[], missionNames: MissionNameType[]): MissionReviewStatusType[] => {
    return missionNames.flatMap((mission) => {
        const foundSameName = projectMissionReviews.find(missionReview => missionReview.mission_name === mission.mission_name)
        if (foundSameName) {
            return foundSameName
        }

        return []
    })
}
