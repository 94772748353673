import { useEffect } from "react"

import { Grid } from "@mui/material"

import Autocomplete from "../../components/Fields/Autocomplete"
import StepperForm from "../../components/StepperForm"
import { Label } from "../../components/Typography"

import LookupTable from "./LookupTable"
import { getLookupValidatorModel } from "./model"

import useLookupTable from "../../hooks/useLookupTable"
import { SelectOptionType } from "../../store/types/lookupTypes"

export default function LookupEditor({ history }: any) {
    const {
        selectedTableId,
        loadingRows,
        rows,
        columns,
        isSubmitting,
        isEdited,
        lookupTableOptions,
        handleTableSelect,
        handleTableDataSubmit,
    } = useLookupTable()

    // fetch initial table
    useEffect(() => {
        handleTableSelect(selectedTableId)
    }, []) // eslint-disable-line

    const handleStepChange = async (newStep: number) => {
        if (newStep === -1) {
            history.goBack()
        } else {
            await handleTableDataSubmit()
        }
    }

    return (
        <StepperForm
            loading={loadingRows}
            submitting={isSubmitting}
            disableSubmit={!isEdited}
            header={
                <Grid>
                    <Grid>
                        <Label>Select a Table to Edit</Label>
                        <Autocomplete
                            value={lookupTableOptions.find((table) => table.id === selectedTableId) ?? null}
                            options={lookupTableOptions}
                            getOptionLabel={(table: SelectOptionType) => table.label}
                            onChange={(e, table) => table && handleTableSelect(table.id)}
                            gutterBottom="lg"
                            width="md"
                            disableClearable={selectedTableId !== null}
                            loading={loadingRows}
                            disabled={loadingRows}
                        />
                    </Grid>
                </Grid>
            }
            validationProps={{
                dependencies: [rows],
                model: getLookupValidatorModel(rows, columns),
            }}
            onStepChange={handleStepChange}
        >
            <LookupTable />
        </StepperForm>
    )
}
