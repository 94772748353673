import { useState } from "react"

import { IconButton, SxProps, Tooltip, TooltipProps } from "@mui/material"
import { Build } from "@mui/icons-material"

import { OptionType } from "../../store/types/appTypes"
import Menu from "../Menu"

interface ToolProps {
    color?: string
    disabled?: boolean
    Icon?: any
    onChange: any
    options: OptionType[]
    tooltip: string | ""
    TooltipProps?: Partial<TooltipProps>
    value: boolean | object
    sx?: SxProps
}

const Tool = ({ color, disabled, Icon, onChange, options, tooltip, TooltipProps, value, sx = [] }: ToolProps) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: any) => {
        if (options && options.length > 0) {
            setAnchorEl(event.currentTarget)
        } else {
            onChange()
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuChange = (option: OptionType) => {
        onChange(option)
        handleClose()
    }

    return (
        <>
            <Tooltip title={tooltip} arrow {...TooltipProps}>
                <span>
                    <IconButton
                        disabled={disabled}
                        onClick={handleClick}
                        sx={[...Array.isArray(sx) ? sx : [sx], color && { color }]}
                    >
                        <Icon color={value && !disabled ? "inherit" : "disabled"} />
                    </IconButton>
                </span>
            </Tooltip>
            {options.length > 0 && (
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    options={options}
                    onChange={handleMenuChange}
                    onClose={handleClose}
                />
            )}
        </>
    )
}

Tool.defaultProps = {
    disabled: false,
    Icon: Build,
    options: [],
    tooltip: "",
    // value
    // toggleable buttons - need to gray out in off state but not disable btn
    // menu buttons - represents selected menu option
    value: true,
}

export default Tool
