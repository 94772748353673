import React from "react"

import { Button, Collapse, Grid, IconButton, Tooltip } from "@mui/material"
import { Add } from "@mui/icons-material"

import AlertModal from "../Modals/AlertModal"
import Autocomplete from "../Fields/Autocomplete"
import LoadingButton from "../LoadingButton"
import { Label } from "../Typography"

import { Types } from "../../store/types"
import { AOINameType } from "../../store/types/projectsTypes"
import useProjects from "../../hooks/useProjects"
import { fetchAoi } from "../../store/actions/projectsActions"
import useToggle from "../../hooks/useToggle"
import useStyles from "../../pages/ProjectEditor/styles"
import { useAcquisitionModeStore } from "../../zstore/AcquisitionModeStore"

function AOISelect() {
    const { classes } = useStyles()

    const {
        selectedProject,
        selectedAOI,
        isAOIOpen,
        isSubmitAOIFlow,
        isEditAOIFlow,
        aoiList,
        loadingProject,
        loadingAOI,
        loadingAOIList,
        deletingAOI,
        handleAOIDelete,
        dispatch,
    } = useProjects()

    const [deleteModalOpen, toggleDeleteModalOpen] = useToggle()

    let label = "Select an Existing AOI to Edit or Submit a New AOI"
    if (isSubmitAOIFlow) {
        label = "Submitting New AOI"
    } else if (isEditAOIFlow) {
        label = "Editing AOI"
    }

    const setSelectedAoi = useAcquisitionModeStore(state => state.setSelectedAoi)
    const clearSelected = useAcquisitionModeStore(state => state.clearSelected)

    const handleEditSelect = async (value: AOINameType) => {
        // TODO: currently aoiData is saved in both Redux and now zustand.
        // fix to only be in one area

        clearSelected()
        const aoiData = await fetchAoi(dispatch, value)

        if (!aoiData) {
            dispatch({
                type: Types.App_UpdateError,
                payload: { error: "Error in fetching selected AOI data" },
            })
        } else {
            setSelectedAoi(aoiData)
        }
    }

    const handleSubmitSelect = (event: React.MouseEvent) => {
        event.stopPropagation()
        if (!selectedProject?.project_number) {
            dispatch({
                type: Types.App_UpdateError,
                payload: { error: "Error in submitting. Could not find project number in selected project" },
            })
            return
        }

        dispatch({
            type: Types.Projects_SelectSubmitAOI,
            payload: { project_number: selectedProject?.project_number },
        })
    }

    const handleDelete = () => {
        toggleDeleteModalOpen()
        handleAOIDelete()
    }

    const handleCancel = () => {
        dispatch({ type: Types.Projects_ResetAOIData })
    }

    return (
        <Grid>
            <Label gutterBottom="lg">{label}</Label>
            <Collapse in={!isSubmitAOIFlow}>
                <Autocomplete
                    TextFieldProps={{
                        label: "Edit Existing AOI",
                        helperText: isAOIOpen ? "Switching the selected AOI will lose any new changes" : "",
                        boldHelperText: true,
                    }}
                    gutterBottom={isSubmitAOIFlow ? "sm" : "md"}
                    width="md"
                    disableClearable={selectedAOI !== null}
                    disabled={loadingAOI || deletingAOI}
                    loading={loadingAOI || loadingProject || loadingAOIList}
                    value={aoiList.find((aoi) => aoi.name === selectedAOI?.name) ?? null}
                    options={aoiList.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    getOptionLabel={(option: AOINameType) => option.name || ""}
                    onChange={(_, value) => value && handleEditSelect(value)}
                    endAdornment={
                        !isEditAOIFlow &&
                        !loadingAOI && (
                            <Tooltip title="Submit New AOI">
                                <IconButton size="small" onClick={handleSubmitSelect}>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                />
            </Collapse>
            <Collapse in={isAOIOpen}>
                <Grid container spacing={2} className={classes.gutterBottom__md}>
                    {isEditAOIFlow && (
                        <Grid item>
                            <LoadingButton
                                pending={deletingAOI}
                                disabled={loadingAOI || loadingProject || loadingAOIList}
                                variant="outlined"
                                onClick={toggleDeleteModalOpen}
                                className={classes.btn_delete}
                            >
                                Delete
                            </LoadingButton>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={deletingAOI || loadingAOI || loadingProject || loadingAOIList}
                            onClick={handleCancel}
                        >
                            {isSubmitAOIFlow ? "Cancel Submission" : "Cancel Edit"}
                        </Button>
                    </Grid>
                </Grid>
            </Collapse>
            <AlertModal
                danger
                open={deleteModalOpen}
                pending={deletingAOI}
                title="Delete AOI?"
                onConfirm={handleDelete}
                onClose={toggleDeleteModalOpen}
            >
                Are you sure you want to delete this AOI? This action cannot be reversed.
            </AlertModal>
        </Grid>
    )
}

export default AOISelect
