import moment from "moment"
import { FlightlineEventDTO, FlightlineEventType } from "./store/types/missionsTypes"
import { ToolbarOptions, ToolbarOptionType, UserGroupType } from "./store/types/appTypes"
import * as loglevel from "loglevel"
import { Order, TimeFormat } from "./LocalStorageUtil"
import { FlightPlannedLineType } from "./store/types/flightplansTypes"

const nv5gClients = ["qsi-test", "nv5g"]
const toolbarNames: string[] = Object.values(ToolbarOptions)

export const debounce = (func: any, wait: number) => {
    let timeout: any

    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout)
            func(...args)
        }

        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
    }
}

const dateFormat = "YYYY-MM-DD"
const timeFormat = "HH:mm:ss"
export const formatDate = (date?: any) => moment(date).format(dateFormat)
export const formatTime = (time?: any) => time && moment(time).utc().format(timeFormat)

export const parseDate = (d?: string | null) => d != null ? moment(d, dateFormat) : null
export const parseTimeHMS = (t?: string | null) => t != null ? moment(t, timeFormat) : null
export const parseDateTimeHMS = (d: string | null, t?: string | null) => (d != null && t != null) ? moment(d + " " + t, dateFormat + " " + timeFormat) : null

export const flattenObj = (
    currentNode: {
        [key: string]: any
    },
    target: any = null,
) => {
    if (!target) {
        target = {}
    }
    for (const key in currentNode) {
        const value = currentNode[key]

        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            flattenObj(value, target)
        } else {
            target[key] = value
        }
    }
    return target
}

const template = "00000"
export const parseFlightlineEventNames = (
    flightlineEvents: FlightlineEventType[] | FlightlineEventDTO[],
    matchingExpression: string,
    plannedLines: FlightPlannedLineType[],
): FlightlineEventType[] => {
    return flightlineEvents.map((event): FlightlineEventType => {
        const { line_original_name } = event

        const newFlightlineEvent: FlightlineEventType = {
            ...event,
            planned_line_id: null,
            flightline_name: null,
        }

        try {
            const matches = new RegExp(matchingExpression || "").exec(line_original_name)
            const id = matches && matches[1] ? matches[1] : null

            if (id && id.length <= template.length) {
                newFlightlineEvent.flightline_name = template.slice(0, template.length - id.length) + id
            }

        } catch {
            // fields use the null default
        }

        // always will want to find matching planned_line_id afterward
        return getMatchingPlannedLineId(newFlightlineEvent, plannedLines)
    })
}

export const getMatchingPlannedLineId = (lineEvent: FlightlineEventType, plannedLines: FlightPlannedLineType[]): FlightlineEventType => {
    let planned_line_id = null

    // Find matching lines with planned_line_id
    let matchingPlannedLine = plannedLines.find(pl => pl.planned_line_id === lineEvent.planned_line_id)

    if (!matchingPlannedLine && lineEvent.flightline_name) {
        matchingPlannedLine = plannedLines.find(fp => fp.flight_line_name === lineEvent.flightline_name)
    }

    if (matchingPlannedLine) {
        planned_line_id = matchingPlannedLine.planned_line_id ?? null
    }

    return { ...lineEvent, planned_line_id }
}

/**
 * Deep equality comparison between two objs or arrays.
 */
export const isEqual = (obj1: any, obj2: any) => {
    if (obj1 === obj2) {
        return true
    }

    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 == null || obj2 == null) {
        return false
    }

    const keysA = Object.keys(obj1)
    const keysB = Object.keys(obj2)

    if (keysA.length !== keysB.length) {
        return false
    }

    let result = true

    keysA.forEach((key) => {
        if (!keysB.includes(key)) {
            result = false
        }

        if (typeof obj1[key] === "function" || typeof obj2[key] === "function") {
            if (obj1[key].toString() !== obj2[key].toString()) {
                result = false
            }
        }

        if (!isEqual(obj1[key], obj2[key])) {
            result = false
        }
    })

    return result
}

/**
 * Returns items in arr1 that aren't exactly duplicated in arr2.
 */
export const diff = (arr1: any[], arr2: any[]) => {
    return arr1.filter((elem1) => !arr2.some((elem2) => isEqual(elem1, elem2)))
}

/**
 * Returns true if there is a difference.
 */
export const isDiff = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) !== JSON.stringify(obj2)
}

export const sortByField = (arr: any[], field: string, sortingArr: (number | string)[] | null = null) => {
    return [...arr].sort((a: any, b: any) => {
        if (sortingArr && !a[field]) {
            throw new Error("Sorting error: missing field supplied.")
        }

        //  self
        if (!sortingArr) {
            if (a[field]) {
                if (b[field]) {
                    return 0
                }
                return -1
            } else {
                return 1
            }
        }
        // arr w/ objs ids for sorting
        else {
            if (sortingArr.includes(a[field])) {
                if (sortingArr.includes(b[field])) {
                    return 0
                }
                return -1
            } else {
                return 1
            }
        }
    })
}

// https://rosettacode.org/wiki/Range_expansion#ES6
/**
 * Takes a string containing a range and ouputs range as list
 *
 * @param str     string containing range ex 5, 3-11, 20
 * @param unique  filter duplicates
 * @param sort    sort asc
 */
export const rangeToList = (str: string, unique = true, sort = true) => {
    // range :: Int -> Int -> Maybe Int -> [Int]
    const range = (m: any, n: any, step: any) => {
        const d = (step || 1) * (n >= m ? 1 : -1)
        return Array.from({ length: Math.floor((n - m) / d) + 1 }, (_, i) => m + i * d)
    }

    // check for invalid chars
    const regex = /[^0-9-,\s]/i
    const match = str.match(regex)
    if (str && match) {
        throw new Error(`Invalid character in range: "${match[0]}"`)
    }

    // remove whitespace around dashes
    const shakenStr = str.replace(/(\s+)?-(\s+)?/g, "-")

    // concat map yields flattened output list
    const listRange: number[] = [].concat.apply(
        [],
        shakenStr
            .split(/[\s,]+/) // split by whitespace and comma
            .map((x) => {
                const xSplit = x.split("-")

                if (xSplit.length > 2) {
                    throw new Error("Multiple dashes in sub-range: " + xSplit.join("-"))
                }

                return xSplit.reduce((accumulator: any = [], current: string, index: number, arr: string[]) => {
                    const currentTrimmed = current.trim()

                    if (currentTrimmed.length) {
                        if (index) {
                        // item is negative if preceded by empty string (arr[index.tsx - 1].length === "")
                            const value = parseInt(arr[index - 1].trim().length ? currentTrimmed : "-" + currentTrimmed, 10)
                            if (value < 0) {
                                throw new Error("Negative value in range: " + value)
                            }
                            return accumulator.concat(value)
                        } else {
                            return [+currentTrimmed]
                        }
                    } else {
                        return accumulator
                    }
                }, [])
            })
            .map((r: any) => (r.length > 1 ? range.apply(null, r) : r)),
    )

    // remove duplicates and sort
    let result = [...listRange]
    if (unique) {
        result = [...new Set(listRange)]
    }
    if (sort) {
        [...result].sort((a, b) => (a > b ? 1 : -1))
    }

    return result
}

/**
 * Finds single toolbar option by name.
 */
export const findToolbarOption = (options: ToolbarOptionType[], name: string) => {
    return options.find((opt) => opt.name === name)
}

/**
 * Returns User group with correct NV5G access and has toolbar option enabled
 *
 * @param userGroup    singular user group option in user object
 */
export const getNv5ClientGroup = (userGroup: UserGroupType) => {
    const nexusToolbarEnabled = userGroup.toolbar_options.some((opt) => opt.enabled && opt.name === "Nexus")

    return nv5gClients.includes(userGroup.client_id) && nexusToolbarEnabled
}

/**
 * Calls setItem and fires a "storage" event. By design, "storage"
 * events don't fire when setItem is called on the current browser tab.
 */
export const setLocalStorageItem = (items: { [key: string]: string }, disableEvent: boolean = false) => {
    for (const [key, value] of Object.entries(items)) {
        localStorage.setItem(key, value)
    }

    if (!disableEvent) {
        (window as any).dispatchEvent(new Event("storage"))
    }
}

/**
 * Loops through Nexus suboptions of each user group and record permissions.
 *
 * Permission granted if at least one usergroup has the suboption enabled.
 * Name must be included in ToolbarOptions
 */
export const generatePermissions = (userGroups: UserGroupType[]) => {
    const permissions: { [key: string]: boolean } = {}

    userGroups.forEach((userGroup) => {
        const nexusOption = findToolbarOption(userGroup.toolbar_options, "Nexus")

        if (nexusOption?.enabled) {
            nexusOption?.suboptions.forEach((suboption) => {
                const { name, enabled } = suboption
                if (enabled && toolbarNames.includes(name)) {
                    permissions[name] = true
                }
            })
        }
    })

    return permissions
}

/**
 * Downloads a file using an anchor tag.
 * @param blob      file
 * @param filename
 */
export const downloadFile = (blob: Blob, filename: string = "nexus_default_export_name.txt") => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = filename
    document.body.appendChild(a) // required for firefox
    a.click()
    a.remove()
}

export const roundDecimals = (value: number, decimals: number) => {
    return Math.floor(value * 10 ** decimals) / 10 ** decimals
}

export const countDecimals = (value: number) => {
    if (Math.floor(value) === value) {
        return 0
    }
    const val = value.toString().split(".")[1]
    return val ? val.length : 0
}

export const groupBy = <T, >(toGroup: T[], groupBy?: (value: T) => any): Map<T, number> => {
    const valueMap = new Map<T, number>()
    toGroup.forEach(value => {
        const groupByValue = groupBy == null ? value : groupBy(value)
        if (!valueMap.has(groupByValue)) {
            valueMap.set(groupByValue, 0)
        }
        valueMap.set(groupByValue, valueMap.get(groupByValue)! + 1)
    })
    return valueMap
}

export const sortedByCount = <T, >(toSortByCount: T[]): T[] => {
    const arrayValues = Array.from(groupBy(toSortByCount), ([value, count]) => ({ value, count }))
    return arrayValues
        .sort((item1, item2) => (item1.count - item2.count))
        .map(toMap => toMap.value)
}

/**
 * Useful for debugging and dev purposes, pretty print JSON objects with a message.
 */
export const printPrettyJson = (message: string, ...objs: any[]) => {
    log.debug(message + "\n\n" + objs.map((obj, idx) => (idx > 0 ? "\n\n" : "") + JSON.stringify(obj, null, 2)))
}

export const isProdNode = process.env.NODE_ENV && process.env.NODE_ENV === "production"
export const isDevNode = !process.env.NODE_ENV || process.env.NODE_ENV === "development"

/**
 * Comparison functions for table sort
 */
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] && a[orderBy] && b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] && a[orderBy] && b[orderBy] > a[orderBy]) {
        return 1
    }
    if (a[orderBy] && !b[orderBy]) {
        return 1
    }
    if (!a[orderBy] && b[orderBy]) {
        return -1
    }
    return 0
}

export function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export const log = loglevel
log.setDefaultLevel(isDevNode ? "debug" : "info")

export const snakeCaseToTitleCase = (text: string) => {
    return text.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
        .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase())
}

export const getFormat = (format: TimeFormat) =>
    format === TimeFormat.twelve ? { formatString: "hh:mm:ss A", formatHourTwelve: true } : {
        formatString: "HH:mm:ss",
        formatHourTwelve: false,
    }
