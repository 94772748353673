import { useState } from "react"

import { Grid } from "@mui/material"

import LoadingButton from "../../components/LoadingButton"
import Menu from "../../components/Menu"

import { OptionType } from "../../store/types/appTypes"

interface MissionUploadButtonProps {
    disabled: boolean
    onChange: any
    options: OptionType[]
    pending: boolean
}

export default function MissionUploadButton({ disabled, onChange, options, pending }: MissionUploadButtonProps) {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: any) => {
        if (options && options.length > 0) {
            setAnchorEl(event.currentTarget)
        } else {
            onChange()
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuChange = (option: string) => {
        onChange(option)
        handleClose()
    }

    return (
        <Grid>
            <LoadingButton
                pending={pending}
                disabled={disabled}
                onClick={handleClick}
                variant="outlined"
                color="primary"
                size="large"
            >
                Submit Log without Uploading
            </LoadingButton>
            {options.length > 0 && (
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    options={options}
                    elevation={2}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    renderOption={(opt: any) => opt.label}
                    onChange={handleMenuChange}
                    onClose={handleClose}
                />
            )}
        </Grid>
    )
}
