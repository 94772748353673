import { CheckboxProps, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { Convert, HandleSelect, onSelectRowOverlay, TableColumn } from "../../models/Table"
import { Order, useLocalStorageUtil } from "../../LocalStorageUtil"
import React from "react"
import Checkbox from "../Fields/Checkbox"
import TableCell from "./cells"
import { checkboxName } from "../../utils/tableUtils"

interface CustomCheckboxProps extends CheckboxProps {
    disableSelectAll?: boolean
}

interface TableHeaderProps {
    columns: TableColumn[]
    stickyColumns?: TableColumn[]
    canSelect?: boolean
    disableSelectAll?: boolean
    handleSelect: HandleSelect
    numSelected: number
    numSelectable: number
    overlay?: onSelectRowOverlay
}

export const TableHeader = ({
    columns,
    stickyColumns,
    canSelect,
    disableSelectAll,
    handleSelect,
    numSelected,
    numSelectable,
    overlay,
}: TableHeaderProps) => {

    const checkboxProps: CustomCheckboxProps = {
        indeterminate: !!numSelected && numSelected !== numSelectable,
        checked: canSelect && numSelected === numSelectable,
        onClick: (e) => handleSelect(e.shiftKey),
        disableSelectAll,
    }

    return (
        <TableHead>
            <TableRow>
                {stickyColumns &&
                    <TableCell header sticky>
                        {stickyColumns.map(column =>
                            <TableCell key={column.id} column={column}>
                                <HeaderCell
                                    column={column}
                                    CheckboxProps={checkboxProps}
                                />
                            </TableCell>
                        )}
                    </TableCell>
                }
                {columns.map(column =>
                    <TableCell key={column.id} column={column}>
                        <HeaderCell
                            key={column.id}
                            column={column}
                            CheckboxProps={checkboxProps}
                        />
                    </TableCell>
                )}
                {overlay && <TableCell sx={{ p: 0 }} />}
            </TableRow>
        </TableHead>
    )
}

interface HeaderCellProps {
    column: TableColumn
    CheckboxProps: CustomCheckboxProps
}

const HeaderCell = ({ column, CheckboxProps }: HeaderCellProps) => {
    const [prefDistUnit, prefHeightUnit] = useLocalStorageUtil(state => [state.preferredDistanceUnit(), state.preferredHeightUnit()])
    const { order = Order.asc, orderBy } = useLocalStorageUtil(state => state.sortOrder())
    const saveSortOrder = useLocalStorageUtil(state => state.saveSortOrder)

    const { disableSelectAll, ...checkboxPropsRest } = CheckboxProps
    const select = column.label === checkboxName

    const createSortHandler = () => {
        const isAsc = orderBy === column.id && order === "asc"
        saveSortOrder(column.id, isAsc ? Order.desc : Order.asc)
    }

    let label = column.label
    if (column.convertType === Convert.DISTANCE) {
        label = `${label} (${prefDistUnit})`
    } else if (column.convertType === Convert.HEIGHT) {
        label = `${label} (${prefHeightUnit})`
    }

    return (
        <>
            {!select &&
                <TableSortLabel
                    disabled={column.disableSort}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? Order[order] : "asc"}
                    onClick={createSortHandler}
                >
                    {label}
                </TableSortLabel>
            }
            {(select && !disableSelectAll) &&
                <Checkbox {...checkboxPropsRest} />
            }
        </>
    )
}
