// Variables specific to a deployment tier can be set post-build and retrieved from this interface
// by running `.\node_modules\.bin\react-inject-env set`
// before launching node
// Inspiration: https://dev.to/eslynn/how-to-dynamically-change-your-react-environment-variables-without-re-building-55el
declare global {
    interface Window {
        env: any
    }
}

// change with your own variables
type EnvType = { REACT_APP_API_URL: string }
export const tierEnv: EnvType = { ...process.env, ...window.env }
