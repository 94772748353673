import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    button: {
        position: "relative",
    },
    buttonText__pending: {
        opacity: 0,
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -10,
        marginLeft: -10,
        color: theme.palette.text.disabled,
    },
}))

export default useStyles
