import { create } from "zustand"
import { CollectionEventCreator } from "../../models/CollectionEventCreator"
import { FlightlineEventType } from "../../store/types/missionsTypes"
import { formatTime } from "../../tools"
import { FlightlineStatusOption, unplannedLineValue } from "../../pages/MissionEditor/FlightlineTable"
import { StringOrEmpty } from "../../utils/stringUtils"

export const initialCollectionEventCreation: CollectionEventCreator = {
    planned_line_id: "",
    line_original_name: "",
    coll_event_name: "",
    status: FlightlineStatusOption.UNDER_REVIEW,
    start_time: "",
    stop_time: "",
    duration: "",
    flown_agl: "",
    notes: "",
}

interface CollectionEventEditorState {
    values: CollectionEventCreator
    setValuesFromFlightline: (flightline?: FlightlineEventType) => void
    updateValues: (newValues: CollectionEventCreator) => void
}

const initialEventEditorState = {
    values: initialCollectionEventCreation,
}

export const useCollectionEventEditorStore = create<CollectionEventEditorState>((set) => ({
    ...initialEventEditorState,
    setValuesFromFlightline: (flightline) => {
        let lineInfo: CollectionEventCreator = { ...initialCollectionEventCreation }
        if (flightline) {
            const planned_line_id = flightline.planned_line_id ?? unplannedLineValue
            lineInfo = {
                id: flightline.flight_line_event_id ?? null,
                distance: StringOrEmpty(flightline.flight_line_distance),
                line_original_name: flightline.line_original_name ?? "",
                coll_event_name: flightline.coll_event_name ?? "",
                planned_line_id: StringOrEmpty(planned_line_id),
                status: flightline.flight_status ?? null,
                start_time: formatTime(flightline.flight_line_start_time),
                stop_time: formatTime(flightline.flight_line_stop_time),
                duration: StringOrEmpty(flightline.flight_line_duration),
                flown_agl: StringOrEmpty(flightline.flown_agl),
                notes: flightline.notes ?? "",
            }
        }

        set({ values: { ...initialCollectionEventCreation, ...lineInfo } })
    },
    updateValues: (values) => set({ values }),
}))
