import { memo } from "react"

import Autocomplete from "./Autocomplete"

import { Types } from "../../store/types"
import { EmployeeType } from "../../store/types/missionsTypes"
import { WidthOpt } from "../../assets/inputStyles"
import { DispatchNexus } from "../../store/context"

type EmployeeFieldProps = {
    field: string
    employeeId: string | null | undefined
    employeeList: {
        employees: EmployeeType[]
        project_employees: {
            pilots: string[]
            operators: string[]
            managers: string[]
            reviewers: string[]
        }
        nexus_employees: {
            pilots: string[]
            operators: string[]
            managers: string[]
            reviewers: string[]
        }
    }
    employeeRole: "pilots" | "operators" | "managers" | "reviewers"
    dispatch: DispatchNexus
    required?: boolean
    isReview?: boolean
    label?: string
    width?: WidthOpt
    disabled?: boolean
}

const EmployeeField = memo(({
    field,
    required,
    employeeId,
    employeeRole,
    employeeList,
    dispatch,
    isReview,
    ...other
}: EmployeeFieldProps) => {
    const { employees, project_employees, nexus_employees } = employeeList

    const inMissions = (id: string, arr: string[]) => arr.some((uniqueID) => uniqueID === id)
    const inProject = (id: string) => inMissions(id, project_employees[employeeRole])
    const inNexus = (id: string) => inMissions(id, nexus_employees[employeeRole])

    const options = [...employees].sort((a, b) => {
        const idA = a.employee_id
        const idB = b.employee_id

        const inProjectA = idA && inProject(idA)
        const inProjectB = idB && inProject(idB)

        if (inProjectA) {
            if (inProjectB) {
                return 0
            } else {
                return -1
            }
        }

        const inNexusA = idA && inNexus(idA)
        const inNexusB = idB && inNexus(idB)

        if (inNexusA) {
            if (inProjectB || inNexusB) {
                return 0
            } else {
                return -1
            }
        } else {
            return 0
        }
    })

    const groupBy = (opt: EmployeeType) => {
        const id = opt.employee_id!

        if (inProject(id)) {
            return "Used in This Project"
        } else if (inNexus(id)) {
            return "Used in Other Projects"
        } else {
            return "All Employees"
        }
    }

    const handleChange = (value: any, field: string) => {
        let type = Types.Missions_UpdateMissionData
        if (isReview) {
            type = Types.Missions_UpdateMissionReviewData
        } // mission_review

        dispatch({
            type,
            payload: { [field]: value },
        })
    }

    return (
        <Autocomplete
            value={employees.find((emp: EmployeeType) => emp.employee_id === employeeId) || null}
            options={options}
            getOptionLabel={(emp: EmployeeType) => emp.employee_full_name}
            renderOption={(props, emp) => <li {...props} key={emp.employee_id}>{emp.employee_full_name}</li>}
            groupBy={groupBy}
            onChange={(e, value) => handleChange(value?.employee_id ?? null, field)}
            TextFieldProps={{ helperText: required && "Required", ...other }}
            gutterBottom={required ? "sm" : "md"}
        />
    )
})

export default EmployeeField
