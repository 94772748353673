import { useContext } from "react"
import { Link, Redirect, useParams } from "react-router-dom"

import { Button, Container, Grid } from "@mui/material"

import ProjectTitle from "../../components/ProjectTitle"
import { Body, Label } from "../../components/Typography"

import { RootContext } from "../../store/context"
import routes, { ConfirmationRoutes } from "../../routes"
import useStyles from "./styles"

type ConfirmationMessageType = { label: string, body: string, callToAction?: string, getActionRoute?: any }
type ConfirmationMessagesType = { [key: string]: ConfirmationMessageType }

export const confirmationMessages: ConfirmationMessagesType = {
    [ConfirmationRoutes.MISSION_SUBMITTED]: {
        label: "Submitted Successfully",
        body: "Mission submitted. Would you like to submit another sensor file?",
        callToAction: "Submit Another",
        getActionRoute: (projectNumber: string) => `/${projectNumber}${routes.missionUpload}`,
    },
    [ConfirmationRoutes.MISSION_EDITED]: {
        label: "Updated Successfully",
        body: "Mission updated. Would you like to edit another?",
        callToAction: "Edit Another",
        getActionRoute: (projectNumber: string) => `/${projectNumber}${routes.missionSelect}`,
    },
    [ConfirmationRoutes.FLIGHTPLAN_SUBMITTED]: {
        label: "Submitted Successfully",
        body: "Flightplan submitted. Would you like to submit another?",
        callToAction: "Submit Another",
        getActionRoute: (projectNumber: string) => `/${projectNumber}${routes.flightplanUpload}`,
    },
    [ConfirmationRoutes.FLIGHTPLAN_EDITED]: {
        label: "Updated Successfully",
        body: "Flightplan updated. Would you like to edit another?",
        callToAction: "Edit Another",
        getActionRoute: (projectNumber: string) => `/${projectNumber}${routes.flightplanEditor}/edit`,
    },
    [ConfirmationRoutes.PROJECT_EDITED]: {
        label: "Updated Successfully",
        body: "Project updated. Would you like to continue editing?",
        callToAction: "Edit Again",
        getActionRoute: (projectNumber: string) => `/${projectNumber}${routes.projectEditor}`,
    },
}

export default function Confirmation() {
    const { classes } = useStyles()
    const { confirmationRoute } = useParams<{ [key: string]: string }>()

    const { state } = useContext(RootContext)
    const { app, projects } = state
    const { confirmation } = app
    const { selectedProject } = projects
    const { entityName } = confirmation

    const confirmationMessage = confirmationMessages[confirmationRoute]
    const { label, body, callToAction, getActionRoute } = confirmationMessage

    if (!confirmationMessage) {
        return <Redirect to={routes.home} />
    }

    return (
        <Container maxWidth="md" className={classes.root}>
            <ProjectTitle redirectOnState />

            <Label gutterBottom="sm">
                {entityName}
                {label}
            </Label>
            <Body gutterBottom="lg">{body}</Body>

            <Grid container spacing={2}>
                <Grid item>
                    <Button
                        component={Link}
                        to={routes.home}
                        variant={callToAction ? "outlined" : "contained"}
                        color="primary"
                        size="large"
                    >
                        Return Home
                    </Button>
                </Grid>
                <Grid item>
                    {callToAction && (
                        <Button
                            component={Link}
                            to={getActionRoute && selectedProject ? getActionRoute(selectedProject.project_number) : routes.home}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {callToAction}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}
