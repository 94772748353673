import { parseFlightlineEventNames, rangeToList } from "../../tools"
import { Types } from "../types"
import {
    FlightlineEventType,
    MissionReviewType,
    MissionsActions,
    MissionShippingInfoType,
    MissionsType,
    MissionType,
} from "../types/missionsTypes"
import { initialSelectedMission, initialState } from "../context"
import { FlightPlannedLineType } from "../types/flightplansTypes"
import { MissionActiveFlow } from "../../utils/validateFlightlineEvents"
import { insertTemporaryTableId } from "../../utils/tableUtils"

// fields that should not equal each other
const complements: { [key: string]: string } = {
    sensor_id_1: "sensor_id_2",
    sensor_id_2: "sensor_id_1",
    operator_1_id: "operator_2_id",
    operator_2_id: "operator_1_id",
    pilot_1_id: "pilot_2_id",
    pilot_2_id: "pilot_1_id",
}

const missionsReducer = (state: MissionsType, action: MissionsActions): MissionsType => {
    const cleanupAndMergeFlightNotes = (oldNotes: string | null, newNotes: string | null) => {
        // shared function to clean up common formatting errors in notes field
        const notesDivider = "---"
        const oldNotesSplit = oldNotes?.split(notesDivider).map(note => note.trim()) || null
        const newNotesSplit = newNotes?.split(notesDivider).map(note => note.trim()) || null

        if (newNotesSplit && newNotesSplit?.length === 2) {
            if (oldNotesSplit) {  //combine old user notes (i=0) with new selection's sensor notes (i=1)
                return [oldNotesSplit[0]!, newNotesSplit[1]!].join(notesDivider)
            } else {              //use the new notes now that they have been cleaned
                return newNotesSplit.join(notesDivider)
            }
        } else {
            return oldNotes
        }
    }

    const getSelectedPlannedLines = (): FlightPlannedLineType[] => {
        const { flightPlannedLines, selectedFlightplanIds } = state

        return flightPlannedLines.filter(line => {
            const flightplanId = Number(line.flight_plan_id)
            return selectedFlightplanIds.includes(flightplanId)
        })
    }

    switch (action.type) {
        case Types.Missions_ResetState: {
            return initialState.missions
        }
        case Types.Missions_SelectParsedMission: {
            const { selectedMission } = action.payload
            const { flightline_events, flight_notes: newNotes, serial_number_1, serial_number_2 } = selectedMission
            const { flight_notes: oldNotes } = state.selectedMission
            const { matchingExpression } = state

            const flight_notes: string | null = cleanupAndMergeFlightNotes(oldNotes, newNotes)
            const error = null

            const findSensorIdBySerial = (serial: string) =>
                state.sensorList.sensors.find((s: any) => s.serial_number === serial)?.sensor_id || null

            const sensor_id_1 = findSensorIdBySerial(serial_number_1)
            let sensor_id_2 = null
            if (serial_number_2 && serial_number_1 !== serial_number_2) {
                sensor_id_2 = findSensorIdBySerial(serial_number_2)
            }

            // keep the user's edits if possible
            let mission: MissionType = { ...state.selectedMission }

            // overwrite relevant fields with the new selection
            for (const key in selectedMission) {
                const field = selectedMission[key as keyof MissionType]

                if (field) {
                    mission = { ...mission, [key]: field }
                }
            }

            return {
                ...state,
                flightlineEvents: parseFlightlineEventNames(flightline_events, matchingExpression.active, getSelectedPlannedLines()),
                selectedMission: {
                    ...mission,
                    flight_notes,
                    sensor_id_1,
                    sensor_id_2,
                },
                missionsError: error,
            }
        }
        case Types.Missions_SelectMissionId: {
            return {
                ...state,
                selectedMission: initialSelectedMission,
                selectedMissionId: action.payload.selectedMissionId,
            }
        }
        case Types.Missions_SelectFlightplans: {
            return {
                ...state,
                selectedFlightplans: action.payload.selectedFlightplans,
            }
        }
        case Types.Missions_SelectFlightplanList: {
            return {
                ...state,
                selectedFlightplanIds: action.payload.selectedFlightplanIds,
            }
        }
        case Types.Missions_SelectActiveFlow: {
            const { activeFlow } = action.payload

            return { ...state, activeFlow }
        }
        case Types.Missions_ClearImportError: {
            return {
                ...state,
                importExpression: {
                    ...state.importExpression,
                    error: "",
                },
            }
        }
        case Types.Missions_ImportFlightplannedLines: {
            const { range } = action.payload
            const {
                importExpression,
                flightPlannedLines,
                flightlineEvents,
                selectedFlightplans,
                selectedMission,
            } = state
            let parsedRange: number[] = []
            // convert flightplans to array of ids
            const _flightplan_ids = selectedFlightplans.map((plan) => plan.flight_plan_id)

            try {
                parsedRange = rangeToList(range)
            } catch (error) {
                return {
                    ...state,
                    importExpression: {
                        ...importExpression,
                        error: error.message,
                    },
                }
            }

            // get flightPlannedLines that are in "range" using flight_line_names
            let importedPlannedLines: FlightPlannedLineType[] = flightPlannedLines.filter((line) => parsedRange.includes(+line.flight_line_name))

            // further filtering by flightplans
            importedPlannedLines = importedPlannedLines.filter((line) => {
                return _flightplan_ids.includes(Number(line.flight_plan_id))
            })

            const newImports: FlightlineEventType[] = importedPlannedLines.map((pl): FlightlineEventType => {
                // Find current flightlineEvent data if exists
                const currEvent = flightlineEvents.find(line => line.planned_line_id === pl.planned_line_id)
                return {
                    planned_line_id: pl.planned_line_id,
                    flightline_name: pl.flight_line_name,
                    coll_event_name: "",
                    flight_line_distance: pl.planned_distance ?? 0,
                    flight_line_duration: 0,
                    flight_line_event_id: null,
                    flight_line_start_time: "",
                    flight_line_stop_time: "",
                    flight_status: "",
                    flown_agl: 0,
                    line_original_name: "",
                    notes: "",
                    mission_id: selectedMission.mission_id,
                    ...(currEvent),
                }
            })

            //merge
            const newFlightlineEvents: FlightlineEventType[] = [...newImports]
                .sort((a, b) =>
                    (a.flightline_name && b.flightline_name) && a.flightline_name > b.flightline_name ? 1 : -1,
                ).map((fl, idx): FlightlineEventType =>
                // Add temp flight_line_event_id for editing purposes
                    ({ ...fl, flight_line_event_id: insertTemporaryTableId(idx) }),
                )

            return {
                ...state,
                flightlineEvents: newFlightlineEvents,
                importExpression: {
                    value: range,
                    error: "",
                },
            }
        }
        case Types.Missions_UpdateMissionData: {
            const { selectedMission, activeFlow } = state
            let newState: Partial<MissionType> = {}

            for (const field in action.payload) {
                const value = action.payload[field as keyof MissionType]
                const complement = complements[field]
                const complementValue = selectedMission[complement as keyof MissionType]

                newState = { ...newState, [field]: value }

                if (value && value === complementValue) {
                    newState = { ...newState, [complement]: null }
                }
            }

            // mission_name
            let { sensor_id_1, flown_date, flight_num } = selectedMission
            if (action.payload.sensor_id_1) {
                sensor_id_1 = action.payload.sensor_id_1
            }
            if (action.payload.flown_date) {
                flown_date = action.payload.flown_date
            }
            if (action.payload.flight_num) {
                flight_num = action.payload.flight_num
            }

            if (sensor_id_1 && flown_date) {
                const sensor = state.sensorList.sensors.find((s) => s.sensor_id === sensor_id_1)
                const sensorNumber = sensor?.serial_number
                const date = flown_date.replace(/\D/g, "")

                let missionName = `${sensorNumber}_${date}`

                // Only sets missionName with flight number if valid mission workflow
                if (flight_num !== null && activeFlow !== MissionActiveFlow.NO_FLIGHT) {
                    missionName = `${missionName}_F${flight_num}`
                }

                newState.mission_name = missionName
            }

            return {
                ...state,
                selectedMission: {
                    ...state.selectedMission,
                    ...newState,
                },
            }
        }
        case Types.Missions_UpdateMissionShippingInfoData: {
            let newState: MissionShippingInfoType | null | undefined = state.selectedMission.mission_shipping_info

            if (!newState) {
                newState = {
                    mission_shipping_id: null,
                    mission_id: state.selectedMission.mission_id || null,
                    plane_drive_name: null,
                    backup_drive_name: null,
                    delivery_drive_name: null,
                    shipping_number: null,
                    office_id: null,
                    raw_storage_size: null,
                }
            }

            for (const field in action.payload) {
                newState = { ...newState, [field]: action.payload[field] }
            }

            return {
                ...state,
                selectedMission: {
                    ...state.selectedMission,
                    mission_shipping_info: {
                        ...state.selectedMission.mission_shipping_info,
                        ...newState,
                    },
                },
            }
        }
        case Types.Missions_UpdateMissionReviewData: {
            let newState: MissionReviewType | null | undefined = state.selectedMission.mission_review

            if (!newState) {
                newState = {
                    mission_review_id: null,
                    mission_id: state.selectedMission.mission_id || null,
                    review_date: null,
                    reviewer_id: null,
                    reviewer_notes: null,
                }
            }

            for (const field in action.payload) {
                newState = { ...newState, [field]: action.payload[field as keyof MissionReviewType] }
            }

            return {
                ...state,
                selectedMission: {
                    ...state.selectedMission,
                    mission_review: {
                        ...state.selectedMission.mission_review,
                        ...newState,
                    },
                },
            }
        }
        case Types.Missions_UpdateFlightlineEventData: {
            return {
                ...state,
                flightlineEvents: action.payload.rows,
            }
        }
        /* todo: generalize to allow batch edit of any field? */
        case Types.Missions_UpdateFlightlineEventNotes: {
            const { startIndex, notesList } = action.payload
            const { flightlineEvents } = state

            const stopIndex = (startIndex + notesList.length)

            const result = flightlineEvents.map((event, index) => {
                if (index >= startIndex && index < stopIndex) {
                    event.notes = notesList[index - startIndex]
                }
                return event
            })

            return {
                ...state,
                flightlineEvents: result,
            }
        }
        case Types.Missions_UpdateFlightlineEventStatuses: {
            const { indexes, status } = action.payload
            const { flightlineEvents } = state

            const result = flightlineEvents.map((event, index) => {
                if (indexes.includes(index)) {
                    event.flight_status = status
                }
                return event
            })

            return {
                ...state,
                flightlineEvents: result,
            }
        }
        case Types.Missions_UpdateMatchingExpressionData: {
            const { matchingExpression, flightlineEvents } = state
            const newMatchingExpression = { ...matchingExpression, ...action.payload }

            newMatchingExpression.active = newMatchingExpression.isCustom
                ? newMatchingExpression.custom
                : newMatchingExpression.selectedTemplate?.value || ""

            const newState = {
                ...state,
                matchingExpression: newMatchingExpression,
            }

            if (matchingExpression.active !== newMatchingExpression.active) {
                newState.flightlineEvents = parseFlightlineEventNames(flightlineEvents, newMatchingExpression.active, getSelectedPlannedLines())
            }

            return newState
        }
        case Types.Missions_UpdateIsVendorFlow: {
            return {
                ...state,
                isVendorFlow: action.payload,
            }
        }
        case Types.Missions_FetchMissionNameList: {
            return {
                ...state,
                missionNameList: [],
                loadingMissionNameList: true,
            }
        }
        case Types.Missions_FetchMissionNameListSuccess: {
            return {
                ...state,
                missionNameList: action.payload.missionNameList,
                loadingMissionNameList: false,
            }
        }
        case Types.Missions_FetchMission: {
            return { ...state, selectedMission: initialSelectedMission }
        }
        case Types.Missions_FetchMissionSuccess: {
            const { selectedMission } = action.payload

            const flight_notes: string | null = cleanupAndMergeFlightNotes(null, selectedMission.flight_notes)

            return {
                ...state,
                selectedMission: {
                    ...selectedMission,
                    flight_notes,
                },
                isVendorFlow: Boolean(selectedMission.vendor_name),
            }
        }
        case Types.Missions_DeleteMission: {
            return {
                ...state,
                isDeleting: true,
            }
        }
        case Types.Missions_DeleteMissionSuccess:
        case Types.Missions_DeleteMissionError: {
            return {
                ...state,
                isDeleting: false,
            }
        }
        case Types.Missions_FetchFlightlineEvents: {
            return { ...state, flightlineEvents: [] }
        }
        case Types.Missions_FetchFlightlineEventsSuccess: {
            const { flightlineEvents, selectedFlightplans, flightPlannedLines, selectedFlightplanIds } = action.payload
            return {
                ...state,
                flightlineEvents,
                selectedFlightplans,
                flightPlannedLines,
                selectedFlightplanIds,
            }
        }
        case Types.Missions_FetchFlightplannedLines: {
            return { ...state, loadingFlightPlannedList: true }
        }
        case Types.Missions_FetchFlightplannedLinesSuccess: {
            const { flightPlannedLines } = action.payload

            return {
                ...state,
                flightPlannedLines,
                loadingFlightPlannedList: false,
            }
        }
        case Types.Missions_FetchParsedMissions: {
            return { ...state, parsedMissions: [] }
        }
        case Types.Missions_FetchParsedMissionsSuccess: {
            const { parsedMissions, warnings, best_regex } = action.payload

            return {
                ...state,
                parsedMissions,
                warnings,
                matchingExpression: {
                    active: best_regex.value,
                    custom: best_regex.value,
                    selectedTemplate: best_regex,
                    isCustom: false,
                },
            }
        }
        case Types.Missions_FetchEmployeeList: {
            return {
                ...state,
                employeeList: initialState.missions.employeeList,
            }
        }
        case Types.Missions_FetchEmployeeListSuccess: {
            return {
                ...state,
                employeeList: action.payload.employeeList,
            }
        }
        case Types.Missions_FetchVendorNameList: {
            return {
                ...state,
                vendorNameList: initialState.missions.vendorNameList,
            }
        }
        case Types.Missions_FetchVendorNameListSuccess: {
            return {
                ...state,
                vendorNameList: action.payload.vendorNameList,
            }
        }
        case Types.Missions_FetchFlightplanList: {
            return {
                ...state,
                flightplanList: [],
                loadingFlightplanList: true,
            }
        }
        case Types.Missions_FetchFlightplanListSuccess: {
            return {
                ...state,
                flightplanList: action.payload.flightplanList,
                loadingFlightplanList: false,
            }
        }
        case Types.Missions_FetchSensorList: {
            return {
                ...state,
                sensorList: { sensors: [], project_sensors: [] },
            }
        }
        case Types.Missions_FetchSensorListSuccess: {
            return {
                ...state,
                sensorList: action.payload.sensorList,
            }
        }
        case Types.Missions_FetchPlatformList: {
            return {
                ...state,
                platformList: { platforms: [], project_platforms: [] },
            }
        }
        case Types.Missions_FetchPlatformListSuccess: {
            return {
                ...state,
                platformList: action.payload.platformList,
            }
        }
        case Types.Missions_FetchAirportList: {
            return {
                ...state,
                airportList: [],
            }
        }
        case Types.Missions_FetchAirportListSuccess: {
            return {
                ...state,
                airportList: action.payload.airportList,
            }
        }
        case Types.Missions_UpdateError: {
            const { error } = action.payload

            return {
                ...state,
                missionsError: typeof error === "string" ? error : error?.message || null,
            }
        }
        case Types.Missions_ClearError: {
            return { ...state, missionsError: null }
        }
        default:
            return state
    }
}

export default missionsReducer
