import { DatePicker, LocalizationProvider, DatePickerProps } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

import useStyles from "./styles"

interface CustomDatePickerProps<T> extends DatePickerProps<T> {
    gutterBottom?: "md" | "sm"
    helperText?: string
    required?: boolean
    minDateMessage?: string
}

export const DatePickerCustom = <T, >({
    value,
    gutterBottom = "md",
    helperText,
    required,
    ...rest
}: CustomDatePickerProps<T>) => {
    const { classes, cx } = useStyles()

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                format="MM/DD/YYYY"
                value={value}
                className={cx(classes.input__sm, !!gutterBottom && classes[`gutterBottom__${gutterBottom}`])}
                slotProps={{
                    textField: {
                        helperText: helperText ?? (required && "Required"),
                        variant: "outlined",
                        fullWidth: true,
                    },
                    toolbar: { hidden: true },
                }}
                {...rest}
            />
        </LocalizationProvider>
    )
}
