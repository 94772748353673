import React, { useContext, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"

import { Button, Container, Grid, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"

import Alert from "../../components/Alert"
import Autocomplete from "../../components/Fields/Autocomplete"
import Dropzone from "../../components/Fields/Dropzone"
import LoadingButton from "../../components/LoadingButton"
import ProjectTitle from "../../components/ProjectTitle"
import { Body, Label } from "../../components/Typography"

import MissionUploadButton from "./MissionUploadButton"

import { RootContext } from "../../store/context"
import { Types } from "../../store/types"
import { FlightplanType } from "../../store/types/flightplansTypes"
import { fetchProject } from "../../store/actions/projectsActions"
import {
    fetchFlightplanList,
    fetchFlightPlannedLinesForMission,
    parseSensorFile,
} from "../../store/actions/missionsActions"
import routes from "../../routes"
import useStyles from "./styles"
import {
    MissionActiveFlow,
    MissionTypeOptions,
    setNoMatchToIgnored,
    setRegexErrorToIgnored,
    validateFlightplanLines,
} from "../../utils/validateFlightlineEvents"
import { MissionType } from "../../store/types/missionsTypes"
import useMissions from "../../hooks/useMissions"
import { MissionValidatorDialog } from "./MissionValidatorDialog"

export interface FlightLineConflict {
    conflicts: string[]
    planName: string
}

export interface FlightLineEventErrorMessage {
    missionName: string
    totalLines: number
    conflictLines?: FlightLineConflict[]
    noMatchLines?: string[]
    regexErrorLines?: string[]
    showConflictLines: boolean
    showNoMatchLines: boolean
    showRegexErrorLines: boolean
}

export default function MissionUpload({ history }: any) {
    const { classes } = useStyles()
    const { projectNumber } = useParams<{ [key: string]: string }>()
    const { state, dispatch } = useContext(RootContext)
    const { error } = state.app
    const { selectedProject } = state.projects
    const {
        flightplanList,
        loadingFlightplanList,
        loadingFlightPlannedList,
        flightPlannedLines,
        parsedMissions,
        selectedFlightplanIds,
    } = state.missions
    const { handleSelectFlightplanIds } = useMissions()

    const [sensorFilesInfo, setSensorFilesInfo] = useState<{
        sensorFiles: File[]
        sameFilesExt: boolean
    }>({ sensorFiles: [], sameFilesExt: true })
    const [loading, setLoading] = useState(false)
    const [loadingAcquisition, setLoadingAcquisition] = useState(false)
    const [flightlineEventErrors, setFlightlineEventErrors] = useState<FlightLineEventErrorMessage[] | null>(null)

    const selectedFlightplanList = () => flightplanList.filter((fp) => selectedFlightplanIds.includes(Number(fp?.flight_plan_id)))

    // options for mission editor flow
    const missionTypeOptions: MissionTypeOptions[] = [
        { id: MissionActiveFlow.NO_FLIGHT, label: "No Flight Today" },
        { id: MissionActiveFlow.NO_ACQUISITION, label: "Non-Acquisition Flight" },
        {
            id: MissionActiveFlow.NO_FILE,
            label: "Acquisition Flight w/o File",
            disabled: !selectedFlightplanIds.length,
            tooltip: !selectedFlightplanIds.length ? "Select a flightplan to enable" : "",
        },
        {
            id: MissionActiveFlow.FULL_FLIGHT,
            label: "Upload File",
            disabled: !selectedFlightplanIds.length || !sensorFilesInfo.sensorFiles.length,
        },
    ]
    const flightplanRequired = [MissionActiveFlow.NO_FILE, MissionActiveFlow.FULL_FLIGHT]
    const fileRequired = [MissionActiveFlow.FULL_FLIGHT]

    useEffect(() => {
        if (selectedFlightplanIds.length) {
            fetchFlightPlannedLinesForMission(dispatch, selectedFlightplanIds)
        }
    }, [dispatch, selectedFlightplanIds])

    useEffect(() => {
        dispatch({ type: Types.App_ClearError })
        dispatch({ type: Types.Missions_ResetState })
        dispatch({ type: Types.Missions_FetchFlightplanList })

        const fetchData = async () => {
            if (projectNumber && !selectedProject?.project_number) {
                await fetchProject(dispatch, projectNumber)
            }
            await fetchFlightplanList(dispatch, projectNumber)
        }

        fetchData()
    }, []) // eslint-disable-line

    const handleChangeFile = (newFiles: File[]) => {
        let sameExt = true
        // check that all files have the same ext.
        newFiles.reduce((acc: string[], cur) => {
            const curExt = cur.name.slice(cur.name.length - 3)
            if (acc.length && !acc.includes(curExt)) {
                sameExt = false
            }
            return [...acc, curExt]
        }, [])

        setSensorFilesInfo({ sensorFiles: newFiles, sameFilesExt: sameExt })
        if (!sameExt) {
            dispatch({
                type: Types.App_UpdateError,
                payload: { error: "Files are not of the same type" },
            })
        }
    }

    const handleFlightplanChange = (values: FlightplanType[]) => {
        const flightIds = values.reduce((idArray: number[], cur) => {
            if (cur.flight_plan_id) {
                return [...idArray, cur.flight_plan_id]
            } else {
                return idArray
            }
        }, [])
        handleSelectFlightplanIds([...flightIds])
    }

    // validates
    useEffect(() => {
        if (loadingAcquisition) {
            const validationErrors = validateFlightplanLines(parsedMissions, flightPlannedLines)
            const errorStringArray: FlightLineEventErrorMessage[] = []

            if (Object.keys(validationErrors).length) {
                // mission display names are the keys
                for (const [missionName, errorObject] of Object.entries(validationErrors)) {
                    const commonFlightlineConflicts: { [key: number]: string[] } = {}
                    // grouping conflicts assigned with same plan id
                    for (const [flight_line_id, { flight_plan_id }] of Object.entries(errorObject.conflictErrors)) {
                        if (commonFlightlineConflicts[flight_plan_id]) {
                            commonFlightlineConflicts[flight_plan_id] = [...commonFlightlineConflicts[flight_plan_id], flight_line_id]
                        } else {
                            commonFlightlineConflicts[flight_plan_id] = [flight_line_id]
                        }
                    }
                    let conflictLines: FlightLineConflict[] = []
                    for (const [commonPlan, valueArray] of Object.entries(commonFlightlineConflicts)) {
                        const flight_plan_name = selectedFlightplanList().find(fp => fp.flight_plan_id === Number(commonPlan))?.flight_plan_name ?? commonPlan
                        conflictLines = [...conflictLines, { conflicts: valueArray, planName: flight_plan_name }]
                    }
                    const missionSpecificErrorMessage: FlightLineEventErrorMessage =
                        {
                            missionName,
                            conflictLines,
                            noMatchLines: errorObject.noMatchErrors,
                            regexErrorLines: errorObject.regexErrors,
                            showConflictLines: false,
                            showNoMatchLines: false,
                            showRegexErrorLines: false,
                            totalLines: errorObject.numberOfFlightLines,
                        }

                    errorStringArray.push(missionSpecificErrorMessage)
                }
                setFlightlineEventErrors(errorStringArray)
            } else {
                moveToStepper(missionTypeOptions[3])
            }
        }
        setLoadingAcquisition(false)
    }, [loadingAcquisition]) // eslint-disable-line

    const handleMissionFlowSelect = async (option: MissionTypeOptions) => {
        setLoading(true)
        // parse file
        if (fileRequired.includes(option.id)) {
            parseSensorFile(dispatch, sensorFilesInfo.sensorFiles, projectNumber!, flightPlannedLines).then((success) => {
                if (success) {
                    setLoadingAcquisition(true)
                } else {
                    setLoading(false)
                }
            })
        } else {
            await moveToStepper(option)
        }
    }

    const closeModal = async () => {
        setFlightlineEventErrors(null)
        await setLoading(false)
    }

    const moveToStepper = async (option: MissionTypeOptions) => {
        await closeModal()
        if (flightplanRequired.includes(option.id)) {
            // save flightplan
            const selectedFlightplans = selectedFlightplanList()

            // set events to Ignored status if the flightplan couldn't be found
            const noMatchFlightlineEvents = flightlineEventErrors?.reduce((acc: string[], cur) => {
                return cur.noMatchLines ? [...acc, ...cur.noMatchLines] : acc
            }, [])

            if (noMatchFlightlineEvents) {
                setNoMatchToIgnored(noMatchFlightlineEvents, parsedMissions as MissionType[])
            }

            // set events to Ignored status if the flightplan couldn't be found
            const regexErrorFlightlineEvents = flightlineEventErrors?.reduce((acc: string[], cur) => {
                return cur.regexErrorLines ? [...acc, ...cur.regexErrorLines] : acc
            }, [])

            if (regexErrorFlightlineEvents) {
                setRegexErrorToIgnored(regexErrorFlightlineEvents, parsedMissions as MissionType[])
            }

            dispatch({
                type: Types.Missions_SelectFlightplans,
                payload: { selectedFlightplans },
            })
        }
        // save selected option
        dispatch({
            type: Types.Missions_SelectActiveFlow,
            payload: { activeFlow: option.id },
        })
        // go to mission editor
        await history.push(`/${projectNumber}${routes.missionEditor}/submit`)
    }

    return (
        <Container maxWidth="md" className={classes.root}>
            <ProjectTitle redirectOnParamNorState />
            <Grid className={classes.gutterBottom__md}>
                <Label gutterBottom="sm">Sensor File Upload</Label>
                <Body>Drag and drop your files here, or click the dropzone to select.</Body>
                <Body>Select "No Flight Today" if you don't have a sensor file and only want to submit a daily log.</Body>
            </Grid>

            <Dropzone
                acceptedFiles={[".rpp", ".zip"]}
                filesLimit={9}
                onChange={handleChangeFile}
            />

            <Label gutterBottom="sm">Select the Flightplans Used</Label>
            <Autocomplete
                multiple
                TextFieldProps={{ label: "Select" }}
                getOptionDisabled={(option: FlightplanType) => option.status !== "Active"}
                options={flightplanList}
                getOptionLabel={(opt: FlightplanType) => opt.flight_plan_name + (opt.status === "Active" ? "" : ` (${opt.status})`)}
                onChange={(e, value) => handleFlightplanChange(value)}
                gutterBottom="lg"
                width="lg"
                loading={loadingFlightplanList || loadingFlightPlannedList}
            />

            {error && (
                <Alert animate onClose={() => dispatch({ type: Types.App_ClearError })} gutterBottom={"md"}>
                    {error}
                </Alert>
            )}

            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Button component={Link} to={routes.home} variant="outlined" color="primary" size="large">
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <MissionUploadButton
                                options={missionTypeOptions.slice(0, 3)}
                                pending={loading && !loadingAcquisition}
                                disabled={loadingAcquisition}
                                onChange={handleMissionFlowSelect}
                            />
                        </Grid>
                        <Grid item>
                            {/* Upload Button */}
                            <LoadingButton
                                pending={loadingAcquisition}
                                size="large"
                                color="primary"
                                variant="contained"
                                disabled={!(sensorFilesInfo.sensorFiles.length && selectedFlightplanIds.length !== 0) || (loading && !loadingAcquisition) || !sensorFilesInfo.sameFilesExt || loadingFlightPlannedList}
                                onClick={() => handleMissionFlowSelect(missionTypeOptions[3])}
                            >
                                {missionTypeOptions[3].label}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Dialog fullWidth open={!!flightlineEventErrors} onClose={closeModal}>
                {flightlineEventErrors &&
                    <MissionValidatorDialog
                        setFlightlineEventErrors={setFlightlineEventErrors}
                        flightlineEventErrors={flightlineEventErrors}
                    />
                }
                <DialogContent sx={{ overflowY: "initial" }}>
                    <DialogContentText variant={"h6"}>Do you wish to proceed?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button color={"primary"} onClick={() => moveToStepper(missionTypeOptions[3])}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

MissionUpload.defaultProps = {}
