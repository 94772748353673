import { makeStyles } from "tss-react/mui"
import animations from "../../../assets/animations"
import spacing from "../../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...animations(theme),
    ...spacing(theme),

    icon: {
        opacity: 0.7,
        animationDelay: "0.62s",
        transition: "0.18s all ease-in-out",

        "&:hover": {
            opacity: 0.9,
        },
    },

    text_key: {
        color: theme.palette.grey[700],
        fontWeight: 400,
        marginBottom: theme.spacing(0.25),
        marginLeft: theme.spacing(1),
    },
    text_value: {
        marginBottom: theme.spacing(0.25),
        marginRight: theme.spacing(0.625),
    },
}))

export default useStyles
