import React, { useContext, useState, useEffect, useRef, ReactNode } from "react"

import { Button, Container, ContainerProps, Grid, LinearProgress, Paper, Tooltip } from "@mui/material"

import Alert from "../../components/Alert"
import LoadingButton from "../../components/LoadingButton"
import Stepper from "../../components/Stepper"
import { Body } from "../Typography"
import { Validator, ValidatorModel } from "../../validation"

import { RootContext } from "../../store/context"
import { Types } from "../../store/types"
import useDebouncer from "../../hooks/useDebouncer"
import useStyles from "./styles"

interface StepperFormProps extends ContainerProps {
    activeStep: number
    disableSubmit?: boolean
    empty?: boolean
    emptyText?: string
    error?: string | null
    header: ReactNode
    loading?: boolean
    onErrorClose?: () => void
    onStepChange: (newStep: number) => (Promise<void> | void)
    secondaryAction?: ReactNode
    steps: { label: string, key: string }[]
    submitting: boolean
    submitTooltip?: string
    validationProps: { dependencies: React.DependencyList, model: ValidatorModel }
    warnings: string[]
}

export default function StepperForm({
    activeStep,
    children,
    className,
    disableSubmit,
    empty,
    emptyText,
    error,
    header,
    loading,
    onErrorClose,
    onStepChange,
    secondaryAction,
    steps,
    submitting,
    submitTooltip,
    validationProps,
    warnings,
}: StepperFormProps) {
    const { classes, cx } = useStyles()
    const { state, dispatch } = useContext(RootContext)
    const { error: appError } = state.app

    const [completedSteps, setCompletedSteps] = useState<boolean[]>(Array(steps.length).fill(false))
    const stepLabels = steps.map((step: { label: string, key: string }) => step.label)
    const stepGroups = steps.length > 0 ? steps.map((step: { label: string, key: string }) => step.key) : null
    const isSingleStep = (steps && steps.length === 0) || false
    const isFinalStep = isSingleStep || activeStep === steps.length - 1
    const isValidating = Object.keys(validationProps).length > 0

    const { dependencies, model } = validationProps

    const scrollRef = useRef<HTMLDivElement | null>(null)

    // validation
    const getValidator = () => {
        return isValidating ? new Validator(model) : null
    }
    const [validator, setValidator] = useState<Validator | null>(getValidator())
    const isValid = validator && Object.keys(validator.validatorErrors).length === 0

    useEffect(() => {
        if (!loading) {
            if (isValidating) {
                const newValidator = getValidator()!
                newValidator.validate(stepGroups)
                if (stepGroups) {
                    handleCompletedSteps(newValidator)
                }
                setValidator(newValidator)
            }
        }
    }, [loading]) // eslint-disable-line

    const handleValidation = useDebouncer(function(model: ValidatorModel, stepGroups: string[]) {
        const newValidator = new Validator(model)
        newValidator.validate(stepGroups)

        if (stepGroups) {
            handleCompletedSteps(newValidator)
        }
        setValidator(newValidator)
    }, 170)

    useEffect(() => {
        if (!loading && isValidating) {
            handleValidation(model, stepGroups)
        }
    }, dependencies) // eslint-disable-line

    const handleCompletedSteps = (validator: Validator) => {
        if (stepGroups) {
            setCompletedSteps(stepGroups.map((grp: string) => validator.isGroupValid(grp)))
        }
    }

    const getValidatorErrors = (): ReactNode[] => {
        const result = []
        if (!validator) {
            return []
        }

        for (const key in validator!.validatorErrors) {
            const error = validator!.validatorErrors[key]
            const { errorMessage, displayMessage } = error!
            let message: ReactNode = errorMessage

            if (displayMessage) {
                const splitMessage = displayMessage.split(":")
                message = (
                    <>
                        <strong>{splitMessage[0]}:</strong>
                        {splitMessage[1]}
                    </>
                )
            }

            result.push(
                <Grid key={key}>
                    <li>{message}</li>
                </Grid>,
            )
        }

        return result
    }

    // general errors
    useEffect(() => {
        if (error || appError) {
            const top = scrollRef.current!.offsetTop - 67
            window.scrollTo({ top, behavior: "smooth" })
        }
    }, [error, appError])

    const handleErrorClose = () => {
        dispatch({ type: Types.App_ClearError })
        if (onErrorClose) {
            onErrorClose()
        }
    }

    // steps
    const handleContinue = () => {
        onStepChange(activeStep + 1)
    }

    const handleBack = () => {
        onStepChange(activeStep - 1)
    }

    return (
        <Container maxWidth={"lg"} className={cx(classes.root, className)}>
            {header}
            <Paper className={classes.editorRoot} ref={scrollRef}>
                {!isSingleStep && (
                    <Grid className={classes.gutterBottom__md}>
                        <Stepper
                            steps={stepLabels}
                            completedSteps={loading ? [] : completedSteps}
                            activeStep={activeStep}
                            onClick={onStepChange}
                            disabled={loading}
                            className={cx({ [classes.stepper__narrow]: steps.length === 2 })}
                        />
                    </Grid>
                )}

                {(error || appError) && (
                    <Alert animate onClose={handleErrorClose}>
                        {error || appError}
                    </Alert>
                )}

                {warnings.map((warning: string, idx: number) => (
                    <Alert key={idx} severity="warning">{warning}</Alert>
                ))}

                {loading ? (
                    <LinearProgress sx={{ mb: 2, marginTop: isSingleStep ? 2 : 0 }} />
                ) : (
                    <>
                        {empty ? (
                            <Body className={cx(classes.empty_text, classes.gutterBottom__md)}>{emptyText}</Body>
                        ) : (
                            <>
                                <Grid className={classes.gutterBottom__md}>{children}</Grid>

                                {isValidating && isFinalStep && !isValid && (
                                    <Alert
                                        title="Please fix the following input validation errors:"
                                        gutterBottom="lg"
                                        className={classes.validation__alert}
                                    >
                                        {getValidatorErrors()}
                                    </Alert>
                                )}
                            </>
                        )}
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Button onClick={handleBack} variant="outlined" color="primary" size="large">
                                    Back
                                </Button>
                            </Grid>
                            <Grid item sx={{ display: "flex", gap: 2 }}>
                                {secondaryAction}
                                <Tooltip title={(isFinalStep && submitTooltip) || ""} arrow enterDelay={300}>
                                    <div>
                                        <LoadingButton
                                            onClick={handleContinue}
                                            pending={submitting}
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disabled={
                                                (isFinalStep && (isValidating ? !isValid : false)) || (disableSubmit && isFinalStep) || empty
                                            }
                                        >
                                            {isFinalStep ? "Submit" : "Continue"}
                                        </LoadingButton>
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Paper>
        </Container>
    )
}

StepperForm.defaultProps = {
    activeStep: 0,
    disableSubmit: false,
    steps: [],
    completedSteps: [],
    validationProps: {},
    emptyText: "Content failed to load.",
    warnings: [],
}
