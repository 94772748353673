import { useContext, useEffect } from "react"
import { Link, useParams } from "react-router-dom"

import { Button, Container, Grid, Typography } from "@mui/material"
import { red } from "@mui/material/colors"

import Alert from "../../components/Alert"
import Autocomplete from "../../components/Fields/Autocomplete"
import ProjectTitle from "../../components/ProjectTitle"
import { Label } from "../../components/Typography"

import { RootContext } from "../../store/context"
import { Types } from "../../store/types"
import { MissionNameType, MissionReviewStatusType } from "../../store/types/missionsTypes"
import { fetchProject } from "../../store/actions/projectsActions"
import routes from "../../routes"
import useStyles from "./styles"
import { fetchMissionNameList } from "../../store/actions/missionsActions"
import { useFindReviewStatus } from "../../api/MissionEditApi"
import LinearProgressWithLabel from "../../components/LinearProgressWithLabel"
import { getPercentComplete, getStatusDesign, ReviewStatusText } from "../../utils/getMissionStatusDesigns"

export default function MissionEdit() {
    const { classes } = useStyles()
    const { projectNumber } = useParams<{ [key: string]: string }>()
    const { state, dispatch } = useContext(RootContext)
    const { error } = state.app
    const { selectedProject } = state.projects
    const { missionNameList, selectedMissionId, loadingMissionNameList } = state.missions

    const reviewStatusListResponse = useFindReviewStatus(missionNameList, selectedProject?.project_number)
    const reviewStatusList = reviewStatusListResponse.data ?? []

    const selectedMission = reviewStatusList.find((s) => s.mission_id === selectedMissionId) || null

    useEffect(() => {
        dispatch({ type: Types.App_ClearError })
        dispatch({ type: Types.Missions_ResetState })
        const fetchData = async () => {
            if (projectNumber && !selectedProject?.project_number) {
                await fetchProject(dispatch, projectNumber)
            }
            await fetchMissionNameList(dispatch, projectNumber || selectedProject?.project_number)
        }
        fetchData()
    }, []) // eslint-disable-line

    const handleChange = (value: number | null) => {
        dispatch({
            type: Types.Missions_SelectMissionId,
            payload: { selectedMissionId: value },
        })
    }

    return (
        <Container maxWidth="md" className={classes.root}>
            <ProjectTitle redirectOnState />

            <Label gutterBottom="sm">Select an Existing Mission to Edit</Label>
            <Autocomplete
                sx={{ minHeight: 80 }}
                disabled={reviewStatusListResponse.isLoading}
                TextFieldProps={{ helperText: selectedMission && getHelperText(selectedMission) }}
                value={reviewStatusList.find((s) => s.mission_id === selectedMissionId) || null}
                options={reviewStatusList}
                getOptionLabel={(opt: MissionNameType) => opt.mission_name ?? ""}
                renderOption={(props, opt: MissionReviewStatusType) => {
                    const status = getStatusDesign(opt)

                    return (
                        <li style={{ display: "flex", gap: 10, width: "100%" }} {...props}>
                            <Typography sx={{ flexBasis: "30%", color: status.color }}>
                                {opt.mission_name}
                            </Typography>
                            <Typography
                                sx={{
                                    flexBasis: !!opt.event_ct_total ? "20%" : "auto",
                                    color: status.color,
                                }}
                            >
                                {status.text}
                            </Typography>
                            {
                                !!opt.event_ct_total &&
                                <LinearProgressWithLabel
                                    color={status.text === ReviewStatusText.UNDER_REVIEW ? "secondary" : "primary"}
                                    sx={{ bgColor: red[50] }}
                                    value={getPercentComplete(opt)}
                                    percentAtEnd
                                />
                            }
                        </li>
                    )
                }}
                onChange={(e, value) => handleChange(value?.mission_id ?? null)}
                gutterBottom="lg"
                fullWidth
                loading={loadingMissionNameList || reviewStatusListResponse.isLoading}
            />

            {error && (
                <Alert animate onClose={() => dispatch({ type: Types.App_ClearError })}>
                    {error}
                </Alert>
            )}

            <Grid container justifyContent="space-between">
                <Grid item>
                    <Button component={Link} to={routes.home} variant="outlined" color="primary" size="large">
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        component={Link}
                        // prettier-ignore
                        to={`/${projectNumber}${routes.missionEditor}/edit/${selectedMissionId}`}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={selectedMissionId === null}
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

const getHelperText = (selectedMission: MissionReviewStatusType) => {
    let text = getStatusDesign(selectedMission).text as string
    if (selectedMission.event_ct_total) {
        text = `${text} - ${getPercentComplete(selectedMission)}%`
    }
    return text
}

MissionEdit.defaultProps = {
    missions: ["Mission 1", "Mission 2"],
}
