import { makeStyles } from "tss-react/mui"
import { lighten } from "@mui/material/styles"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

export const useToolbarStyles = makeStyles()((theme) => ({
    root: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(1),
        backgroundColor: theme.palette.grey[50],
        borderBottom: "1px solid rgba(224,224,224,1)",
        position: "relative",
    },
    highlight:
        theme.palette.mode === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}))

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    // FlightlineTable
    table__root: {
        width: "100%",
    },
    table__container: {
        height: 424,
    },
    table__input_root: {
        width: "100%",
    },
    table__input: {
        padding: theme.spacing(1, 1.25),
    },
    table__placeholder: {
        color: theme.palette.text.secondary,
    },
    table__delete: {
        paddingLeft: theme.spacing(1),
        paddingRight: 0,
    },
    table__pagination: {
        border: "none",
    },

    row__selected: {
        // sticky cell backgrounds
        "& .MuiTableCell-root": {
            "& .MuiTableCell-root": {
                backgroundColor: "rgba(113, 178, 201, 0.08)",
            },
        },
    },
    cell__empty: {
        height: 1,
        width: 6,
        backgroundColor: "black",
        margin: "0 auto",
    },
    cell__empty_select: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2),
    },
    cell__padding_input: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    cell__delete: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        textAlign: "center",
    },
    cell__autocomplete_padding: {
        // reduce autocomplete inputRoot padding to match other fields
        paddingTop: `${theme.spacing(0.375)}px !important`,
        paddingBottom: `${theme.spacing(0.5)}px !important`,
    },
}))

export default useStyles
