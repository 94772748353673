import { ActionMap, Types } from "./index"
import { TableColumn } from "../../models/Table"

export type SelectOptionType = {
    id: string
    label: string
    description?: string
}

export const lookupTableOptions: SelectOptionType[] = [
    { id: "platform", label: "Platforms" },
    { id: "sensor", label: "Sensors" },
    { id: "acquisition_mode", label: "Acquisition Mode" },
]

export type LookupDataType = {
    [key: string]: string | number | null
}

export type LookupType = {
    selectedTableId: string
    selectedActionId: string
    rows: LookupDataType[]
    columns: TableColumn[]
    loadingRows: boolean
    cache: {
        initialRows: any[]
    }
}

export type LookupPayload = {
    [Types.Lookup_ResetState]: undefined
    [Types.Lookup_SelectAction]: { selectedActionId: string }
    [Types.Lookup_UpdateRowData]: { rows: LookupDataType[] }
    [Types.Lookup_UpdateDataSuccess]: undefined
    [Types.Lookup_DeleteTableRow]: {
        primaryKey: string
        ids: any[]
    }
    [Types.Lookup_FetchTable]: { selectedTableId: string }
    [Types.Lookup_FetchTableSuccess]: {
        rows: LookupDataType[]
        columns: TableColumn[]
    }
}

export type LookupActions = ActionMap<LookupPayload>[keyof ActionMap<LookupPayload>]
