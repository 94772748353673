import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(8),
    },

    // stepper
    stepper__narrow: {
        maxWidth: 420,
        margin: "0 auto",
    },

    // validation
    validation__alert: {
        marginTop: theme.spacing(0.5),
    },

    // Editor
    editorRoot: {
        padding: theme.spacing(3),
    },
    editorFormNumber: {
        width: 100,
    },
    editorNotesHelperText: {
        marginBottom: -theme.spacing(1.5),
    },

    status_description: {
        width: "100%",
        whiteSpace: "normal",
    },

    hobbs_field: {
        width: 120,
    },

    storage_field: {
        width: 180,
    },

    import__text: {
        // maxWidth: 550,
    },
    import__range: {
        marginRight: theme.spacing(3),
    },
    import__button: {
        width: 75,
        borderRadius: 4,
    },

    delete_tooltip: {
        display: "inline-flex",
        marginRight: 16,
    },
}))

export default useStyles
