import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"
import Landing from "./pages/Landing"
import ProjectInit from "./pages/ProjectInit"
import ProjectEditor from "./pages/ProjectEditor"
import MissionUpload from "./pages/MissionUpload"
import MissionEdit from "./pages/MissionEdit"
import MissionEditor from "./pages/MissionEditor"
import Confirmation from "./pages/Confirmation"
import FlightplanUpload from "./pages/FlightplanUpload"
import LookupEditor from "./pages/LookupEditor"
import { RootProvider } from "./store/context"
import Layout from "./components/Layout"
import theme from "./assets/theme"
import routes from "./routes"
import "./index.css"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { FlightplanEditor } from "./pages/FlightplanEditor"

const queryClient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <RootProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Layout>
                            <Switch>
                                <Route exact path={routes.home} component={Landing} />
                                <Route path={routes.projectInit} component={ProjectInit} />
                                <Route path={routes.lookupEditor} component={LookupEditor} />
                                <Route path={`${routes.confirm}/:confirmationRoute`} component={Confirmation} />

                                {/* project number required */}
                                <Route path={`/:projectNumber${routes.projectEditor}`} component={ProjectEditor} />
                                <Route
                                    path={`/:projectNumber${routes.flightplanUpload}`}
                                    component={FlightplanUpload}
                                />
                                <Route
                                    path={`/:projectNumber${routes.flightplanEditor}/:flightplanFlow/:flightPlanId?`}
                                    component={FlightplanEditor}
                                />
                                <Route path={`/:projectNumber${routes.missionUpload}`} component={MissionUpload} />
                                <Route path={`/:projectNumber${routes.missionSelect}`} component={MissionEdit} />
                                <Route
                                    path={`/:projectNumber${routes.missionEditor}/:missionFlow/:missionId?`}
                                    component={MissionEditor}
                                />
                            </Switch>
                        </Layout>
                    </Router>
                </ThemeProvider>
            </RootProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App
