import { useContext, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"

import { Button, Container, Grid, TextField } from "@mui/material"

import Alert from "../../components/Alert"
import Dropzone from "../../components/Fields/Dropzone"
import LoadingButton from "../../components/LoadingButton"
import ProjectTitle from "../../components/ProjectTitle"
import { Body, Label } from "../../components/Typography"

import { RootContext } from "../../store/context"
import { Types } from "../../store/types"
import { fetchProject } from "../../store/actions/projectsActions"
import { parseFlightplanFile } from "../../store/actions/flightplansActions"
import routes from "../../routes"
import useStyles from "./styles"

export default function FlightplanUpload({ history }: any) {
    const { classes, cx } = useStyles()
    const { projectNumber } = useParams<{ [key: string]: string }>()
    const { state, dispatch } = useContext(RootContext)
    const { error } = state.app
    const { selectedProject } = state.projects

    const [file, setFile] = useState<any>(null)
    const [flightplanName, setFlightplanName] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch({ type: Types.App_ClearError })
        dispatch({ type: Types.Flightplans_ResetState })

        const fetchData = async () => {
            if (projectNumber && !selectedProject?.project_number) {
                await fetchProject(dispatch, projectNumber)
            }
        }
        fetchData()
    }, []) // eslint-disable-line

    const handleChangeFile = (newFiles: File[]) => {
        const file = newFiles[0]
        if (!file) {
            return
        }
        const { name } = file

        setFile(newFiles[0] || null)
        setFlightplanName(name.split(".")[0])
    }

    const handleNameChange = (value: string | null) => {
        setFlightplanName(value)
    }

    const uploadFile = async () => {
        setLoading(true)
        const success = await parseFlightplanFile(dispatch, file, flightplanName!, projectNumber!)

        if (success) {
            await history.push(`/${projectNumber}${routes.flightplanEditor}/submit`)
        }

        await setLoading(false)
    }

    return (
        <Container maxWidth="md" className={classes.root}>
            <ProjectTitle redirectOnParam />
            <Grid className={classes.gutterBottom__md}>
                <Label gutterBottom="sm">Flightplan Upload</Label>
                <Body>Drag and drop your files here, or click the dropzone to select.</Body>
            </Grid>

            <Dropzone
                acceptedFiles={[".mdb", ".fpd3", ".zip"]}
                filesLimit={1}
                onChange={handleChangeFile}
            />

            <Label gutterBottom="sm">New Flightplan Name</Label>
            <Grid>
                <TextField
                    variant="outlined"
                    value={flightplanName || ""}
                    onChange={(e) => handleNameChange(e.target.value || null)}
                    className={cx(classes.gutterBottom__lg, classes.input__lg, classes.text_field)}
                />
            </Grid>

            {error && (
                <Alert animate onClose={() => dispatch({ type: Types.App_ClearError })}>
                    {error}
                </Alert>
            )}

            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Button component={Link} to={routes.home} variant="outlined" color="primary" size="large">
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <LoadingButton
                        onClick={uploadFile}
                        pending={loading}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!file || !flightplanName || !selectedProject?.project_number}
                    >
                        Upload File
                    </LoadingButton>
                </Grid>
            </Grid>
        </Container>
    )
}

FlightplanUpload.defaultProps = {}
