import Table from "../../components/Table"

import useLookupTable from "../../hooks/useLookupTable"
import { lookupTableOptions } from "../../store/types/lookupTypes"
import { TableColumn } from "../../models/Table"

const disableRowWhen = <T, >(r: T) => r["inuse" as keyof T] as unknown as boolean

function LookupTable() {
    const {
        rows,
        columns,
        primaryKey,
        selectedTableLabel,
        handleTableDataChange,
    } = useLookupTable()

    const tableIsAcq = selectedTableLabel === lookupTableOptions[2].label
    const addDisableWhenFunc = (col: TableColumn) => {
        return col.id === "mode_type" ? { ...col, disableRowWhen } : col
    }

    const formattedColumns: TableColumn[] = tableIsAcq ? columns.map(addDisableWhenFunc) : columns
    return (
        <Table
            primaryKey={primaryKey}
            label={"Edit " + selectedTableLabel}
            columns={formattedColumns}
            rows={rows}
            updateTableRows={handleTableDataChange}
            canAddRow
        />
    )
}

export default LookupTable
