import { ActionMap, Types } from "./index"

export enum FlightplanStatuses {
    Active = "Active",
    Inactive = "Inactive",
    Complete = "Complete",
}

export type FlightplanType = {
    flight_plan_id: number | null
    flight_plan_name: string | null
    project_number: string | null
    status: string | null
    source_file: string | null
}

export type FlightPlannedLineType = {
    planned_line_id: number
    flight_line_name: string
    flight_plan_id?: number | null
    planned_distance: number | null
    mission_name: string | null
    shape?: string
}

export type FlightplansType = {
    warnings: string[]
    selectedFlightplan: FlightplanType
    selectedFlightplanId: number | null
    flightplanList: FlightplanType[]
    flightPlannedLines: FlightPlannedLineType[]
    deletedPlannedLineIds: number[]
    isDeleting: boolean
}

export type FlightplansPayload = {
    [Types.Flightplans_ResetState]: undefined
    [Types.Flightplans_SelectFlightplan]: { selectedFlightplan: FlightplanType }
    [Types.Flightplans_SelectFlightplanSuccess]: { flightPlannedLines: FlightPlannedLineType[] }
    [Types.Flightplans_UpdateFlightplanData]: { [key: string]: any }
    [Types.Flightplans_UpdateFlightPlannedLines]: {
        flightPlannedLines: FlightPlannedLineType[]
        deletedLines: FlightPlannedLineType[]
    }
    [Types.Flightplans_FetchFlightplanList]: undefined
    [Types.Flightplans_FetchFlightplanListSuccess]: {
        flightplanList: FlightplanType[]
        flightPlannedLines: FlightPlannedLineType[]
        selectedFlightplan: FlightplanType
    }
    [Types.Flightplans_FetchFlightplannedLinesList]: undefined
    [Types.Flightplans_FetchFlightplannedLinesListSuccess]: { flightPlannedLines: FlightPlannedLineType[] }
    [Types.Flightplans_ParseFile]: undefined
    [Types.Flightplans_ParseFileSuccess]: {
        flightplan: FlightplanType
        flightPlannedLines: FlightPlannedLineType[]
        warnings: string[]
    }
    [Types.Flightplans_DeleteFlightplan]: undefined
    [Types.Flightplans_DeleteFlightplanSuccess]: undefined
    [Types.Flightplans_DeleteFlightplanError]: undefined
}

export type FlightplansActions = ActionMap<FlightplansPayload>[keyof ActionMap<FlightplansPayload>]
