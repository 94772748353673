import { ActionMap, Types } from "./index"

export type OptionType = string | { id: string, label: string, disabled?: boolean, tooltip?: string }

export enum ToolbarOptions {
    Initialize_Project = "Initialize Projects",
    Edit_Project_Areas = "Edit Project Areas",
    Submit_Flightplans = "Submit Flightplans",
    Edit_Flightplans = "Edit Flightplans",
    Submit_Mission_Logs = "Submit Mission Logs",
    Edit_Mission_Logs = "Edit Mission Logs",
    Edit_Lookup_Tables = "Edit Lookup Tables",
    Edit_Blog_Entries = "Edit Blog Entries",
    Mission_Admin = "Mission Admin",
    Flightline_QC_Review = "Flightline QC Review",
}

export type PermissionsType = {
    [ToolbarOptions.Initialize_Project]?: boolean
    [ToolbarOptions.Edit_Project_Areas]?: boolean
    [ToolbarOptions.Submit_Flightplans]?: boolean
    [ToolbarOptions.Edit_Flightplans]?: boolean
    [ToolbarOptions.Submit_Mission_Logs]?: boolean
    [ToolbarOptions.Edit_Mission_Logs]?: boolean
    [ToolbarOptions.Edit_Lookup_Tables]?: boolean
    [ToolbarOptions.Edit_Blog_Entries]?: boolean
    [ToolbarOptions.Mission_Admin]?: boolean
    [ToolbarOptions.Flightline_QC_Review]?: boolean
}

export type ToolbarOptionType = {
    name: string
    enabled: boolean
    suboptions: ToolbarOptionType[]
}

export type UserGroupType = {
    _id: string
    client_id: string
    toolbar_options: ToolbarOptionType[]
}

export type UserType = {
    auth_token: string
    avatar?: null
    email: string
    role: string
    clients: any[]
    created_at: object | null
    default_extent: any[]
    default_user_groups: object[]
    id: object | null
    is_active: boolean
    last_login: object | null
    name: string
    organisation: string
    phone: string
    policy: string
    scopes: object[]
    user_groups: UserGroupType[]
}

export type ConfirmationType = {
    entityName: string | null
}

export type AppType = {
    userLoading: boolean
    userMgmtUrl: string
    user: UserType
    permissions: PermissionsType | null
    databaseLoading: boolean
    databaseName: string | null
    databaseVersion: string | null
    databaseError: string
    masterLogLoading: boolean
    loginError: string
    confirmation: ConfirmationType
    error: string
}

export type AppPayload = {
    [Types.App_FetchUserFailed]: { error: any }
    [Types.App_LogoutUser]: undefined
    [Types.App_FetchUser]: undefined
    [Types.App_FetchUserSuccess]: { user: UserType }
    [Types.App_FetchDBVersion]: undefined
    [Types.App_FetchDBVersionSuccess]: {
        databaseName: string
        databaseVersion: string
        userMgmtUrl: string
    }
    [Types.App_FetchDBVersionError]: undefined
    [Types.App_FetchMasterLog]: undefined
    [Types.App_FetchMasterLogSuccess]: undefined
    [Types.App_FetchMasterLogError]: undefined
    [Types.App_UpdateConfirmationData]: { [key: string]: string }
    [Types.App_ResetConfirmationData]: undefined
    [Types.App_UpdateError]: { error: string }
    [Types.App_ClearError]: undefined
}

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>]
