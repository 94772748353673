import { useContext } from "react"

import DetailsTooltip from "../DetailsTooltip"

import { RootContext } from "../../../store/context"

export default function VersionTooltip() {
    const { state } = useContext(RootContext)
    const { databaseLoading, databaseName, databaseVersion } = state.app

    return (
        <DetailsTooltip
            label="Version Info"
            details={[
                { label: "Nexus Version", value: databaseVersion },
                { label: "Database Name", value: databaseName },
                { label: "Node Env", value: process.env.NODE_ENV },
            ]}
            // animate
            disabled={databaseLoading || !databaseVersion || !databaseName}
        />
    )
}
