import React from "react"

import { Button, ButtonProps, CircularProgress } from "@mui/material"

import useStyles from "./styles"

interface LoadingButtonProps extends ButtonProps {
    pending?: boolean
}

export default function LoadingButton({ children, className, disabled, pending, ...other }: LoadingButtonProps) {
    const { classes, cx } = useStyles()

    return (
        <Button
            disabled={disabled || pending}
            className={cx(classes.button, className)}
            {...other}
        >
            <span className={cx({ [classes.buttonText__pending]: pending })}>
                {children}
            </span>
            {pending && <CircularProgress className={classes.buttonProgress} size={20} />}
        </Button>
    )
}

LoadingButton.defaultProps = {}
