import React from "react"

import { Stepper, Step, StepButton, StepLabel, useMediaQuery } from "@mui/material"

import useStyles from "./styles"
import { Theme } from "@mui/material/styles"

type CustomStepperProps = {
  activeStep: number
  className?: string
  completedSteps: boolean[]
  disabled?: boolean
  onClick?: (index: number) => void
  steps: Array<string>
}

function CustomStepper({ activeStep, completedSteps, disabled, onClick, steps, className }: CustomStepperProps) {
    const { classes, cx } = useStyles()

    const isWidthDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
    const orientation = isWidthDown ? "vertical" : "horizontal"

    const handleClick = (index: number) => {
        if (typeof onClick === "function") {
            onClick(index)
        }
    }

    return (
        <Stepper nonLinear orientation={orientation} activeStep={-1} className={className} sx={{ mb: 4 }}>
            {steps.map((label, index) => (
                <Step key={label} completed={completedSteps[index]}>
                    <StepButton disabled={disabled} onClick={() => handleClick(index)}>
                        <StepLabel
                            classes={{
                                root: cx(classes.label, {
                                    [classes.label__active]: orientation === "horizontal" && !disabled && activeStep === index,
                                }),
                            }}
                        >
                            {label}
                        </StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    )
}

export default CustomStepper
