import { ActionMap, Types } from "./index"
import { EmployeeType } from "./missionsTypes"
import { ModeShape } from "../../models/AcquisitionMode"

export const AOI_TYPES = ["Contracted AOI", "Buffered AOI", "Total Area Flown (TAF)", "Legacy AOI"]

export enum AllowedAccess {
    Public = "Public",
    Private = "Private",
    Unknown = "Unknown",
    Other = "Other",
}

export type ProjectDetailsType = {
    project_detail_id?: number
    project_number?: string
    fiscal_year?: number
    allowed_access?: AllowedAccess
    description?: string | null
    planned_start_date?: string | null
}

export enum ProjectStatus {
    ACTIVE = "A",
    DORMANT = "D",
    INACTIVE = "I",
}

export type ProjectType = {
    project_number?: string
    am_employee_id?: string
    pm_employee_id?: string
    charge_type?: string
    client_name?: string
    location?: string
    practice_area?: string
    project_long_name?: string
    project_short_name?: string
    project_status?: ProjectStatus
    project_detail?: ProjectDetailsType | null
    am_employee?: EmployeeType | null
    pm_employee?: EmployeeType | null
}

export interface AoiShape {
    aoi_id?: number

    aoi_type?: string

    area?: number

    area_contracted?: number

    shape?: any

    project_number?: string

    notes?: string

    name?: string

    source_file?: string

    aoi_mode: ModeShape[]

    mode_ids: number[]
}

export type AOINameType = {
    aoi_id: number
    name: string
}

export type ProjectInfoShort = {
    project_number?: string
    project_long_name?: string
}

export type ProjectsType = {
    projectList: ProjectType[]
    selectedProject: ProjectInfoShort | null
    selectedProjectData: ProjectType | null
    selectedAOI: AOINameType | null
    selectedAOIData: AoiShape
    isSubmitAOIFlow: boolean
    isEditAOIFlow: boolean
    doPolygonRepairs: boolean
    nonAcqProjectList: ProjectType[]
    selectedNonAcqProject: ProjectType
    aoiList: AOINameType[]
    loadingProjectList: boolean
    loadingNonAcqProjectList: boolean
    loadingAOIList: boolean
    loadingProject: boolean
    loadingNonAcqProject: boolean
    loadingAOI: boolean
    parsingAOI: boolean
    deletingAOI: boolean
    cache: {
        selectedProjectData: ProjectType | null
        selectedAOIData: AoiShape
    }
}

export type ProjectsPayload = {
    [Types.Projects_FetchProject]: undefined
    [Types.Projects_FetchProjectSuccess]: {
        selectedProject: ProjectType
    }
    [Types.Projects_FetchProjectError]: undefined
    [Types.Projects_FetchProjectData]: undefined
    [Types.Projects_FetchProjectDataSuccess]: {
        selectedProject: ProjectType
        aoi_list: AOINameType[]
    }
    [Types.Projects_FetchProjectDataError]: undefined
    [Types.Projects_FetchAOIList]: undefined
    [Types.Projects_FetchAOIListSuccess]: { aoi_list: AOINameType[] }
    [Types.Projects_FetchAOIListError]: undefined
    [Types.Projects_SelectProject]: { selectedProject: ProjectType | null }
    [Types.Projects_ResetProjectData]: undefined
    [Types.Projects_ResetAOIList]: undefined
    [Types.Projects_ResetAOIData]: undefined
    [Types.Projects_SelectEditAOI]: { selectedAOI: AOINameType }
    [Types.Projects_SelectEditAOISuccess]: { selectedAOIData: AoiShape }
    [Types.Projects_SelectEditAOIError]: undefined
    [Types.Projects_TogglePolygonRepairs]: undefined
    [Types.Projects_SelectSubmitAOI]: { project_number: string }
    [Types.Projects_UpdateProjectDetailsData]: { [key: string]: any }
    [Types.Projects_UpdateAOIData]: { [key: string]: any }
    [Types.Projects_DeleteAOI]: undefined
    [Types.Projects_DeleteAOISuccess]: undefined
    [Types.Projects_DeleteAOIError]: undefined
    [Types.Projects_ParseFile]: undefined
    [Types.Projects_ParseFileSuccess]: {
        shape: string
        area: number
        area_contracted: number
        source_file: string
    }
    [Types.Projects_ParseFileError]: undefined
    [Types.Projects_FetchProjectList]: undefined
    [Types.Projects_FetchProjectListSuccess]: { projectList: ProjectType[] }
    [Types.Projects_FetchNonAcquisitionProjectList]: undefined
    [Types.Projects_FetchNonAcquisitionProjectListSuccess]: { nonAcqProjectList: ProjectType[] }
    [Types.Projects_FetchNonAcquisitionProject]: { loading: boolean }
    [Types.Projects_FetchNonAcquisitionProjectSuccess]: { selectedNonAcqProject: ProjectType }
    [Types.Projects_FetchNonAcquisitionProject]: { loading: boolean }
    [Types.Projects_FetchNonAcquisitionProjectSuccess]: { selectedNonAcqProject: ProjectType }
    [Types.Projects_InitializeAcquistionProject]: undefined
    [Types.Projects_InitializeAcquistionProjectSuccess]: undefined
}

export type ProjectsActions = ActionMap<ProjectsPayload>[keyof ActionMap<ProjectsPayload>]
