import React, { useState }  from "react"

import {
    Autocomplete,
    AutocompleteProps,
    CircularProgress,
    InputAdornment,
    TextField,
    Typography,
    StandardTextFieldProps,
} from "@mui/material"

import useStyles from "./styles"
import { WidthOpt } from "../../assets/inputStyles"
import { ChipTypeMap } from "@mui/material/Chip"

interface CustomAutocompleteProps<
    Value,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType,
> extends Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>, "renderInput" | "onInputChange" | "inputValue"> {
    disableGutter?: boolean
    endAdornment?: React.ReactNode
    inline?: boolean
    gutterBottom?: WidthOpt
    maxLength?: number
    shrinkLabel?: boolean
    width?: WidthOpt
    loading?: boolean
    TextFieldProps?: StandardTextFieldProps & { boldHelperText?: true }
}

function CustomAutocomplete<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
    disableGutter,
    endAdornment,
    TextFieldProps,
    gutterBottom = "md",
    inline,
    maxLength,
    placeholder,
    shrinkLabel = false,
    width = "md",
    loading,
    ...other
}: CustomAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
    const { classes: styles, cx } = useStyles()
    const [inputValue, setInputValue] = useState("")

    return (
        <Autocomplete
            inputValue={inputValue}
            onInputChange={(_, newValue) => setInputValue(newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...TextFieldProps}
                    helperText={
                        <Typography
                            variant="caption"
                            fontWeight={TextFieldProps?.boldHelperText && 600}
                        >
                            {TextFieldProps?.helperText}
                        </Typography>
                    }
                    placeholder={placeholder}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        ...TextFieldProps?.inputProps,
                        maxLength,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        ...TextFieldProps?.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <InputAdornment position="end">
                                        <CircularProgress color="inherit" size={20} />
                                    </InputAdornment>
                                ) : null}
                                {endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    InputLabelProps={shrinkLabel ? { shrink: shrinkLabel } : undefined}
                />
            )}
            className={cx({
                [styles[`input__${width}`]]: !other.fullWidth,
                [styles[`gutterBottom__${gutterBottom}`]]: !disableGutter,
                [styles.inline]: inline,
            })}
            {...other}
        />
    )
}

export default CustomAutocomplete
