export const lookupDisplayNames = {
    rows: "Lookup Table",
}

export const getLookupValidatorModel = (rows: any, columns: any) => {
    const requiredColumns = columns.filter((col: any) => col.required).map((col: any) => col.id)

    return {
        model: { rows },
        display_names: lookupDisplayNames,
        required_fields: [],
        unique_fields: [],
        custom_validation_fields: [
            {
                name: "rows",
                validation: "required_in_array",
                value: requiredColumns,
                text: `Required fields cannot be empty: ${requiredColumns.join(", ")}`,
            },
        ],
        groups: {},
    }
}
