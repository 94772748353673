export interface ValidationFields {
    error: boolean
    errorMessage: string
    displayMessage: string
}

export interface Validation {
    [fieldName: string]: ValidationFields
}

export interface ValidatorFields {
    name: string
    value: any
    validation: string
    validationValue: any
    text: string
}

export interface ValidatorModel {
    model: any
    display_names: { [key: string]: string }
    required_fields: string[]
    unique_fields: {
        name: string
        isError: boolean
        text?: string
    }[]
    custom_validation_fields: {
        name: string
        validation: string
        value: any
        text: string
    }[]
    groups?: { [key: string]: string[] }
}

export class Validator {
    public validatorFields: ValidatorFields[]

    public validatorErrors: Partial<Validation> = {}

    public validatorGroups: { [key: string]: string[] } = {}

    public displayNames: { [key: string]: string } = {}

    constructor(validatorModel: ValidatorModel) {
        const fields1: ValidatorFields[] = validatorModel.unique_fields.map((field) => {
            return {
                name: field.name,
                value: (validatorModel.model as any)[field.name],
                validation: "unique",
                validationValue: field.isError,
                text: field.text ?? "Field(s) must be unique.",
            }
        })

        const fields2 = validatorModel.required_fields.map((field) => {
            return {
                name: field,
                value: (validatorModel.model as any)[field],
                validation: "required",
                validationValue: new RegExp(""),
                text: "Field cannot be empty",
            }
        })

        const fields3 = validatorModel.custom_validation_fields.map((field) => {
            return {
                name: field.name,
                value: (validatorModel.model as any)[field.name],
                validation: field.validation,
                validationValue: field.value,
                text: field.text,
            }
        })

        this.validatorFields = fields2.concat(fields3).concat(fields1)
        if (validatorModel.groups) {
            this.validatorGroups = validatorModel.groups
        }
        if (validatorModel.display_names) {
            this.displayNames = validatorModel.display_names
        }
    }

    validate(groups?: string[] | null): void {
        const isEmpty = (value: any) => {
            if (Array.isArray(value)) {
                return value.length === 0
            }
            return ["", null, undefined, "Invalid date"].includes(value)
        }
        let errors: Partial<Validation> = {}

        this.validatorFields.forEach((field) => {
            if (groups) {
                let group = null

                // find which group field belongs to
                for (const grp in this.validatorGroups) {
                    const children = this.validatorGroups[grp]
                    if (children.includes(field.name)) {
                        group = grp
                    }
                }

                // skip validation if not in groups
                if (!group || !groups.includes(group)) {
                    return
                }
            }

            const displayName = this.displayNames[field.name]

            const errorType: ValidationFields = {
                error: true,
                errorMessage: field.text,
                displayMessage: displayName ? `${displayName}: ${field.text}` : `${field.name}: ${field.text}`,
            }
            let isError: boolean = false

            switch (field.validation) {
                case "required": {
                    if (isEmpty(field.value)) {
                        isError = true
                    }
                    break
                }
                case "unique": {
                    isError = field.validationValue
                    break
                }
                case "regex": {
                    if (!isEmpty(field.value) && field.validationValue && !field.value.match(field.validationValue)) {
                        isError = true
                    }
                    break
                }
                case "relationship": {
                    if (field.value !== null && field.value !== undefined && field.validationValue) {
                        const [operator, complementName] = field.validationValue.split(" ")
                        let complementValue = null

                        if (isNaN(+complementName)) {
                            complementValue = this.validatorFields.find((fld) => fld.name === complementName)?.value
                        } else {
                            complementValue = +complementName
                        }

                        if (complementValue !== null && complementValue !== undefined) {
                            if (operator === ">" && !(field.value > complementValue)) {
                                isError = true
                            }
                            if (operator === "<" && !(field.value < complementValue)) {
                                isError = true
                            }
                            if (operator === ">=" && !(field.value >= complementValue)) {
                                isError = true
                            }
                        }
                    }
                    break
                }
                case "required_in_array": {
                    if (field.value) {
                        if (field.validationValue === "split") {
                            isError = !field.value.split("---")[0].trim().length
                        } else {
                            const requiredProperties: [] = field.validationValue
                            isError = field.value.some((element: { [key: string]: any }) =>
                                requiredProperties.some((property: string) => isEmpty(element[property])),
                            )
                        }
                    }
                    break
                }
                case "requires_other_field_exists": {
                    if (field.value) {
                        const requiredField = this.validatorFields.find(fld => fld.name === field.validationValue)
                        if (!requiredField?.value) {
                            isError = true
                        }
                    }
                    break
                }
            }

            if (isError) {
                errors = { ...errors, [field.name]: errorType }
            }
        })
        this.validatorErrors = errors
    }

    isValid(field: string): boolean {
        return !!(this.validatorErrors && this.validatorErrors[field])
    }

    isValidMessage(field: string): string {
        if (this.validatorErrors && this.validatorErrors[field]) {
            return this.validatorErrors[field]?.errorMessage || ""
        }
        return ""
    }

    isGroupValid(group: string): boolean {
        const fields = this.validatorGroups[group]

        for (const field of fields) {
            if (this.validatorErrors[field]) {
                return false
            }
        }
        return true
    }
}
