import { Types } from "../types"
import { AppType, AppActions } from "../types/appTypes"
import { initialState } from "../context"
import { generatePermissions } from "../../tools"

const appReducer = (state: AppType, action: AppActions): AppType => {
    switch (action.type) {
        case Types.App_FetchDBVersion: {
            return {
                ...state,
                databaseLoading: true,
            }
        }
        case Types.App_FetchUser: {
            return {
                ...state,
                user: { ...initialState.app.user },
                permissions: null,
                userLoading: true,
            }
        }
        case Types.App_FetchUserSuccess: {
            const { user } = action.payload

            return {
                ...state,
                user,
                permissions: generatePermissions(user.user_groups),
                loginError: "",
                userLoading: false,
            }
        }
        case Types.App_FetchUserFailed: {
            const error: string = action.payload.error || action.payload.error
            return {
                ...state,
                loginError: error,
                userLoading: false,
            }
        }
        case Types.App_LogoutUser: {
            return {
                ...state,
                user: { ...initialState.app.user },
                permissions: null,
            }
        }
        case Types.App_FetchDBVersionSuccess: {
            const { databaseName, databaseVersion, userMgmtUrl } = action.payload
            return {
                ...state,
                userMgmtUrl,
                databaseLoading: false,
                databaseName: databaseName || null,
                databaseVersion: databaseVersion || null,
            }
        }
        case Types.App_FetchDBVersionError: {
            return {
                ...state,
                databaseLoading: false,
                databaseError:
                    "Failed loading database information. Please refresh, or contact an administrator if this error persists.",
            }
        }
        case Types.App_FetchMasterLog: {
            return { ...state, masterLogLoading: true }
        }
        case Types.App_FetchMasterLogSuccess:
        case Types.App_FetchMasterLogError: {
            return { ...state, masterLogLoading: false }
        }
        case Types.App_UpdateConfirmationData: {
            return {
                ...state,
                confirmation: {
                    ...state.confirmation,
                    ...action.payload,
                },
            }
        }
        case Types.App_ResetConfirmationData: {
            return {
                ...state,
                confirmation: {
                    ...initialState.app.confirmation,
                },
            }
        }
        case Types.App_UpdateError: {
            const error: string = action.payload.error || action.payload.error
            return {
                ...state,
                error,
            }
        }
        case Types.App_ClearError: {
            return { ...state, error: "" }
        }
        default:
            return state
    }
}

export default appReducer
