import { useCallback, useContext, useMemo } from "react"
import { RootContext } from "../store/context"

import { Types } from "../store/types"
import { FlightlineEventType, MissionType } from "../store/types/missionsTypes"
import { MissionReviewType } from "../store/types/missionsTypes"
import { unplannedLineOption } from "../pages/MissionEditor/FlightlineTable"
import { MissionActiveFlow } from "../utils/validateFlightlineEvents"

const useMissions = () => {
    const { state, dispatch } = useContext(RootContext)
    const { activeFlow, flightlineEvents, flightPlannedLines, selectedFlightplanIds } = state.missions

    const isNoFlight = activeFlow !== null && activeFlow === MissionActiveFlow.NO_FLIGHT
    const isNoAcquisition = activeFlow !== null && activeFlow === MissionActiveFlow.NO_ACQUISITION
    const isNoFile = activeFlow !== null && activeFlow === MissionActiveFlow.NO_FILE
    const isFullFlight = activeFlow !== null && activeFlow === MissionActiveFlow.FULL_FLIGHT

    /**
     * Creates TableOption array for flightline table and event creator
     * Returns only the selected flightPlannedLines in Table Option format
     */
    const flightPlannedLineLabelOptions = useMemo(() => {
        const plannedLineOptions = flightPlannedLines.flatMap(plannedLine => {
            return selectedFlightplanIds.includes(Number(plannedLine.flight_plan_id)) ? {
                label: `${plannedLine.flight_plan_id} - ${plannedLine.flight_line_name}`,
                value: plannedLine.planned_line_id,
            } : []
        })

        return [unplannedLineOption, ...plannedLineOptions]
    }, [flightPlannedLines, selectedFlightplanIds])

    /**
     * Toggle between NV5 team and non-NV5 team (vendor).
     */
    const handleVendor = useCallback(
        (value: boolean) => {
            dispatch({
                type: Types.Missions_UpdateIsVendorFlow,
                payload: value,
            })
        },
        [dispatch], // eslint-disable-line
    )

    /**
     * Change one or more values in selectedMission.
     */
    const handleMissionDataChange = useCallback(
        (data: Partial<MissionType>) => {
            dispatch({
                type: Types.Missions_UpdateMissionData,
                payload: data,
            })
        },
        [dispatch], // eslint-disable-line
    )

    /**
     * Change one or more values in selectedMission.mission_shipping_info.
     */
    const handleMissionShippingInfoChange = useCallback(
        (data: object) => {
            dispatch({
                type: Types.Missions_UpdateMissionShippingInfoData,
                payload: data,
            })
        },
        [dispatch], // eslint-disable-line
    )

    /**
     * Change one or more values in selectedMission.mission_review.
     */
    const handleMissionReviewChange = useCallback(
        (data: Partial<MissionReviewType>) => {
            dispatch({
                type: Types.Missions_UpdateMissionReviewData,
                payload: data,
            })
        },
        [dispatch],
    )

    const handleSelectFlightplanIds = useCallback((selectedFlightplanIds: number[]) => {
        dispatch({
            type: Types.Missions_SelectFlightplanList,
            payload: { selectedFlightplanIds },
        })
    }, [dispatch])

    const handleFlightlineEventChange = useCallback(
        (rows: FlightlineEventType[]) => {
            dispatch({
                type: Types.Missions_UpdateFlightlineEventData,
                payload: { rows },
            })
        },
        [dispatch, flightlineEvents.length], // eslint-disable-line
    )

    return {
        missions: state.missions,
        isNoFlight,
        isNoAcquisition,
        isNoFile,
        isFullFlight,
        flightPlannedLineLabelOptions,
        handleMissionDataChange,
        handleMissionShippingInfoChange,
        handleMissionReviewChange,
        handleSelectFlightplanIds,
        handleVendor,
        handleFlightlineEventChange,
        dispatch,
    }
}

export default useMissions
