import { AoiShape, ProjectType } from "../../store/types/projectsTypes"
import { flattenObj } from "../../tools"
import { ValidatorModel } from "../../validation"

export const projectDisplayNames = {
    description: "Project Description",
    name: "AOI Name",
    source_file: "Source File",
    aoi_type: "AOI Type",
    notes: "AOI Notes",
    mode_ids: "Acq Mode",
}

export const getProjectValidatorModel = (project: ProjectType, aoi: AoiShape, isAOIOpen: boolean): ValidatorModel => {
    const model = flattenObj({ ...project, aoi })

    const conditional_aoi_fields = isAOIOpen ? ["name", "source_file", "aoi_type", "notes"] : []
    const conditional_mode_fields = isAOIOpen && !model.mode_ids.length ? ["mode_ids"] : []

    return {
        model: model,
        display_names: projectDisplayNames,
        required_fields: ["description", ...conditional_aoi_fields, ...conditional_mode_fields],
        unique_fields: [],
        custom_validation_fields: [],
        groups: {
            projectTab: ["description"],
            aoiTab: ["name", "source_file", "aoi_type", "notes", ...conditional_mode_fields],
        },
    }
}
