import { useContext, useState, useEffect } from "react"

import LinearProgressWithLabel from "../../components/LinearProgressWithLabel"
import { Label } from "../../components/Typography"

import { RootContext } from "../../store/context"
import useStyles from "./styles"

export default function FlightplanCompletedLines() {
    const { classes } = useStyles()
    const { state } = useContext(RootContext)
    const { selectedFlightplan, flightPlannedLines } = state.flightplans

    const [calculations, setCalculations] = useState({ linePct: 0, distPct: 0 })

    /**
     * Calculate line percentages when number of lines changes.
     */
    useEffect(() => {
        const lines_total = flightPlannedLines.length
        let lines_flown = 0,
            distance_flown = 0,
            distance_total = 0

        flightPlannedLines.forEach((line) => {
            const counted = Boolean(line.mission_name)
            if (counted) {
                lines_flown++
                distance_flown += line.planned_distance || 0
            }
            distance_total += line.planned_distance || 0
        })

        const getPct = (val: number) => Math.round(val * 1000) / 10 || 0
        const linePct = getPct(lines_flown / lines_total)
        const distPct = getPct(distance_flown / distance_total)

        setCalculations({ linePct, distPct })
    }, [flightPlannedLines.length]) // eslint-disable-line

    return (
        <>
            <Label gutterBottom="sm">Completed Lines</Label>
            {Object.values(calculations).map((value, i) => {
                const supportingText = `(by ${i === 0 ? "line count" : "distance"}${
                    selectedFlightplan.status === "Complete" ? ` - actually ${value}%` : ""
                })`

                return (
                    <LinearProgressWithLabel
                        key={i}
                        value={selectedFlightplan.status === "Complete" ? 100 : value}
                        renderValue={(value: number) => `${value}% ${supportingText}`}
                        className={classes.input__md}
                    />
                )
            })}
        </>
    )
}
