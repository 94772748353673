import { Theme } from "@mui/material/styles"

const inputStyles = (theme: Theme) => ({
    gutterBottom__sm: {
        marginBottom: theme.spacing(1.5),
    },
    gutterBottom__md: {
        marginBottom: theme.spacing(2.75),
    },
    gutterBottom__lg: {
        marginBottom: theme.spacing(4),
    },
})

export default inputStyles
