import React, { useState, useEffect } from "react"

import { Alert, AlertProps, AlertTitle, Collapse } from "@mui/material"

import useStyles from "./styles"
import { WidthOpt } from "../../assets/inputStyles"

interface CustomAlertProps extends AlertProps {
    animate?: boolean
    children: React.ReactNode
    className?: string
    disableGutter?: boolean
    gutterBottom?: WidthOpt
    onClose?: () => void
    title?: string
}

function CustomAlert({
    animate,
    children,
    className,
    disableGutter,
    gutterBottom = "md",
    onClose,
    title,
    severity = "error",
    ...other
}: CustomAlertProps) {
    const { classes, cx } = useStyles()

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (animate) {
            setOpen(true)
        }
    }, []) // eslint-disable-line

    const handleClose = () => {
        setOpen(false)

        if (typeof onClose === "function") {
            if (animate) {
                setTimeout(() => onClose(), 300)
            } else {
                onClose()
            }
        }
    }

    const alert = (
        <Alert
            onClose={onClose ? handleClose : undefined}
            className={cx(!disableGutter && classes[`gutterBottom__${gutterBottom}`], className)}
            severity={severity}
            {...other}
        >
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
        </Alert>
    )

    if (animate) {
        return <Collapse in={open}>{alert}</Collapse>
    } else {
        return alert
    }
}

CustomAlert.defaultProps = {
    gutterBottom: "md",
    severity: "error",
}

export default CustomAlert
