import Config from "../config"
import { downloadFile } from "../tools"

const handleResponse = async (response: Response, defaultMessage = "Failed to fetch resource.") => {
    const result = await response.json()

    if (response.status >= 400 || (result.error && !result.success)) {
        throw new Error(result.message || defaultMessage)
    }
    return result
}

const handleFileResponse = async (response: Response) => {
    const result = await response.blob()
    const contentDisposition = response.headers.get("content-disposition")

    downloadFile(result, contentDisposition?.split("filename=")[1])
    return { success: true }
}

const handleInsiteResponse = async (response: Response, defaultMessage = "Failed to fetch resource.") => {
    const result = await response.json()

    if (response.status >= 400) {
        throw new Error(result.data || defaultMessage)
    }
    return result
}

class API {
    static async get(path: string) {
        const headers = new Headers(Config.getHeader())
        const response = await fetch(`${Config.basePath}${path}`, { method: "GET", headers })
        return handleResponse(response)
    }

    static async post(path: string, body: any) {
        const headers = new Headers(Config.getHeader())
        const response = await fetch(`${Config.basePath}${path}`, {
            method: "POST",
            headers,
            body: JSON.stringify(body),
        })
        return handleResponse(response, "Failed to update/create resource.")
    }

    static async put(path: string, body: any) {
        const headers = new Headers(Config.getHeader())
        const response = await fetch(`${Config.basePath}${path}`, {
            method: "PUT",
            headers,
            body: JSON.stringify(body),
        })
        return handleResponse(response)
    }

    static async delete(path: string) {
        const headers = new Headers(Config.getHeader())
        const response = await fetch(`${Config.basePath}${path}`, { method: "DELETE", headers })
        return handleResponse(response, "Failed to delete resource.")
    }

    static async downloadFile(path: string) {
        Config.checkTokenExpire()
        const headers = new Headers({
            Authorization: `Bearer ${localStorage.getItem("auth_token")}` || "",
            Client: localStorage.getItem("nexus_client"),
            Accept: "Application/octet-stream",
        } as any)
        const response = await fetch(`${Config.basePath}${path}`, { method: "GET", headers })
        return handleFileResponse(response)
    }

    static async uploadFile(path: string, body: any, args?: any) {
        Config.checkTokenExpire()
        const headers = new Headers({
            Authorization: `Bearer ${localStorage.getItem("auth_token")}` || "",
            Client: localStorage.getItem("nexus_client"),
        } as any)
        const queryParams = args ? `?${Object.entries(args).map(([key, val]) => `${key}=${val}`)}` : ""
        const response = await fetch(`${Config.basePath}${path}${queryParams}`, {
            method: "POST",
            headers,
            body: body,
        })
        return handleResponse(response)
    }

    static async getInsiteResource(base: string, url: string) {
        const headers = new Headers(Config.getHeader(false))
        const response = await fetch(`${base}/api${url}`, { method: "GET", headers })
        return handleInsiteResponse(response)
    }

    static async login(base: string, body: any) {
        const headers = new Headers({ "Content-Type": "application/json", Accept: "application/json" })
        const response = await fetch(`${base}/auth/login`, { method: "POST", headers, body: JSON.stringify(body) })
        return handleInsiteResponse(response)
    }
}

export default API
