import { LengthUnits } from "convert-units"
import { PPSUnits } from "../utils/unitCalculations"

export interface AcquisitionMode {
    mode_id: number
    mode_type: ModeType
    mode_name: string
    notes?: string
}

export interface AcquisitionModesForType {
    mode_type: string
    modes: AcquisitionMode[]
}

export interface AoiSpecs {
    flow_conditions: string[]
    imagery_bands: string[]
    leaf_conditions: string[]
    snow_conditions: string[]
    tide_conditions: string[]
}

export enum ModeType {
    Imagery = "Imagery",
    PtCloud = "Point Cloud",
}

export class PointCloud {
    readonly density?: number | null
    readonly accuracy_nva?: number | null
    readonly dbl_coverage?: boolean = false

    constructor(props: PointCloud) {
        Object.assign(this, props)
    }
}

export class Imagery {
    readonly pixel_size?: number | null
    readonly sun_angle_min?: number | null
    readonly sun_angle_max?: number | null
    readonly sidelap?: number | null
    readonly endlap?: number | null
    readonly accuracy_xy?: number | null
    readonly imagery_bands?: string | null

    constructor(props: Imagery) {
        Object.assign(this, props)
    }
}

export interface AoiModeConditions {
    leaf_conditions?: string
    snow_conditions?: string
    tide_conditions?: string
    flow_conditions?: string
    custom_specs?: string
}

export class ModeShape<T = object> implements AcquisitionMode, AoiModeConditions {
    readonly mode_id: number
    readonly mode_type: ModeType
    readonly mode_name: string
    readonly notes?: string

    readonly leaf_conditions?: string
    readonly snow_conditions?: string
    readonly tide_conditions?: string
    readonly flow_conditions?: string
    readonly custom_specs?: string

    readonly specs: PointCloud | Imagery

    constructor(props: {
        mode_id: number
        mode_type: ModeType
        mode_name: string
        notes?: string

        leaf_conditions?: string
        snow_conditions?: string
        tide_conditions?: string
        flow_conditions?: string
        custom_specs?: string
        specs: T
    }) {
        this.mode_id = props.mode_id
        this.mode_type = props.mode_type
        this.mode_name = props.mode_name
        this.notes = props.notes || ""

        this.leaf_conditions = props.leaf_conditions || ""
        this.snow_conditions = props.snow_conditions || ""
        this.tide_conditions = props.tide_conditions || ""
        this.flow_conditions = props.flow_conditions || ""
        this.custom_specs = props.custom_specs || ""

        if (props.mode_type === ModeType.Imagery) {
            this.specs = new Imagery(props.specs as Imagery)
        } else if (props.mode_type === ModeType.PtCloud) {
            this.specs = new PointCloud(props.specs as PointCloud)
        } else {
            throw Error(`Unable to create ${props.mode_type} mode specs for ${props.mode_id}.`)
        }
    }

    static createEmptyModeForm(acquisitionMode: AcquisitionMode) {
        let specs = {}

        if (acquisitionMode.mode_type === ModeType.Imagery) {
            specs = new Imagery({})
        } else if (acquisitionMode.mode_type === ModeType.PtCloud) {
            specs = new PointCloud({})
        } else {
            throw new Error(`Unable to create empty ${acquisitionMode.mode_type} mode with id ${acquisitionMode.mode_id}`)
        }

        return new ModeShape({ ...acquisitionMode, specs })
    }
}


export enum FieldTypes {
    autocomplete = "autocomplete",
    string = "string",
    number = "number",
    lengthCalc = "lengthCalc",
    ppsCalc = "ppsCalc",
    boolean = "boolean",
}

interface StringInputOpt {
    type: Exclude<FieldTypes, FieldTypes.lengthCalc | FieldTypes.ppsCalc | FieldTypes.number>
}

interface NumberInput {
    type: FieldTypes.number
    inputLabel?: string
    min?: number
    max?: number
    decimalCount?: number
}

interface LengthInput {
    type: FieldTypes.lengthCalc
    defaultUnitType: LengthUnits
    units: LengthUnits[]
    decimalCount?: number
}

interface PPSInput {
    type: FieldTypes.ppsCalc
    defaultUnitType: PPSUnits
    units: PPSUnits[]
    decimalCount?: number
}

type InputBase<T> = {
    field: keyof T
    label?: string
    decimalCount?: number
}

export type UniqueInputTypes = StringInputOpt | PPSInput | LengthInput | NumberInput

export type AoiInputType<T> = InputBase<T> & UniqueInputTypes
