import { LinearProgress, Grid, GridProps, Typography, TypographyProps } from "@mui/material"

import useStyles from "./styles"

function LinearProgressWithLabel({ className, renderValue, value, percentAtEnd, ...props }: any) {
    const { classes, cx } = useStyles()

    let gridStyles: GridProps
    let labelStyles: TypographyProps
    if (percentAtEnd) {
        gridStyles = {
            sx: {
                width: .25,
                display: "flex",
                flexDirection: "row-reverse",
                gap: 2,
                alignItems: "center",
            },
        }
        labelStyles = { color: "textPrimary" }
    } else {
        gridStyles = { className: cx(classes.gutterBottom__md, className) }
        labelStyles = { className: classes.label, color: "textSecondary" }
    }

    return (
        <Grid {...gridStyles}>
            <Typography variant="body2" {...labelStyles}>
                {(renderValue && renderValue(value)) || `${value}%`}
            </Typography>
            <LinearProgress variant="determinate" value={value} className={classes.progress} {...props} />
        </Grid>
    )
}

export default LinearProgressWithLabel
