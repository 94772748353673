import { useState, useEffect } from "react"

import { Collapse, Fade, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@mui/material"
import { Replay } from "@mui/icons-material"

import Select from "../../components/Fields/Select"
import { Body, Label } from "../../components/Typography"

import useMissions from "../../hooks/useMissions"
import { Types } from "../../store/types"
import { templates, TemplateType } from "../../store/types/missionsTypes"
import useDebouncer from "../../hooks/useDebouncer"
import useStyles from "./styles"

export default function FlightlineMatchingExpression() {
    const { classes } = useStyles()
    const { missions, dispatch } = useMissions()
    const { active, isCustom, selectedTemplate } = missions.matchingExpression

    // track display value of matchingExpression.custom while debouncing
    const [regExp, setRegExp] = useState(active || "")

    /**
     * Reset regExp if it does not equal new active values
     *
     * Happens when handleCustomChange is run with throttle === true,
     * then ran again with throttle === false before the debouncer
     * is done waiting.
     */
    useEffect(() => {
        if (isCustom && regExp !== active) {
            setRegExp(active || "")
        }
    }, [active]) // eslint-disable-line

    /**
     * Updates matching expression.
     *
     * Use debounced if calling multiple times in quick succession.
     *
     * @param {object} data matchingExpression data to update
     */
    const handleMatchingExpression = (data: {
        custom?: string
        isCustom?: boolean
        selectedTemplate?: TemplateType
    }) => {
        dispatch({
            type: Types.Missions_UpdateMatchingExpressionData,
            payload: data,
        })
    }
    const debounced = useDebouncer(handleMatchingExpression, 160)

    const handleSelectedTemplateChange = (label: string) => {
        const newTemplate = templates.find((t) => t.label === label)!

        setRegExp(newTemplate.value)
        handleMatchingExpression({
            custom: newTemplate.value,
            selectedTemplate: newTemplate,
        })
    }

    const handleIsCustomChange = () => {
        handleMatchingExpression({ isCustom: !isCustom })
    }

    const handleCustomChange = (label: string, throttle: boolean = true) => {
        setRegExp(label)

        if (throttle) {
            debounced({ custom: label })
        } else {
            handleMatchingExpression({ custom: label })
        }
    }

    const handleCustomReset = () => {
        handleCustomChange(selectedTemplate.value, false)
    }

    return (
        <>
            <Label>Flightline Matching Expression</Label>
            <Grid container className={classes.gutterBottom__sm}>
                <Grid item md={4}>
                    <Select
                        fullWidth
                        value={selectedTemplate.label ?? ""}
                        options={templates.map((t) => t.label)}
                        renderValue={(value: string) => value}
                        renderOption={(value: string) => (
                            <MenuItem key={value} value={value}>
                                <Grid className={classes.status_description}>
                                    <Body disableGutter>
                                        {value} {templates.find((s) => s.label === value)?.description}
                                    </Body>
                                </Grid>
                            </MenuItem>
                        )}
                        onChange={(e: any) => handleSelectedTemplateChange(e.target.value)}
                        width="md"
                        disableDefaultValue
                        disableGutter
                    />
                </Grid>
                <Grid item sx={{ display: "flex", alignContent: "center", ml: 2 }} sm>
                    <FormControlLabel
                        label="Use Advanced Expression"
                        control={<Switch checked={isCustom} color="primary" onChange={handleIsCustomChange} />}
                    />
                </Grid>
            </Grid>

            <Collapse in={isCustom} collapsedSize={20}>
                <Fade in={isCustom}>
                    <Grid container item className={classes.gutterBottom__lg} md={4}>
                        <TextField
                            label="Advanced Expression"
                            value={regExp}
                            onChange={(e) => handleCustomChange(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 2 }}
                            className={classes.input__md}
                            InputProps={{
                                endAdornment: regExp !== selectedTemplate.value && (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={handleCustomReset}>
                                            <Replay />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Fade>
            </Collapse>
        </>
    )
}
