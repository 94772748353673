import { Types } from "../types"
import {
    AirportType,
    EmployeeType,
    FlightlineEventDTO,
    FlightlineEventType,
    MissionNameType,
    MissionType,
    missionUtils,
    templates,
    TemplateType,
} from "../types/missionsTypes"
import { formatDate, parseDate, parseFlightlineEventNames } from "../../tools"
import API from "../../api"
import { FlightPlannedLineType, FlightplanType } from "../types/flightplansTypes"
import { DispatchNexus, initialSelectedMission } from "../context"
import { unplannedLineValue } from "../../pages/MissionEditor/FlightlineTable"
import { FlightlineStatusOption } from "../../pages/MissionEditor/FlightlineTable"
import { insertTemporaryTableId, isRowInserted } from "../../utils/tableUtils"

export async function fetchMissionNameList(dispatch: DispatchNexus, projectNumber: string | undefined): Promise<void> {
    dispatch({ type: Types.Missions_FetchMissionNameList })

    try {
        const result = await API.get(`/missions/${projectNumber}/names`)

        dispatch({
            type: Types.Missions_FetchMissionNameListSuccess,
            payload: { missionNameList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving mission list" },
        })
    }
}

export async function fetchMission(dispatch: DispatchNexus, missionId: string): Promise<void> {
    dispatch({ type: Types.Missions_FetchMission })

    try {
        const result = await API.get(`/mission/${missionId}`)
        const selectedMission: MissionType = result.data

        const startTime = selectedMission.start_time
        if (startTime) {
            selectedMission.start_time = startTime.split("T")[1] || null
        }
        const stopTime = selectedMission.stop_time
        if (stopTime) {
            selectedMission.stop_time = stopTime.split("T")[1] || null
        }

        const startDate = parseDate(startTime)
        const stopDate = parseDate(stopTime)
        if (startDate != null && stopDate != null && !startDate.isSame(stopDate)) {
            selectedMission.flown_end_date = formatDate(stopDate)
        }

        dispatch({
            type: Types.Missions_FetchMissionSuccess,
            payload: { selectedMission },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: "Error retrieving mission" },
        })
    }
}

interface UpdateMissionType extends Omit<MissionType, "flight_num"> {
    flight_num: number | null
}

export async function updateMission(
    dispatch: DispatchNexus,
    mission: MissionType,
    flightlineEvents: FlightlineEventType[],
    isNoFile: boolean,
    isVendorFlow: boolean,
    isNoFlight: boolean,
): Promise<boolean> {
    try {
        const formattedMission: UpdateMissionType = JSON.parse(JSON.stringify(mission))
        const formattedFlightlineEvents = JSON.parse(JSON.stringify(flightlineEvents))
        const {
            startDate,
            stopDate,
            startDateTime,
            stopDateTime,
            duration,
        } = missionUtils.calculateStartAndEndDateTimes(mission)

        // check for missing flightplan
        if (flightlineEvents.length > 0) {
            flightlineEvents.forEach((ev) => {
                if (ev.flight_status !== "Ignored" && (!ev.planned_line_id)) {
                    throw Error(`Cannot submit mission due to missing flightplan and name in line: ${ev.flightline_name} (${ev.line_original_name}).\
                     Please verify the event is not ignored.`)
                }
            })
        }
        // no-file flow but no events
        if (isNoFile && flightlineEvents.length === 0) {
            throw Error(
                "Cannot submit mission due to missing flightline events. Please import flightplanned lines using the import tool in the Flightline Review tab.",
            )
        }

        // delete helper fields
        formattedFlightlineEvents.forEach((fl: Partial<FlightlineEventType>) => {
            delete fl.flightline_name
            if (isRowInserted(fl.flight_line_event_id)) {
                delete fl.flight_line_event_id
            }
            if (fl.planned_line_id === unplannedLineValue) {
                fl.planned_line_id = null
            }

            if (isNoFile) {
                if (!fl.flight_line_start_time) {
                    fl.flight_line_start_time = `${startDate!} 12:00:00.000`
                }
                if (!fl.flight_line_stop_time) {
                    fl.flight_line_stop_time = `${stopDate!} 12:30:00.000`
                }
            }
        })
        formattedMission.flightline_events = formattedFlightlineEvents

        formattedMission.start_time = startDateTime ?? null
        formattedMission.stop_time = stopDateTime ?? null
        formattedMission.duration = duration ?? null
        // Backend will not expect to see a flown_end_date
        delete formattedMission.flown_end_date

        // default team or vendor values if the other is used
        if (isVendorFlow) {
            formattedMission.operator_1_id = null
            formattedMission.operator_2_id = null
            formattedMission.pilot_1_id = null
            formattedMission.pilot_2_id = null
        } else {
            formattedMission.vendor_name = null
        }

        if (isNoFlight) {
            formattedMission.flight_num = null
        }

        const result = await API.put(`/mission/${mission.mission_id || 0}`, formattedMission)
        if (result.success) {
            return true
        }
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error },
        })
    }
    return false
}

// @Todo Change to Generic - similar to delete selectedflightplanlist
export async function deleteMission(dispatch: DispatchNexus, mission_id: number): Promise<boolean> {
    dispatch({ type: Types.Missions_DeleteMission })

    try {
        const result = await API.delete(`/mission/${mission_id}`)
        if (!result.success) {
            throw new Error()
        }

        dispatch({ type: Types.Missions_DeleteMissionSuccess })
        return true
    } catch (error) {
        console.error(error)
        dispatch({ type: Types.Missions_DeleteMissionError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error.message || "Error deleting flightplan." },
        })
    }
    return false
}

export async function fetchFlightlineEvents(dispatch: DispatchNexus, missionId: string): Promise<void> {
    dispatch({ type: Types.Missions_FetchFlightlineEvents })

    try {
        const result: {
            data: { flightline_events: FlightlineEventDTO[], flight_plan: FlightplanType[] | null }
        } = await API.get(`/mission/${missionId}/flightline-events`)

        /*
        * If there are flightline events but there are no flightplans
        * result.data returns null - should only happen during testing if all lines are UL
        */
        if (result.data.flightline_events.length > 0 && !result.data.flight_plan) {
            throw Error("Error retrieving flightplan.")
        }

        const flightPlans = result.data.flight_plan ?? []

        // retrieve plannedLines for this mission using flight_plans just retrieved
        const flightplanIds: number[] = flightPlans.flatMap(fp => fp.flight_plan_id ? fp.flight_plan_id : [])

        // Fetch flight planned lines based of retrieved flight plan ids
        // If all flightline events are UL then will have an empty array of flightplanIds
        let plannedLines: FlightPlannedLineType[] = []
        if (flightplanIds.length) {
            plannedLines = await fetchFlightPlannedLines(flightplanIds)
        }

        const events: FlightlineEventType[] = result.data.flightline_events.map((event): FlightlineEventType => {
            // if there is a matching plannedLine gets planned line for flightline_name and flightplan_id
            const matchingPlannedLine = plannedLines.find(pl => pl.planned_line_id === event.planned_line_id)

            // If null - set planned_line_id as unplannedLineValue on fetch for visual purposes
            return {
                ...event,
                planned_line_id: matchingPlannedLine?.planned_line_id ?? unplannedLineValue,
                flightline_name: matchingPlannedLine?.flight_line_name ?? null,
            }
        })

        dispatch({
            type: Types.Missions_FetchFlightlineEventsSuccess,
            payload: {
                flightlineEvents: events,
                selectedFlightplans: flightPlans,
                flightPlannedLines: plannedLines,
                selectedFlightplanIds: flightplanIds,
            },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: error.message ?? "Error retrieving flightline events" },
        })
    }
}

const fetchFlightPlannedLines = async (flight_plan_ids: number[]) => {
    const _flight_plan_ids = flight_plan_ids.map((flight_plan) => {
        return (
            "plan_id=" + String(flight_plan)
        )
    })
    const _url = _flight_plan_ids.join("&")

    const result: {
        data: { flightplanned_lines: FlightPlannedLineType[] }
    } = await API.get(`/mission/flightplanned-lines?${_url}`)

    const plannedLines: FlightPlannedLineType[] = result.data.flightplanned_lines
    if (plannedLines.length === 0) {
        throw Error("Error in retrieving flightplanned lines")
    }
    return plannedLines
}

// Used during file upload
export async function fetchFlightPlannedLinesForMission(dispatch: DispatchNexus, flight_plan_ids: number[]): Promise<void> {
    dispatch({ type: Types.Missions_FetchFlightplannedLines })

    try {
        const plannedLines = await fetchFlightPlannedLines(flight_plan_ids)
        dispatch({
            type: Types.Missions_FetchFlightplannedLinesSuccess,
            payload: { flightPlannedLines: plannedLines },
        })
    } catch {
        dispatch({
            type: Types.Missions_UpdateError,
            payload: {
                error:
                    "Error retrieving flightplanned lines. This means flightline events cannot be imported on the final tab.",
            },
        })
    }
}

export async function parseSensorFile(dispatch: DispatchNexus, sensorFiles: File[], project_number: string, flightPlannedLines: FlightPlannedLineType[]) {
    dispatch({ type: Types.App_ClearError })
    dispatch({ type: Types.Missions_FetchParsedMissions })

    try {
        const formData = new FormData()
        sensorFiles.forEach(file => {
            formData.append("file", file)
        })

        const response: {
            data: {
                missions: {
                    serial_number_1: string
                    serial_number_2: string
                    flown_date: string
                    flight_num: number
                    channel_name: string | null // can be null or missing for file formats without multi-channel data
                    source_file: string
                    flightline_events: FlightlineEventType[]
                    flight_notes: string
                }[]
                warnings: string[]
            }[]
            warnings: string[]
            error?: string
            message?: string
        } = await API.uploadFile("/upload/sensor", formData)
        const result = await API.get(`/missions/${project_number}/names`)

        const missionNames = result.data
        const allWarnings = [...response.warnings || []]

        const allFileMissionsArray = response.data
        if (!allFileMissionsArray.length) {
            throw new Error("Backend response was not a mission array.")
        }

        const missionDict: { [key: string]: MissionType } = {}

        // In searching for the best regex to use, we use whatever regex works best with the first file
        // TODO: for each file should there only be one 'bestRegex'?
        let bestRegex: TemplateType | undefined
        allFileMissionsArray.forEach(({ missions: fileMissionArray, warnings }) => {
            allWarnings.push(...warnings)

            fileMissionArray.forEach((mission) => {
                const {
                    flown_date,
                    flight_num,
                    serial_number_1,
                    serial_number_2,
                    source_file,
                    flight_notes,
                    channel_name,
                } = mission
                let events: FlightlineEventType[]

                if (bestRegex == null) {
                    // For the first channel or if we have no matches, yet we will determine the best regex to use, and thereafter
                    // reuse that one
                    let bestEventsResult: FlightlineEventType[] = []
                    const matchesFound: number = 0

                    templates.forEach(regexTemplate => {
                        const result = parseFlightlineEventNames(mission.flightline_events, regexTemplate.value, flightPlannedLines)
                        const matches = result.filter(event => event.flightline_name != null).length
                        if (bestRegex == null || matchesFound < matches) {
                            bestRegex = regexTemplate
                            bestEventsResult = result
                        }
                    })

                    events = bestEventsResult
                } else {
                    events = parseFlightlineEventNames(mission.flightline_events, bestRegex.value, flightPlannedLines)
                }

                // get next flight_num if mission already exists in database
                let maxExistingFlight = 0
                const compactDate = flown_date.split("-").join("")

                missionNames.forEach((m: MissionNameType) => {
                    if (typeof m.mission_name === "string") {
                        const [serial, date, flight] = m.mission_name!.split("_")
                        if (serial === serial_number_1 && date === compactDate && flight) {
                            maxExistingFlight = Math.max(maxExistingFlight, +flight.slice(1))
                        }
                    }
                })

                // key in missionDict: serialNumber_flownDate_flightNumber
                const compName = (serial_number_1 ? `${serial_number_1}_` : "<SensorNum>_")
                    + (`${compactDate}_F${flight_num}`)
                    + (channel_name ? ` (${channel_name})` : "")

                // Merge into existing record if mission's already in dict
                if (missionDict[compName]) {
                    const old_flight_notes = missionDict[compName].flight_notes
                    const old_source_file = missionDict[compName].source_file

                    missionDict[compName] = {
                        ...missionDict[compName],
                        flightline_events: [...missionDict[compName].flightline_events, ...events],
                        flight_notes: old_flight_notes + "\n\n" + flight_notes.replace("---", "").trim(),
                        source_file: old_source_file?.includes(source_file) ? old_source_file : old_source_file + "," + source_file,
                    }
                } else {
                    missionDict[compName] = {
                        ...initialSelectedMission,
                        channel_name,
                        flightline_events: events,
                        flown_date,
                        flight_notes,
                        serial_number_1,
                        serial_number_2,
                        source_file,
                        flight_num: maxExistingFlight + flight_num,
                    }
                }
            }) //end foreach mission in file
        }) //end foreach file sent to parser

        const parsedMissions: MissionType[] = []
        for (const missionName in missionDict) {
            const newMission = missionDict[missionName]

            newMission.display_name = missionName

            // init flightline events here incase there are multiple missionDict entries with same compName
            newMission.flightline_events.forEach((fl: FlightlineEventType, index: number) => {
                fl.flight_line_event_id = insertTemporaryTableId(index)
                fl.flight_status = FlightlineStatusOption.UNDER_REVIEW
            })

            parsedMissions.push(newMission)
        }

        parsedMissions.sort(function(a, b) {
            if ((a.flown_date && b.flown_date) && a.flown_date !== b.flown_date) {
                return a.flown_date < b.flown_date ? 1 : -1
            }//desc date
            else if (a.flight_num !== b.flight_num) {
                return a.flight_num < b.flight_num ? -1 : 1
            }//asc fnum
            else if (a.channel_name && b.channel_name) {
                return a.channel_name < b.channel_name ? -1 : 1
            } //asc channel
            return 0
        })

        dispatch({
            type: Types.Missions_FetchParsedMissionsSuccess,
            payload: { parsedMissions, warnings: allWarnings, best_regex: bestRegex ?? templates[0] },
        })
        return true
    } catch (error) {
        let errorMessage = error.message || "Error uploading file."
        if (error.message === "413") {
            errorMessage = "File too large (1GB max)."
        }
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: `Parsing Error: ${errorMessage}` },
        })
        return false
    }
}

export async function fetchEmployeeList(dispatch: DispatchNexus, projectNumber: string): Promise<void> {
    dispatch({ type: Types.Missions_FetchEmployeeList })

    try {
        const result = await API.get(`/employees/${projectNumber}/mission`)

        if (result.data && result.data.employees) {
            // sort by pref name
            result.data.employees = result.data.employees.sort((a: EmployeeType, b: EmployeeType) => {
                return a.employee_full_name > b.employee_full_name ? +1 : -1
            })
        }

        dispatch({
            type: Types.Missions_FetchEmployeeListSuccess,
            payload: { employeeList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: "Error retrieving employee list" },
        })
    }
}

export async function fetchVendorNameList(dispatch: DispatchNexus): Promise<void> {
    dispatch({ type: Types.Missions_FetchVendorNameList })

    try {
        const result = await API.get("/vendor-names")

        dispatch({
            type: Types.Missions_FetchVendorNameListSuccess,
            payload: { vendorNameList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: "Error retrieving vendor name list." },
        })
    }
}

export async function fetchFlightplanList(dispatch: DispatchNexus, projectNumber: string | null): Promise<void> {
    try {
        const result = await API.get(`/flightplans/${projectNumber}`)

        dispatch({
            type: Types.Missions_FetchFlightplanListSuccess,
            payload: { flightplanList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: "Error retrieving flightplan list" },
        })
    }
}

export async function fetchSensorList(dispatch: DispatchNexus, projectNumber: string): Promise<void> {
    dispatch({ type: Types.Missions_FetchSensorList })

    try {
        const result = await API.get(`/sensors/${projectNumber}`)

        dispatch({
            type: Types.Missions_FetchSensorListSuccess,
            payload: { sensorList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: "Error retrieving sensor list." },
        })
    }
}

export async function fetchPlatformList(dispatch: DispatchNexus, projectNumber: string): Promise<void> {
    dispatch({ type: Types.Missions_FetchPlatformList })

    try {
        const result = await API.get(`/platforms/${projectNumber}`)

        dispatch({
            type: Types.Missions_FetchPlatformListSuccess,
            payload: { platformList: result.data },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: "Error retrieving platform list" },
        })
    }
}

export async function fetchAirportList(dispatch: DispatchNexus, projectNumber: string): Promise<void> {
    dispatch({ type: Types.Missions_FetchAirportList })

    try {
        const result = await API.get(`/airports/${projectNumber}`)

        const airportList = result.data.filter((ap: AirportType) => ap.airport !== "N/A")
        airportList.unshift({ airport: "N/A", inProject: false })

        dispatch({
            type: Types.Missions_FetchAirportListSuccess,
            payload: { airportList },
        })
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.Missions_UpdateError,
            payload: { error: "Error retrieving airport list" },
        })
    }
}
