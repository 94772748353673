import { create } from "zustand"
import {
    AcquisitionMode,
    AcquisitionModesForType,
    AoiSpecs,
    ModeShape,
} from "../models/AcquisitionMode"
import { AoiShape } from "../store/types/projectsTypes"

interface AcquisitionModeState {
    aoi_mode: ModeShape[]
    selected_aoi?: AoiShape
    viewAcqMode?: AcquisitionMode
    modesByType: AcquisitionModesForType[]
    selectedModeIds: number[]
    aoiSpecsCache: AoiSpecs
}

interface AoiStoreActions {
    setSelectedAoi: (aoi: AoiShape) => void
    updateSpec: (value: any, field: string) => ModeShape[]
    setViewAcqMode: (viewAcqMode: AcquisitionMode) => void
    setAvailableModes: (modes: AcquisitionMode[]) => void
    setSelectedModes: (modeIds: number[], modes?: ModeShape[]) => void
    setAoiSpecsCache: (aoiSpecs: AoiSpecs) => void
    toggleSelectedMode: (modeId: number) => Promise<number[]>
    clearSelected: () => void
}

const initialState: AcquisitionModeState = {
    aoi_mode: [],
    selectedModeIds: [],
    modesByType: [],
    aoiSpecsCache: {
        flow_conditions: [], leaf_conditions: [], tide_conditions: [], snow_conditions: [], imagery_bands: [],
    },
}

/**
 * This zustand store helps share data between the component creating the state, FlightplanAcquisitionModeSelect.tsx
 * And the API call that pushes the data to the server.
 */
export const useAcquisitionModeStore = create<AcquisitionModeState & AoiStoreActions>((set, get) => ({
    ...initialState,
    setAvailableModes: async (modes: AcquisitionMode[]) => {

        const modesByType: AcquisitionModesForType[] = []
        modes.forEach(mode => {
            const groupByValue = mode.mode_type
            const existing = modesByType.find(mode => mode.mode_type === groupByValue)
            const modesWithType = existing ?? { mode_type: groupByValue, modes: [] }
            if (modesWithType.modes.length === 0) {
                modesByType.push(modesWithType)
            }
            modesWithType.modes.push(mode)
        })

        // Sort Mode Types
        modesByType.sort((modeA, modeB) => modeA.mode_type.localeCompare(modeB.mode_type))
        // Sort Modes within Mode Types
        modesByType.forEach(modes => modes.modes.sort((modeA, modeB) => modeA.mode_name.localeCompare(modeB.mode_name)))

        // Set the first mode as viewed
        const viewAcqMode = modesByType[0].modes[0]

        set({ modesByType, viewAcqMode })
    },
    setSelectedModes: (selectedModeIds, aoi_mode) => {
        const state: Partial<AcquisitionModeState> = { selectedModeIds }
        if (aoi_mode) {
            state["aoi_mode"] = aoi_mode
        }
        set(state)
    },
    toggleSelectedMode: async (modeId: number) => {
        const preExistingModeSelections = useAcquisitionModeStore.getState().selectedModeIds!
        const remove = preExistingModeSelections.includes(modeId)
        const selectedModeIds: number[] = remove ? preExistingModeSelections.filter(selectedMode => selectedMode !== modeId) : [...preExistingModeSelections, modeId]
        set({ selectedModeIds })
        return selectedModeIds
    },
    setSelectedAoi: (aoi) => {
        const { setSelectedModes, modesByType } = get()
        const selectedModeIds = aoi.mode_ids || []

        const modes = modesByType.reduce((modeArray: AcquisitionMode[], modeType) => [...modeArray, ...modeType.modes], [])

        const aoi_mode = aoi.aoi_mode.map(mode => new ModeShape(mode)) || []
        // If there are selected mode_ids but are not coverape map supported
        // create empty forms to match
        if (!aoi_mode.length && selectedModeIds.length) {
            selectedModeIds.forEach(modeId => {
                const acqMode = modes.find(mode => mode.mode_id === modeId)
                if (acqMode) {
                    aoi_mode.push(ModeShape.createEmptyModeForm(acqMode))
                }
            })
        }

        setSelectedModes(selectedModeIds, aoi_mode)

        set({ selected_aoi: aoi })
    },
    setViewAcqMode: (selectedMode) => set({ viewAcqMode: selectedMode }),
    updateSpec: (value, key) => {
        const prevAoiModes = get().aoi_mode
        const selectedAcqMode = get().viewAcqMode

        let aoi_mode = [...prevAoiModes]
        if (selectedAcqMode) {
            const selectedIndex = prevAoiModes.findIndex(mode => mode.mode_id === selectedAcqMode.mode_id)
            if (selectedIndex !== -1) {
                let mode: ModeShape = prevAoiModes[selectedIndex]
                if (key in mode) {
                    mode = new ModeShape<object>({ ...mode, [key]: value })
                } else if (key in mode.specs) {
                    mode = new ModeShape<object>({ ...mode, specs: { ...mode.specs, [key]: value } })
                } else {
                    throw Error(`Update error with key: ${key} in mode with id: ${mode.mode_id}, type ${mode.mode_type}.`)
                }

                aoi_mode = getUpdatedArray(mode, prevAoiModes, selectedIndex)
            }

        }
        set({ aoi_mode })
        return aoi_mode
    },
    setAoiSpecsCache: (aoiSpecs) => set({ aoiSpecsCache: aoiSpecs }),
    clearSelected: () => {
        const state = { ...initialState }

        // Keeps mode types since only clearing selected aoi data
        state.modesByType = get().modesByType
        state.aoiSpecsCache = get().aoiSpecsCache

        set(state)
    },
}))

const getUpdatedArray = <T, >(update: T, array: T[], index: number) => {
    const startImgArray = index === 0 ? [] : array.slice(0, index)
    return [...startImgArray, update, ...array.slice(index + 1)]
}
