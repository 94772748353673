import { ReactNode, useContext } from "react"
import { useParams } from "react-router-dom"

import { Grid, MenuItem, Typography } from "@mui/material"

import Select from "../../components/Fields/Select"
import { Body, Label } from "../../components/Typography"

import { FlightplanStatuses } from "../../store/types/flightplansTypes"

import { RootContext } from "../../store/context"
import useStyles from "./styles"

const statusOptions: {label: FlightplanStatuses, description: ReactNode}[] = [
    {
        label: FlightplanStatuses.Active,
        description:
            "Flightplan is currently in use in acquisition. New logs can use this flightplan, and unflown lines should count against the % complete calculation for the project.",
    },
    {
        label: FlightplanStatuses.Inactive,
        description:
            "Flightplan is not currently in use in acquisition. New logs cannot use this flightplan, and unflown lines should not count against the % complete calculation for the project.",
    },
    {
        label: FlightplanStatuses.Complete,
        description: <>Flightplan will never be used in acquisition again. New logs cannot use this flightplan, and <Typography
            display="inline"
            color={"error"}
        > unflown lines will be deleted.</Typography></>,
    },
]

export default function FlightplanStatusSelect({ onChange }: any) {
    const { classes } = useStyles()
    const { flightplanFlow } = useParams<{ [key: string]: string }>()
    const { state } = useContext(RootContext)
    const { selectedFlightplan } = state.flightplans

    const isEditFlow = flightplanFlow === "edit"

    return (
        <>
            <Label gutterBottom="sm">Flightplan Status</Label>
            <Select
                value={selectedFlightplan.status || ""}
                options={statusOptions.map((status) => status.label)}
                disableDefaultValue
                renderValue={(value: string) => value}
                renderOption={(value: string) => (
                    <MenuItem key={value} value={value} disabled={value === FlightplanStatuses.Complete && !isEditFlow}>
                        <Grid container>
                            <Grid item sm={2} xs={3} className={classes.status_label}>
                                <Body disableGutter className={classes.status_label__text}>
                                    {value} -
                                </Body>
                            </Grid>
                            <Grid item sm xs className={classes.status_description}>
                                {statusOptions.find((s) => s.label === value)?.description}
                            </Grid>
                        </Grid>
                    </MenuItem>
                )}
                onChange={(e: any) => onChange(e.target.value || null, "status")}
                width="sm"
                menuHeight="lg"
                helperText="Required"
            />
        </>
    )
}
