import { Grid } from "@mui/material"

import { Label } from "../Typography"

import useProjects from "../../hooks/useProjects"
import useStyles from "./styles"
import { NumberCalculationField } from "../Fields/NumberCalculation"
import { convertArea } from "../../utils/unitCalculations"

//Database saves data in square meters
const areaContractedDefaultUnit = "m2"

type CalculationType = {
    label: string
    field: string
    value?: number
    disabled?: boolean
    blockRender?: boolean
}
type CalculationsType = CalculationType[]

function AOICalculations() {
    const { classes } = useStyles()

    const { selectedAOIData, handleAOIDataChange } = useProjects()

    const calculations: CalculationsType = [
        {
            label: "Contracted Area",
            field: "area_contracted",
            value: selectedAOIData.area_contracted,
        },
        // computed: {
        //   label: "Computed Area (from file)",
        //   field: "area",
        //   value: selectedAOIData.area,
        //   disabled: true,
        // },
    ]

    return (
        <Grid>
            {calculations.map(
                (calc) =>
                    !calc.blockRender && (
                        <Grid key={calc.label}>
                            <Label gutterBottom="sm">{calc.label}</Label>
                            <Grid className={classes.gutterBottom__md}>
                                <NumberCalculationField
                                    convertFunc={convertArea}
                                    disabled={calc.disabled}
                                    value={calc.value}
                                    defaultType={areaContractedDefaultUnit}
                                    convertUnits={["mi2", "ac", "km2"]}
                                    onChange={(value) => handleAOIDataChange(value, "area_contracted")}
                                />
                            </Grid>
                        </Grid>
                    ),
            )}
        </Grid>
    )
}

export default AOICalculations
