import { OptionType } from "../store/types/appTypes"
import { MissionType } from "../store/types/missionsTypes"
import { FlightPlannedLineType } from "../store/types/flightplansTypes"

interface flightlineConflictErrors {
    [flightline_name: string]: {
        flight_plan_id: number // the assigned plan
        conflicts: number[] // conflicting flightplan ids that also have the same flightline_name
    }
}

interface FlightlineErrors {
    [missionName: string]: {
        conflictErrors: flightlineConflictErrors // object of flight lines and plans
        noMatchErrors: string[] // array of line ids
        regexErrors: string[] // array of regex original names
        numberOfFlightLines: number
    }
}

export enum MissionActiveFlow {
    NO_FLIGHT = "no-flight",
    NO_FILE = "no-file",
    NO_ACQUISITION = "no-acquisition",
    FULL_FLIGHT = "full-flight"
}

export interface MissionTypeOptions extends Omit<OptionType, "id"> {
    id: MissionActiveFlow
    label: string
    disabled?: boolean
    tooltip?: string
}

export const validateFlightplanLines = (parsedMissions: MissionType[], flightPlannedLines: FlightPlannedLineType[]) => {
    const errors: FlightlineErrors = {}
    parsedMissions.forEach(mission => {
        errors[mission.display_name] = {
            noMatchErrors: [],
            conflictErrors: {},
            numberOfFlightLines: mission.flightline_events.length,
            regexErrors: [],
        }
        const missionErrors = errors[mission.display_name]
        mission.flightline_events.forEach((lineEvent) => {
            if (lineEvent.flightline_name) {
                // set the flightline_name in obj as dict key
                const curConflictErrors = missionErrors.conflictErrors

                // find the lines in planned lines array with conflicting flightline_names
                const linesWithConflictingPlannedNames = flightPlannedLines.reduce((acc: number[], plannedLine) => {
                    // matches
                    if (plannedLine.flight_plan_id) {
                        if (lineEvent.flightline_name === plannedLine.flight_line_name && !acc.includes(plannedLine.flight_plan_id)) {
                            return [...acc, plannedLine.flight_plan_id]
                        }
                    }
                    return acc
                }, [])

                if (!linesWithConflictingPlannedNames.length && lineEvent.flightline_name) {
                    // No matches
                    missionErrors.noMatchErrors = [...missionErrors.noMatchErrors, lineEvent.flightline_name]
                }

                if (linesWithConflictingPlannedNames.length > 1 && lineEvent.flightline_name) {
                    // conflicting
                    linesWithConflictingPlannedNames.shift()
                    // Flight planned lines that have conflicting line names
                    curConflictErrors[lineEvent.flightline_name] = {
                        flight_plan_id: linesWithConflictingPlannedNames[0],
                        conflicts: linesWithConflictingPlannedNames,
                    }
                }
            } else {
                // regex failed completely when finding line_name and line's plan_id so report the original name instead
                missionErrors.regexErrors = [...missionErrors.regexErrors, lineEvent.line_original_name]
            }
        })

        // remove empty error records
        if (!missionErrors.conflictErrors.length && !missionErrors.noMatchErrors.length && !missionErrors.regexErrors.length) {
            delete errors[mission.display_name]
        }
    })
    return errors
}

export const setNoMatchToIgnored = (flightLines: string[], parsedMissions: MissionType[]) => {
    parsedMissions.forEach(mission => {
        mission.flightline_events.forEach(lineEvent => {
            if (lineEvent?.flightline_name && flightLines.includes(lineEvent?.flightline_name)) {
                lineEvent.flight_status = "Ignored"
            }
        })
    })
}

export const setRegexErrorToIgnored = (flightLines: string[], parsedMissions: MissionType[]) => {
    parsedMissions.forEach(mission => {
        mission.flightline_events.forEach(lineEvent => {
            if (lineEvent?.line_original_name && flightLines.includes(lineEvent?.line_original_name)) {
                lineEvent.flight_status = "Ignored"
            }
        })
    })
}

