import { useContext, useState, useEffect } from "react"
import { Link, RouteComponentProps } from "react-router-dom"

import { Button, Container, Grid } from "@mui/material"

import Alert from "../../components/Alert"
import Autocomplete from "../../components/Fields/Autocomplete"
import DetailsList, { Detail } from "../../components/DetailsList"
import LoadingButton from "../../components/LoadingButton"
import { Label, Title } from "../../components/Typography"

import { RootContext } from "../../store/context"
import {
    fetchNonAcquisitionProjectList,
    fetchNonAcquisitionProject,
    initializeAcquisitionProject,
} from "../../store/actions/projectsActions"
import { Types } from "../../store/types"
import { ProjectType } from "../../store/types/projectsTypes"
import routes from "../../routes"
import useStyles from "./styles"

const projectStatuses: { [key: string]: string } = {
    A: "Active",
    I: "Inactive",
    D: "Dormant",
}

const projectFields: Detail[] = [
    { field: "project_number", label: "Project Number" },
    { field: "project_short_name", label: "Project Name" },
    { field: "client_name", label: "Client Name" },
    { field: "practice_area", label: "Practice Area" },
    { field: "location", label: "Location" },
    {
        field: "am_employee_id",
        label: "Account Manager",
        render: (project: ProjectType) => (project.am_employee?.employee_full_name || null),
    },
    {
        field: "pm_employee_id",
        label: "Project Manager",
        render: (project: ProjectType) => (project.pm_employee?.employee_full_name || null),
    },
    {
        field: "project_status",
        label: "Project Status",
        render: (project: ProjectType) => (project ? projectStatuses[project.project_status!] : null),
    },
]

export default function ProjectInit({ history }: RouteComponentProps) {
    const { classes } = useStyles()
    const { state, dispatch } = useContext(RootContext)
    const { error } = state.app
    const { nonAcqProjectList, selectedNonAcqProject, loadingNonAcqProjectList, loadingNonAcqProject } = state.projects

    const [selectedId, setSelectedId] = useState<string | null>(null)
    const [pending, setPending] = useState<boolean>(false)

    useEffect(() => {
        dispatch({ type: Types.App_ClearError })
        dispatch({ type: Types.Flightplans_ResetState })
        dispatch({ type: Types.Missions_ResetState })
        // deselect project if one selected
        dispatch({ type: Types.Projects_ResetProjectData })

        const fetchData = async () => {
            await fetchNonAcquisitionProjectList(dispatch)
        }
        fetchData()
    }, []) // eslint-disable-line

    const handleChange = async (value: string | null) => {
        const selectedProject = nonAcqProjectList.find((project) => project.project_number === value)
        const newId = selectedProject?.project_number || null

        setSelectedId(newId)
        await fetchNonAcquisitionProject(dispatch, newId)
    }

    const handleInitialization = async () => {
        setPending(true)

        const success = await initializeAcquisitionProject(dispatch, selectedId!)

        if (success) {
            setPending(false)
            history.push(`/${selectedNonAcqProject.project_number}${routes.projectEditor}`)
        }
    }

    return (
        <Container maxWidth="md" className={classes.root}>
            <Grid>
                <Title>Acquisition Project Initialization</Title>
            </Grid>

            <Label gutterBottom="sm">Select Project to Initialize</Label>
            <Autocomplete
                placeholder="Select..."
                value={nonAcqProjectList.find((s: ProjectType) => s.project_number === selectedId) ?? null}
                options={nonAcqProjectList}
                getOptionLabel={(opt) => `${opt.project_number} - ${opt.project_long_name}`}
                onChange={(_, value) => handleChange(value?.project_number ?? null)}
                gutterBottom="lg"
                fullWidth
                loading={loadingNonAcqProjectList || loadingNonAcqProject}
                disabled={loadingNonAcqProject}
            />

            <DetailsList
                label="Deltek Description of Selected Project"
                details={projectFields}
                value={selectedNonAcqProject}
            />

            {error && (
                <Alert animate onClose={() => dispatch({ type: Types.App_ClearError })}>
                    {error}
                </Alert>
            )}

            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Button component={Link} to={routes.home} variant="outlined" color="primary" size="large">
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <LoadingButton
                        onClick={handleInitialization}
                        pending={pending}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!selectedNonAcqProject?.project_number || loadingNonAcqProject}
                    >
                        Initialize New Acquisition Project
                    </LoadingButton>
                </Grid>
            </Grid>
        </Container>
    )
}
