import { Types } from "../types"
import { UserType } from "../types/appTypes"
import { getNv5ClientGroup, setLocalStorageItem } from "../../tools"
import API from "../../api"
import { DispatchNexus } from "../context"

export async function loginUser(dispatch: DispatchNexus, userMgmtUrl: string): Promise<boolean> {
    dispatch({ type: Types.App_FetchUser })

    try {
        const result: { data: UserType } = await API.getInsiteResource(userMgmtUrl, "/users/get/current_user")
        const user = result.data

        if (user && user.user_groups) {
            // save  client_id with nexus enabled and in nv5gClients
            const grp = user.user_groups.find(getNv5ClientGroup)
            if (!grp) {
                throw new Error(
                    // eslint-disable-next-line
                    "Login successful, but user must be assigned a Nexus-enabled user group. \
                    Try refreshing, or contact an administrator if this error persists.",
                )
            }

            dispatch({
                type: Types.App_FetchUserSuccess,
                payload: { user: user },
            })
            setLocalStorageItem({ nexus_client: grp.client_id })

            return true
        }

        throw new Error(
            // eslint-disable-next-line
            "Login successful, but failed to fetch user data. \
            Try refreshing, or contact an administrator if this error persists.",
        )
    } catch (error) {
        let displayError = error
        if (error.name === "TypeError") {
            displayError = "Login failed. Try refreshing, or contact an administrator if this error persists."
        }

        console.error(displayError)
        dispatch({
            type: Types.App_FetchUserFailed,
            payload: { error: displayError },
        })
    }
    return false
}

export async function logoutUser(dispatch: DispatchNexus): Promise<boolean> {
    try {
        await dispatch({ type: Types.App_LogoutUser })
        await setLocalStorageItem({ auth_token: "", nexus_client: "" })

        return true
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Logout failed." },
        })
    }
    return false
}

export async function fetchDatabaseVersion(dispatch: DispatchNexus): Promise<void> {
    dispatch({ type: Types.App_FetchDBVersion })

    try {
        const result = await API.get("/version")

        if (result.error || !result.data?.user_mgmt_url) {
            throw new Error()
        }
        dispatch({
            type: Types.App_FetchDBVersionSuccess,
            payload: {
                databaseName: result.data.database_name,
                databaseVersion: result.data.database_version,
                userMgmtUrl: result.data.user_mgmt_url,
            },
        })
    } catch (error) {
        console.error(error)
        dispatch({ type: Types.App_FetchDBVersionError })
    }
}

export async function fetchMasterLog(dispatch: DispatchNexus): Promise<void> {
    dispatch({ type: Types.App_FetchMasterLog })

    try {
        await API.downloadFile("/master-log")

        dispatch({ type: Types.App_FetchMasterLogSuccess })
    } catch (error) {
        console.error(error)
        dispatch({ type: Types.App_FetchMasterLogError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error retrieving master log." },
        })
    }
}
