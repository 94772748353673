import propTypes from "prop-types"

import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material"

import useStyles from "./styles"

type CustomSelectProps = {
    id?: string
    label?: string
    value: string
    onChange: any
    options: string[]
    displayValues?: string[]
    disabled?: boolean
    disableDefaultValue?: boolean
    fullWidth?: boolean
    width?: "sm" | "md" | "lg"
    gutterBottom?: "sm" | "md" | "lg"
    menuHeight?: "sm" | "md" | "lg"
    disableGutter?: boolean
    helperText?: string
    error?: boolean
    classes?: any
    className?: string
    displayEmpty?: boolean
    MenuProps?: any
    [key: string]: any
}

export default function CustomSelect({
    id,
    label,
    value,
    onChange,
    options,
    renderOption,
    displayValues,
    disabled,
    disableDefaultValue,
    fullWidth,
    width = "sm",
    gutterBottom = "md",
    disableGutter,
    menuHeight = "sm",
    helperText,
    error,
    inline,
    classes,
    className,
    ...other
}: CustomSelectProps) {
    const { classes: styles, cx } = useStyles()

    return (
        <Grid
            className={cx(
                {
                    [styles[`gutterBottom__${gutterBottom}`]]: !disableGutter,
                    [styles.inline]: inline,
                },
                className,
            )}
            classes={classes.root}
        >
            <FormControl
                variant="outlined"
                fullWidth={fullWidth}
                error={error}
                disabled={disabled}
                className={cx({ [styles[`input__${width}`]]: !fullWidth })}
                classes={classes.formControl}
            >
                <InputLabel id={id && `${id}-select-label`} classes={classes.inputLabel}>
                    {label}
                </InputLabel>
                <Select
                    labelId={id && `${id}-select-label`}
                    id={id && `${id}-select`}
                    value={value}
                    onChange={onChange}
                    label={label}
                    classes={classes.select}
                    className={cx(styles.select, { [styles.placeholder]: value === "" })}
                    MenuProps={{
                        classes: {
                            paper: styles[`menuHeight__${menuHeight}`],
                        },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                    }}
                    {...other}
                >
                    {!disableDefaultValue && (
                        <MenuItem value="" className={styles.placeholder}>
                            Select...
                        </MenuItem>
                    )}
                    {options.map((option, index) => {
                        return (
                            (renderOption && renderOption(option)) || (
                                <MenuItem key={option} value={option}>
                                    {displayValues ? displayValues[index] : option}
                                </MenuItem>
                            )
                        )
                    })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Grid>
    )
}

CustomSelect.propTypes = {
    id: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.string),
    fullWidth: propTypes.bool,
    width: propTypes.oneOf(["sm", "md", "lg"]),
    gutterBottom: propTypes.oneOf(["sm", "md", "lg"]),
    menuHeight: propTypes.oneOf(["sm", "md", "lg"]),
    disableGutter: propTypes.bool,
    classes: propTypes.shape({
        root: propTypes.shape({}),
        formControl: propTypes.shape({}),
        inputLabel: propTypes.shape({}),
        select: propTypes.shape({}),
    }),
}

CustomSelect.defaultProps = {
    width: "sm",
    gutterBottom: "md",
    menuHeight: "sm",
    classes: {},
}
