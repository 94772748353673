import * as React from "react"
import Drawer, { DrawerProps } from "@mui/material/Drawer"
import { Box, Typography } from "@mui/material"

interface TemporaryDrawerProps extends DrawerProps {
    title?: string
}

function TemporaryDrawer({ title, children, ...props }: TemporaryDrawerProps) {
    return (
        <Drawer {...props}>
            <Box sx={{ px: 4, pt: 4, pb: 2, maxWidth: "700px" }}>
                {title &&
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6">{title}</Typography>
                    </Box>
                }

                {children}
            </Box>
        </Drawer>
    )
}

export default TemporaryDrawer
