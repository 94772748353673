import { makeStyles } from "tss-react/mui"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...spacing(theme),

    underline: {
        textDecoration: "underline",
    },
}))

export default useStyles
