import { useAcquisitionModeStore } from "../../zstore/AcquisitionModeStore"
import {
    Box,
    ListItemSecondaryAction,
    ListItemText,
    List,
    ListItemButton,
    Checkbox,
    Typography,
    Grid,
    Divider,
} from "@mui/material"
import { ModeView } from "./ModeView"
import { AcquisitionMode, AoiSpecs, ModeShape } from "../../models/AcquisitionMode"
import { useEffect } from "react"
import useProjects from "../../hooks/useProjects"
import { Label, Subtitle2 } from "../Typography"

interface ModeTabProps {
    modes: AcquisitionMode[]
    aoiSpecs: AoiSpecs
    modeSelectionDisabled?: boolean
}

export const ModeTabs = ({ modes, aoiSpecs, modeSelectionDisabled }: ModeTabProps) => {
    const { handleAOIModesChange } = useProjects()

    const setAvailableModes = useAcquisitionModeStore(state => state.setAvailableModes)
    const selectedModeIds = useAcquisitionModeStore(state => state.selectedModeIds)
    const modesByType = useAcquisitionModeStore(state => state.modesByType)
    const setSelectedModes = useAcquisitionModeStore(state => state.setSelectedModes)
    const viewAcqMode = useAcquisitionModeStore(state => state.viewAcqMode)
    const setViewAcqMode = useAcquisitionModeStore(state => state.setViewAcqMode)
    const aoi_mode = useAcquisitionModeStore(state => state.aoi_mode)
    const setAoiSpecsCache = useAcquisitionModeStore(state => state.setAoiSpecsCache)

    useEffect(() => {
        setAoiSpecsCache(aoiSpecs)
    }, [setAoiSpecsCache, aoiSpecs])

    useEffect(() => {
        setAvailableModes(modes)
    }, [setAvailableModes, modes])

    const handleToggle = (value: AcquisitionMode) => {
        const currentIndex = aoi_mode.findIndex(mode => mode.mode_id === value.mode_id)

        const updatedAoiMode: ModeShape[] = [...aoi_mode]
        if (currentIndex === -1) {
            const createMode = ModeShape.createEmptyModeForm(value)
            updatedAoiMode.push(createMode)
        } else {
            updatedAoiMode.splice(currentIndex, 1)
        }

        const newChecked = updatedAoiMode.map(mode => mode.mode_id)
        setSelectedModes(newChecked, updatedAoiMode)
        handleAOIModesChange(updatedAoiMode, newChecked)
    }

    const modeIsChecked = (modeId: number) => selectedModeIds?.indexOf(modeId) !== -1

    return (
        <>
            <Label disableGutter>Select Acquisition Modes</Label>
            <Subtitle2 gutterBottom={"md"}>Required</Subtitle2>
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <List disablePadding>
                        {modesByType.map((modesObj, idx) => (
                            <Box key={idx}>
                                <Typography><strong>{modesObj.mode_type}</strong></Typography>
                                <Divider />
                                {modesObj.modes.map((value, idx) => {
                                    const labelId = `checkbox-list-secondary-label-${value.mode_id}`
                                    const selected = viewAcqMode?.mode_id === value.mode_id
                                    return (
                                        <ListItemButton key={idx} onClick={() => setViewAcqMode(value)} selected={selected}>
                                            <ListItemText id={labelId} primary={value.mode_name} />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    disabled={modeSelectionDisabled}
                                                    edge="end"
                                                    onClick={() => {
                                                        handleToggle(value)
                                                        setViewAcqMode(value)
                                                    }}
                                                    checked={modeIsChecked(value.mode_id)}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                    )
                                })}
                            </Box>
                        ))}
                    </List>
                </Grid>
                <Grid item xs sm md>
                    {viewAcqMode &&
                        <ModeView selectedMode={viewAcqMode} checked={modeIsChecked(viewAcqMode.mode_id)} />
                    }
                </Grid>
            </Grid>
        </>
    )
}
