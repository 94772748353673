import { useCallback, useContext } from "react"

import Table from "../../components/Table"

import { Types } from "../../store/types"
import { RootContext } from "../../store/context"
import { Convert, TableColumn } from "../../models/Table"
import { FlightPlannedLineType } from "../../store/types/flightplansTypes"

const flightplanTablePrimaryKey: keyof FlightPlannedLineType = "planned_line_id"

const columns: TableColumn[] = [
    { id: "flight_line_name", label: "Line ID", align: "right", minWidth: 120, width: "22%" },
    {
        id: "planned_distance",
        label: "Distance\u00a0",
        align: "right",
        minWidth: 100,
        width: "22%",
        convertType: Convert.DISTANCE,
    },
    { id: "mission_name", label: "Flown Mission ID", align: "center", minWidth: 190, width: "56%", defaultDash: true },
]

function FlightplanTable() {
    const { state, dispatch } = useContext(RootContext)
    const { selectedFlightplan, flightPlannedLines } = state.flightplans
    const { status } = selectedFlightplan

    const filteredRows = flightPlannedLines
        .filter((fl) => (status === "Complete" ? Boolean(fl.mission_name) : true)) // filter out unflown lines if Complete

    const handleUpdate = useCallback(
        (lines: FlightPlannedLineType[], changed: FlightPlannedLineType[] = []) => {
            dispatch({
                type: Types.Flightplans_UpdateFlightPlannedLines,
                payload: { flightPlannedLines: lines, deletedLines: changed },
            })
        },
        [dispatch, filteredRows.length], // eslint-disable-line
    )

    return (
        <Table
            label="Flightplan Lines"
            columns={columns}
            rows={filteredRows}
            primaryKey={flightplanTablePrimaryKey}
            updateTableRows={handleUpdate}
            disableRowWithKey={"mission_name"}
            canSelect
            canDeleteSelected
        />
    )
}

export default FlightplanTable
