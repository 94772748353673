import { Button, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material"
import React from "react"
import { FlightLineEventErrorMessage } from "./index"

interface MissionValidatorDialogProps {
    flightlineEventErrors: FlightLineEventErrorMessage[]
    setFlightlineEventErrors:  React.Dispatch<React.SetStateAction<FlightLineEventErrorMessage[] | null>>
}

enum ShowWhatLines {
    SHOW_CONFLICTS = "showConflictLines",
    SHOW_NO_MATCH = "showNoMatchLines",
    SHOW_REGEX_ERRORS = "showRegexErrorLines"
}

export const MissionValidatorDialog = ({ flightlineEventErrors, setFlightlineEventErrors }: MissionValidatorDialogProps) => {

    const handleShowLines = (whichLines: ShowWhatLines, missionName: string) => {
        const changeBool = (key: keyof FlightLineEventErrorMessage) => flightlineEventErrors.map(flError => {
            if (flError.missionName === missionName) {
                return {
                    ...flError,
                    [key]: !flError[key]
                }
            } else {
                return flError
            }
        })
        const currentMissionObj = changeBool(whichLines)
        setFlightlineEventErrors(currentMissionObj)
    }

    return (
        <>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                {flightlineEventErrors?.map((errorObject, index) =>
                    <DialogContent key={index}>
                        <DialogContentText variant={"h6"}>
                            The following errors occurred in {errorObject.missionName}:
                        </DialogContentText>
                        {errorObject?.conflictLines?.map((lines, index) =>
                            <ValidatorDialogSegment
                                key={index}
                                text={`${lines.conflicts.length}/${errorObject.totalLines} flight lines have been assigned ${lines.planName} due to conflicts with selected flight plans.`}
                                onClick={() => handleShowLines(ShowWhatLines.SHOW_CONFLICTS, errorObject.missionName)}
                                linesHidden={!errorObject.showConflictLines}
                                bottomText={lines?.conflicts.join(", ")}
                            />
                        )}
                        {!!errorObject.noMatchLines?.length &&
                            <ValidatorDialogSegment
                                divider={!!errorObject?.conflictLines?.length}
                                text={`${errorObject.noMatchLines.length}/${errorObject.totalLines} flight lines could not be found in the selected flightplans. They will be set to Ignored status.`}
                                onClick={() => handleShowLines(ShowWhatLines.SHOW_NO_MATCH, errorObject.missionName)}
                                linesHidden={!errorObject.showNoMatchLines}
                                bottomText={errorObject.showNoMatchLines ? errorObject.noMatchLines.join(", ") : ""}
                            />
                        }
                        {!!errorObject?.regexErrorLines?.length &&
                            <ValidatorDialogSegment
                                divider={!!(errorObject.conflictLines?.length || errorObject.noMatchLines?.length)}
                                text={`${errorObject.regexErrorLines.length}/${errorObject.totalLines} flight line names could not be determined. They will be set to Ignored status.`}
                                onClick={() => handleShowLines(ShowWhatLines.SHOW_REGEX_ERRORS, errorObject.missionName)}
                                linesHidden={!errorObject.showRegexErrorLines}
                                bottomText={errorObject.showRegexErrorLines ? errorObject.regexErrorLines.join(", ") : ""}
                            />
                        }
                    </DialogContent>
                )}
            </DialogContent>
        </>
    )
}

interface ValidatorDialogSegmentProps {
    text: string
    onClick: () => void
    bottomText?: string
    divider?: boolean
    linesHidden?: boolean
}

const ValidatorDialogSegment = ({ text, onClick, bottomText, divider, linesHidden }: ValidatorDialogSegmentProps) => {
    return (
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {divider &&
                <Divider variant={"middle"} sx={{ mb: 2 }} />
            }
            <DialogContentText>{text}</DialogContentText>
            <DialogContentText>
                <Button size={"small"} variant={"outlined"} onClick={onClick}>
                    { linesHidden ? "Show" : "Hide" } Lines
                </Button>
            </DialogContentText>
            {bottomText &&
                <DialogContentText>{bottomText}</DialogContentText>
            }
        </DialogContent>
    )
}
