const animations = (theme: any) => ({
    bounce: {
        animation: `$bounce 800ms ${theme.transitions.easing.easeInOut}`,
    },

    "@keyframes bounce": {
        "0%": { transform: "translateY(0)" },
        "20%": { transform: "translateY(0)" },
        "40%": { transform: "translateY(-6px)" },

        "50%": { transform: "translateY(0)" },
        "60%": { transform: "translateY(-3px)" },

        "80%": { transform: "translateY(0)" },
        "100%": { transform: "translateY(0)" },
    },
})

export default animations
