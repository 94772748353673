import { Types } from "../types"
import { FlightPlannedLineType, FlightplanType } from "../types/flightplansTypes"
import API from "../../api"
import { AppActions } from "../types/appTypes"
import { Dispatch } from "react"
import { DispatchNexus } from "../context"
import { insertTemporaryTableId, isRowInserted } from "../../utils/tableUtils"

interface FlightPlannedLineCreateDTO extends Omit<FlightPlannedLineType, "planned_line_id"> {
    planned_line_id?: number
}

const getFlightPlannedLines = async (flightPlanId: number): Promise<FlightPlannedLineType[]> => {
    const result: { data: FlightPlannedLineType[] } = await API.get(`/flight-planned-lines/${flightPlanId}`)
    if (!result.data) {
        throw new Error(`Error in retrieving flight planned lines in Flightplan with flightplan ID: ${flightPlanId}.`)
    }
    return result.data
}

export async function fetchFlightplanList(
    dispatch: DispatchNexus,
    projectNumber: string | undefined,
    flightplanId: string | null,
): Promise<void> {
    dispatch({ type: Types.Flightplans_FetchFlightplanList })

    try {
        let selectedFlightplan = null
        let flightPlannedLines: FlightPlannedLineType[] = []

        const result = await API.get(`/flightplans/${projectNumber}`)
        const flightplanList = result.data

        if (flightplanList && flightplanList.length > 0) {
            if (flightplanId) {
                selectedFlightplan = flightplanList.find((fp: FlightplanType) => fp.flight_plan_id === +flightplanId) || null
            }

            const { flight_plan_id } = selectedFlightplan || flightplanList[0]
            flightPlannedLines = await getFlightPlannedLines(flight_plan_id)
        }

        dispatch({
            type: Types.Flightplans_FetchFlightplanListSuccess,
            payload: { flightplanList, flightPlannedLines, selectedFlightplan },
        })
    } catch (error) {
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error.message ?? "Error fetching Flightplan List" },
        })
    }
}

export async function selectFlightplan(dispatch: DispatchNexus, selectedFlightplan: FlightplanType): Promise<boolean> {
    dispatch({
        type: Types.Flightplans_SelectFlightplan,
        payload: { selectedFlightplan },
    })

    try {
        if (!selectedFlightplan.flight_plan_id) {
            throw new Error("Error in finding Flightplan ID for selected Flightplan")
        }
        const plannedLines = await getFlightPlannedLines(selectedFlightplan.flight_plan_id)

        dispatch({
            type: Types.Flightplans_SelectFlightplanSuccess,
            payload: { flightPlannedLines: plannedLines },
        })
        return true
    } catch (error) {
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error.message ?? "Error fetching flightplan" },
        })
        return false
    }
}

interface UpdateFlightplan extends FlightplanType {
    deleted_lines: number[]
    acquisition_mode_ids: number[]
}

export async function updateFlightplan(dispatch: DispatchNexus, flight_plan: FlightplanType, deletedPlannedLineIds: number[], acquisition_mode_ids: number[]): Promise<boolean> {
    try {
        const formattedFlightplan: UpdateFlightplan = {
            ...flight_plan,
            deleted_lines: deletedPlannedLineIds,
            acquisition_mode_ids,
        }
        const result = await API.put(`/flightplan/${flight_plan.flight_plan_id}`, formattedFlightplan)

        if (result.success) {
            return true
        }
    } catch (error) {
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error.message || "Error updating flightplan." },
        })
    }
    return false
}
export async function createFlightplan(
    dispatch: Dispatch<AppActions>,
    flight_plan: FlightplanType,
    flightPlannedLines: FlightPlannedLineType[],
    acquisition_mode_ids: number[],
): Promise<boolean> {
    try {
        const flight_planned_lines: FlightPlannedLineCreateDTO[] = flightPlannedLines.map(line => {
            const newLine: FlightPlannedLineCreateDTO = { ...line }
            // If row is inserted than assure temp planned_line_id is removed
            if (isRowInserted(line.planned_line_id)) {
                delete newLine.planned_line_id
            }
            return newLine
        })
        const formattedFlightplan: any = { flight_plan, flight_planned_lines, acquisition_mode_ids }
        const result = await API.post("/flightplan/0", formattedFlightplan)

        if (result.success) {
            return true
        }
    } catch (error) {
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error.message || "Error submitting flightplan." },
        })
    }
    return false
}

//@Todo: replace with generic
export async function deleteFlightplan(dispatch: DispatchNexus, flight_plan_id: number): Promise<boolean> {
    dispatch({ type: Types.Flightplans_DeleteFlightplan })

    try {
        const result = await API.delete(`/flightplan/${flight_plan_id}`)
        if (!result.success) {
            throw new Error()
        }

        dispatch({ type: Types.Flightplans_DeleteFlightplanSuccess })
        return true
    } catch (error) {
        dispatch({ type: Types.Flightplans_DeleteFlightplanError })
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: error.message || "Error deleting flightplan." },
        })
    }
    return false
}

export async function parseFlightplanFile(
    dispatch: DispatchNexus,
    file: File,
    fileName: string,
    projectNumber: string,
): Promise<boolean> {
    dispatch({ type: Types.Flightplans_ParseFile })
    const parserError = true

    try {
        const formData = new FormData()
        formData.append("file", file)

        const response: {
            data: {
                flight_planned_lines: FlightPlannedLineCreateDTO[]
                warnings: string[]
                success: boolean
                source_file: string | null
            }
        } = await API.uploadFile("/upload/flightplan", formData)

        const flightplan: FlightplanType = {
            flight_plan_id: null,
            flight_plan_name: fileName,
            project_number: projectNumber,
            status: "Active",
            source_file: response.data.source_file,
        }
        let flightPlannedLines: FlightPlannedLineType[] = response.data.flight_planned_lines.map((plannedLine, idx) => ({
            ...plannedLine,
            planned_line_id: insertTemporaryTableId(idx),
        }))
        flightPlannedLines = flightPlannedLines.sort((a, b) => (a.flight_line_name > b.flight_line_name ? 1 : -1))

        dispatch({
            type: Types.Flightplans_ParseFileSuccess,
            payload: {
                flightplan,
                flightPlannedLines,
                warnings: response.data.warnings,
            },
        })

        return true
    } catch (error) {
        let errorMessage = "Error uploading file."
        if (parserError) {
            errorMessage = error.message
        }
        if (error.message === "413") {
            errorMessage = "File too large (50MB max)."
        }
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: errorMessage },
        })
        return false
    }
}
