import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"

import {
    AppBar,
    Container,
    Grid,
    Toolbar,
    Typography,
    Button,
    FormControl,
    Menu,
    MenuList,
    MenuItem,
    Select,
    Box,
} from "@mui/material"
import { ArrowDropDown, ExitToApp, GetApp, Settings } from "@mui/icons-material"

import Tool from "../Tool"

import { OptionType } from "../../store/types/appTypes"
import { fetchMasterLog } from "../../store/actions/appActions"
import { RootContext } from "../../store/context"
import useAuth from "../../hooks/useAuth"
import routes from "../../routes"
import useStyles from "./styles"
import { DistanceUnits, HeightUnits, TimeFormat, useLocalStorageUtil } from "../../LocalStorageUtil"
import useToggle from "../../hooks/useToggle"
import AlertModal from "../Modals/AlertModal"
import { getDistanceUnits, getHeightUnits } from "../../utils/unitCalculations"

const jumpToFields: { label: string, link: string }[] = [
    { label: "inSITE", link: "https://insite.nv5geospatial.com" },
    { label: "inSITE (Test)", link: "https://insite-test.nv5geospatial.com/" },
    {
        label: "User Guides",
        link: "https://nv5inc.sharepoint.com/sites/NexusUserGroup/SitePages/Nexus-User-Guide.aspx",
    },
    { label: "Feedback", link: "https://quantumspatial.atlassian.net/servicedesk/customer/portal/3/group/-1" },
]

// slower tooltip
const TooltipProps = {
    enterDelay: 400,
    enterNextDelay: 100,
}

export default function Header() {
    const { classes } = useStyles()
    const { state, dispatch } = useContext(RootContext)
    const { masterLogLoading } = state.app
    const { userLoading, isLoggedIn, checkNV5GClientAccess, handleLogout } = useAuth(true)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()
    const [prefModalOpen, togglePrefModalOpen] = useToggle(false)

    const timeFormat = useLocalStorageUtil(state => state.timeFormat())
    const saveTimeFormat = useLocalStorageUtil(state => state.saveTimeFormat)
    const distanceUnits = useLocalStorageUtil(state => state.preferredDistanceUnit())
    const saveDistanceUnits = useLocalStorageUtil(state => state.savePreferredDistanceUnit)
    const heightUnit = useLocalStorageUtil(state => state.preferredHeightUnit())
    const saveHeightUnit = useLocalStorageUtil(state => state.savePreferredHeightUnit)

    const downloadOptions: OptionType[] = [{ id: "0", label: "Download Master Log", disabled: masterLogLoading }]

    const handleMasterLogDownload = async () => {
        await fetchMasterLog(dispatch)
    }

    const handleDownloadTool = (option: OptionType) => {
        if (typeof option === "object") {
            switch (option.id) {
                case "0":
                    handleMasterLogDownload()
                    break
                default:
                    break
            }
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const id = anchorEl ? "simple-popover" : undefined

    return (
        <>
            <AppBar position="fixed">
                <Container maxWidth="md">
                    <Toolbar disableGutters>
                        <Grid container justifyContent="space-between">
                            {/* Uploader Title and Link selection */}
                            <Grid item className={classes.header_action_group}>
                                <Typography component={Link} to={routes.home} variant="h6" className={classes.title}>
                                    Uploader
                                </Typography>
                                <FormControl variant="standard" sx={{ m: 2, ml: 4, minWidth: 130 }}>
                                    <Button
                                        sx={{ alignItems: "center" }}
                                        disableRipple
                                        className={classes.linksButton}
                                        aria-describedby={id}
                                        onClick={(e) => handleClick(e)}
                                    >
                                        <Typography variant="body1">
                                            Jump to...
                                        </Typography>
                                        <ArrowDropDown />
                                    </Button>
                                    <Menu
                                        id={id}
                                        open={!!anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorEl={anchorEl}
                                    >
                                        <MenuList sx={{ maxWidth: 150 }}>
                                            {jumpToFields.map((jump, idx) => {
                                                return (
                                                    <a key={idx} href={jump.link} className={classes.linkName}>
                                                        <MenuItem>{jump.label}</MenuItem>
                                                    </a>)
                                            })}
                                        </MenuList>
                                    </Menu>
                                </FormControl>
                            </Grid>
                            {/* Download and Logout */}
                            <Grid item className={classes.header_action_group}>
                                {!userLoading && isLoggedIn && (
                                    <Grid container sx={{ gap: 2 }}>
                                        <Tool
                                            color="white"
                                            tooltip="Preferences"
                                            Icon={Settings}
                                            onChange={togglePrefModalOpen}
                                        />
                                        {checkNV5GClientAccess() && window.location.pathname === routes.home && (
                                            <Tool
                                                tooltip="Download Reports"
                                                color="white"
                                                options={downloadOptions}
                                                Icon={GetApp}
                                                onChange={handleDownloadTool}
                                                TooltipProps={TooltipProps}
                                            />
                                        )}
                                        <Tool
                                            tooltip="Logout"
                                            color="white"
                                            Icon={ExitToApp}
                                            onChange={handleLogout}
                                            TooltipProps={TooltipProps}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            {/* fill space under fixed AppBar */}
            <AlertModal onClose={togglePrefModalOpen} title={"Preferences"} open={prefModalOpen} declineText="Close">
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, m: 2 }}>
                    <Select
                        labelId="distunits-select-label"
                        id="distunits-select"
                        value={distanceUnits}
                        onChange={(e: any) => saveDistanceUnits(e.target.value as DistanceUnits)}
                        renderValue={value => `Distance (${value})`}
                    >
                        {getDistanceUnits().map((u, idx) => <MenuItem key={idx} value={u.abbr}>{u.plural}</MenuItem>)}
                    </Select>
                    <Select
                        labelId="heightunits-select-label"
                        id="heightunits-select"
                        value={heightUnit}
                        onChange={(e: any) => saveHeightUnit(e.target.value as HeightUnits)}
                        renderValue={value => `Height (${value})`}
                    >
                        {getHeightUnits().map((u, idx) =>
                            <MenuItem key={idx} value={u.abbr}>{u.plural}</MenuItem>)
                        }
                    </Select>
                    <Select
                        labelId="time-format-select-label"
                        id="time-format-select"
                        value={timeFormat}
                        onChange={(e: any) => saveTimeFormat(e.target.value as TimeFormat)}
                    >
                        <MenuItem value={TimeFormat.twelve}>12 hour</MenuItem>
                        <MenuItem value={TimeFormat.twenty_four}>24 hour</MenuItem>
                    </Select>
                </Box>
            </AlertModal>
            <Toolbar />
        </>
    )
}
