import { Grid, Button, TextField } from "@mui/material"

import { OptionsType } from "../../store/types/missionsTypes"
import useStyles from "./styles"
import { FlightplanType } from "../../store/types/flightplansTypes"

interface IMultipleFlightPlanSelector {
    plans: OptionsType[] // all plans in select element format {id:number, label:string}
    selectedPlans: FlightplanType[] //selected plans
    manageFlightPlans: () => void
}

const MultipleFlightPlanSelector = ({
    plans,
    selectedPlans,
    manageFlightPlans,
}: IMultipleFlightPlanSelector) => {
    const { classes, cx } = useStyles()
    if (plans.length !== 0) {
        return (
            <Grid>
                {selectedPlans.map((plan, index) => (
                    <Grid key={index}>
                        <TextField
                            variant="outlined"
                            label={"Flight Plan " + plan.flight_plan_id}
                            key={"flight_plan_" + index}
                            value={plans.find(element => element.id === plan.flight_plan_id)?.flightplan_name}
                            disabled
                            className={cx(classes.input__md, classes.gutterBottom__md)}
                        />

                        {/* <IconButton color="primary"  aria-label="add another" onClick={addRow} >
                                <AddCircleOutlineRoundedIcon />
                                </IconButton>
                                <IconButton color="primary" aria-label="remove" onClick={e => subtractRow(e, planid)} >
                                <HighlightOffIcon />
                         </IconButton> */}
                    </Grid>
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={manageFlightPlans}
                >
                    Manage Flight Plans
                </Button>
            </Grid>
        )
    }
    return <></>
}

export default MultipleFlightPlanSelector
