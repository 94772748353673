import React, { useRef, useState } from "react"

import {
    Box,
    IconButton,
    MenuItem,
    Select,
    TableRow,
    TextField,
    useTheme,
} from "@mui/material"
import {
    Convert,
    GetTableRowId,
    HandleChange,
    HandleDeleteRow,
    HandlePaste,
    HandleSelect,
    onSelectRowOverlay,
    RowId,
    TableColumn,
    TableRowType,
    TableVariant,
} from "../../models/Table"
import { useLocalStorageUtil } from "../../LocalStorageUtil"
import { formatTime } from "../../tools"
import { Remove } from "@mui/icons-material"
import useStyles from "./styles"
import Checkbox from "../Fields/Checkbox"
import Autocomplete from "../Fields/Autocomplete"
import Tool from "../Tool"
import TableCell from "./cells"
import { checkboxName, isRowInserted } from "../../utils/tableUtils"
import { StringOrEmpty } from "../../utils/stringUtils"
import { convertDistance } from "../../utils/unitCalculations"

interface TableRowProps<T> {
    row: T & TableRowType
    stickyColumns?: TableColumn[]
    columns: TableColumn[]
    handleDeleteRow: HandleDeleteRow
    handleChange: HandleChange
    handlePaste: HandlePaste
    handleSelect: HandleSelect
    getRowId: GetTableRowId<T>
    selected: boolean
    rowOverlay?: onSelectRowOverlay
}

export const EnhancedTableRow = <T, >({
    row,
    stickyColumns,
    columns,
    getRowId,
    selected,
    rowOverlay,
    ...rest
}: TableRowProps<T>) => {
    const ref = useRef<HTMLTableRowElement | null>(null)
    const theme = useTheme()
    const rowId = getRowId(row)
    const [showOverlay, setShowOverlay] = useState(false)

    const show = () => setShowOverlay(true)
    const hide = () => setShowOverlay(false)

    return (
        <TableRow
            selected={selected}
            ref={ref}
            sx={{ overflow: "hidden" }}
            //Show edit overlay
            onMouseEnter={show}
            onTouchStart={show}
            //Hide edit overlay
            onMouseLeave={hide}
            onTouchEnd={hide}
        >
            {stickyColumns &&
                <TableCell sticky TableRowProps={{ selected }}>
                    {stickyColumns.map((column, idx) =>
                        <TableCell key={idx} column={column}>
                            <TableCellVariant
                                selected={selected}
                                row={row}
                                column={column}
                                value={row[column.id as keyof T]}
                                rowId={rowId}
                                {...rest}
                            />
                        </TableCell>,
                    )}
                </TableCell>
            }
            {columns.map(column =>
                <TableCell column={column} key={column.id}>
                    <TableCellVariant
                        selected={selected}
                        row={row}
                        column={column}
                        value={row[column.id as keyof T]}
                        rowId={rowId}
                        disabled={column.disableRowWhen && column.disableRowWhen(row)}
                        {...rest}
                    />
                </TableCell>,
            )}
            {rowOverlay &&
                <TableCell sx={{ position: "sticky", right: 0, p: 0 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            visibility: showOverlay ? "visible" : "hidden",
                            right: 0,
                            border: "1px solid rgba(224, 224, 224, 1)",
                            backgroundColor: theme.palette.background.paper,
                            opacity: 0.5,
                            transition: "opacity 0.3s",
                            "&:hover": { opacity: 1 },
                        }}
                    >
                        {rowOverlay.map((tool, index) => {
                            return (
                                <Tool
                                    TooltipProps={{ placement: "left" }}
                                    key={index}
                                    tooltip={tool.label}
                                    Icon={tool.Icon}
                                    onChange={() => tool.onChange({ selected: [rowId] })}
                                />
                            )
                        })}
                    </Box>
                </TableCell>
            }
        </TableRow>
    )
}

const TableCellVariant = <V, T>({
    column,
    row,
    value,
    rowId,
    handleChange,
    handleDeleteRow,
    handlePaste,
    handleSelect,
    selected,
    disabled,
}: {
    column: TableColumn
    row: T & TableRowType
    value: V
    rowId: RowId
    selected: boolean
    handleChange: HandleChange
    handleDeleteRow: HandleDeleteRow
    handlePaste: HandlePaste
    handleSelect: HandleSelect
    disabled?: boolean
}) => {
    const { classes } = useStyles()
    const prefDistUnit = useLocalStorageUtil(state => state.preferredDistanceUnit())
    const prefHeightUnit = useLocalStorageUtil(state => state.preferredHeightUnit())
    const error = !!(column.required && !value) || !!(column.cellError && column?.cellError(rowId))

    const select = column.label === checkboxName
    if (select && row.selectable) {
        return <Checkbox checked={selected} onClick={(e) => handleSelect(e.shiftKey, rowId)} />
    }

    const convert = <T, >(value: T, col: TableColumn) => {
        const val = String(value ?? "")
        if (col.convertType === Convert.DISTANCE) {
            return convertDistance(+val, prefDistUnit)
        } else if (col.convertType === Convert.HEIGHT) {
            return convertDistance(+val, prefHeightUnit)
        }
        return val
    }

    if (isRowInserted(rowId) && handleDeleteRow && column.primary) {
        return (
            <IconButton
                size="small"
                onClick={() => handleDeleteRow(rowId)}
            >
                <Remove fontSize="small" />
            </IconButton>
        )
    }

    switch (column.variant) {
        case TableVariant.custom:
            return <Box>{column.renderCell(rowId, value)}</Box>
        case TableVariant.input:
            return <TextField
                error={error}
                onPaste={(e) => handlePaste(e, rowId, column.id)}
                value={value ?? ""}
                size="small"
                variant="outlined"
                sx={{ minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth, textAlign: "left" }}
                onBlur={(e) => handleChange(e.target.value.trim() || null, column.id, rowId)}
                onChange={(e) => handleChange(e.target.value, column.id, rowId)}
                InputProps={{ classes: { root: classes.table__input_root, input: classes.table__input } }}
            />
        case TableVariant.select:
            return <Select
                error={error}
                value={StringOrEmpty(value)}
                disabled={disabled}
                onChange={(e) => handleChange(e.target.value, column.id, rowId)}
                variant="outlined"
                fullWidth
                size="small"
                MenuProps={{ sx: { maxHeight: 500 } }}
            >
                {column.options.map((option, idx) => <MenuItem key={idx} value={option}>{option}</MenuItem>)}
            </Select>
        case TableVariant.autocomplete:
            const val = String(value ?? "")
            return <Autocomplete
                value={val}
                options={column.options}
                freeSolo
                size="small"
                onChange={(e, value) => handleChange(value, column.id, rowId)}
                disableGutter
                classes={{ inputRoot: classes.cell__autocomplete_padding }}
            />
        case TableVariant.time:
            return <Box>{formatTime(value)}</Box>
        default:
            return <Box>{convert(value, column)}</Box>
    }
}
