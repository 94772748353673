import { setLocalStorageItem } from "./tools"
import { tierEnv } from "./env"

export default class Config {
    static basePath = tierEnv.REACT_APP_API_URL ?? "http://127.0.0.1:5000"

    static decodeToken(token: string): string {
        if (token) {
            const base64Url = token.split(".")[1]
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")

            return decodeURIComponent(
                atob(base64)
                    .split("")
                    .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                    .join(""),
            )
        }
        return ""
    }

    static checkTokenExpire() {
        if (localStorage.getItem("auth_token")) {
            try {
                const token: any = Config.decodeToken(localStorage.getItem("auth_token") || "")
                const expire = JSON.parse(token).exp
                const timeNow = new Date().getTime()

                if (timeNow > expire * 1000) {
                    throw new Error("Token expired.")
                }
            } catch {
                // clear existing token if invalid or expired
                setLocalStorageItem({
                    auth_token: "",
                    nexus_client: "",
                });
                (window as any).location.href = `${window.location.origin}`
            }
        }
    }

    static getHeader(includeClient = true): any {
        Config.checkTokenExpire()
        const headers: { [key: string]: string } = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}` || "",
        }
        if (includeClient) {
            headers.Client = localStorage.getItem("nexus_client") || ""
        }
        return headers
    }
}
