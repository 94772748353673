import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(8),
    },

    // stepper
    stepper__narrow: {
        maxWidth: 420,
        margin: "0 auto",
    },

    // validation
    validation__alert: {
        marginTop: theme.spacing(0.5),
    },

    // Editor
    editorRoot: {
        padding: theme.spacing(3),
    },

    empty_text: {
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
}))

export default useStyles
