import { useState, useEffect } from "react"

import { Grid, IconButton ,InputAdornment ,TextField ,Tooltip } from "@mui/material"
import { GetApp } from "@mui/icons-material"

import { Label, Body } from "../../components/Typography"

import useMissions from "../../hooks/useMissions"
import { Types } from "../../store/types"
import useStyles from "./styles"

export default function FlightlineImport() {
    const { classes, cx } = useStyles()
    const { missions, dispatch } = useMissions()
    const { value, error } = missions.importExpression

    const [localInput, setLocalInput] = useState(value)

    // reset importExpression.error on unmount
    useEffect(() => {
        return () => dispatch({ type: Types.Missions_ClearImportError })
    }, []) // eslint-disable-line

    const handleImport = () => {
        dispatch({
            type: Types.Missions_ImportFlightplannedLines,
            payload: { range: localInput },
        })
    }

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            handleImport()
        }
    }

    return (
        <Grid className={classes.gutterBottom__lg}>
            <Label>Import Flightplanned Lines</Label>

            <Body gutterBottom="sm" color="textSecondary" className={classes.import__text}>
                Enter a range below to match flightplanned lines by their name and import them as flightline events. Range must
                include positive integers and leading zeros are ignored.
            </Body>
            <Body gutterBottom="sm" color="textSecondary" className={classes.import__text}>
                Warning: Importing a new range may delete previous ranges if they have been removed from the input field. Please
                include all target lines in your range.
            </Body>
            <Body gutterBottom="lg" color="textSecondary" className={classes.import__text}>
                Example: 5, 1-3, 8, 10-13
            </Body>

            <TextField
                label="Enter Range"
                variant="outlined"
                value={localInput}
                error={Boolean(error)}
                helperText={error}
                onChange={(e) => setLocalInput(e.target.value)}
                onKeyDown={handleKeyDown}
                className={cx(classes.import__range, classes.input__md, classes.gutterBottom__sm)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Import & Overwrite">
                                <span>
                                    <IconButton
                                        size="small"
                                        disabled={value === localInput && !error}
                                        onClick={handleImport}
                                    >
                                        <GetApp />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    )
}
