import { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { Grid, TextField, Tooltip } from "@mui/material"

import Autocomplete from "../../components/Fields/Autocomplete"
import AlertModal from "../../components/Modals/AlertModal"
import ProjectTitle from "../../components/ProjectTitle"
import StepperForm from "../../components/StepperForm"
import { Label } from "../../components/Typography"

import FlightplanCompletedLines from "./FlightplanCompletedLines"
import FlightplanStatusSelect from "./FlightplanStatusSelect"
import FlightplanTable from "./FlightplanTable"

import { RootContext } from "../../store/context"
import { Types } from "../../store/types"
import { FlightplanStatuses, FlightplanType } from "../../store/types/flightplansTypes"
import { fetchProject } from "../../store/actions/projectsActions"
import {
    createFlightplan,
    deleteFlightplan,
    fetchFlightplanList,
    selectFlightplan,
    updateFlightplan,
} from "../../store/actions/flightplansActions"
import routes, { ConfirmationRoutes } from "../../routes"
import { getFlightplanValidatorModel } from "./model"
import useAuth from "../../hooks/useAuth"
import useToggle from "../../hooks/useToggle"
import useStyles from "./styles"
import LoadingButton from "../../components/LoadingButton"
import { ModesSelect } from "../../components/Modes/ModesSelect"
import { useAcquisitionModeStore } from "../../zstore/AcquisitionModeStore"
import { useFindAcquisitionModes, useFindAcquisitionModesForFlightPlan } from "../../api/AcquisitionModeApi"
import { StringOrEmpty } from "../../utils/stringUtils"

export const FlightplanEditor = () => {
    const history = useHistory()
    const { classes, cx } = useStyles()
    const { projectNumber, flightplanFlow, flightPlanId } = useParams<{ [key: string]: string }>()
    const { state, dispatch } = useContext(RootContext)
    const { selectedProject } = state.projects
    const { ToolbarOptions, checkToolbarAccess } = useAuth()
    const {
        selectedFlightplan,
        selectedFlightplanId,
        flightplanList,
        deletedPlannedLineIds,
        flightPlannedLines,
        isDeleting,
        warnings,
    } = state.flightplans

    // Ideally we would use react-query to load just the Project, and the FlightPlan + related data in two
    // backend requests. However, it would be difficult to rewrite this entire component to use react-query and not
    // use Redux at all, so for now we are loading acquisition modes separately with react-query.
    const acquisitionModesResponse = useFindAcquisitionModes()
    const acquisitionModesForFlightPlanResponse = useFindAcquisitionModesForFlightPlan(Number(selectedFlightplanId), selectedFlightplanId != null)
    const acquisitionModeIds = useAcquisitionModeStore(state => state.selectedModeIds) ?? []

    const isEditFlow = flightplanFlow === "edit"
    const isFlightplanEmpty = isEditFlow && flightplanList.length === 0

    let isDeleteDisabled = false
    let deleteTooltip = ""
    if (!checkToolbarAccess(ToolbarOptions.Edit_Flightplans)) {
        // shouldn't be possible since route protected as well
        isDeleteDisabled = true
        deleteTooltip = "Missing required permissions"
    } else if (flightPlannedLines?.some((line) => !!line.mission_name)) {
        isDeleteDisabled = true
        deleteTooltip = "Flown missions must be deleted before deleting a flightplan"
    }

    const [loadingFlightPlan, setLoadingFlightPlan] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [deleteModalOpen, toggleDeleteModalOpen] = useToggle()
    const [completeModalOpen, toggleCompleteModalOpen] = useToggle()

    // Load everything before we stop showing the loading bar
    const loading = loadingFlightPlan || acquisitionModesResponse.isLoading || acquisitionModesForFlightPlanResponse.isLoading

    useEffect(() => {
        const reqs = []

        dispatch({ type: Types.App_ClearError })

        if (isEditFlow) {
            if (!selectedProject?.project_number) {
                reqs.push(fetchProject(dispatch, projectNumber!))
            }
            reqs.push(fetchFlightplanList(dispatch, projectNumber!, flightPlanId))
        }

        Promise.all(reqs).then(() => {
            setLoadingFlightPlan(false)
        })
    }, []) // eslint-disable-line

    const getUnflownLineIds = () => {
        return flightPlannedLines?.filter((fl) => Boolean(!fl.mission_name)).map((fl) => fl.planned_line_id)
    }

    /**
     * Navigate to confirmation page after saving flightplan name.
     */
    const handleConfirmation = () => {
        dispatch({
            type: Types.App_UpdateConfirmationData,
            payload: {
                entityName: `${selectedFlightplan.flight_plan_name} `,
            },
        })

        if (isEditFlow) {
            history.push(`${routes.confirm}/${ConfirmationRoutes.FLIGHTPLAN_EDITED}`)
        } else {
            history.push(`${routes.confirm}/${ConfirmationRoutes.FLIGHTPLAN_SUBMITTED}`)
        }
    }

    /**
     * Attempt to submit changes, or go back.
     */
    const handleStepChange = async (newStep: number) => {
        if (newStep === -1) {
            // return to upload screen, or return home in edit flow
            let backRoute = `/${selectedProject!.project_number}${routes.flightplanUpload}`
            if (isEditFlow) {
                backRoute = routes.home
            }

            history.push(backRoute)
        } else {
            setSubmitting(true)

            // save flightplan
            const unflownLines = selectedFlightplan.status === FlightplanStatuses.Complete ? getUnflownLineIds() : []
            const success = isEditFlow
                ? await updateFlightplan(dispatch, selectedFlightplan, [...deletedPlannedLineIds, ...unflownLines], acquisitionModeIds)
                : await createFlightplan(dispatch, selectedFlightplan, flightPlannedLines, acquisitionModeIds)

            setSubmitting(false)
            if (success) {
                handleConfirmation()
            }
        }
    }

    const handleSelectedFlightplanChange = async (value: FlightplanType) => {
        setLoadingFlightPlan(true)
        history.push(`/${projectNumber}${routes.flightplanEditor}/edit/${value.flight_plan_id}`)
        await selectFlightplan(dispatch, value)
        setLoadingFlightPlan(false)
    }

    const handleChange = (value: string | null, field: string) => {
        if (value === FlightplanStatuses.Complete && field === "status") {
            toggleCompleteModalOpen()
            return
        }

        dispatch({
            type: Types.Flightplans_UpdateFlightplanData,
            payload: { [field]: value },
        })
    }

    /**
     * Runs after confirming the "Complete" status in the modal.
     */
    const handleCompleteConfirm = () => {
        dispatch({
            type: Types.Flightplans_UpdateFlightplanData,
            payload: { status: FlightplanStatuses.Complete },
        })
        toggleCompleteModalOpen()
    }

    const handleDelete = async () => {
        toggleDeleteModalOpen()

        if (selectedFlightplanId !== null) {
            const success = await deleteFlightplan(dispatch, selectedFlightplanId)
            if (success) {
                history.push(routes.home)
            }
        }
    }

    const unflownLineCount = getUnflownLineIds().length

    return (
        <StepperForm
            warnings={warnings}
            loading={loading}
            submitting={submitting}
            empty={isEditFlow && flightplanList.length === 0}
            emptyText="No flightplans found for this project."
            validationProps={{
                model: getFlightplanValidatorModel(selectedFlightplan, flightPlannedLines),
                dependencies: [selectedFlightplan, flightPlannedLines],
            }}
            onStepChange={handleStepChange}
            header={
                <Grid>
                    <ProjectTitle redirectOnParam redirectOnState={!isEditFlow} />
                    {isEditFlow && (
                        <Grid>
                            <Label>Select a Flightplan to Edit</Label>
                            <Grid>
                                <Autocomplete
                                    value={flightplanList.find((fp) => fp.flight_plan_id === selectedFlightplanId) ?? null}
                                    options={flightplanList}
                                    getOptionLabel={(opt: FlightplanType) => StringOrEmpty(opt.flight_plan_name)}
                                    renderOption={(props, plan) =>
                                        <li {...props} key={plan.flight_plan_id}>{plan.flight_plan_name}</li>
                                    }
                                    onChange={(e, value) => value && handleSelectedFlightplanChange(value)}
                                    gutterBottom="lg"
                                    width="md"
                                    disableClearable={selectedFlightplan !== null}
                                    disabled={loading}
                                    inline
                                />
                            </Grid>
                        </Grid>
                    )}
                    <AlertModal
                        danger
                        open={deleteModalOpen}
                        pending={isDeleting}
                        title="Delete Flightplan?"
                        onConfirm={handleDelete}
                        onClose={toggleDeleteModalOpen}
                    >
                        Are you sure you want to delete this flightplan? This action cannot be reversed.
                    </AlertModal>
                    <AlertModal
                        danger={unflownLineCount > 0}
                        open={completeModalOpen}
                        title="Delete Unflown Lines?"
                        confirmText={unflownLineCount > 0 ? "Confirm" : "Yes"}
                        onConfirm={handleCompleteConfirm}
                        onClose={toggleCompleteModalOpen}
                    >
                        Changing flightplan status to "Complete" will cause <strong>{unflownLineCount} unflown lines</strong> to
                        be permanently deleted.
                        <p>
                            {unflownLineCount > 0
                                ? "This cannot be undone. Are you sure you want to perform this action?"
                                : "Proceed?"}
                        </p>
                    </AlertModal>
                </Grid>
            }
            disableSubmit={isDeleting || acquisitionModeIds.length === 0}
            secondaryAction={
                isEditFlow && (
                    <Tooltip arrow title={!isFlightplanEmpty && isDeleteDisabled ? deleteTooltip : ""}>
                        <span className={classes.delete_tooltip}>
                            <LoadingButton
                                onClick={toggleDeleteModalOpen}
                                pending={isDeleting}
                                disabled={isFlightplanEmpty || loading || isDeleteDisabled || submitting}
                                variant="outlined"
                                color="primary"
                                size="large"
                                className={classes.btn_delete}
                            >
                                Delete
                            </LoadingButton>
                        </span>
                    </Tooltip>
                )
            }
        >
            <Grid>
                <Label gutterBottom="sm">Flightplan Name</Label>
                <TextField
                    value={selectedFlightplan.flight_plan_name || ""}
                    onChange={(e) => handleChange(e.target.value || null, "flight_plan_name")}
                    variant="outlined"
                    helperText="Required"
                    className={cx(classes.input__md, classes.gutterBottom__md)}
                />

                <FlightplanCompletedLines />
                <FlightplanStatusSelect onChange={handleChange} />
                <ModesSelect
                    modes={acquisitionModesResponse.data!}
                    initialModeIds={acquisitionModesForFlightPlanResponse.data ?? []}
                />
                <FlightplanTable />
            </Grid>
        </StepperForm>
    )
}
