import { makeStyles } from "tss-react/mui"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...spacing(theme),

    root: {
        height: "calc(100% - 64px)",
    },
    wrapper: {
        padding: theme.spacing(3),
        minHeight: "100%",
        textAlign: "center",
    },
}))

export default useStyles
