import React, { useCallback, useState } from "react"

import { Box, BoxProps, Chip, Grid, Typography, useTheme } from "@mui/material"

import useStyles from "./styles"
import { useDropzone } from "react-dropzone"
import { grey, lightBlue } from "@mui/material/colors"
import { Title } from "../Typography"
import { FileUpload } from "@mui/icons-material"
import Alert from "../Alert"

const lightBackgroundColor = grey[50]

interface DropzoneProps extends Omit<BoxProps, "onChange"> {
    onChange: (files: File[]) => void
    acceptedFiles?: string[]
    filesLimit?: number
    maxFileSize?: number
}

export default function Dropzone({ onChange, acceptedFiles, filesLimit = 1, maxFileSize }: DropzoneProps) {
    const theme = useTheme()
    const [files, setFiles] = useState<File[]>([])
    const { classes: styles, cx } = useStyles()

    const validator = useCallback((file: File | DataTransferItem) => {
        if (file && !(file instanceof DataTransferItem)) {
            const ext = file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
            const acceptedToLower = acceptedFiles?.map(fileExt => fileExt.toLowerCase())
            if (acceptedToLower && !acceptedToLower.includes(ext)) {
                return {
                    code: "ext-not-accepted",
                    message: `File type "${ext}" is not an accepted file type: ${acceptedToLower.join(" ")}`,
                }
            }
        }
        return null
    }, [acceptedFiles])

    const onDrop = useCallback((acceptedFiles: File[]) => {
        let validFiles: File[] = []
        if (filesLimit > 1) {
            // Allow only the max number of files
            validFiles = [...files, ...acceptedFiles].slice(0, filesLimit)
        } else {
            validFiles = acceptedFiles
        }
        setFiles(validFiles)
        onChange(validFiles)
    }, [onChange])

    const handleDeleteFile = (index: number) => {
        const newFiles = [...files.slice(0, index), ...files.slice(index + 1)]
        setFiles(newFiles)
        onChange(newFiles)
    }

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone(
        { onDrop, maxSize: maxFileSize ?? 1e9, validator, maxFiles: filesLimit }
    )

    const color = theme.palette.primary.main
    return (
        <Grid container direction={"column"}>
            <Grid item>
                <Box
                    sx={{
                        p: 8,
                        color,
                        border: `${color} dashed 2px`,
                        borderRadius: 4,
                        background: isDragActive ? `linear-gradient(to right bottom, ${lightBackgroundColor}, ${lightBlue[200]})` : lightBackgroundColor,
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: 250,
                    }}
                    className={cx(styles.gutterBottom__lg)}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <Typography sx={{ transform: "scale(1.8)" }}><FileUpload /></Typography>
                    <Title sx={{ color }} disableGutter>
                        Drag and drop a file <br /> or click here
                    </Title>
                </Box>
            </Grid>
            <Grid item>
                <Grid container sx={{ mb: 2 }} direction={"column"}>
                    <Grid item>
                        {!!fileRejections.length &&
                            <Alert disableGutter>
                                {fileRejections.map((rej) =>
                                    rej.errors.map(((err, idx) =>
                                        <li key={idx}>{err.message}</li>
                                    ))
                                )}
                            </Alert>
                        }
                    </Grid>
                    {!!files.length &&
                        <>
                            <Grid item>
                                <Typography>Selected file(s)</Typography>
                            </Grid>
                            <Grid item>
                                {files.map((file, idx) =>
                                    <Chip
                                        sx={{ m: .5 }}
                                        key={idx}
                                        label={file.name}
                                        variant="outlined"
                                        onDelete={() => handleDeleteFile(idx)}
                                        className={cx(styles.dropzone__preview_chip)}
                                    />
                                )}
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
