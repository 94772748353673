import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    status_label: {
        flex: "0 1 auto",
        width: 85,
        textAlign: "right",
        marginRight: theme.spacing(1.5),
    },
    status_label__text: {
        fontWeight: 700,
    },

    status_description: {
        width: 400,
        whiteSpace: "normal",
    },

    delete_tooltip: {
        display: "inline-flex",
        marginRight: 16,
    },
}))

export default useStyles
