import { TextField, StandardTextFieldProps } from "@mui/material"

import { countDecimals } from "../../tools"

export interface NumberFieldProps extends Omit<StandardTextFieldProps, "onChange"> {
    disableArrows?: boolean
    field: string
    onChange?: (value: number | null, field: string) => void
    min?: number
    max?: number
    step?: number
    decimalCount?: number
}

const NumberField = ({
    disableArrows = false,
    disabled,
    field,
    required,
    value,
    onChange,
    className,
    min,
    max,
    step,
    decimalCount,
    sx = [],
    ...other
}: NumberFieldProps) => {

    const handleChange = (value?: number | null) => {
        if (!onChange) {
            return
        } else if (value == null) {
            onChange(null, field)
            return
        }

        // is a number
        if ((decimalCount && countDecimals(value ?? 0) > decimalCount) || (max && value > max)) {
            return
        }
        onChange(value, field)
    }

    return (
        <TextField
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            type="number"
            size="small"
            value={value ?? ""}
            onChange={(e) => handleChange(e.target.value === "" ? null : +e.target.value)}
            helperText={!disabled && required && "Required"}
            disabled={disabled}
            sx={[
                ...Array.isArray(sx) ? sx : [sx],
                disableArrows && {
                    "& input[type=number]": {
                        MozAppearance: "textfield",
                    },
                    "& [type=number]::-webkit-outer-spin-button, & [type=number]::-webkit-inner-spin-button": {
                        appearance: "none",
                        WebKitAppearance: "none",
                        margin: 0,
                    },
                }
            ]}
            className={className}
            {...other}
            inputProps={{ step: step ?? 0.1, min: min ?? 0, max, ...other.inputProps }}
        />
    )
}

export default NumberField
