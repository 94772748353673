import { useState, useEffect, useContext, useCallback } from "react"
import { useHistory } from "react-router-dom"

import { RootContext } from "../store/context"
import { Types } from "../store/types"
import { ToolbarOptions } from "../store/types/appTypes"
import { loginUser, logoutUser } from "../store/actions/appActions"
import { getNv5ClientGroup } from "../tools"
import routes from "../routes"
import { FlightPlanFlow } from "../models/FlightPlanFlow"

const routePermissions = {
    // app
    [routes.projectInit]: ToolbarOptions.Initialize_Project,
    // flightplans
    [routes.flightplanUpload]: ToolbarOptions.Submit_Flightplans,
    [`${routes.flightplanEditor}/${FlightPlanFlow.SUBMIT}`]: ToolbarOptions.Submit_Flightplans,
    [`${routes.flightplanEditor}/${FlightPlanFlow.EDIT}`]: ToolbarOptions.Edit_Flightplans,
    // missions
    [routes.missionUpload]: ToolbarOptions.Submit_Mission_Logs,
    [routes.missionSelect]: ToolbarOptions.Edit_Mission_Logs,
    // lookup
    [routes.lookupEditor]: ToolbarOptions.Edit_Lookup_Tables,
}

const checkLogin = () => Boolean(localStorage.getItem("auth_token") && localStorage.getItem("nexus_client"))

/**
 * API for handling most auth functionality.
 *
 * @param trackLoginStatus whether to use storage event handlers to track isLoggedIn
 */
const useAuth = (trackLoginStatus = false) => {
    const history = useHistory()
    const { state, dispatch } = useContext(RootContext)
    const { userLoading, userMgmtUrl, user, permissions, loginError } = state.app
    const { user_groups } = user
    // track logged in status by checking local storage
    const [isLoggedIn, setIsLoggedIn] = useState(checkLogin())

    const goToLandingScreen = () => history.push(routes.home)
    const goToUserMgmtLoginScreen = () =>
        window.location.replace(`${userMgmtUrl}/authentication?redirected_from=${window.location.origin}`)

    /**
     * Listen to localStorage updates and check token and client exist.
     */
    useEffect(() => {
        if (trackLoginStatus) {
            const callback = () => setIsLoggedIn(checkLogin())

            window.addEventListener("storage", () => callback())
            return window.removeEventListener("storage", () => callback())
        }
    }, [trackLoginStatus])

    /**
     * Fetches user data, including token, user group, and toolbar.
     */
    const getUser = useCallback(async () => {
        await loginUser(dispatch, userMgmtUrl)
    }, [dispatch, userMgmtUrl])

    /**
     * Clear auth_token and log out.
     */
    const handleLogout = useCallback(
        async () => {
            await logoutUser(dispatch)
            await goToUserMgmtLoginScreen()
        },
        [dispatch], // eslint-disable-line
    )

    /**
     * Check if user has access to a NV5G client and is assigned to
     * a Nexus-enabled user group in that client.
     */
    const checkNV5GClientAccess = useCallback(() => {
        return user_groups.some(getNv5ClientGroup)
    }, [user_groups])

    /**
     * Check if user has access to toolbar sub-option(s).
     *
     * Can take a single name or multiple in an array. Names must exist
     * in ToolbarOptions.
     */
    const checkToolbarAccess = useCallback(
        (names: ToolbarOptions[] | ToolbarOptions) => {
            const namesArray = typeof names === "string" ? [names] : names
            return namesArray.some((name) => permissions && permissions[name])
        },
        [permissions],
    )

    /**
     * Redirect to landing if user doesn't have permission for current
     * route.
     *
     * Runs after user data is loaded and userLoaded and permissions
     * have been initialized.
     * User shouldn't be able to externally or internally (anchor tag)
     * navigate to secured pages w/o permission.
     */
    const checkRouteAccess = useCallback(() => {
        if (permissions) {
            const { pathname } = window.location

            for (const [route, permissions] of Object.entries(routePermissions)) {
                if (pathname.includes(route) && !checkToolbarAccess(permissions)) {
                    goToLandingScreen()
                    break
                }
            }
        }
    }, [permissions]) // eslint-disable-line

    const clearError = useCallback(() => {
        dispatch({ type: Types.App_ClearError })
    }, [dispatch]) // eslint-disable-line

    return {
        isLoggedIn,
        userLoading,
        user,
        permissions,
        loginError,
        ToolbarOptions,
        goToLandingScreen,
        goToUserMgmtLoginScreen,
        handleLogout,
        checkNV5GClientAccess,
        checkToolbarAccess,
        checkRouteAccess,
        getUser,
        clearError,
        dispatch,
    }
}

export default useAuth
