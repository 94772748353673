import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),
}))

export default useStyles
