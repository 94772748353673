import React, { useState } from "react"

import { Divider, Grid, FormControlLabel, Switch } from "@mui/material"

import Autocomplete from "../../components/Fields/Autocomplete"
import NumberField from "../../components/Fields/NumberField"
import ReviewField from "../../components/Fields/ReviewField"
import TimeField from "../../components/Fields/TimeField"
import { Label } from "../../components/Typography"

import useMissions from "../../hooks/useMissions"
import useStyles from "./styles"
import { formatDate, parseDate } from "../../tools"
import { DatePickerCustom } from "../../components/Fields/DatePickerCustom"
import { MissionShippingInfoType } from "../../store/types/missionsTypes"
import { WidthOpt } from "../../assets/inputStyles"

const reviewFields: {
    label: string
    field: keyof MissionShippingInfoType
    helperText?: string
    gutterBottom?: WidthOpt
}[] = [
    {
        label: "Plane Drive Name",
        field: "plane_drive_name",
        helperText: "Required (hard drive used during acquisition)",
    },
    { label: "Main Backup Drive", field: "backup_drive_name", helperText: "Required (field backup hard drive)" },
    {
        label: "Shipped Drive Name",
        field: "delivery_drive_name",
        helperText: "Required (deliverable drive sent to office)",
    },
    { label: "Tracking Number", field: "shipping_number", gutterBottom: "lg" },
]

export default function FlightData() {
    const { classes } = useStyles()
    const { missions, isNoAcquisition, handleMissionDataChange, handleMissionShippingInfoChange } = useMissions()
    const { selectedMission, isVendorFlow } = missions

    const handleFlightNumChange = (value: any, field: string) => {
        if (field === "flight_num") {
            const hasNonDigits = !/^[0-9]*$/.test(String(value))
            if (value !== null && (hasNonDigits || value >= 100)) {
                return
            }
        }
        handleMissionDataChange({ [field]: value })
    }

    const handleHobbsChange = (value: number | null, field: string) => {
        let mobHobbs: number | null

        if (typeof value === "number" && value < 0) {
            value = null
        }

        const startHobbs = field === "start_hobbs" ? value : selectedMission.start_hobbs
        const stopHobbs = field === "stop_hobbs" ? value : selectedMission.stop_hobbs
        const activeHobbs = field === "active_hobbs" ? value : selectedMission.active_hobbs

        if (startHobbs !== null && stopHobbs !== null && activeHobbs !== null) {
            mobHobbs = stopHobbs - startHobbs - activeHobbs
            mobHobbs = Math.round(mobHobbs * 10) / 10
        } else {
            mobHobbs = null
        }

        handleMissionDataChange({ [field]: value, mob_hobbs: mobHobbs })
    }

    const handleStorageSizeChange = (value: number | null, field: string) => {
        handleMissionShippingInfoChange({ [field]: value })
    }

    const flownEndDate = parseDate(selectedMission["flown_end_date"])
    const flownDate = parseDate(selectedMission["flown_date"])

    const [addedEndDate, setAddedEndDate] = useState(!!flownEndDate)

    return (
        <Grid container>
            <Grid item>
                <Label>Flight Data</Label>
                <Grid container item className={classes.gutterBottom__md}>
                    <NumberField
                        label="Flight No."
                        field="flight_num"
                        required
                        min={1}
                        max={99}
                        value={selectedMission["flight_num"]}
                        onChange={handleFlightNumChange}
                        step={1}
                    />
                </Grid>

                <Label>Time in Air (local time)</Label>
                <Grid container spacing={4} className={classes.gutterBottom__md}>
                    <Grid item>
                        <TimeField
                            label="Wheels Up"
                            field="start_time"
                            required={!isVendorFlow}
                            value={selectedMission["start_time"] as string}
                            onChange={(field, value) => handleMissionDataChange({ [field]: value })}
                        />
                    </Grid>
                    <Grid item>
                        <TimeField
                            label="Wheels Down"
                            field="stop_time"
                            required={!isVendorFlow}
                            value={selectedMission["stop_time"] as string}
                            onChange={(field, value) => handleMissionDataChange({ [field]: value })}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={addedEndDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const added = event.target.checked
                                        handleMissionDataChange({ flown_end_date: added ? formatDate(flownDate) : null })
                                        setAddedEndDate(added)
                                    }}
                                />
                            }
                            label="End Date Different from Start"
                        />
                    </Grid>
                </Grid>

                {addedEndDate && (
                    <Grid container spacing={4} className={classes.gutterBottom__sm}>
                        <Grid item>
                            <DatePickerCustom
                                label="End Date"
                                value={flownEndDate}
                                minDate={flownDate}
                                minDateMessage={"Must be after initial activity date"}
                                onChange={(date) => {
                                    handleMissionDataChange({ flown_end_date: formatDate(date) })
                                }}
                            />
                        </Grid>
                    </Grid>
                )}

                <Label>Hobbs (hrs)</Label>
                <Grid container spacing={4} className={classes.gutterBottom__md}>
                    <Grid item>
                        {/* Split into two groups, so they are 2x2 on small screens */}
                        <Grid container spacing={4}>
                            <Grid item>
                                <NumberField
                                    className={classes.hobbs_field}
                                    label="Start"
                                    field="start_hobbs"
                                    value={selectedMission["start_hobbs"]}
                                    required={!isVendorFlow}
                                    onChange={handleHobbsChange}
                                />
                            </Grid>
                            <Grid item>
                                <NumberField
                                    className={classes.hobbs_field}
                                    label="Stop"
                                    field="stop_hobbs"
                                    value={selectedMission["stop_hobbs"]}
                                    required={!isVendorFlow}
                                    onChange={handleHobbsChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container spacing={4}>
                            <Grid item>
                                <NumberField
                                    className={classes.hobbs_field}
                                    label="Online"
                                    field="active_hobbs"
                                    value={selectedMission["active_hobbs"]}
                                    required={!isVendorFlow}
                                    onChange={handleHobbsChange}
                                />
                            </Grid>
                            <Grid item>
                                <NumberField
                                    className={classes.hobbs_field}
                                    label="Mob"
                                    field="mob_hobbs"
                                    value={selectedMission["mob_hobbs"]}
                                    onChange={handleHobbsChange}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* mission_shipping_info */}
            {!isNoAcquisition && (
                <>
                    <Divider className={classes.gutterBottom__md} />

                    <Grid container direction="column" className={classes.gutterBottom__md}>
                        <Label>Storage Drives</Label>

                        {reviewFields.map((field) => (
                            <Grid key={field.field} item>
                                <ReviewField
                                    value={selectedMission.mission_shipping_info && selectedMission.mission_shipping_info[field.field]}
                                    onChange={handleMissionShippingInfoChange}
                                    required={!isVendorFlow}
                                    {...field}
                                />
                            </Grid>
                        ))}

                        <Autocomplete
                            shrinkLabel
                            value={selectedMission?.mission_shipping_info?.office_id || null}
                            options={["ANC", "CVO", "LEX", "PDX"]}
                            onChange={(e: any, value: string | null) => handleMissionShippingInfoChange({ office_id: value })}
                            TextFieldProps={{ label: "Office Data Shipped to", helperText: "Required" }}
                            width="sm"
                        />

                        <NumberField
                            className={classes.storage_field}
                            size="medium"
                            label="Raw Storage Size (GB)"
                            field="raw_storage_size"
                            value={selectedMission?.mission_shipping_info?.raw_storage_size}
                            required={!isVendorFlow}
                            onChange={handleStorageSizeChange}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}
