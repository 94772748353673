import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const marginBase = 4

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    list_container: {
        padding: theme.spacing(2.25, 2.5, 1),
    },
    list_item: {
        minHeight: 41,
        marginLeft: theme.spacing(marginBase),
        width: `calc(100% - ${theme.spacing(marginBase)}px)`,
    },
    list_item_key: {
        minWidth: 105,
        marginRight: theme.spacing(3.5),
    },
    list_item_value: {
        padding: 0,
    },
}))

export default useStyles
