import { Types } from "../types"
import { ProjectsType, ProjectsActions } from "../types/projectsTypes"
import { initialState } from "../context"

const projectsReducer = (state: ProjectsType, action: ProjectsActions): ProjectsType => {
    switch (action.type) {
        case Types.Projects_FetchProjectList: {
            return {
                ...state,
                projectList: [],
                loadingProjectList: true,
            }
        }
        case Types.Projects_FetchProjectListSuccess: {
            return {
                ...state,
                projectList: action.payload.projectList,
                loadingProjectList: false,
            }
        }
        case Types.Projects_ResetProjectData: {
            return {
                ...state,
                selectedProject: null,
                selectedProjectData: null,
                aoiList: [],
                selectedAOI: null,
                selectedAOIData: initialState.projects.selectedAOIData,
                isSubmitAOIFlow: false,
                isEditAOIFlow: false,
                doPolygonRepairs: false,
                cache: initialState.projects.cache,
            }
        }
        case Types.Projects_FetchProject: {
            return {
                ...state,
                selectedProject: null,
                loadingProject: true,
            }
        }
        case Types.Projects_FetchProjectSuccess: {
            return {
                ...state,
                selectedProject: action.payload.selectedProject,
                loadingProject: false,
            }
        }
        case Types.Projects_FetchProjectError: {
            return {
                ...state,
                loadingProject: false,
            }
        }
        case Types.Projects_FetchProjectData: {
            return {
                ...state,
                selectedProject: null,
                selectedProjectData: null,
                aoiList: [],
                loadingProject: true,
                cache: initialState.projects.cache,
            }
        }
        case Types.Projects_FetchProjectDataSuccess: {
            return {
                ...state,
                selectedProject: action.payload.selectedProject,
                selectedProjectData: action.payload.selectedProject,
                aoiList: action.payload.aoi_list,
                loadingProject: false,
                cache: {
                    ...state.cache,
                    selectedProjectData: action.payload.selectedProject,
                },
            }
        }
        case Types.Projects_FetchProjectDataError: {
            return {
                ...state,
                loadingProject: false,
            }
        }
        case Types.Projects_FetchAOIList: {
            return {
                ...state,
                aoiList: [],
                selectedAOI: null,
                selectedAOIData: initialState.projects.selectedAOIData,
                loadingAOIList: true,
                cache: {
                    ...state.cache,
                    selectedAOIData: initialState.projects.selectedAOIData,
                },
            }
        }
        case Types.Projects_FetchAOIListSuccess: {
            return {
                ...state,
                aoiList: action.payload.aoi_list,
                loadingAOIList: false,
            }
        }
        case Types.Projects_FetchAOIListError: {
            return {
                ...state,
                aoiList: [],
                loadingAOIList: false,
            }
        }
        case Types.Projects_SelectProject: {
            return {
                ...state,
                selectedProject: action.payload.selectedProject,
            }
        }
        case Types.Projects_ResetAOIList: {
            return {
                ...state,
                aoiList: [],
                selectedAOIData: initialState.projects.selectedAOIData,
                isSubmitAOIFlow: false,
                isEditAOIFlow: false,
                doPolygonRepairs: false,
                cache: {
                    ...state.cache,
                    selectedAOIData: initialState.projects.selectedAOIData,
                },
            }
        }
        case Types.Projects_ResetAOIData: {
            return {
                ...state,
                selectedAOI: null,
                selectedAOIData: initialState.projects.selectedAOIData,
                isSubmitAOIFlow: false,
                isEditAOIFlow: false,
                doPolygonRepairs: false,
                cache: {
                    ...state.cache,
                    selectedAOIData: initialState.projects.selectedAOIData,
                },
            }
        }
        case Types.Projects_SelectEditAOI: {
            const { selectedAOI } = action.payload

            return {
                ...state,
                selectedAOI,
                selectedAOIData: initialState.projects.selectedAOIData,
                loadingAOI: true,
                doPolygonRepairs: false,
                cache: {
                    ...state.cache,
                    selectedAOIData: initialState.projects.selectedAOIData,
                },
            }
        }
        case Types.Projects_SelectEditAOISuccess: {
            const { selectedAOIData } = action.payload

            return {
                ...state,
                selectedAOIData,
                isEditAOIFlow: true,
                loadingAOI: false,
                cache: {
                    ...state.cache,
                    selectedAOIData: selectedAOIData,
                },
            }
        }
        case Types.Projects_SelectEditAOIError: {
            return {
                ...state,
                selectedAOI: null,
                selectedAOIData: initialState.projects.selectedAOIData,
                isEditAOIFlow: false,
                loadingAOI: false,
            }
        }
        case Types.Projects_SelectSubmitAOI: {
            const { project_number } = action.payload
            const { aoiList } = state

            const aoiCharCodes = [64]

            if (aoiList.length > 0) {
                aoiList.forEach((aoi) => {
                    const block = aoi.name.split("Block ")[1]

                    if (aoi.name.startsWith("Block ") && block.length === 1) {
                        const blockChar = block.charCodeAt(0)
                        if (blockChar > 64 && blockChar < 92) {
                            aoiCharCodes.push(blockChar)
                        }
                    }

                    aoiCharCodes.push(64) // "@" (before "A")
                })
            }

            const highestChar = Math.max(...aoiCharCodes)
            const newBlock = String.fromCharCode(highestChar + 1)

            return {
                ...state,
                selectedAOI: null,
                selectedAOIData: {
                    ...initialState.projects.selectedAOIData,
                    project_number,
                    name: newBlock !== "[" ? `Block ${newBlock}` : "",
                },
                isSubmitAOIFlow: true,
                doPolygonRepairs: false,
                cache: {
                    ...state.cache,
                    selectedAOIData: initialState.projects.selectedAOIData,
                },
            }
        }
        case Types.Projects_TogglePolygonRepairs: {
            return {
                ...state,
                doPolygonRepairs: !state.doPolygonRepairs,
            }
        }
        case Types.Projects_UpdateProjectDetailsData: {
            return {
                ...state,
                selectedProjectData: {
                    ...state.selectedProjectData,
                    project_detail: {
                        ...(state.selectedProjectData?.project_detail || {}),
                        ...action.payload,
                    },
                },
            }
        }
        case Types.Projects_UpdateAOIData: {
            return {
                ...state,
                selectedAOIData: {
                    ...state.selectedAOIData,
                    ...action.payload,
                },
            }
        }
        case Types.Projects_DeleteAOI: {
            return {
                ...state,
                deletingAOI: true,
            }
        }
        case Types.Projects_DeleteAOISuccess:
        case Types.Projects_DeleteAOIError: {
            return {
                ...state,
                deletingAOI: false,
                doPolygonRepairs: false,
            }
        }
        case Types.Projects_ParseFile: {
            return {
                ...state,
                parsingAOI: true,
            }
        }
        case Types.Projects_ParseFileSuccess: {
            const { shape, area, area_contracted, source_file } = action.payload

            return {
                ...state,
                selectedAOIData: {
                    ...state.selectedAOIData,
                    shape,
                    area: area,
                    area_contracted: area_contracted,
                    source_file,
                },
                parsingAOI: false,
            }
        }
        case Types.Projects_ParseFileError: {
            return {
                ...state,
                parsingAOI: false,
            }
        }
        case Types.Projects_FetchNonAcquisitionProjectList: {
            return {
                ...state,
                nonAcqProjectList: [],
                selectedNonAcqProject: {},
                loadingNonAcqProjectList: true,
            }
        }
        case Types.Projects_FetchNonAcquisitionProjectListSuccess: {
            return {
                ...state,
                nonAcqProjectList: action.payload.nonAcqProjectList,
                loadingNonAcqProjectList: false,
            }
        }
        case Types.Projects_FetchNonAcquisitionProject: {
            return {
                ...state,
                selectedNonAcqProject: {},
                loadingNonAcqProject: action.payload.loading,
            }
        }
        case Types.Projects_FetchNonAcquisitionProjectSuccess: {
            return {
                ...state,
                selectedNonAcqProject: action.payload.selectedNonAcqProject,
                loadingNonAcqProject: false,
            }
        }
        default:
            return state
    }
}

export default projectsReducer
