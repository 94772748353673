import { TimeFormat, useLocalStorageUtil } from "../../LocalStorageUtil"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { StandardTextFieldProps } from "@mui/material"
import moment from "moment"
import { getFormat } from "../../tools"

interface TimeFieldProps extends Omit<StandardTextFieldProps, "onChange"> {
    field: string
    onChange: (field: string, value: string, momemt: moment.Moment | null) => void
    value: string
    error?: boolean
}

const TimeField = ({ field, required, value, onChange, error, ...rest }: TimeFieldProps) => {
    const timeFormat = useLocalStorageUtil(state => state.timeFormat())
    const { formatString, formatHourTwelve } = getFormat(timeFormat)

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
                value={value != null ? moment(value, formatString) : null}
                onChange={(time) => {
                    // onChange formats the value to twenty-four hour ver for consistent saving to db
                    const timeAsTwentyFour = time?.format(getFormat(TimeFormat.twenty_four).formatString) || ""
                    onChange(field, timeAsTwentyFour, time)
                }}
                ampm={formatHourTwelve}
                slotProps={{
                    popper: { sx: { "& .MuiMultiSectionDigitalClockSection-root": { width: "100%" } } },
                    textField: {
                        size: "small",
                        helperText: required && "Required",
                        required,
                        error,
                        ...rest,
                    },
                }}
            />
        </LocalizationProvider>
    )
}

export default TimeField
