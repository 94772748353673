import { ActionMap, Types } from "./index"
import moment from "moment"
import { FlightPlannedLineType, FlightplanType } from "./flightplansTypes"
import { RowId } from "../../models/Table"
import { unplannedLineValue } from "../../pages/MissionEditor/FlightlineTable"

export type TemplateType = {
    label: string
    description?: string
    value: string
}

export type OptionsType = {
    id: number | null
    flightplan_name: string | ""
    label: string | ""
}

export const templates: TemplateType[] = [
    { label: "Riegl Record Naming", description: "(RecordXXX_LineXXX)", value: "Record\\d+_Line(\\d+)" },
    { label: "Chiroptera Record Naming", description: "(FLXXX)", value: "FL(\\d+)" },
    { label: "UltraCam Record Naming", description: "(Run_XXX)", value: "Run_(\\d+)" },
]

export type EmployeeType = {
    employee_id?: string
    employee_position?: string
    employee_email?: string
    employee_full_name: string
    employee_phone?: string
    employee_status?: string
}

export type SensorType = {
    sensor_id?: number
    serial_number?: string
    sensor_model?: string
    sensor_type?:
        | "Unknown"
        | "NIR Lidar"
        | "Topobathy Lidar"
        | "3-band Camera"
        | "4-band Camera"
        | "Hyperspectral Camera"
        | "Thermal Camera"
}

export type PlatformType = {
    platform_id?: number
    platform_name?: string
    platform_type?: "Unknown" | "Helicopter" | "uas" | "Automobile" | "Watercraft"
}

export type AirportType = {
    airport: string
    inProject: boolean
}

/*
* The Data Type transferred between the DB
* */
export interface FlightlineEventDTO {
    planned_line_id: number | null
    flight_line_event_id: number | null
    line_original_name: string
    coll_event_name: string // Sensor's label
    flight_line_start_time: string
    flight_line_stop_time: string
    flight_line_distance: number
    flight_line_duration: number
    flown_agl: number
    flight_status: string
    mission_id: number | null
    notes: string
    shape?: string
}

export type PlannedLineId = number | typeof unplannedLineValue | null

export interface FlightlineEventType extends Omit<FlightlineEventDTO, "flight_line_event_id" | "planned_line_id"> {
    /**
     * Front-end only
     */
    flightline_name: string | null
    /*
    * Can be existing unique ID or inserted string - used for editing
    * */
    flight_line_event_id: RowId | null
    planned_line_id: PlannedLineId
}

export type MissionShippingInfoType = {
    mission_shipping_id: number | null
    mission_id: number | null
    plane_drive_name: string | null
    backup_drive_name: string | null
    delivery_drive_name: string | null
    shipping_number: string | null
    office_id: string | null
    raw_storage_size: number | null
}

export type MissionReviewType = {
    mission_review_id: number | null
    mission_id: number | null
    review_date: string | null
    reviewer_id: string | null
    reviewer_notes: string | null
}

export type MissionNameType = {
    mission_id?: number
    mission_name?: string
}

export type MissionReviewStatusType = {
    event_ct_remaining: number
    event_ct_total: number
    mission_id: number
    mission_name: string
    project_number: string
    review_date: string
    reviewer_id: string
    reviewer_name: string
}

/*
*   From the Backend when a mission is uploaded
* */
export interface ParsedMissionDTO {
    mission_id: number | null
    mission_name: string | null
    /**
     * Parsed files always return a number
     */
    flight_num: number
    sensor_id_1: number | null
    sensor_id_2: number | null
    platform_id: number | null
    flight_plan_id: number | null
    project_number: string | null
    flown_date: string | null
    start_time: string | null
    stop_time: string | null
    duration: number | null
    start_hobbs: number | null
    stop_hobbs: number | null
    active_hobbs: number | null
    mob_hobbs: number | null
    operator_1_id: string | null
    operator_2_id: string | null
    pilot_1_id: string | null
    pilot_2_id: string | null
    base_airport: string | null
    manager_id: string | null
    flight_notes: string | null
    error_notes: string | null
    error_action: string | null
    tomorrow_notes: string | null
    flight_blog: string | null
    source_file: string | null
    vendor_name: string | null
    mission_shipping_info: MissionShippingInfoType | null
    mission_review: MissionReviewType | null
    flight_plan: FlightplanType[]
    flightline_events: FlightlineEventType[]
}

/*
* Frontend's version
* */
export interface MissionType extends ParsedMissionDTO {
    display_name: string
    channel_name: string | null
    flight_notes: string | null
    serial_number_1: string
    serial_number_2: string
    flown_date: string | null
    /*
    * Front end use only
    * */
    flown_end_date?: string | null
}

export type MissionsType = {
    warnings: string[]
    missionNameList: MissionNameType[]
    selectedMission: MissionType
    selectedMissionId: number | null
    selectedFlightplans: FlightplanType[]
    selectedFlightplanIds: number[]
    parsedMissions: MissionType[]
    flightlineEvents: FlightlineEventType[]
    flightPlannedLines: FlightPlannedLineType[]
    importExpression: {
        value: string
        error: string
    }
    matchingExpression: {
        active: string
        custom: string
        selectedTemplate: TemplateType
        isCustom: boolean
    }
    activeFlow: string | null
    isVendorFlow: boolean
    isDeleting: boolean
    employeeList: {
        employees: EmployeeType[]
        project_employees: {
            pilots: string[]
            operators: string[]
            managers: string[]
            reviewers: string[]
        }
        nexus_employees: {
            pilots: string[]
            operators: string[]
            managers: string[]
            reviewers: string[]
        }
    }
    vendorNameList: string[]
    flightplanList: FlightplanType[]
    sensorList: {
        sensors: SensorType[]
        project_sensors: number[]
    }
    platformList: {
        platforms: PlatformType[]
        project_platforms: number[]
    }
    airportList: AirportType[]
    missionsError: string | null
    loadingMissionNameList: boolean
    loadingFlightplanList: boolean
    loadingFlightPlannedList: boolean
}

export type MissionsPayload = {
    [Types.Missions_ResetState]: undefined
    [Types.Missions_SelectParsedMission]: { selectedMission: MissionType }
    [Types.Missions_SelectMissionId]: { selectedMissionId: number | null }
    [Types.Missions_SelectFlightplans]: { selectedFlightplans: FlightplanType[] }
    [Types.Missions_SelectFlightplanList]: { selectedFlightplanIds: number[] }
    [Types.Missions_SelectActiveFlow]: { activeFlow: string }
    [Types.Missions_ImportFlightplannedLines]: { range: string }
    [Types.Missions_UpdateMissionData]: Partial<MissionType>
    [Types.Missions_UpdateMissionShippingInfoData]: { [key: string]: any }
    [Types.Missions_UpdateMissionReviewData]: { [key: string]: any }
    [Types.Missions_UpdateFlightPlanData]: { [key: string]: any }
    [Types.Missions_UpdateFlightlineEventData]: {
        rows: FlightlineEventType[]
    }
    [Types.Missions_UpdateFlightlineEventNotes]: {
        startIndex: number
        notesList: string[]
    }
    [Types.Missions_UpdateFlightlineEventStatuses]: {
        indexes: number[]
        status: string
    }
    [Types.Missions_UpdateMatchingExpressionData]: {
        custom?: string
        selectedTemplate?: { label: string, value: string }
        isCustom?: boolean
    }
    [Types.Missions_UpdateIsVendorFlow]: boolean
    [Types.Missions_DeleteMission]: undefined
    [Types.Missions_DeleteMissionSuccess]: undefined
    [Types.Missions_DeleteMissionError]: undefined
    [Types.Missions_FetchParsedMissions]: undefined
    [Types.Missions_FetchParsedMissionsSuccess]: {
        parsedMissions: MissionType[]
        warnings: string[]
        best_regex: TemplateType
    }
    [Types.Missions_FetchMissionNameList]: undefined
    [Types.Missions_FetchMissionNameListSuccess]: { missionNameList: MissionNameType[] }
    [Types.Missions_FetchMission]: undefined
    [Types.Missions_FetchMissionSuccess]: { selectedMission: MissionType }
    [Types.Missions_FetchFlightlineEvents]: undefined
    [Types.Missions_FetchFlightlineEventsSuccess]: {
        flightlineEvents: FlightlineEventType[]
        selectedFlightplans: FlightplanType[]
        flightPlannedLines: FlightPlannedLineType[]
        selectedFlightplanIds: number[]
    }
    [Types.Missions_FetchFlightplannedLines]: undefined
    [Types.Missions_FetchFlightplannedLinesSuccess]: {
        flightPlannedLines: FlightPlannedLineType[]
    }
    [Types.Missions_FetchEmployeeList]: undefined
    [Types.Missions_FetchEmployeeListSuccess]: {
        employeeList: {
            employees: EmployeeType[]
            project_employees: {
                pilots: string[]
                operators: string[]
                managers: string[]
                reviewers: string[]
            }
            nexus_employees: {
                pilots: string[]
                operators: string[]
                managers: string[]
                reviewers: string[]
            }
        }
    }
    [Types.Missions_FetchVendorNameList]: undefined
    [Types.Missions_FetchVendorNameListSuccess]: { vendorNameList: string[] }
    [Types.Missions_FetchFlightplanList]: undefined
    [Types.Missions_FetchFlightplanListSuccess]: { flightplanList: FlightplanType[] }
    [Types.Missions_FetchSensorList]: undefined
    [Types.Missions_FetchSensorListSuccess]: {
        sensorList: {
            sensors: SensorType[]
            project_sensors: number[]
        }
    }
    [Types.Missions_FetchPlatformList]: undefined
    [Types.Missions_FetchPlatformListSuccess]: {
        platformList: {
            platforms: PlatformType[]
            project_platforms: number[]
        }
    }
    [Types.Missions_FetchAirportList]: undefined
    [Types.Missions_FetchAirportListSuccess]: { airportList: AirportType[] }
    [Types.Missions_ParseSensorFile]: undefined
    [Types.Missions_ParseSensorFileSuccess]: { sensorFile: any }
    [Types.Missions_UpdateError]: { error: any }
    [Types.Missions_ClearError]: undefined
    [Types.Missions_ClearImportError]: undefined
}

export type MissionsActions = ActionMap<MissionsPayload>[keyof ActionMap<MissionsPayload>]

// @Todo update with flightline only calculation
export const missionUtils = {
    calculateStartAndEndDateTimes: (mission: MissionType) => {
        const { flown_date, flown_end_date, start_time, stop_time } = mission
        const startDate = flown_date
        const stopDate = flown_end_date ?? flown_date
        const startDateTime = flown_date != null && start_time != null ? `${flown_date} ${start_time}` : undefined
        const stopDateTime = stopDate != null && stop_time != null ? `${stopDate} ${stop_time}` : undefined
        const startMoment = startDateTime != null ? moment(startDateTime) : null
        const stopMoment = stopDateTime != null ? moment(stopDateTime) : null
        const duration = startMoment != null && stopMoment != null ? moment.duration(stopMoment.diff(startMoment)).asSeconds() : undefined
        return { startDate, stopDate, startDateTime, stopDateTime, duration }
    },
}

