import { create } from "zustand"

enum LSKeys {
    OFFICES = "OFFICES",
    UNITS_DISTANCE = "UNITS_DISTANCE",
    UNITS_HEIGHT = "UNITS_HEIGHT",
    SHOW_INACTIVE = "SHOW_INACTIVE",
    SORT_ORDER = "SORT_ORDER",
    TIME_FORMAT_24 = "TIME_FORMAT_24",
}

export enum DistanceUnits {
    m = "m",
    nMi = "nMi",
    km = "km",
    mi = "mi",
}

export enum HeightUnits {
    m = "m",
    km = "km",
    mi = "mi",
    ft = "ft",
}

export enum Order { asc = "asc", desc = "desc"}

type SortOrder = { orderBy: string, order: Order }

export enum TimeFormat {
    twelve = "twelve",
    twenty_four = "twenty_four",
}

interface LocalStorageState {
    saveOffices: (offices: string[]) => void
    offices: () => string[]
    savePreferredDistanceUnit: (distanceUnit: DistanceUnits) => void
    preferredDistanceUnit: () => DistanceUnits
    savePreferredHeightUnit: (heightUnit: HeightUnits) => void
    preferredHeightUnit: () => HeightUnits
    showInactive: () => boolean
    saveShowInactive: (show: boolean) => void
    saveSortOrder: (orderBy: string, order?: Order) => void
    sortOrder: () => SortOrder
    saveTimeFormat: (timeSupports24Format: TimeFormat) => void
    timeFormat: () => TimeFormat
}

export const useLocalStorageUtil = create<LocalStorageState>((set, get) => ({
    saveOffices: (offices: string[]) => {
        localStorage.setItem(LSKeys.OFFICES, JSON.stringify(offices))
        set({ offices: get().offices })
    },
    offices: (): string[] => {
        const serializedOffices = localStorage.getItem(LSKeys.OFFICES)
        return (serializedOffices == null ? [] : JSON.parse(serializedOffices)) as string[]
    },
    savePreferredDistanceUnit: (distanceUnit: DistanceUnits) => {
        localStorage.setItem(LSKeys.UNITS_DISTANCE, JSON.stringify(distanceUnit))
        set({ preferredDistanceUnit: get().preferredDistanceUnit })
    },
    preferredDistanceUnit: (): DistanceUnits => {
        const serializedDistanceUnit = localStorage.getItem(LSKeys.UNITS_DISTANCE)
        return serializedDistanceUnit == null ? DistanceUnits.m : JSON.parse(serializedDistanceUnit)
    },
    savePreferredHeightUnit: (heightUnit) => {
        localStorage.setItem(LSKeys.UNITS_HEIGHT, JSON.stringify(heightUnit))
        set({ preferredHeightUnit: get().preferredHeightUnit })
    },
    preferredHeightUnit: () => {
        const serializedHeightUnit = localStorage.getItem(LSKeys.UNITS_HEIGHT)
        return serializedHeightUnit == null ? HeightUnits.m : JSON.parse(serializedHeightUnit)
    },
    saveShowInactive: (show) => {
        localStorage.setItem(LSKeys.SHOW_INACTIVE, JSON.stringify(show))
        set({ showInactive: get().showInactive })
    },
    showInactive: () => {
        const serializedShowInactive = localStorage.getItem(LSKeys.SHOW_INACTIVE)
        return serializedShowInactive == null ? false : JSON.parse(serializedShowInactive)
    },
    saveSortOrder: (orderBy, order = Order.asc) => {
        localStorage.setItem(LSKeys.SORT_ORDER, JSON.stringify({ orderBy, order }))
        set({ sortOrder: get().sortOrder })
    },
    sortOrder: () => {
        const serializedOrder = localStorage.getItem(LSKeys.SORT_ORDER)
        const defaultOrder = { order: Order.asc, orderBy: "" }
        if (!serializedOrder) {
            return defaultOrder
        }
        const parsedOrder = JSON.parse(serializedOrder) as SortOrder
        return { orderBy: parsedOrder.orderBy, order: parsedOrder.order ?? defaultOrder.order }
    },
    saveTimeFormat: (timeSupports24Format) => {
        localStorage.setItem(LSKeys.TIME_FORMAT_24, JSON.stringify(timeSupports24Format))
        set({ timeFormat: get().timeFormat })
    },
    timeFormat: () => {
        const serializedTimeFormat = localStorage.getItem(LSKeys.TIME_FORMAT_24)
        return serializedTimeFormat == null ? TimeFormat.twenty_four : JSON.parse(serializedTimeFormat)
    },
}))
