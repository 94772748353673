import { FlightlineEventType } from "../../store/types/missionsTypes"
import useStyles from "./styles"
import TimeField from "../Fields/TimeField"
import useMissions from "../../hooks/useMissions"
import React, { useEffect, useState } from "react"
import moment from "moment"
import {
    isEqual,
    parseDateTimeHMS,
    parseTimeHMS,
} from "../../tools"
import {
    flightLineEventId,
    statusOptions,
    unplannedLineValue,
} from "../../pages/MissionEditor/FlightlineTable"
import {
    Button,
    DialogActions,
    FormControl,
    Grid,
    Alert,
    TextField,
} from "@mui/material"
import {
    initialCollectionEventCreation,
    useCollectionEventEditorStore,
} from "./CollectionEventEditorStore"
import { CollectionEventCreator } from "../../models/CollectionEventCreator"
import Autocomplete from "../Fields/Autocomplete"
import { insertTemporaryTableId, isRowInserted } from "../../utils/tableUtils"

const defaultErrorValues: CollectionEventCreator = {
    ...initialCollectionEventCreation,
    status: "",
}

const requiredValues: { key: (keyof CollectionEventCreator), label: string }[] = [
    { key: "planned_line_id", label: "Derived name" },
]

interface IFlightlineProps {
    toggleCollectionEventDrawerOpen: (...prop: any) => void
}

const CollectionEventEditor = ({ toggleCollectionEventDrawerOpen }: IFlightlineProps) => {
    const { classes, cx } = useStyles()
    const { missions, flightPlannedLineLabelOptions } = useMissions()
    const { flightlineEvents, flightPlannedLines } = missions

    const values = useCollectionEventEditorStore(state => state.values)
    const updateValues = useCollectionEventEditorStore(state => state.updateValues)
    const [errors, setErrors] = useState<CollectionEventCreator>(defaultErrorValues)

    const handleInputUpdate = (key: string, value: string) => {
        updateValues({ ...values, [key]: value })
    }

    const handleDurationInput = (key: string, value: string) => {
        const newValues = { ...values }
        const field = key as keyof CollectionEventCreator

        // Update duration
        const isStartKey = field === "start_time"
        const isStopKey = field === "stop_time"

        if ((isStartKey && values.stop_time) || (isStopKey && values.start_time)) {
            const start = isStartKey ? value : values.start_time
            const stop = isStopKey ? value : values.stop_time
            // combine date with time to generate proper datetime obj for moment
            const startMoment = parseDateTimeHMS(missions.selectedMission.flown_date, start)
            const stopMoment = parseDateTimeHMS(missions.selectedMission.flown_date, stop)
            const durationTemp = (startMoment === null || stopMoment == null || startMoment.isSameOrAfter(stopMoment))
                ? 0 : stopMoment.diff(startMoment, "seconds")
            newValues.duration = durationTemp.toString()
        }

        updateValues({
            ...newValues,
            [key]: value,
        })
    }

    // Error handling
    // this function will check if the form values are valid
    useEffect(() => {
        const newErrors: CollectionEventCreator = { ...defaultErrorValues }
        if (!isEqual(values, initialCollectionEventCreation)) {

            requiredValues.forEach(req => {
                newErrors[req.key] = !values[req.key] ? `${req.label} is required.` : ""
            })

            if (Number(values.duration) <= 0 || isNaN(+values.duration)) {
                newErrors.duration = "Duration must be positive."
            }

            // stop_time validation after start_time
            if (values?.start_time || values?.stop_time) {
                const tstart = parseTimeHMS(values.start_time)
                const tstop = parseTimeHMS(values.stop_time)

                if (tstart === null || tstop === null || tstart.isSameOrAfter(tstop)) {
                    newErrors.stop_time = "Stop time must be after the start time."
                } else {
                    newErrors.stop_time = ""
                }
            }

            // planned_line_id validation
            if (Number(values.planned_line_id) === unplannedLineValue && values.status !== "Ignored") {
                newErrors.status = "An unplanned line's status must be set as Ignored."
            } else {
                newErrors.status = ""
            }

        }
        setErrors({ ...newErrors })
    }, [values, flightPlannedLines]) // eslint-disable-line

    const formIsValid = () => {
        return Object.values(errors).every((x) => x === "")
    }

    const handleFlightlineCreate = async () => {
        // this function will be triggered by the submit event
        if (formIsValid()) {
            // update flightlineEvents obj in state
            const {
                id,
                status,
                start_time,
                stop_time,
                duration,
                notes,
                flown_agl,
                planned_line_id,
                line_original_name,
                coll_event_name,
            } = values

            const plannedLine = flightPlannedLines.find(_plannedline => _plannedline.planned_line_id === Number(planned_line_id))

            //load Distance
            const flightline_distance = values.distance ?? plannedLine?.planned_distance

            const mission_id = missions.selectedMissionId
            const flown_date = missions.selectedMission.flown_date

            const insertedNumLines = flightlineEvents.filter(line => isRowInserted(line[flightLineEventId])).length
            const newFlightLine_event: FlightlineEventType = {
                planned_line_id: plannedLine?.planned_line_id ?? unplannedLineValue,
                flightline_name: plannedLine?.flight_line_name ?? null,
                line_original_name,
                coll_event_name,
                flight_line_distance: Number(flightline_distance ?? 0),
                flight_line_duration: Number(duration),
                flight_line_event_id: insertTemporaryTableId(insertedNumLines),
                flight_line_start_time: moment.utc(
                    flown_date + " " + start_time,
                ).toISOString(),
                flight_line_stop_time: moment.utc(
                    flown_date + " " + stop_time,
                ).toISOString(),
                flight_status: status,
                flown_agl: Number(flown_agl),
                mission_id: mission_id,
                notes: notes,
                shape: plannedLine?.shape,
            }

            // update state missions
            // if from edit will contain "id"
            if (id) {
                const index = flightlineEvents.findIndex((fl) => fl.flight_line_event_id === id)
                flightlineEvents.splice(index, 1, newFlightLine_event)
            } else {
                flightlineEvents.unshift(newFlightLine_event)
            }
            toggleCollectionEventDrawerOpen()
        }
    }

    return (
        <Grid
            container
            direction={"column"}
            key="new-collection-event-form-wrapper"
            className={cx(classes.input__md)}
        >
            <FormControl key="new-collection-event-form-control">
                <TextField
                    name="line_original_name"
                    label="Original Name"
                    value={values.line_original_name}
                    variant="outlined"
                    className={cx(classes.gutterBottom__md)}
                    required
                    onChange={(e) => handleInputUpdate(e.target.name, e.target.value)}
                    key="new-flightline-event-original-name"
                    error={errors.line_original_name !== ""}
                />
                <Autocomplete
                    id="planned_line_id"
                    value={flightPlannedLineLabelOptions.find(opt => opt.value == Number(values.planned_line_id)) ?? null}
                    options={flightPlannedLineLabelOptions}
                    getOptionLabel={(option) => `${option.label}`}
                    TextFieldProps={{ label: "Derived Name", required: true, error: errors.planned_line_id !== "" }}
                    onChange={(_, option) => handleInputUpdate("planned_line_id", String(option?.value ?? ""))}
                />
                <TextField
                    name="coll_event_name"
                    label="Sensor Label"
                    key="new-coll-event-name"
                    variant="outlined"
                    className={cx(classes.gutterBottom__md, classes.input__md)}
                    onChange={(e) => handleInputUpdate("coll_event_name", e.target.value)}
                    error={errors.coll_event_name !== ""}
                />
                <Autocomplete
                    id="status"
                    options={statusOptions}
                    value={values.status ?? null}
                    key="new-collection-event-status"
                    className={cx(classes.gutterBottom__md)}
                    onChange={(_, value) => handleInputUpdate("status", value ?? "")}
                    TextFieldProps={{ label: "Status", error: errors.status !== "" }}
                />
                <Grid item key="new-notes-wrapper" container direction="row" className={cx(classes.gutterBottom__md)}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label={"Notes"}
                        value={values.notes}
                        onChange={(e) => handleInputUpdate("notes", e.target.value)}
                    />
                </Grid>
                <Grid container key="time-container" columnSpacing={2} className={cx(classes.gutterBottom__sm)}>
                    <Grid item xs={6} key="start-time-subcontainer">
                        <TimeField
                            fullWidth
                            required
                            label="Start Time"
                            key="new-collection-event-start-time"
                            field="start_time"
                            inputProps={{
                                step: 1, // enable hh:mm:ss display
                            }}
                            onChange={handleDurationInput}
                            value={values.start_time ?? ""}
                            error={!!errors.start_time}
                        />
                    </Grid>
                    <Grid item xs={6} key="stop-time-subcontainer">
                        <TimeField
                            fullWidth
                            required
                            label="Stop Time"
                            key="new-collection-event-stop-time"
                            field="stop_time"
                            inputProps={{
                                step: 1, // enable hh:mm:ss display
                            }}
                            onChange={handleDurationInput}
                            value={values.stop_time ?? ""}
                            error={!!errors.stop_time}
                        />
                    </Grid>
                </Grid>
                <TextField
                    name="new-collection-event_duration"
                    key="collection-event-duration"
                    label="Duration (s)"
                    value={values.duration}
                    disabled
                    variant="outlined"
                    className={cx(classes.gutterBottom__md, classes.input__md)}
                />
                <TextField
                    name="flown_agl"
                    label="FlightLine AGL (m)"
                    key="new-collection-event-agl"
                    variant="outlined"
                    value={values.flown_agl}
                    className={cx(classes.gutterBottom__md, classes.input__md)}
                    onChange={(e) => handleInputUpdate("flown_agl", e.target.value)}
                />
            </FormControl>
            <Grid>
                {!formIsValid() && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {Object.values(errors).filter((e) => (e !== "")).map((msg, idx) =>
                            <li style={{ width: "100%" }} key={idx}>{msg}</li>,
                        )}
                    </Alert>
                )}
            </Grid>
            <DialogActions>
                <Button
                    color="info"
                    size="large"
                    onClick={() => toggleCollectionEventDrawerOpen()}
                    variant="outlined"
                    key="new-button-cancel"
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    size="large"
                    onClick={handleFlightlineCreate}
                    variant="contained"
                    key="new-button-update"
                    disabled={(!formIsValid() || !(values.planned_line_id.length) || !(values.start_time.length > 0) || !(values.stop_time.length > 0))}
                >
                    Update Events Table
                </Button>
            </DialogActions>
        </Grid>
    )
}

export default CollectionEventEditor
