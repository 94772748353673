import React from "react"
import propTypes from "prop-types"

import { Typography, TypographyProps } from "@mui/material"
import useStyles from "./styles"

interface CustomTypographyProps extends Omit<TypographyProps, "gutterBottom"> {
    disableGutter?: boolean
    gutterBottom?: "sm" | "md" | "lg"
    disabled?: boolean
}

const CustomTypography = ({
    children,
    className,
    disableGutter,
    gutterBottom = "md",
    disabled,
    ...other
}: CustomTypographyProps) => {
    const { classes, cx } = useStyles()

    return (
        <Typography
            color={disabled ? "textSecondary" : "textPrimary"}
            className={cx({ [classes[`gutterBottom__${gutterBottom}`]]: !disableGutter }, className)}
            {...other}
        >
            {children}
        </Typography>
    )
}

export const Title = (props: CustomTypographyProps) => <CustomTypography variant="h5" {...props} />

export const PrimaryLabel = (props: CustomTypographyProps) => <Label className={useStyles().classes.underline} {...props} />
export const Label = (props: CustomTypographyProps) => <CustomTypography variant="h6" {...props} />

export const Body = (props: CustomTypographyProps) => <CustomTypography variant="body1" {...props} />

export const UnderlinedLabel = (props: CustomTypographyProps) =>
    <CustomTypography sx={{ textDecoration: "underline" }} {...props} />

export const Subtitle2 = (props: CustomTypographyProps) =>
    <CustomTypography variant="subtitle2" color="textSecondary" {...props} />

CustomTypography.propTypes = {
    disableGutter: propTypes.bool,
    gutterBottom: propTypes.oneOf(["sm", "md", "lg"]),
}

CustomTypography.defaultProps = {
    gutterBottom: "md",
}
