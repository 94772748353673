import propTypes from "prop-types"

import { Divider, List, ListItem, Paper, Typography } from "@mui/material"
import { Label } from "../Typography"

import useStyles from "./styles"

export interface Detail {
    label: string
    field: string
    render?: any
}

interface DetailsListType {
    className?: any
    details: Detail[]
    disableSurface?: boolean
    label?: string
    value: { [key: string]: any }
}

export default function DetailsList({ className, details, disableSurface, label, value }: DetailsListType) {
    const { classes, cx } = useStyles()

    const list = (
        <List disablePadding>
            {details.map((detail, i) => (
                <ListItem key={i} disableGutters divider={i !== details.length - 1} className={classes.list_item}>
                    <Typography variant="caption" color="textSecondary" className={classes.list_item_key}>
                        {detail.label}
                    </Typography>
                    <Typography className={classes.list_item_value}>
                        {detail.render ? detail.render(value) : value[detail.field]}
                    </Typography>
                </ListItem>
            ))}
        </List>
    )

    if (disableSurface) {
        return list
    }

    return (
        <Paper className={cx(classes.list_container, classes.gutterBottom__lg, className)}>
            {label && <Label gutterBottom="sm">{label}</Label>}
            <Divider />
            {list}
        </Paper>
    )
}

DetailsList.propTypes = {
    className: propTypes.string,
    details: propTypes.arrayOf(
        propTypes.shape({
            label: propTypes.string,
            field: propTypes.string,
            render: propTypes.func,
        }),
    ),
    label: propTypes.string,
    value: propTypes.object,
}
