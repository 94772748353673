import { TableColumn, TableRowType } from "../models/Table"

/**
 * Returns true if id has "insert" as a substring.
 */
export const isRowInserted = <T, >(id: T): boolean => {
    if (typeof id === "string") {
        return id?.includes("insert") === true
    } else if (typeof id === "number") {
        return id < 0
    }
    return false
}

// Handle Checkbox appearing in correct column array if canSelect and stickyColumns enabled
export const checkboxName = "checkboxName"
const checkboxColumn: TableColumn = { id: checkboxName, label: checkboxName }

export const addSelectToColumns = (columns: TableColumn[], canSelect?: boolean, stickyColumns?: TableColumn[]) => {
    const stickyCols = (canSelect && stickyColumns) ? [checkboxColumn, ...stickyColumns] : stickyColumns
    const cols = (canSelect && !stickyCols) ? [checkboxColumn, ...columns] : columns
    return { cols, stickyCols }
}

// Handling of inserted id to row to be consistent among all table usages
// uses a negative number for distinction, as sorting between numbers is better than numbers AND strings
export const insertTemporaryTableId = (num: number) => -(num + 1)

export const addTableRow = <R>(row: R, numAddedRows: number, primary: keyof R): R => {
    return ({
        ...row,
        [primary]: insertTemporaryTableId(numAddedRows),
    })
}

export const transformRowsToTableRows = <R>(rows: R[], options?: { disableRowWithKey?: keyof R }) => {
    return rows.map((row): R & TableRowType => {
        const { disableRowWithKey } = options ?? {}

        return ({
            ...row,
            selectable: !(disableRowWithKey && row[disableRowWithKey]),
            show: true,
        })
    })
}
