import { makeStyles } from "tss-react/mui"
import animations from "../../../assets/animations"
import spacing from "../../../assets/spacing"

const lightTooltip = {
    backgroundColor: "#f5f5f9",
    border: "1px solid #dadde9",
}

const useStyles = makeStyles()((theme) => ({
    ...animations(theme),
    ...spacing(theme),

    tooltip: {
        ...lightTooltip,
        padding: theme.spacing(0.75, 1.25),
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(12),

        "& .MuiTooltip-arrow": {
            "&::before": {
                ...lightTooltip,
            },
        },
    },

    text_label: {
        marginBottom: theme.spacing(0.25),
    },
}))

export default useStyles
