import { createTheme } from "@mui/material/styles"

const theme = createTheme({
    palette: {
        primary: {
            main: "#006298",
        },
        secondary: {
            main: "#71b2c9",
        },
    },
})

export default theme
