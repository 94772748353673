import appReducer from "./app"
import flightplansReducer from "./flightplans"
import missionsReducer from "./missions"
import lookupReducer from "./lookup"
import projectsReducer from "./projects"
import { AppActions, AppType } from "../types/appTypes"
import { MissionsActions, MissionsType } from "../types/missionsTypes"
import { FlightplansActions, FlightplansType } from "../types/flightplansTypes"
import { LookupActions, LookupType } from "../types/lookupTypes"
import { ProjectsActions, ProjectsType } from "../types/projectsTypes"

export type InitialStateType = {
    app: AppType
    flightplans: FlightplansType
    missions: MissionsType
    lookup: LookupType
    projects: ProjectsType
}

export const mainReducer = (
    { app, flightplans, missions, lookup, projects }: InitialStateType,
    action: AppActions | FlightplansActions | MissionsActions | LookupActions | ProjectsActions,
): InitialStateType => ({
    app: appReducer(app, action as AppActions),
    flightplans: flightplansReducer(flightplans, action as FlightplansActions),
    missions: missionsReducer(missions, action as MissionsActions),
    lookup: lookupReducer(lookup, action as LookupActions),
    projects: projectsReducer(projects, action as ProjectsActions),
})
