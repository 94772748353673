const routes = {
    home: "/",
    login: "/login",
    projectInit: "/projects/initialize",
    projectEditor: "/project-editor",
    missionUpload: "/mission-upload",
    missionSelect: "/mission-select",
    missionEditor: "/mission-editor",
    flightplanUpload: "/flightplan-upload",
    flightplanEditor: "/flightplan-editor",
    lookupEditor: "/lookup-table",
    confirm: "/confirmation",
}

export enum ConfirmationRoutes {
    MISSION_SUBMITTED = "mission-submitted",
    MISSION_EDITED = "mission-edited",
    FLIGHTPLAN_SUBMITTED = "flightplan-submitted",
    FLIGHTPLAN_EDITED = "flightplan-edited",
    PROJECT_EDITED = "project-edited",
}

export default routes
