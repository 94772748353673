import { Toolbar, Typography, Divider } from "@mui/material"
import { Add, Delete } from "@mui/icons-material"
import Tool from "../Tool"
import { useState } from "react"
import { useToolbarStyles } from "./styles"
import { ToolArray, ToolDivider, ToolShape } from "../../models/Table"

interface CustomToolbarProps {
    selected: (number | string)[]
    label: string
    tools?: ToolArray
    addRow?: false | (() => void)
    onDeleteSelectedRows?: () => void
}

export const EnhancedTableToolbar = ({
    selected,
    label,
    tools,
    addRow,
    onDeleteSelectedRows,
}: CustomToolbarProps) => {
    const { classes, cx } = useToolbarStyles()

    const [toolValues, setToolValues] = useState(Array(tools?.length).fill(false))
    const numSelected = selected.length

    const handleTools = (tool: ToolShape, index: number, option: string) => {
        if (!tool.options || !tool.options.length) {
            const newValue = !toolValues[index]
            setToolValues([...toolValues.slice(0, index), newValue, ...toolValues.slice(index + 1)])
            if (tool.onChange) {
                tool.onChange({ bool: newValue, selected })
            }
        } else {
            tool.onChange({ option, selected })
        }
    }

    return (
        <Toolbar
            className={cx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 &&
                <Typography className={classes.title} variant="subtitle1">{numSelected} selected</Typography>
            }
            {numSelected <= 0 &&
                <Typography className={classes.title} variant="h6" id="tableTitle">{label}</Typography>
            }
            {tools?.map((toolOrDivider, index) => {
                if (toolOrDivider === ToolDivider) {
                    return <Divider sx={{ mx: 2 }} key={index} orientation={"vertical"} variant="middle" flexItem />
                }

                const tool = toolOrDivider
                return (
                    <Tool
                        key={index}
                        tooltip={tool.label}
                        Icon={tool.Icon}
                        value={tool.enabled || (tool.enableOnSelect && numSelected > 0) || (tool.enableOnSingleSelect && numSelected === 1) ? true : toolValues[index]}
                        disabled={(tool.enableOnSelect && numSelected === 0) || (tool.enableOnSingleSelect && numSelected !== 1)}
                        options={tool.options}
                        onChange={(option: string) => handleTools(tool, index, option)}
                    />
                )
            })}
            {addRow && (
                <Tool
                    tooltip="Add Row"
                    Icon={Add}
                    onChange={addRow}
                />
            )}
            {onDeleteSelectedRows && (
                <Tool
                    tooltip="Delete"
                    disabled={numSelected === 0}
                    Icon={Delete}
                    value={numSelected !== 0}
                    onChange={onDeleteSelectedRows}
                />
            )}
        </Toolbar>
    )
}
