import { Box, Grid, TextField, useTheme, Checkbox, InputAdornment, Divider } from "@mui/material"
import {
    AcquisitionMode,
    AoiInputType,
    AoiModeConditions,
    AoiSpecs,
    FieldTypes,
    Imagery,
    ModeShape,
    PointCloud,
} from "../../models/AcquisitionMode"
import NumberField from "../Fields/NumberField"
import { snakeCaseToTitleCase } from "../../tools"
import { useAcquisitionModeStore } from "../../zstore/AcquisitionModeStore"
import { Body, Label, UnderlinedLabel } from "../Typography"
import useProjects from "../../hooks/useProjects"
import { LengthUnits } from "convert-units"
import { NumberCalculationField } from "../Fields/NumberCalculation"
import Autocomplete from "../Fields/Autocomplete"
import { convertLength, convertPPS, PPSUnits } from "../../utils/unitCalculations"

const specsDefaultLengthUnitType = "m"
const specsDefaultPPSUnitType = "ppsm"
const imagerySpecs: AoiInputType<Imagery>[] = [
    {
        type: FieldTypes.lengthCalc,
        field: "pixel_size",
        units: ["m", "ft", "in"],
        defaultUnitType: specsDefaultLengthUnitType
    },
    {
        type: FieldTypes.lengthCalc,
        field: "accuracy_xy",
        label: "Hz Accuracy (RMSE)",
        units: ["m", "ft", "in"],
        defaultUnitType: specsDefaultLengthUnitType,
    },
    { type: FieldTypes.number, field: "sun_angle_min", inputLabel: "°", min: 5, max: 90, decimalCount: 1 },
    { type: FieldTypes.number, field: "sun_angle_max", inputLabel: "°", min: 5, max: 90, decimalCount: 1 },
    { type: FieldTypes.number, field: "sidelap", inputLabel: "%", decimalCount: 2 },
    { type: FieldTypes.number, field: "endlap", inputLabel: "%", decimalCount: 2 },
    { type: FieldTypes.autocomplete, field: "imagery_bands" },
]

const ptCloudSpecs: AoiInputType<PointCloud>[] = [
    { type: FieldTypes.ppsCalc, field: "density", decimalCount: 2, units: ["ppsm", "ppsf"], defaultUnitType: specsDefaultPPSUnitType },
    {
        type: FieldTypes.lengthCalc,
        field: "accuracy_nva",
        label: "Vertical Accuracy (RMSE)",
        units: ["m", "ft"],
        defaultUnitType: specsDefaultLengthUnitType,
        decimalCount: 3,
    },
    { type: FieldTypes.boolean, field: "dbl_coverage", label: "Double Coverage?" },
]

const conditions: Array<keyof AoiModeConditions> = [
    "leaf_conditions",
    "snow_conditions",
    "tide_conditions",
    "flow_conditions",
]

const getInputs = <T, >(spec: T): AoiInputType<T>[] => {
    if (spec instanceof Imagery) {
        return imagerySpecs as AoiInputType<T>[]
    } else if (spec instanceof PointCloud) {
        return ptCloudSpecs as AoiInputType<T>[]
    }
    return []
}

interface ModeViewProps {
    selectedMode: AcquisitionMode
    checked: boolean
}

export const ModeView = ({ selectedMode, checked }: ModeViewProps) => {
    const theme = useTheme()
    const disabled = !checked
    const aoi_mode = useAcquisitionModeStore(state => state.aoi_mode)
    // get mode from aoi_mode array or create a temporary empty mode to view
    const modeForm = aoi_mode.find(mode => mode.mode_id === selectedMode.mode_id) || ModeShape.createEmptyModeForm(selectedMode)

    const { mode_type, specs, ...mode } = modeForm

    return (
        <Box
            border={1}
            borderColor={"grey.300"}
            sx={{
                p: 4,
                height: "100%",
                backgroundColor: disabled ? "rgba(0, 0, 0, 0.05)" : theme.palette.background.paper,
            }}
        >
            <Label
                sx={{ textAlign: "center" }}
                color={disabled ? "textSecondary" : "textPrimary"}
            >
                {selectedMode.mode_name} Acquisition Mode Specifications
            </Label>
            {selectedMode.notes &&
                <TextField
                    label={"Acq Mode Notes"}
                    value={selectedMode.notes}
                    fullWidth
                    disabled
                    sx={{ mb: 4 }}
                />
            }
            <UnderlinedLabel paragraph disableGutter>General Specifications</UnderlinedLabel>
            <Grid
                container
                sx={{ display: "flex", alignItems: "center", jc: "space-between", mb: 4 }}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column", justifyItems: "flex-start" }}
                >
                    {conditions.map((field, idx) =>
                        <Field
                            key={idx}
                            label={snakeCaseToTitleCase(field)}
                            field={field}
                            value={mode[field]}
                            type={FieldTypes.autocomplete}
                            disabled={disabled}
                        />,
                    )}
                </Grid>
                <Grid item md sm xs>
                    <Field
                        label={"Custom Conditions"}
                        field={"custom_specs"}
                        value={mode["custom_specs"]}
                        type={FieldTypes.string}
                        disabled={disabled}
                        multiline
                        minRows={12}
                    />
                </Grid>
            </Grid>
            <UnderlinedLabel paragraph disableGutter>{mode_type} Specifications</UnderlinedLabel>
            <Grid container>
                {getInputs(specs)
                    .map((specField, idx, array) =>
                        <Grid
                            key={idx}
                            item
                            md={(specField.type === FieldTypes.number || specField.type === FieldTypes.boolean) ? 6 : 12}
                            sm={12}
                            sx={{ width: 1, pb: 2 }}
                        >
                            <ViewLabelAndValue
                                disabled={disabled}
                                value={specs[specField.field]}
                                {...specField}
                            />
                            {idx !== (array.length - 1) && <Divider />}
                        </Grid>,
                    )
                }
            </Grid>
        </Box>
    )
}

interface FieldProps {
    value: string | number | boolean | undefined
    field: string
    type: FieldTypes
    units?: (PPSUnits | LengthUnits)[]
    defaultUnitType?: (PPSUnits | LengthUnits)
    inputLabel?: string
    disabled?: boolean
    label?: string
    minRows?: number
    multiline?: boolean
    min?: number
    max?: number
    decimalCount?: number
}

const ViewLabelAndValue = ({ field, disabled, ...fieldProps }: FieldProps) => {
    const label = fieldProps.label ?? snakeCaseToTitleCase(field)
    const calculationType = fieldProps.type === FieldTypes.lengthCalc || fieldProps.type === FieldTypes.ppsCalc

    return (
        <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            columnSpacing={2}
        >
            {(fieldProps.type !== FieldTypes.string && fieldProps.type !== FieldTypes.autocomplete) &&
                <Grid item md={calculationType ? 12 : 6} sm={12}>
                    <Body disabled={disabled} disableGutter>{label}:</Body>
                </Grid>
            }
            <Grid item md sm>
                <Field label={label} field={field} disabled={disabled} {...fieldProps} />
            </Grid>
        </Grid>
    )
}

const Field = ({
    value,
    field,
    type,
    disabled,
    label,
    minRows,
    multiline,
    ...additionalProps
}: FieldProps) => {
    const updateSpec = useAcquisitionModeStore(state => state.updateSpec)
    const aoiSpecs = useAcquisitionModeStore(state => state.aoiSpecsCache)
    const { handleAOIDataChange } = useProjects()

    const commonFields = { field, disabled }

    const handleUpdate = (value: string | number | boolean | null) => {
        const aoi_mode = updateSpec(value, field)
        handleAOIDataChange(aoi_mode, "aoi_mode")
    }

    let fieldContent
    switch (type) {
        case FieldTypes.string:
            fieldContent = <TextField
                variant={"outlined"}
                value={value ?? ""}
                fullWidth
                label={label}
                minRows={minRows}
                multiline={multiline}
                onChange={(e) => handleUpdate(e.target.value)}
                onBlur={(e) => handleUpdate(e.target.value?.trim() ?? "")}
            />
            break
        case FieldTypes.autocomplete:
            fieldContent = <Autocomplete
                freeSolo
                fullWidth
                disableGutter
                options={aoiSpecs[field as keyof AoiSpecs] ?? []}
                value={value as string ?? null}
                TextFieldProps={{ minRows, multiline, label }}
                onChange={(_, value) => handleUpdate(value)}
                {...commonFields} />
            break
        case FieldTypes.number:
            fieldContent = <NumberField
                value={value}
                fullWidth
                disableArrows={disabled}
                onChange={handleUpdate}
                min={additionalProps.min}
                max={additionalProps.max}
                decimalCount={additionalProps.decimalCount}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{additionalProps.inputLabel}</InputAdornment>,
                }}
                {...commonFields} />
            break
        case FieldTypes.lengthCalc:
        case FieldTypes.ppsCalc:
            fieldContent =
                <NumberCalculationField
                    convertFunc={type === FieldTypes.lengthCalc ? convertLength : convertPPS}
                    value={value}
                    decimalCount={additionalProps.decimalCount ?? 2}
                    defaultType={additionalProps.defaultUnitType!}
                    convertUnits={additionalProps.units ?? []}
                    onChange={handleUpdate}
                    {...commonFields} />
            break
        case FieldTypes.boolean:
            fieldContent = <Checkbox
                checked={!!value as unknown as boolean}
                onChange={(e) => handleUpdate(e.target.checked)}
                {...commonFields} />
            break
        default:
            throw new Error(`Field ${field} of type ${type} is not handled.`)
    }
    return <Box sx={{ p: 2, pl: 0 }}>{fieldContent}</Box>
}
