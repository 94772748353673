import {
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableRow,
    TableRowProps,
    useTheme,
    TableHead,
} from "@mui/material"
import { TableColumn } from "../../models/Table"

interface CustomTableCellProps extends TableCellProps {
    header?: boolean
    sticky?: boolean
    column?: TableColumn
    TableRowProps?: TableRowProps
}

const CustomTableCell = ({
    sticky,
    header,
    sx = [],
    children,
    column,
    TableRowProps,
    ...props
}: CustomTableCellProps) => {
    const theme = useTheme()

    return (
        <TableCell
            {...props}
            sx={[
                ...(Array.isArray(sx) ? sx : [sx]),
                sticky && {
                    p: 0,
                    position: "sticky",
                    left: 0,
                    width: "auto",
                    zIndex: theme.zIndex.mobileStepper,
                    backgroundColor: theme.palette.background.paper,
                    borderRight: "1px solid rgba(224,224,224,1)",
                },
                (sticky && header) && {
                    zIndex: theme.zIndex.mobileStepper + 1,
                },
                column && {
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth,
                },
                { whiteSpace: "nowrap" },
            ]}
        >
            {!sticky && children}
            {sticky &&
                <Table size="small" sx={{display: "inline-flex"}}>
                    {header && <TableHead><CustomTableRow {...TableRowProps}>{children}</CustomTableRow></TableHead>}
                    {!header && <TableBody><CustomTableRow {...TableRowProps}>{children}</CustomTableRow></TableBody>}
                </Table>
            }
        </TableCell>
    )
}

const CustomTableRow = ({children, ...props}: TableRowProps) => {
    return <TableRow
        sx={{ "& > td, & > th": { border: "none" } }}
        {...props}
    >
        {children}
    </TableRow>
}

export default CustomTableCell
