import { Grid, GridSize, TextField, TextFieldProps, useMediaQuery } from "@mui/material"

import { Label } from "../../components/Typography"

import useAuth from "../../hooks/useAuth"
import useMissions from "../../hooks/useMissions"
import useStyles from "./styles"
import { Theme } from "@mui/material/styles"
import { MissionType } from "../../store/types/missionsTypes"

type NoteFieldType = {
    id: keyof MissionType
    label: string
    grid?: GridSize
    helperText?: string
    disabled?: boolean
}

const MissionNotes = () => {
    const { classes, cx } = useStyles()
    const { ToolbarOptions, checkToolbarAccess } = useAuth()
    const { missions, handleMissionDataChange } = useMissions()
    const { selectedMission } = missions

    const isWidthUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))

    const commonNoteFields: Partial<TextFieldProps> = {
        multiline: true,
        fullWidth: true,
        variant: "outlined",
        minRows: 4,
    }

    const noteFields: NoteFieldType[] = [
        { id: "flight_notes", label: "General Notes", helperText: "Required" },
        { id: "error_notes", label: "Error Notes", grid: 6 },
        { id: "error_action", label: "Action Taken", grid: 6 },
        { id: "tomorrow_notes", label: "Tomorrow's Plan", helperText: "Required" },
        {
            id: "flight_blog",
            label: "Add Blog Entry (Managers Only)",
            disabled: !checkToolbarAccess([ToolbarOptions.Edit_Blog_Entries, ToolbarOptions.Mission_Admin]),
        },
    ]

    return (
        <Grid>
            <Label>Mission Notes</Label>
            <Grid
                container
                spacing={3}
                direction={isWidthUp ? "row" : "column"}
            >
                {noteFields.map((note) => {
                    const { helperText, id, label, grid } = note
                    // sets value as string if doesn't exist
                    let value = selectedMission[id] ?? ""

                    const isFlightNotes = id === "flight_notes"
                    let sensorNote: string | undefined

                    if (isFlightNotes) {
                        // noteArray is saved
                        const noteArray = typeof value === "string" ? value.split("---") : []
                        value = noteArray[0] ?? ""
                        sensorNote = noteArray[1] ?? ""
                    }

                    return (
                        <Grid key={label} item md={grid ?? 12}>
                            <TextField
                                value={value}
                                onChange={(e) => {
                                    handleMissionDataChange({ [id]: addSensorNotes(e.target.value, sensorNote) })
                                }}
                                onBlur={(e) => {
                                    handleMissionDataChange({ [id]: addSensorNotes(e.target.value?.trim(), sensorNote) })
                                }}
                                className={cx(helperText && classes.editorNotesHelperText)}
                                {...note}
                                {...commonNoteFields}
                            />
                            {isFlightNotes &&
                                <TextField
                                    sx={{ mt: 2 }}
                                    value={sensorNote}
                                    disabled={true}
                                    label="Sensor Notes"
                                    {...commonNoteFields}
                                />
                            }
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

const addSensorNotes = (note: string, sensorNote?: string) => note + (sensorNote ? "---" + sensorNote : "")

export default MissionNotes
