import configureMeasurements, { area, length, Measure, Unit } from "convert-units"
import { DistanceUnits, HeightUnits } from "../LocalStorageUtil"

/**
 * Display unit abstraction functions.
 * Separate convert variables for TS
 */

export type PPSSystems = "PPSMeasureSystem"
export type PPSUnits = "ppsm" | "ppsf"

const PPSMeasureSystem: Record<PPSUnits, Unit> = {
    ppsm: {
        name: {
            singular: "ppsm",
            plural: "ppsm",
        },
        to_anchor: 1,
    },
    ppsf: {
        name: {
            singular: "ppsf",
            plural: "ppsf",
        },
        to_anchor: 0.3048 * 0.3048,
    },
}

const customMeasure: Measure<PPSSystems, PPSUnits> = {
    systems: { PPSMeasureSystem },
}

export const convertPPS = configureMeasurements({ customMeasure })
export const convertLength = configureMeasurements({ length })
export const convertArea = configureMeasurements({ area })

export const convertDistance = (distance_m: number, distanceUnits: DistanceUnits | HeightUnits) => {
    return parseFloat(convertLength(distance_m).from("m").to(distanceUnits).toFixed(2))
}

export const getDistanceUnits = () => {
    const distanceList = Object.keys(DistanceUnits)
    return convertLength().list("length").filter(u => distanceList.includes(u.abbr))
}

export const getHeightUnits = () => {
    const distanceList = Object.keys(HeightUnits)
    return convertLength().list("length").filter(u => distanceList.includes(u.abbr))
}
