import { useState, useEffect } from "react"
import { Button, Checkbox, Collapse, FormControlLabel, Grid, TextField, Tooltip, Typography } from "@mui/material"

import Dropzone from "../../components/Fields/Dropzone"
import LoadingButton from "../../components/LoadingButton"
import { Label } from "../Typography"

import { parseAOIFile } from "../../store/actions/projectsActions"
import useStyles from "./styles"
import useProjects from "../../hooks/useProjects"
import { Types } from "../../store/types"

function AOIUpload() {
    const { classes, cx } = useStyles()

    const { selectedAOIData, doPolygonRepairs, loadingAOI, parsingAOI, isAOIOpen, dispatch } = useProjects()

    const [files, setFiles] = useState<File[]>([])
    const [dropzoneOpen, setDropzoneOpen] = useState(false)

    useEffect(() => {
        if (!isAOIOpen && (dropzoneOpen || files.length > 0)) {
            setFiles([])
            setDropzoneOpen(false)
        }
    }, [isAOIOpen]) // eslint-disable-line

    useEffect(() => {
        if (loadingAOI) {
            setDropzoneOpen(false)
        }
    }, [loadingAOI])

    const handleFileSelect = (newFiles: File[]) => {
        setFiles(newFiles)
    }

    const handleFileUpload = async () => {
        if (files.length > 0) {
            const success = await parseAOIFile(dispatch, files[0], doPolygonRepairs)
            if (success) {
                handleCancelUpload()
            }
        } else {
            setDropzoneOpen(true)
        }
    }

    const handleCancelUpload = () => {
        setFiles([])
        setDropzoneOpen(false)
    }

    const toggleReplacePolygon = () => {
        dispatch({ type: Types.Projects_TogglePolygonRepairs })
    }

    return (
        <Grid>
            <Label>Source File</Label>
            <Grid>
                <TextField
                    disabled
                    label="File Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    helperText="Required"
                    value={selectedAOIData.source_file || ""}
                    className={cx(classes.gutterBottom__md, classes.input__lg)}
                />
            </Grid>
            <Collapse in={dropzoneOpen}>
                {dropzoneOpen && ( // reset controlled files on close
                    <>
                        <Dropzone
                            acceptedFiles={[".kml", ".kmz", ".zip"]}
                            filesLimit={1}
                            maxFileSize={5e7}
                            onChange={handleFileSelect}
                            classes={{ root: classes.dropzone_container }}
                        />
                        <Typography className={classes.gutterBottom__md} variant="subtitle2">
                            Supported file formats include KML, KMZ, and SHP. Shapefiles must be compressed into a ZIP to ensure that the necessary PRJ and
                            other accompanying files are also included.
                        </Typography>
                    </>
                )}
            </Collapse>
            <Grid className={classes.gutterBottom__md}>
                <LoadingButton
                    disabled={loadingAOI || (dropzoneOpen && !files[0])}
                    pending={parsingAOI}
                    variant="contained"
                    color="primary"
                    onClick={handleFileUpload}
                >
                    {dropzoneOpen ? "Parse File" : selectedAOIData.source_file ? "Replace Source File" : "Upload Source File"}
                </LoadingButton>
                {dropzoneOpen && (
                    <>
                        <Button
                            disabled={loadingAOI || parsingAOI}
                            variant="outlined"
                            color="primary"
                            onClick={handleCancelUpload}
                            className={classes.cancel_btn}
                        >
                            Cancel
                        </Button>

                        <Tooltip
                            arrow
                            enterDelay={250}
                            title={
                                <span>
                                    <span>Attempt to repair invalid geometries, causing very minor distortions to the polygons.</span>
                                    <br />
                                    <br />
                                    <span>
                                        First, the parser will attempt to buffer the polygons by 0.0. If that fails, the parse will then
                                        attempt to repair the polygons by buffering and unbuffering them by 0.01.
                                    </span>
                                </span>
                            }
                        >
                            <FormControlLabel
                                disabled={parsingAOI}
                                control={
                                    <Checkbox
                                        checked={doPolygonRepairs}
                                        onChange={toggleReplacePolygon}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Repair Polygons"
                                sx={{ ml: 2 }}
                            />
                        </Tooltip>
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default AOIUpload
