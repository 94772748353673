import { Container, CircularProgress, Grid, Button } from "@mui/material"

import useStyles from "./styles"
import { Body, Label } from "../../components/Typography"

interface LoadingScreenProps {
    body?: string
    button?: string
    label: string
    onButtonClick?: any
}

const LoadingScreen = ({ body, button, label, onButtonClick }: LoadingScreenProps) => {
    const { classes } = useStyles()

    return (
        <Container maxWidth="xs" className={classes.root}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
                className={classes.wrapper}
            >
                {!body ? (
                    <>
                        <Grid className={classes.gutterBottom__sm}>
                            <CircularProgress />
                        </Grid>
                        {label && (
                            <Grid>
                                <Body>{label}</Body>
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Label gutterBottom="sm">{label}</Label>
                        <Body gutterBottom="md">{body}</Body>
                        {button && onButtonClick && (
                            <Button variant="contained" color="primary" onClick={onButtonClick}>
                                {button}
                            </Button>
                        )}
                    </>
                )}
            </Grid>
        </Container>
    )
}

LoadingScreen.defaultProps = {
    label: "Loading...",
}

export default LoadingScreen
