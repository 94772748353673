import { QueryKeys } from "./QueryKeys"
import { useQuery } from "react-query"
import API from "./index"
import { AcquisitionMode, AoiSpecs } from "../models/AcquisitionMode"

export const useFindAcquisitionModes = () => {
    return useQuery([QueryKeys.FIND_ACQUISITION_MODES], async () => {
        const result: { data: AcquisitionMode[] } = await API.get("/acquisition-modes")
        return result.data
    })
}


export const useFindAcquisitionModesAoiSpecs = () => {
    return useQuery([QueryKeys.FIND_ACQUISITION_MODES_AOI_SPECS], async () => {
        const result: { data: AoiSpecs } = await API.get("/acquisition-modes/aoi-specs")
        return result.data
    })
}

export const useFindAcquisitionModesForFlightPlan = (flightPlanId: number, enabled?: boolean) => {
    return useQuery(
        [QueryKeys.FIND_ACQUISITION_MODES_FOR_FLIGHT_PLAN, flightPlanId],
        async () => {
            const result = await API.get(`/flight-plan-acquisition-modes/${flightPlanId}`)
            const flightPlanModeIds: number[] = result.data
            return flightPlanModeIds
        },
        // We are not using a react-query mutation to register the change, so cache time should be 0 since we don't want stale results.
        { enabled: enabled !== false, cacheTime: 0 },
    )
}
