import { Grid, InputAdornment } from "@mui/material"
import NumberField, { NumberFieldProps } from "./NumberField"
import { countDecimals, roundDecimals } from "../../tools"
import { Converter } from "convert-units"
import { ConvertToDefault, ConvertToUnit } from "../../models/NumberCalculations"

interface NumberCalculationProps<TMeasures extends string, TSystems extends string, TUnits extends string> extends Omit<NumberFieldProps, "field"> {
    defaultType: TUnits
    convertFunc: (value?: number | undefined) => Converter<TMeasures, TSystems, TUnits>
    convertUnits: TUnits[]
    decimalCount?: number
    onChange: (value: number | null) => void
}

/**
 *     Calculates numbers between different units
 *
 *     @param defaultType Unit type from convert-units
 *     @param convertFunc the function in which the convert calculation field will use
 *     @param props props for NumberCalculationBase
 *     @param convertUnits array of unit types
 *     @param value
 *     @param onChange function (value: number | null) => void
 *     @param decimalCount number
 **/
export const NumberCalculationField = <TMeasures extends string, TSystems extends string, TUnits extends string>({
    convertUnits,
    value,
    onChange,
    decimalCount = 1,
    defaultType,
    convertFunc,
    ...props
}: NumberCalculationProps<TMeasures, TSystems, TUnits>) => {
    const validUnits = convertFunc().list().map(u => u.abbr)

    if(!validUnits.includes(defaultType)) {
        throw new Error(`Calculation field cannot use ${defaultType} as a default value as it does not exist as a valid conversion unit in ${validUnits}`)
    }

    convertUnits.forEach(unit => {
        if(!validUnits.includes(unit)) {
            throw new Error(`Calculation field cannot use ${unit} in the convert units field as it does not exist as a valid conversion unit in ${validUnits}`)
        }
    })

    const convertToUnit: ConvertToUnit<TUnits> = (value, type) => convertFunc(value ?? 0).from(defaultType).to(type)
    const convertToDefault: ConvertToDefault<TUnits> = (value, from) => convertFunc(value ?? 0).from(from).to(defaultType)
    const getLabel = (unit: TUnits) => convertFunc().getUnit(unit)?.unit.name.plural.toLowerCase()

    const units = convertUnits.map(unit => {
        const val = value != null ? convertToUnit(value as number, unit) : null
        return { value: val, type: unit }
    })

    const handleChange = (value: number | null, from: TUnits) => {
        if (value == null) {
            onChange(null)
            return
        }

        // number
        if (countDecimals(value) > decimalCount) {
            return
        }

        onChange(convertToDefault(value, from))
    }

    return <Grid container spacing={2}>
        {units.map((unit, idx) =>
            <Grid item md sm={12} xs={12} key={idx}>
                <NumberField
                    disableArrows
                    fullWidth
                    field={unit.type as unknown as string}
                    value={unit.value != null ? roundDecimals(unit.value, decimalCount) : null}
                    onChange={(value) => handleChange(value, unit.type)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{getLabel(unit.type)}</InputAdornment>,
                    }}
                    {...props}
                />
            </Grid>
        )}
    </Grid>
}
