import { Grid, TextField } from "@mui/material"

import { Label } from "../../components/Typography"

import useProjects from "../../hooks/useProjects"
import useStyles from "./styles"
import { AllowedAccess } from "../../store/types/projectsTypes"
import CustomSelect from "../../components/Fields/Select"
import React from "react"

const currentYear = new Date().getFullYear()

function ProjectTab() {
    const { classes, cx } = useStyles()
    const { selectedProjectData, handleProjectDetailsChange } = useProjects()

    const projectDeltekDetails: { label: string, value?: string | number }[] = [
        { label: "Client", value: selectedProjectData?.client_name },
        { label: "Account Manager", value: selectedProjectData?.am_employee?.employee_full_name },
        { label: "Project Manager", value: selectedProjectData?.pm_employee?.employee_full_name },
    ]

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Label>Project Details</Label>
                <TextField
                    label="Description"
                    multiline
                    minRows={4}
                    fullWidth
                    variant="outlined"
                    helperText="Required"
                    value={selectedProjectData?.project_detail?.description || ""}
                    onChange={(e) => handleProjectDetailsChange(e.target.value || null, "description")}
                />
            </Grid>
            <Grid item sm={12}>
                <Label>Project Info (Deltek)</Label>
                <Grid container>
                    <Grid item md={6} xs={12}>
                        {projectDeltekDetails.map((detail, idx) =>
                            <TextField
                                key={idx}
                                label={detail.label}
                                value={detail.value ?? ""}
                                disabled
                                variant="outlined"
                                className={cx(classes.input__md, classes.gutterBottom__lg)}
                            />
                        )}
                    </Grid>
                    <Grid item md>
                        <TextField
                            label="Fiscal Year"
                            variant="outlined"
                            type="number"
                            name="fiscal_year"
                            value={selectedProjectData?.project_detail?.fiscal_year ?? currentYear}
                            onChange={(e) => handleProjectDetailsChange(e.target.value, e.target.name)}
                            onBlur={e => {
                                const { min, max } = e.target as HTMLInputElement
                                let { value } = e.target

                                if (parseInt(value) < parseInt(min)) {
                                    value = min
                                } else if (parseInt(value) > parseInt(max)) {
                                    value = max
                                }

                                handleProjectDetailsChange(value, e.target.name)
                            }}
                            InputProps={{ inputProps: { min: "2001", max: currentYear + 1, step: "1" } }}
                            className={cx(classes.input__sm, classes.gutterBottom__lg)}
                        />
                        <CustomSelect
                            fullWidth
                            label="Allowed Access"
                            disableDefaultValue
                            value={selectedProjectData?.project_detail?.allowed_access ?? ""}
                            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                handleProjectDetailsChange(e.target.value as string, "allowed_access")
                            }
                            options={Object.keys(AllowedAccess)}
                            helperText="Other: Please provide more information in the description"
                            className={cx(classes.input__sm)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProjectTab
