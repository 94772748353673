import { Collapse, Divider, Grid, TextField, LinearProgress } from "@mui/material"

import Select from "../../components/Fields/Select"
import { Label, PrimaryLabel } from "../../components/Typography"

import AOISelect from "../../components/AOI/AOISelect"
import AOICalculations from "../../components/AOI/AOICalculations"
import AOIUpload from "../../components/AOI/AOIUpload"

import { AOI_TYPES } from "../../store/types/projectsTypes"
import useProjects from "../../hooks/useProjects"
import useStyles from "./styles"
import { useFindAcquisitionModes, useFindAcquisitionModesAoiSpecs } from "../../api/AcquisitionModeApi"
import { ModeTabs } from "../../components/Modes/ModeTabs"

function AOITab() {
    const { classes, cx } = useStyles()

    const { selectedAOIData, isAOIOpen, loadingAOI, handleAOIDataChange } = useProjects()

    const acquisitionModesResponse = useFindAcquisitionModes()
    const aoiSpecsResponse = useFindAcquisitionModesAoiSpecs()

    // This should already be loaded and the StepperForm should show its loading bar, so this loading message should not
    // be able to show
    const loading = acquisitionModesResponse.isLoading || aoiSpecsResponse.isLoading

    if (loading) {
        return <LinearProgress sx={{ mb: 2, mt: 2 }} />
    }

    return (
        <Grid>
            <AOISelect />
            <Divider className={classes.gutterBottom__md} />

            <Collapse in={isAOIOpen} timeout={{ enter: 800, exit: 400 }}>
                <Grid>
                    <PrimaryLabel>AOI Attributes</PrimaryLabel>

                    <Grid className={classes.gutterBottom__md}>
                        <TextField
                            disabled={loadingAOI}
                            label="AOI Name"
                            variant="outlined"
                            helperText="Required"
                            value={selectedAOIData.name || ""}
                            onChange={(event) => handleAOIDataChange(event.target.value, "name")}
                            className={classes.input__md}
                        />
                    </Grid>

                    <AOIUpload />
                    <AOICalculations />

                    <Label gutterBottom="sm">AOI Type</Label>
                    <Select
                        disabled={loadingAOI}
                        width="md"
                        helperText="Required"
                        value={selectedAOIData?.aoi_type || ""}
                        options={AOI_TYPES}
                        onChange={(event: any) => handleAOIDataChange(event.target.value || null, "aoi_type")}
                    />

                    {isAOIOpen && <ModeTabs modeSelectionDisabled={loadingAOI} modes={acquisitionModesResponse.data!} aoiSpecs={aoiSpecsResponse.data!} />}

                    <Label gutterBottom="sm">AOI Notes</Label>
                    <TextField
                        disabled={loadingAOI}
                        multiline
                        minRows={4}
                        fullWidth
                        variant="outlined"
                        helperText="Required"
                        value={selectedAOIData?.notes || ""}
                        onChange={(event: any) => handleAOIDataChange(event.target.value, "notes")}
                        className={cx(classes.gutterBottom__lg)}
                    />
                </Grid>
            </Collapse>
        </Grid>
    )
}

export default AOITab
