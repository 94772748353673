import { useParams } from "react-router"
import { useContext, useEffect, useRef, useState } from "react"

import { Grid, TextField, Button, FormControlLabel, Switch } from "@mui/material"

import { DatePickerCustom } from "../../components/Fields/DatePickerCustom"
import EmployeeField from "../../components/Fields/EmployeeField"
import { Label } from "../../components/Typography"

import FlightlineImport from "./FlightlineImport"
import FlightlineMatchingExpression from "./FlightlineMatchingExpression"
import FlightlineTable from "./FlightlineTable"

import useAuth from "../../hooks/useAuth"
import useMissions from "../../hooks/useMissions"
import { formatDate, getMatchingPlannedLineId, parseDate } from "../../tools"
import useStyles from "./styles"
import { RootContext } from "../../store/context"
import { Types } from "../../store/types"
import { FlightlineEventType, OptionsType } from "../../store/types/missionsTypes"
import MultipleFlightPlanSelector from "../../components/MultipleFlightPlanSelector"
import DataGridModal from "../../components/Modals/DataGridModal"
import useToggle from "../../hooks/useToggle"
import CollectionEventEditor from "../../components/CollectionEventEditor"
import Drawer from "../../components/Drawer"
import { useCollectionEventEditorStore } from "../../components/CollectionEventEditor/CollectionEventEditorStore"
import { GridColDef } from "@mui/x-data-grid"
import { FlightplanType } from "../../store/types/flightplansTypes"

export default function FlightlineDetails() {
    const { classes } = useStyles()
    const { missionFlow } = useParams<{ [key: string]: string }>()
    const { ToolbarOptions, checkToolbarAccess } = useAuth()
    const { missions, isNoFile, isFullFlight, handleMissionReviewChange, handleSelectFlightplanIds } = useMissions()
    const { state, dispatch } = useContext(RootContext)
    const { employeeList, selectedMission, flightPlannedLines } = missions

    // Saved separately first so the flightline events are only updated after user confirms flightplan changes
    // and only saved to redux state on close of flightplan modal selection
    const [userSelectedFlightPlanIds, setUserSelectedFlightPlanIds] = useState<number[]>([])
    // Load everything before we stop showing the loading bar
    const [optionsModalOpen, toggleOptionsModalOpen] = useToggle()
    const newoptionsFlightplanList = useRef<OptionsType[]>([])
    const [flightlineCreatorDrawerOpen, toggleFlightlineCreatorDrawerOpen] = useToggle()
    const setEventCreator = useCollectionEventEditorStore(state => state.setValuesFromFlightline)

    const [canSelectInactiveFlightplans, setCanSelectInactiveFlightplans] = useState(false)

    const isSubmitFlow = missionFlow === "submit"

    const columns: GridColDef<(OptionsType[])>[] = [
        { field: "id", headerName: "Plan ID", type: "number", minWidth: 130, flex: 2 },
        { field: "flightplan_name", headerName: "Plan Name", minWidth: 190, flex: 5 },
        { field: "status", headerName: "Status", minWidth: 150, flex: 3 },
    ]

    const manageFlightPlans = () => {
        toggleOptionsModalOpen()
    }

    const handleoptionsFlightplanUpdate = (ids: number[]) => {
        setUserSelectedFlightPlanIds(ids)
    }

    const handleOptionsModalClose = async () => {
        handleSelectFlightplanIds(userSelectedFlightPlanIds)

        // update frontend with new list of flightplans.
        const selectedFlightplans: FlightplanType[] = state.missions.flightplanList.flatMap((plan) => {
            if (plan.flight_plan_id !== null && userSelectedFlightPlanIds.includes(plan.flight_plan_id)) {
                return plan
            }
            return []
        })

        // Save selected flightplans to state
        dispatch({
            type: Types.Missions_SelectFlightplans,
            payload: { selectedFlightplans },
        })

        const updatedFlightlineEvents: FlightlineEventType[] = []

        const selectedFlightPlannedLines = flightPlannedLines
            .filter(fpl => fpl.flight_plan_id && userSelectedFlightPlanIds.includes(fpl.flight_plan_id))

        // update events if their assigned flight plan was added or removed
        state.missions.flightlineEvents.forEach((event) => {
            const newEvent = getMatchingPlannedLineId(event, selectedFlightPlannedLines)

            updatedFlightlineEvents.push(newEvent)
        })
        dispatch({
            type: Types.Missions_UpdateFlightlineEventData,
            payload: { rows: updatedFlightlineEvents },
        })
        toggleOptionsModalOpen()
    }

    const handleFlightLineCreator = (line?: FlightlineEventType) => {
        setEventCreator(line)
        toggleFlightlineCreatorDrawerOpen()
    }

    useEffect(() => {
        newoptionsFlightplanList.current = state.missions.flightplanList.map((plan) => ({
            id: plan.flight_plan_id,
            flightplan_name: plan.flight_plan_name ? plan.flight_plan_name : "",
            label: "Flight Plan " + plan.flight_plan_id,
            status: plan.status,
        }))

        const _selected: number[] = []
        state.missions.selectedFlightplans.forEach((plan) => {
            _selected.push(plan.flight_plan_id!)
        })

        setUserSelectedFlightPlanIds(_selected)
        handleSelectFlightplanIds(_selected)
    }, [])

    const disableReviewFields = !checkToolbarAccess(ToolbarOptions.Flightline_QC_Review)

    const handleDate = (date: moment.Moment | null) => {
        const formattedDate = formatDate(date)
        handleMissionReviewChange({ review_date: formattedDate === "Invalid date" ? null : formattedDate })
    }

    return (
        <Grid>
            <Label>Lines Flown</Label>
            <Grid className={classes.gutterBottom__lg}>
                <MultipleFlightPlanSelector
                    plans={newoptionsFlightplanList.current}
                    selectedPlans={state.missions?.selectedFlightplans}
                    manageFlightPlans={manageFlightPlans}
                />
            </Grid>

            {isSubmitFlow && isFullFlight && <FlightlineMatchingExpression />}
            {isSubmitFlow && isNoFile && <FlightlineImport />}

            <FlightlineTable handleFlightLineCreator={handleFlightLineCreator} />
            {!(isSubmitFlow && isNoFile) &&
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => handleFlightLineCreator()}
                    sx={{ mb: 4 }}
                >
                    Add Additional Flightlines
                </Button>
            }

            <Label>Mission Review (Managers Only)</Label>
            <DatePickerCustom
                label="Review Date"
                value={
                    selectedMission?.mission_review?.review_date ? parseDate(selectedMission?.mission_review?.review_date) : null
                }
                onChange={handleDate}
                disabled={disableReviewFields}
                gutterBottom="md"
                required={!!selectedMission.mission_review?.reviewer_id}
            />
            <EmployeeField
                label="Reviewer"
                field="reviewer_id"
                employeeId={selectedMission?.mission_review?.reviewer_id}
                employeeRole="reviewers"
                employeeList={employeeList}
                dispatch={dispatch}
                required={!!selectedMission.mission_review?.review_date}
                isReview
                width="md"
                disabled={disableReviewFields}
            />
            <Grid>
                <TextField
                    label="Notes"
                    value={selectedMission?.mission_review?.reviewer_notes || ""}
                    onChange={(e) => handleMissionReviewChange({ reviewer_notes: e.target.value })}
                    multiline
                    minRows={4}
                    variant="outlined"
                    fullWidth
                    className={classes.gutterBottom__md}
                    disabled={disableReviewFields}
                />
            </Grid>
            <DataGridModal
                children={<></>}
                columns={columns}
                open={optionsModalOpen}
                title={"Manage Flight Plans"}
                rows={newoptionsFlightplanList.current}
                selected={userSelectedFlightPlanIds}
                onConfirm={handleOptionsModalClose}
                confirmText={"OK"}
                danger={false}
                onClose={handleOptionsModalClose}
                onSelectionChange={handleoptionsFlightplanUpdate}
                isRowSelectableFunct={(params) => canSelectInactiveFlightplans || params.row.status === "Active" || userSelectedFlightPlanIds.includes(params.row.id)}
                additionalActions={
                    <FormControlLabel
                        label="Unlock All Flightplans"
                        control={
                            <Switch
                                checked={canSelectInactiveFlightplans}
                                onChange={() => setCanSelectInactiveFlightplans(!canSelectInactiveFlightplans)}
                            />
                        }
                    />
                }
            />
            <Drawer
                open={flightlineCreatorDrawerOpen}
                title="Manage A Flight Line"
                anchor="right"
                onClose={() => handleFlightLineCreator()}
            >
                <CollectionEventEditor toggleCollectionEventDrawerOpen={handleFlightLineCreator} />
            </Drawer>
        </Grid>
    )
}
