import { flattenObj } from "../../tools"
import { FlightlineEventType, MissionType, missionUtils } from "../../store/types/missionsTypes"
import { ValidatorModel } from "../../validation"
import { unplannedLineValue } from "./FlightlineTable"
import { MissionStep } from "./index"

export const teamRadioOptions = [
    { id: "nv5", label: "NV5 Acquisition Team", value: false },
    { id: "vendor", label: "Non-NV5 Acquisition Team", value: true },
]

export const missionDisplayNames = {
    active_hobbs: "Online Hobbs",
    backup_drive_name: "Main Backup Drive",
    base_airport: "Base Airport",
    delivery_drive_name: "Shipped Drive Name",
    error_action: "Action Taken",
    error_notes: "Error Notes",
    flight_blog: "Add Blog Entry",
    flightlineEvents: "Flightline Events",
    flight_notes: "General Notes",
    flight_num: "Flight Number",
    flown_date: "Activity Date",
    manager_id: "Manager On Call",
    mob_hobbs: "Mob Hobbs",
    office_id: "Target Office",
    raw_storage_size: "Storage Size",
    operator_1_id: "Sensor Operator 1",
    operator_2_id: "Sensor Operator 2",
    pilot_1_id: "Pilot 1",
    pilot_2_id: "Pilot 2",
    plane_drive_name: "Plane Drive Name",
    platform_id: "Aircraft/Platform",
    sensor_id_1: "Primary Sensor",
    sensor_id_2: "Secondary Sensor",
    shipping_number: "Shipping Number",
    start_hobbs: "Start Hobbs",
    start_time: "Wheels Up",
    stop_hobbs: "Stop Hobbs",
    duration: "Duration",
    tomorrow_notes: "Tomorrow's Plan",
    review_date: "Review Date",
    reviewer_id: "Reviewer",
    reviewer_notes: "Reviewer Notes",
    vendor_name: "Acquisition Team Name",
}

// vendor == non-nv5 acquisition team
export const getMissionValidatorModel = (
    selectedMission: MissionType,
    flightlineEvents: FlightlineEventType[],
    activeFlow: string | null,
    isVendorFlow: boolean,
): ValidatorModel => {
    const mission = flattenObj({ ...selectedMission, flightlineEvents })
    mission.duration = missionUtils.calculateStartAndEndDateTimes(selectedMission).duration

    // vendors vs NV5
    const conditional_team_fields = isVendorFlow
        ? ["vendor_name"]
        : ["platform_id", "operator_1_id", "pilot_1_id", "base_airport"]
    const conditional_flight_data_fields = isVendorFlow
        ? []
        : ["start_time", "duration", "start_hobbs", "stop_hobbs", "active_hobbs"]

    // mission types
    let conditional_acquisition_fields = ["plane_drive_name", "backup_drive_name", "delivery_drive_name", "office_id", "raw_storage_size"]
    if (activeFlow === "no-acquisition") {
        conditional_acquisition_fields = []
    } else if (isVendorFlow) {
        conditional_acquisition_fields = ["office_id"]
    }

    const conditional_flightlineEvents_fields = ["flight_status", "planned_line_id"]
    const conditional_flightlineEvents_message = "Name, and status fields cannot be empty"

    // Review conditionals
    const conditional_reviewer_required =
        selectedMission.mission_review?.review_date ? ["reviewer_id"] : []

    const conditional_reviewDate_required =
        selectedMission.mission_review?.reviewer_id ? ["review_date"] : []

    return {
        model: mission,
        display_names: missionDisplayNames,
        required_fields: [
            "sensor_id_1",
            "flown_date",
            ...conditional_team_fields,
            "manager_id",
            "flight_notes",
            "tomorrow_notes",
            "flight_num",
            ...conditional_flight_data_fields,
            ...conditional_acquisition_fields,
            "flightlineEvents",
            "reviewer_id",
            ...conditional_reviewer_required,
            ...conditional_reviewDate_required,
        ],
        unique_fields: [
            {
                name: "flightlineEvents",
                isError: validateUnplannedEventsIgnored(flightlineEvents),
                text: "An unplanned line's status must be Ignored.",
            },
        ],
        custom_validation_fields: [
            {
                name: "error_notes",
                validation: "regex",
                value: /^[\S\s]{0,65000}$/,
                text: "65000 character limit",
            },
            {
                name: "error_action",
                validation: "regex",
                value: /^[\S\s]{0,65000}$/,
                text: "65000 character limit",
            },
            {
                name: "tomorrow_notes",
                validation: "regex",
                value: /^[\S\s]{0,65000}$/,
                text: "65000 character limit",
            },
            {
                name: "flight_blog",
                validation: "regex",
                value: /^[\S\s]{0,65000}$/,
                text: "65000 character limit",
            },
            {
                name: "duration",
                validation: "relationship",
                value: "> 0",
                text: "Stop date and time must be after start date and time.",
            },
            {
                name: "stop_hobbs",
                validation: "relationship",
                value: "> start_hobbs",
                text: `Value must be greater than ${missionDisplayNames.start_hobbs}`,
            },
            {
                name: "mob_hobbs",
                validation: "relationship",
                value: ">= 0",
                text: "Value cannot be negative (Online > Stop - Start)",
            },
            {
                name: "raw_storage_size",
                validation: "relationship",
                value: "> 0",
                text: "Value must be greater than 0",
            },
            {
                name: "flightlineEvents",
                validation: "required_in_array",
                value: conditional_flightlineEvents_fields,
                text: conditional_flightlineEvents_message,
            },
            {
                name: "flight_notes",
                validation: "required_in_array",
                value: "split",
                text: "General Notes cannot be empty",
            },
            {
                name: "review_date",
                validation: "requires_other_field_exists",
                value: "reviewer_id",
                text: "Field cannot be empty if reviewer exists",
            },
            {
                name: "reviewer_id",
                validation: "requires_other_field_exists",
                value: "review_date",
                text: "Field cannot be empty if review date exists",
            },
        ],
        groups: {
            [MissionStep.teamIdentification]: [
                "sensor_id_1",
                "flown_date",
                "platform_id",
                "operator_1_id",
                "pilot_1_id",
                "manager_id",
                "vendor_name",
                "base_airport",
            ],
            [MissionStep.notes]: ["flight_notes", "error_notes", "error_action", "tomorrow_notes", "flight_blog"],
            [MissionStep.flightData]: [
                "flight_num",
                "start_time",
                "duration",
                "start_hobbs",
                "stop_hobbs",
                "active_hobbs",
                "mob_hobbs",
                "plane_drive_name",
                "backup_drive_name",
                "delivery_drive_name",
                "office_id",
                "raw_storage_size",
            ],
            [MissionStep.flightlineDetails]: [
                "flightlineEvents",
                ...conditional_reviewDate_required,
                ...conditional_reviewer_required,
            ],
        },
    }
}

const validateUnplannedEventsIgnored = (events: FlightlineEventType[]): boolean => {
    return !!events.find(line => line.planned_line_id === unplannedLineValue && line.flight_status !== "Ignored")
}
