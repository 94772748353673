import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    // common
    background: {
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "inline-block",
    },
    placeholder: {
        color: theme.palette.text.secondary,
    },

    // specific
    select: {
        backgroundColor: theme.palette.background.paper,
    },
    dropzone: {
        transition: "0.08s background-color ease-out",

        "&:focus": {
            outlineColor: theme.palette.primary.main,
        },

        "&.MuiDropzoneArea-root": {
            animation: "none",
            backgroundImage: "none",
            backgroundColor: theme.palette.grey[200],
        },

        "& .MuiDropzoneArea-text": {
            marginBottom: theme.spacing(1.25),
        },

        "& .MuiDropzoneArea-icon": {
            color: theme.palette.grey[500],
        },
    },
    dropzone__preview_chip: {
        minWidth: 160,
        maxWidth: 340,
        backgroundColor: theme.palette.background.paper,
    },
}))

export default useStyles
