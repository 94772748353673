import { useState, useEffect, useContext } from "react"
import { Redirect, useParams } from "react-router-dom"

import { Title } from "../Typography"

import { RootContext } from "../../store/context"
import routes from "../../routes"

interface ProjectTitleProps {
    redirectOnParam: boolean // redirects if no project_number is query params
    redirectOnState: boolean // redirects on refreshes
    redirectOnParamNorState: boolean // redirects if no project_number AND no state data
    useLocalTitle: boolean
}

const ProjectTitle = ({
    redirectOnParam,
    redirectOnState,
    redirectOnParamNorState,
    useLocalTitle,
}: ProjectTitleProps) => {
    const { projectNumber } = useParams<{ [key: string]: string }>()

    const { state } = useContext(RootContext)
    const { selectedProject } = state.projects

    const [localTitle, setLocalTitle] = useState("")

    const title = selectedProject ? `${selectedProject.project_number} - ${selectedProject.project_long_name}` : ""

    /**
     * Save title locally, and use it if there is no title and useLocalTitle
     * is true.
     */
    useEffect(() => {
        if (title) {
            setLocalTitle(title)
        }
    }, [title])

    // redirect if project data not present
    if (redirectOnParamNorState) {
        if (!projectNumber && !selectedProject) {
            return <Redirect to={routes.home} />
        }
    } else {
        if (redirectOnState && !selectedProject) {
            return <Redirect to={routes.home} />
        }
        if (redirectOnParam && !projectNumber) {
            return <Redirect to={routes.home} />
        }
    }

    return <Title>Project {title || (useLocalTitle ? localTitle : title)}</Title>
}

ProjectTitle.defaultProps = {
    redirectOnParam: false,
    redirectOnState: false,
    redirectOnParamNorState: false,
    useLocalTitle: false,
}

export default ProjectTitle
