import { Types } from "../types"
import API from "../../api"
import { diff } from "../../tools"
import { LookupDataType } from "../types/lookupTypes"
import { DispatchNexus } from "../context"
import { isRowInserted } from "../../utils/tableUtils"

export async function fetchLookupTable(dispatch: DispatchNexus, tableId: string): Promise<boolean> {
    dispatch({
        type: Types.Lookup_FetchTable,
        payload: { selectedTableId: tableId },
    })

    try {
        const result = await API.get(`/lookup-tables/${tableId}`)
        const { rows, columns } = result.data

        dispatch({
            type: Types.Lookup_FetchTableSuccess,
            payload: { rows, columns },
        })
        return true
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Error fetching table data" },
        })
        return false
    }
}

export async function updateLookupData(
    dispatch: DispatchNexus,
    rows: LookupDataType[],
    initialRows: LookupDataType[],
    selectedTableId: string,
    primaryKey: string,
): Promise<boolean> {
    try {
        const formattedRows = rows.map((row) => {
            const newRow = { ...row }
            if (isRowInserted(newRow[primaryKey])) {
                newRow[primaryKey] = null
            }
            return newRow
        })

        const result = await API.put(`/lookup-tables/${selectedTableId}`, { rows: diff(formattedRows, initialRows) })

        if (result.success) {
            await fetchLookupTable(dispatch, selectedTableId)
            return true
        }
    } catch (error) {
        console.error(error)
        dispatch({
            type: Types.App_UpdateError,
            payload: { error: "Failed to update table data." },
        })
    }
    return false
}
