import propTypes from "prop-types"
import { Grid, Tooltip, TooltipProps, Typography } from "@mui/material"

import useStyles from "./styles"
import React from "react"

interface HtmlTooltipProps extends TooltipProps {
    disabled: boolean
    body: React.ReactNode
}

export default function HtmlTooltip({ body, children, className, disabled, title, ...other }: HtmlTooltipProps) {
    const { classes } = useStyles()

    const html = (
        <Grid>
            <Typography color="inherit" className={classes.text_label}>
                {title}
            </Typography>
            {body}
        </Grid>
    )

    return (
        <Tooltip
            arrow
            placement="bottom"
            title={!disabled ? html : ""}
            classes={{ tooltip: classes.tooltip }}
            className={className}
            {...other}
        >
            {children}
        </Tooltip>
    )
}

HtmlTooltip.propTypes = {
    body: propTypes.node,
    children: propTypes.node,
    className: propTypes.string,
    disabled: propTypes.bool,
    interactive: propTypes.bool,
    title: propTypes.string,

    placement: propTypes.string,
}

HtmlTooltip.defaultProps = {}
