import { makeStyles } from "tss-react/mui"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...spacing(theme),

    root: {
        paddingTop: theme.spacing(25),
        paddingBottom: theme.spacing(4),
    },
    title: {
        fontWeight: 500,
    },
    button: {
        alignSelf: "center",
        textAlign: "center",
    },
    flex_center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: theme.spacing(3.5),
    },

    version: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(2, 1.5),
        paddingRight: 0,
    },

    formControl: {
        marginBottom: theme.spacing(2),
        minWidth: 240,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    selectRoot: {
        height: theme.spacing(4),
    },
}))

export default useStyles
