import { makeStyles } from "tss-react/mui"
import inputStyles from "../../assets/inputStyles"
import spacing from "../../assets/spacing"

const useStyles = makeStyles()((theme) => ({
    ...inputStyles(theme),
    ...spacing(theme),

    label: {
        minWidth: 35,
        marginBottom: theme.spacing(1.25),
    },
    progress: {
        width: "100%",
    },
}))

export default useStyles
