import { Box } from "@mui/material"

import Autocomplete from "../../components/Fields/Autocomplete"
import { DatePickerCustom } from "../../components/Fields/DatePickerCustom"
import EmployeeField from "../../components/Fields/EmployeeField"
import RadioGroup from "../../components/Fields/RadioGroup"
import { Label } from "../../components/Typography"

import useMissions from "../../hooks/useMissions"
import { SensorType, PlatformType, MissionType } from "../../store/types/missionsTypes"
import { formatDate, parseDate, sortByField } from "../../tools"
import { teamRadioOptions } from "./model"

type EmployeeFieldType = {
    label: string
    field: string
    employeeRole: "pilots" | "operators" | "managers"
    required?: boolean
}

const sensorFields = [
    { label: "Primary Sensor", field: "sensor_id_1", required: true },
    { label: "Secondary Sensor", field: "sensor_id_2" },
]

const employeeFields: EmployeeFieldType[] = [
    { label: "Sensor Operator 1", field: "operator_1_id", employeeRole: "operators", required: true },
    { label: "Sensor Operator 2", field: "operator_2_id", employeeRole: "operators" },
    { label: "Pilot 1", field: "pilot_1_id", employeeRole: "pilots", required: true },
    { label: "Pilot 2", field: "pilot_2_id", employeeRole: "pilots" },
    { label: "Manager On Call", field: "manager_id", employeeRole: "managers", required: true },
]

export default function TeamIdentification() {
    const { missions, handleMissionDataChange, handleVendor, dispatch } = useMissions()
    const { selectedMission, isVendorFlow, sensorList, platformList, employeeList, vendorNameList, airportList } =
        missions
    const { sensors, project_sensors } = sensorList
    const { platforms, project_platforms } = platformList

    return (
        <Box>
            <Label>Sensors</Label>
            {sensorFields.map((sensor, idx) => (
                <Autocomplete
                    key={idx}
                    value={sensors.find((s: SensorType) => s.sensor_id === selectedMission[sensor.field as keyof MissionType]) || null}
                    options={sortByField(sensors, "sensor_id", project_sensors)}
                    groupBy={(opt: SensorType) =>
                        project_sensors.includes(opt?.sensor_id || -1) ? "Used in this Project" : "All Sensors"
                    }
                    getOptionLabel={(opt: SensorType) => `${opt.sensor_model} - ${opt.serial_number}`}
                    renderOption={(props, opt) =>
                        <li {...props} key={opt.sensor_id}>{opt.sensor_model} - {opt.serial_number}</li>}
                    onChange={(e, value) => handleMissionDataChange({ [sensor.field]: value?.sensor_id ?? null })}
                    TextFieldProps={{ helperText: sensor.required ? "Required" : null, ...sensor }}
                    gutterBottom={"md"}
                />
            ))}

            <Label gutterBottom="sm">Activity Date</Label>
            <DatePickerCustom
                label="Select"
                value={parseDate(selectedMission.flown_date)}
                onChange={(date) => {
                    handleMissionDataChange({ flown_date: formatDate(date) })
                }}
                helperText="Required"
                gutterBottom="sm"
            />

            <Label>Aircraft/Platform</Label>
            <Autocomplete
                TextFieldProps={{ label: "Select", helperText: !isVendorFlow && "Required" }}
                value={platforms.find((p) => p.platform_id === selectedMission.platform_id) || null}
                groupBy={(opt: PlatformType) =>
                    project_platforms.includes(opt.platform_id!) ? "Used in this Project" : "All Platforms"
                }
                options={sortByField(platforms, "platform_id", project_platforms)}
                getOptionLabel={(opt: PlatformType) => opt.platform_name ?? ""}
                renderOption={(props, opt) => <li {...props} key={opt.platform_id}>{opt.platform_name}</li>}
                onChange={(e, value) => handleMissionDataChange({ platform_id: value?.platform_id ?? null })}
                gutterBottom={!isVendorFlow ? "sm" : "md"}
                width="sm"
            />

            <Label>Team Members</Label>
            <RadioGroup
                value={teamRadioOptions.find((option) => option.value === isVendorFlow)?.id}
                options={teamRadioOptions}
                onChange={(option: typeof teamRadioOptions[0]) => handleVendor(option.value)}
            />
            {isVendorFlow && (
                <Autocomplete
                    TextFieldProps={{ label: "Team Name", helperText: "Required" }}
                    value={selectedMission.vendor_name || null}
                    options={vendorNameList}
                    onChange={(e, value) => handleMissionDataChange({ vendor_name: value ?? null })}
                    freeSolo
                    forcePopupIcon
                    gutterBottom="md"
                />
            )}
            {employeeFields.slice(isVendorFlow ? 4 : 0).map((employee, idx) => (
                <EmployeeField
                    key={idx}
                    employeeId={selectedMission[employee.field as keyof MissionType] as string}
                    employeeList={employeeList}
                    dispatch={dispatch}
                    {...employee}
                />
            ))}

            <Label>Base Airport</Label>
            <Autocomplete
                TextFieldProps={{
                    label: "Select",
                    helperText: !isVendorFlow && "Required",
                    inputProps: { sx: { textTransform: "uppercase" } },
                }}
                value={selectedMission.base_airport || null}
                options={sortByField(airportList, "inProject").map((ap) => ap.airport)}
                groupBy={(opt: string) =>
                    airportList.find((ap) => ap.airport === opt)?.inProject ? "Used in this Project" : "All Airports"
                }
                onChange={(e, value) => handleMissionDataChange({ base_airport: value?.toUpperCase() ?? null })}
                freeSolo
                forcePopupIcon
                maxLength={4}
                gutterBottom={!isVendorFlow ? "sm" : "md"}
            />
        </Box>
    )
}
