import { useContext, useEffect, useState } from "react"
import { Redirect, useParams } from "react-router-dom"

import { Grid, Tooltip } from "@mui/material"

import TeamIdentification from "./TeamIdentification"
import Notes from "./MissionNotes"
import FlightData from "./FlightData"
import FlightlineDetails from "./FlightlineDetails"

import AlertModal from "../../components/Modals/AlertModal"
import LoadingButton from "../../components/LoadingButton"
import ProjectTitle from "../../components/ProjectTitle"
import Select from "../../components/Fields/Select"
import StepperForm from "../../components/StepperForm"
import { Body } from "../../components/Typography"

import {
    deleteMission,
    fetchAirportList,
    fetchEmployeeList,
    fetchFlightlineEvents,
    fetchFlightplanList,
    fetchFlightPlannedLinesForMission,
    fetchMission,
    fetchPlatformList,
    fetchSensorList,
    fetchVendorNameList,
    updateMission,
} from "../../store/actions/missionsActions"
import useAuth from "../../hooks/useAuth"
import useMissions from "../../hooks/useMissions"
import useToggle from "../../hooks/useToggle"
import { RootContext } from "../../store/context"
import { Types } from "../../store/types"
import { FlightplanStatuses } from "../../store/types/flightplansTypes"
import { fetchProject } from "../../store/actions/projectsActions"
import { formatDate } from "../../tools"
import routes, { ConfirmationRoutes } from "../../routes"
import { getMissionValidatorModel } from "./model"
import useStyles from "./styles"
import { MissionActiveFlow } from "../../utils/validateFlightlineEvents"
import { MissionType } from "../../store/types/missionsTypes"

export enum MissionStep {
    teamIdentification = "teamIdentification",
    notes = "notes",
    flightData = "flightData",
    flightlineDetails = "flightlineDetails",
}

const initialSteps = [
    { label: "Team Identification", key: MissionStep.teamIdentification },
    { label: "Mission Notes", key: MissionStep.notes },
    { label: "Flight Data", key: MissionStep.flightData },
    { label: "Flightline Details", key: MissionStep.flightlineDetails },
]

const getStepContent = (activeStep: number) => {
    switch (activeStep) {
        case 0:
            return <TeamIdentification />
        case 1:
            return <Notes />
        case 2:
            return <FlightData />
        case 3:
            return <FlightlineDetails />
        default:
            return "Error loading content"
    }
}

export default function MissionEditor({ history }: any) {
    const { classes, cx } = useStyles()
    const { projectNumber, missionFlow, missionId } = useParams<{ [key: string]: string }>()
    const { state, dispatch } = useContext(RootContext)
    const { selectedProject } = state.projects
    const { ToolbarOptions, checkToolbarAccess } = useAuth()
    const { missions, isNoFlight, isNoAcquisition, isNoFile, isFullFlight, handleMissionDataChange } = useMissions()
    const {
        selectedMission,
        selectedMissionId,
        flightlineEvents,
        matchingExpression,
        isVendorFlow,
        isDeleting,
        selectedFlightplans,
        parsedMissions,
        activeFlow,
        warnings,
        missionsError,
    } = missions

    const isEditFlow = missionFlow === "edit"
    const isRedirecting = !selectedProject // occurs on refresh
    const isDeleteDisabled = !checkToolbarAccess([ToolbarOptions.Edit_Blog_Entries, ToolbarOptions.Mission_Admin])
    const isFlightplanCompleted = !!(selectedFlightplans.length) && (selectedFlightplans[0].status === FlightplanStatuses.Complete)

    const [selectedParsedMissionIndex, setSelectedParsedMissionIndex] = useState(0)
    const [steps, setSteps] = useState(initialSteps)
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [deleteModalOpen, toggleDeleteModalOpen] = useToggle()

    const selectedParsedMission = parsedMissions[selectedParsedMissionIndex]

    const setNoFlightSteps = () => {
        setSteps(steps.slice(0, 2))
    }
    const setNoAcquisitionSteps = () => {
        setSteps(steps.slice(0, 3))
    }

    // fetch data
    useEffect(() => {
        dispatch({ type: Types.App_ClearError })

        if (!isRedirecting) {
            // Has selectedProject -> not refreshed

            const reqs = []
            // get lists
            if (projectNumber) {
                if (!selectedProject?.project_number) {
                    reqs.push(fetchProject(dispatch, projectNumber))
                }
                reqs.push(fetchSensorList(dispatch, projectNumber!))
                reqs.push(fetchPlatformList(dispatch, projectNumber!))
                reqs.push(fetchEmployeeList(dispatch, projectNumber!))
                reqs.push(fetchVendorNameList(dispatch))
                reqs.push(fetchAirportList(dispatch, projectNumber!))
                reqs.push(fetchFlightplanList(dispatch, projectNumber!))
            }
            // edit
            if (isEditFlow) {
                reqs.push(fetchMission(dispatch, missionId!))
                reqs.push(fetchFlightlineEvents(dispatch, missionId!))
            }

            // flight types
            if (isNoFlight) {
                setNoFlightSteps()
            }
            if (isNoAcquisition) {
                setNoAcquisitionSteps()
            }
            if (!isFullFlight) {
                handleMissionDataChange({ flown_date: !selectedMission.flown_date ? formatDate() : null })
            }

            // If inside !isRefreshing block then doesn't cause update on unmount error
            // loading all flightPlanned lines
            Promise.all(reqs).then(() => {
                setLoading(false)
            })
        }// end of !isRefreshing block
    }, []) // eslint-disable-line

    // init after loading done
    useEffect(() => {
        if (!loading) {
            // parsed missions index found by display name
            // saved before making further changes to selectedMission
            if (parsedMissions.length) {
                handleMissionSelect(selectedParsedMission.display_name)
            }

            // edit init
            if (isEditFlow) {
                let flow = MissionActiveFlow.FULL_FLIGHT

                if (selectedMission.flight_num === null) {
                    setNoFlightSteps()
                    flow = MissionActiveFlow.NO_FLIGHT
                } else if (selectedMission.source_file === null) {
                    if (flightlineEvents.length === 0) {
                        setNoAcquisitionSteps()
                        flow = MissionActiveFlow.NO_ACQUISITION
                    } else {
                        flow = MissionActiveFlow.NO_FILE
                    }
                }

                dispatch({
                    type: Types.Missions_SelectActiveFlow,
                    payload: { activeFlow: flow },
                })
            }

            // Fetch new flightPlannedLines for all IDs in flightplanList (in missions or flightplans)
            // then saves to state
            const planList = state.missions.flightplanList.length ? state.missions.flightplanList : state.flightplans.flightplanList
            const flightPlanIds = planList.flatMap(fp => fp.flight_plan_id ?? [])
            fetchFlightPlannedLinesForMission(dispatch, flightPlanIds)
        }
    }, [loading]) // eslint-disable-line

    /**
     * Navigate to confirmation page after saving mission name.
     */
    const handleConfirmation = () => {
        dispatch({
            type: Types.App_UpdateConfirmationData,
            payload: {
                entityName: `${selectedMission.mission_name} `,
            },
        })

        if (isEditFlow) {
            history.push(`${routes.confirm}/${ConfirmationRoutes.MISSION_EDITED}`)
        } else {
            history.push(`${routes.confirm}/${ConfirmationRoutes.MISSION_SUBMITTED}`)
        }
    }

    /**
     * Move back or forward one step. If out of forward steps,
     * attempt to submit mission.
     */
    const handleStepChange = async (newStep: number) => {
        if (newStep === steps.length) {
            setSubmitting(true)
            const newMission: MissionType = { ...selectedMission, project_number: projectNumber }

            const success = await updateMission(
                dispatch,
                newMission,
                flightlineEvents,
                isNoFile,
                isVendorFlow,
                isNoFlight,
            )
            setSubmitting(false)
            if (success) {
                handleConfirmation()
            }
        } else if (newStep === -1) {
            history.goBack()
        } else {
            setActiveStep(newStep)
        }
    }

    const handleErrorClose = () => {
        dispatch({ type: Types.Missions_ClearError })
    }

    /**
     * Select mission.
     */
    const handleMissionSelect = (selection: string) => {
        const index = parsedMissions.findIndex((m) => m.display_name === selection)
        setSelectedParsedMissionIndex(index)

        dispatch({
            type: Types.Missions_SelectParsedMission,
            payload: { selectedMission: parsedMissions[index] },
        })
    }

    const handleDelete = async () => {
        toggleDeleteModalOpen()

        if (selectedMissionId) {
            const success = await deleteMission(dispatch, selectedMissionId)
            if (success) {
                history.push(routes.home)
            }
        }
    }

    if (isRedirecting) {
        return <Redirect to={routes.home} />
    }

    return (
        <StepperForm
            steps={steps}
            activeStep={activeStep}
            loading={loading}
            submitting={submitting}
            warnings={warnings}
            onStepChange={handleStepChange}
            onErrorClose={handleErrorClose}
            validationProps={{
                dependencies: [selectedMission, flightlineEvents, matchingExpression, activeFlow, isVendorFlow],
                model: getMissionValidatorModel(selectedMission, flightlineEvents, activeFlow, isVendorFlow),
            }}
            error={missionsError}
            header={
                <Grid>
                    <ProjectTitle />

                    {!loading && !isEditFlow && parsedMissions.length > 1 && (
                        <Grid className={classes.gutterBottom__lg}>
                            <Body>The uploaded files contained multiple missions. Select which channel or mission you'd like to import.</Body>

                            <Select
                                id="select-channel"
                                value={selectedParsedMission ? selectedParsedMission.display_name : ""}
                                options={parsedMissions.map((m) => m.display_name)}
                                onChange={(e: any) => handleMissionSelect(e.target.value || null)}
                                width="md"
                                disableDefaultValue
                            />
                        </Grid>
                    )}
                    <AlertModal
                        danger
                        open={deleteModalOpen}
                        pending={isDeleting}
                        title="Delete Mission?"
                        onConfirm={handleDelete}
                        onClose={toggleDeleteModalOpen}
                    >
                        Are you sure you want to delete this mission and its associated flightline events? This action cannot be
                        reversed.
                        {isFlightplanCompleted &&
                            <>
                                <br />
                                <br />
                                <span>
                                    Flightplan <strong>{selectedFlightplans[0]?.flight_plan_name}</strong> utilized by this mission currently has
                                    the Complete status. Status will be reset to Active for this flightplan.
                                </span>
                            </>
                        }
                    </AlertModal>
                </Grid>
            }
            disableSubmit={isDeleting || selectedMission.flight_notes?.split("---")[0] === ""}
            secondaryAction={
                isEditFlow && (
                    <Tooltip arrow title={isDeleteDisabled ? "Missions can be deleted by Acquisition Managers" : ""}>
                        <div>
                            <LoadingButton
                                disabled={isDeleteDisabled || submitting}
                                onClick={toggleDeleteModalOpen}
                                pending={isDeleting}
                                variant="outlined"
                                color="primary"
                                size="large"
                                className={cx(classes.btn_delete)}
                            >
                                Delete
                            </LoadingButton>
                        </div>
                    </Tooltip>
                )
            }
        >
            {getStepContent(activeStep)}
        </StepperForm>
    )
}
