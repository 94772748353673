import propTypes from "prop-types"

import { Menu, MenuItem, MenuProps, Tooltip, Typography } from "@mui/material"

import { OptionType } from "../../store/types/appTypes"
import useStyles from "./styles"

interface CustomMenuProps extends MenuProps {
    animate?: boolean
    onChange: any
    onClose?: any
    open: boolean
    options: OptionType[]
    renderOption?: (arg0: OptionType) => string
}

const CustomMenu = ({ animate, onChange, onClose, open, options, renderOption, ...other }: CustomMenuProps) => {
    const { classes } = useStyles()

    const handleChange = (option: OptionType, disabled: boolean) => {
        // disabled does not automatically disable click events on MenuItem
        if (!disabled) {
            onChange(option)
        }
    }

    return (
        <Menu onClose={onClose} open={open} transitionDuration={animate ? "auto" : 0} {...other}>
            {options.map((option: OptionType, index: number) => {
                let label = ""
                let tooltip = ""
                let disabled = false

                if (typeof option !== "string") {
                    label = option.label
                    tooltip = option.tooltip || ""
                    disabled = Boolean(option.disabled)
                }

                const item = (
                    <MenuItem
                        key={index}
                        disabled={disabled}
                        onClick={() => handleChange(option, disabled)}
                        classes={{ root: classes.menu_item }}
                    >
                        {renderOption ? renderOption(option) : label || option}
                    </MenuItem>
                )

                if (tooltip) {
                    return (
                        <Tooltip
                            key={index}
                            title={<Typography variant="body2">{tooltip}</Typography>}
                            arrow
                        >
                            {item}
                        </Tooltip>
                    )
                }

                return item
            })}
        </Menu>
    )
}

CustomMenu.propTypes = {
    animate: propTypes.bool,
    onChange: propTypes.func,
    onClose: propTypes.func,
    open: propTypes.bool,
    options: propTypes.arrayOf(propTypes.any),
    renderOption: propTypes.func,

    anchorEl: propTypes.any,
    elevation: propTypes.number,
    transformOrigin: propTypes.shape({}),
}

CustomMenu.defaultProps = {
    options: [],
}

export default CustomMenu
